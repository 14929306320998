import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import { deleteAttribute } from "../../../../../redux/actions/Ecommerce/AttributeAction";
import SuccessAlert from "../../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../../components/general/ErrorAlert";

class index extends Component {

  deleteAttribute = (e) => {
    e.preventDefault();
    try {
      let response = this.props.deleteAttribute(this.props.attr._id);
      //console.log(response);
      SuccessAlert("success", "Sucessfully Deleted");

      //redirect to profile attribute if came from edit attribute page
      if (this.props.history) this.props.history.push("/crm/profile_attribute");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };
  render() {
    console.log(this.props);
    return (
      <div>
        <View
          modal={this.props.modal}
          attr={this.props.attr}
          toggle={this.props.toggle}
          onSumbit={this.deleteAttribute}
        />
      </div>
    );
  }
}

export default connect(null, { deleteAttribute })(index);
