import {
    ADD_ROLE,
    EDIT_ROLE,
    DELETE_ROLE,
    GET_ROLES,
    FIND_ROLE,
    GET_PERMISSIONS,
  } from "./types";
  import fetchAPIClass from "../fetchAPI";
  import { restfulUrl, API_URL , AUTH_URL} from "context/url";
  

  export const getRoles = () => (dispatch) => {
    let url = `${AUTH_URL}/roles/all`;
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_ROLES);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };

  export const getPermissions = () => (dispatch) => {
    let url = `${AUTH_URL}/permissions/all`;
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_PERMISSIONS);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  
  
  export const addRole = (inputs) => (dispatch) => {
    let requestBody = inputs;
    let url = `${AUTH_URL}/roles`;
    console.log(requestBody);
    try {
      const api = new fetchAPIClass(url, "POST", requestBody, ADD_ROLE);
      let response = api.fetchAPI(dispatch, inputs);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const deleteRole = (input) => (dispatch) => {
    let url = `${AUTH_URL}/roles/${input}`;
    try {
      const api = new fetchAPIClass(url, "DELETE", null, DELETE_ROLE);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const editRole = (inputs, RoleId) => (dispatch) => {
    let requestBody = inputs;
    let url = `${AUTH_URL}/roles`;
  
    try {
      const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_ROLE);
      let response = api.fetchAPI(dispatch, inputs);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  /*export const findRole = (RoleId) => (dispatch) => {
    let url = `${AUTH_URL}/roles/find/${RoleId}`;
    let data = "";
    try {
      const api = new fetchAPIClass(url, "GET", null, FIND_ROLE);
      api.fetchAPI(dispatch, data);
    } catch (err) {
      console.log(err);
    }
  };*/
  