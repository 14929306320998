import React from "react";
import ReactSVG from "react-svg";

const StepNavigator = (props) => {
  const handlePrev = (step) => {
    return (
      <button className="btn prev" onClick={() => props.handleClick(step)}>
        <ReactSVG src="/assets/img/svg/left-arrow.svg" />
        {"Prev"}
      </button>
    );
  };

  const handleNext = (step) => {
    return (
      <button className="btn next" onClick={() => props.handleClick(step)}>
        <ReactSVG src="/assets/img/svg/right-arrow.svg" />
        {"Next"}
      </button>
    );
  };

  return (
    <div className="steps-navigation">
      {props.currentStep !== 1 && handlePrev(props.currentStep - 1)}

      {props.currentStep !== props.totalSteps &&
        handleNext(props.currentStep + 1)}
      {props.currentStep === props.totalSteps && (
        <button className="btn next" onClick={props.handleSubmit}>
          {"Save"}
        </button>
      )}
    </div>
  );
};

export default StepNavigator;
