import {
  ADD_ATTRIBUTE,
  EDIT_ATTRIBUTE,
  GET_ATTRIBUTES,
  GET_ONE_LIST_ATTRIBUTES,
  FIND_ATTRIBUTE,
  DELETE_ATTRIBUTE,
} from "./types";
import  fetchAPIClass  from "./fetchAPI";
import { graphqlUrl, restfulUrl } from "../../../src/context/url";
//import fetchData from "./UseFetch"
import fetchData from "../../fetchApi/fetchData";

export const addAttribute = (inputs) => (dispatch) => {
  console.log("InSide Add attribute Action");
  let requestBody = `
  mutation {
    addAttribute(${inputs}) {
      label
    }
  }
  `;
  try {
    const api = new fetchAPIClass(
      graphqlUrl,
      "POST",
      JSON.stringify({ query: requestBody }),
      ADD_ATTRIBUTE
    );
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
  /* try {
    let response = fetchData(graphqlUrl, "POST", JSON.stringify({ query: requestBody }), ADD_ATTRIBUTE, dispatch)
    console.log(response)

  } catch (error) {
    console.log(error)
  }*/
};

export const editAttribute = (inputs) => (dispatch) => {
  let requestBody = `
  mutation {
    editAttribute(${inputs}) {
      label
    }
  }
  `;
  try {
    const api = new fetchAPIClass(
      graphqlUrl,
      "POST",
      JSON.stringify({ query: requestBody }),
      EDIT_ATTRIBUTE
    );
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
/**
 * This Action for Get one array of attribute >> flatten Concept
 * @param {*} businessId
 */
export const getOneListAttribute = (businessId) => (dispatch) => {
  console.log("Inside Get List Attribute");
  let url = `${restfulUrl}/attribute/getListAttribute/${businessId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_ONE_LIST_ATTRIBUTES);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getAttributes = (businessId) => (dispatch) => {
  let requestBody = `{ 
    businessAccount(${businessId}){
    attributes{
      label
      inner_name
      id
      is_default
      arabic_label
      attributes{
        label
        inner_name
        arabic_label
        system_name
        desc
        is_required
        in_form
        to_search
        last_update
        updated_by
        path
        operator{
          value
          label
        }
        type
        class
        data{
          is_default
          arabic_label
          value
          label
        }
        
      }
    }
  }
}`;
  try {
    const api = new fetchAPIClass(
      graphqlUrl,
      "POST",
      JSON.stringify({ query: requestBody }),
      GET_ATTRIBUTES
    );
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }

  /* try {
    let response = fetchData(graphqlUrl, "POST", JSON.stringify({ query: requestBody }), GET_ATTRIBUTES, dispatch)
    console.log(response)
    /*.then(res => res.json())
      .then(data =>
        dispatch({
          type: GET_ATTRIBUTES,
          payload: data
        })
      )
      .catch(err => console.log({ message: err }))*/

  /* } catch (error) {
    console.log(error)
  }*/
};

export const deleteAttribute = (inputs) => (dispatch) => {
  let requestBody = `
  mutation {
    deleteAttribute(${inputs}) {
      inner_name
    }
  }`;
  try {
    const api = new fetchAPIClass(
      graphqlUrl,
      "POST",
      JSON.stringify({ query: requestBody }),
      DELETE_ATTRIBUTE
    );
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const findAttribute = (businessID, attrID) => (dispatch) => {
  let requestBody = `
    {
      getAttribute(${businessID}){
        attribute(${attrID}){
          label
          arabic_label
          in_form
          inner_name
          data{
            value
            label
            arabic_label
            is_default
          }
        }
     }
    }  
    `;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(
      graphqlUrl,
      "POST",
      JSON.stringify({ query: requestBody }),
      FIND_ATTRIBUTE
    );
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
