import React, { useState } from "react";
import ModalComponent from "../../../../components/general/Modal";
import FormComponent from "../../../../components/general/FormController";
import { Button, Form, ModalFooter, Alert } from "reactstrap";

const View = (props) => {
  const { onChange, data, modal, toggle, group, errors, onValidate } = props;
  return (
    <div>
      <ModalComponent
        title="Update Group"
        toggle={() => toggle(group)}
        isOpen={modal}
      >
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="text"
            name="label"
            label="Label"
            value={data.label}
            slug={false}
            updateState={onChange}
          />
          <FormComponent
            type="text"
            name="arabic_label"
            label="Arabic Label"
            value={data.arabic_label}
            updateState={onChange}
          />
          <FormComponent
            type="text"
            name="inner_name"
            label="Inernal Name"
            value={data.inner_name}
            onChange={onChange}
            disabled={true}
          />
          <ModalFooter>
            {errors && <Alert color="danger"> {errors.required} </Alert>}
            <Button
              //type="submit"
              className="btn btn-default"
              onClick={() => onValidate(group)}
            >
              {"Update"}
            </Button>
            <Button className="btn btn-secondary" onClick={() => toggle(group)}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </div>
  );
};
export default View;
