import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";
import _ from "lodash";

import {
  deleteCategory,
  editCategory,
  getCategories,
} from "../../../../redux/actions/Ecommerce/CategoryAction";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import {getProductsCategories} from "redux/actions/Ecommerce/ProductAction"
import {getFilterdAttributes} from "redux/actions/Ecommerce/AttributeAction"
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
//import { getGroupProductAttributes , getGroups,findGroupProductAttributes} from "../../../../redux/actions/Ecommerce/ProductAttributeAction";
import {
  getGroups,
  findGroupProductAttributes,
} from "../../../../redux/actions/Ecommerce/GroupAction";
import { categoryHandler } from "../handler";

const Index = (props) => {
  let category = props.location.state.item;
  category.seo = !_.isEmpty(category.seo) ? category.seo : {};
  category.status = _.toLower(category.status) === "active" ? true : false;

  let [state, setState] = useState(category);
  let [productList, setProductList] = useState([]);

  useEffect(() => {
    // GET Group Attribute List
    props.getGroups();
    props.getCatalogs();
    props.getCategories();
    //props.getFilterdAttributes("ecommerce")
    //props.getProductsCategories(JSON.stringify({categoryId:`${state._id}`}))
  }, []);

  useEffect(() => {
    props.getFilterdAttributes("ecommerce")
    props.getProductsCategories(JSON.stringify({categoryId:`${state._id}`}))
  }, []);

  useEffect(() => {}, [props.category]);

  useEffect(() => {
    if (category) {
      setProductList(category.products);
      props.getProductsCategories(JSON.stringify({categoryId:`${state._id}`}))
    }
  }, [state]);

  const updateSelectedCategory = (state) => setState(state); // to set selected category in state for editing information

  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "sku",
      align: "center",
      title: "sku",
    },
    {
      type: "name_en",
      sortable: true,
      title: "Name",
    },
    {
      type: "name_ar",
      title: "Name Ar",
    },
    {
      type: "external_id",
      title: "External Id",
    },
    {
      type: "type",
      title: "Type",
      align: "center",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
  ];

  // console.log(productList)

  const deleteCategory = (categoryId) => {
    try {
      props.deleteCategory(categoryId);
      SuccessAlert("success", "Sucessfully deleted");
      props.history.push("/category");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const updateState = (input) => {
    console.log("Input", input);
    setState({ ...state, [_.keys(input)]: _.get(input, `${_.keys(input)}`) });
  };

  const editCategory = () => {
    try {
      state.status = state.status === true ? "active" : "inactive";
      state.parent = _.map(state.parent, p => typeof p === "string"? p :`${p._id}`)
      console.log(JSON.stringify(state));
      let response = props.editCategory(JSON.stringify(state), state._id);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log("State", state);
  //console.log("Group Attributes", props.groupAttributes);
  return props.catalogs && props.categories && props.products? (
    <div>
      {" "}
      <View
        {...state}
        data={state}
        {...props}
        headerList={headerList}
        deleteCategory={deleteCategory}
        editCategory={editCategory}
        onChange={updateState}
        updateSelectedCategory={updateSelectedCategory}
      />{" "}
    </div>
  ) : (
    <div></div>
  );
};

const mapStateToProps = (state) => ({
  category: state.categories.item,
  groupAttributes: state.ecommerceGroups.items,
  catalogs: state.catalogs.items,
  categories: state.categories.items,
  products: state.products.items,
  attributes: state.ecommerceAttributes.items,
  //groupAttribute: state.ecommerceGroups.item,
});

export default connect(mapStateToProps, {
  deleteCategory,
  editCategory,
  getGroups,
  getCatalogs,
  getCategories,
  getProductsCategories,
  getFilterdAttributes
  //findGroupProductAttributes
})(Index);
