import React from "react";
import Table from "../../../components/general/Table";
import Card from "../../../components/general/Card";
import HeaderActions from "../../../components/general/HeaderBtns";

const View = (props) => {
  let { promotions, deletePromotion, handleCreate } = props;
  console.log(props);

    const headerList = [
        {
            type: "_id",
            isKey: true,
            hidden: true,
            title: "ID",
        },
        {
            sortable: true,
            type: "name_en",
            align: "center",
            title: "Promotion Name",
        },
        {
            sortable: true,
            type: "typeName",
            align: "center",
            title: "Promotion Type",
        },
        {
            sortable: true,
            type: "cvr",
            align: "center",
            title: "CVR",
        },
        {
            sortable: true,
            type: "revenu",
            align: "center",
            title: "Revenu",
        },
        {
            sortable: true,
            type: "increase",// TODO: CHANGE THIS
            align: "center",
            title: "% increase",
        },
        {
            sortable: true,
            type: "priority",
            align: "center",
            title: "Priority",
        },
        {
            type: "start_date",
            sortable: true,
            title: "Start Date",
            align: "center",
        },
        {
            type: "end_date",
            sortable: true,
            title: "End Date",
            align: "center",
        },
        {
            sortable: true,
            type: "status",
            align: "center",
            title: "Status",
        },
        {
            type: "action",
            sortable: true,
            align: "center",
            export: false,
            title: "Action",
        },
    ];
  return (
    <>
      <Card>
        <HeaderActions
          createAction={{ title: "Create", onClick: handleCreate }}
          importAction={{ title: "Import", onClick: () => {} }}
          ActionList={{ title: "Action", list: "Delete productation" }}
        />
        <div className="contact-table">
          <Table
            data={promotions}
            isSelectRow={true}
            EDIT_URL={"ecommerce/edit_promotion"}
            deleteAction={deletePromotion}
            headers={headerList}
            {...props}
          />
        </div>
        <div className="verified-container m-t-10">
          <ul className="list-unstyled">
            <li>
              <i className="fa fa-circle m-r-5"></i>
              {
                "Draft product: The product is not saved and can't use with any campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 active"></i>
              {
                "Active product: The product is active and currently used with active campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 inactive"></i>
              {
                "Inactive product: The product is inactive and currently not used with active campaign."
              }
            </li>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default View;
