import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getBrands,
  deleteBrand,
} from "../../../redux/actions/Ecommerce/BrandAction";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";
import View from "./View";

const Index = (props) => {
  useEffect(() => {
    props.getBrands();
  }, [props.brands]);

  const deleteBrand = (brandId) => {
    try {
      let response = props.deleteBrand(brandId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    props.history.push("/brand");
  };

  return (
    <div>
      {props.brands && (
        <View
          {...props}
          deleteBrand={deleteBrand}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  brands: state.brands.items,
});

export default connect(mapStateToProps, {
  getBrands,
  deleteBrand,
})(Index);
