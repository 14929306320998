import React, { Component } from "react";
import { connect } from "react-redux";
import View from "./View";
import GroupList from "./group-list";
import ContactSummary from "./components/ContactSummary";
import {
  getCustomerAttributes,
  editCustomerAttributes,
} from "../../../../redux/actions/CustomerAttributesActions";
import { getGroups } from "redux/actions/Ecommerce/GroupAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import { Form } from "reactstrap";
import { getCustomerTypes } from "redux/actions/settings/customerTypeAction";
class index extends Component {
  state = {
    // customerAttributes: this.props.getCustomerAttributes || {},
    contact_status: "",
    contact_id: this.props.location.state.id,
    addresses: this.props.location.state.item.addresses,
    customer_type: "",
    date_from: "",
    date_to: "",
    ...this.props.location.state.item
  };

  componentWillMount() {
    const customerId = this.state.contact_id;
    const businessId = "5cc2f00577ebc157b4f1c64c";
    this.props.getCustomerAttributes(customerId, businessId);
    this.props.getCustomerTypes();
  }

  componentWillReceiveProps(nextProps) {
    const customerAttributes = nextProps.customerAttributes;
    if (customerAttributes && customerAttributes[0]) {
      this.setState({
        contact_status: false,
        customer_type: this.props.location.state.item.customer_type || null,
        date_from: "",
        date_to: "",
        ...this.props.location.state.item
      });
    }

    if (customerAttributes) {
      customerAttributes.forEach((customerAttribute) => {
        customerAttribute.attributes.forEach((attribute) => {
          const value = attribute.value;
          const name = attribute.system_name;
          console.log("inside componentWillReceiveProps: ");
          console.log("name: ", name, ", and value: ", value);

          this.setState({
            // customerAttributes: nextProps.customerAttributes,
            [name]: value,
            ...this.state
          });
        });
      });
    }
  }

  getContactDetails = () => {
    return JSON.stringify(this.state);
  };

  onChange = (input) => {
    this.setState(input);
  };

  editAttributes = (e) => {
    e.preventDefault();
    console.log("On Submit custom attributes edit = ");
    const inputs = this.getContactDetails();
    console.log(inputs);

    try {
      const inputs = this.getContactDetails();
      console.log("Current State = ");
      console.log(this.state);

      this.props.editCustomerAttributes(this.state.contact_id, inputs);
      SuccessAlert("success", "Contact details has been modified successfully");
      // this.props.history.goBack()
      // this.props.history.push('/contacts');
    } catch (error) {
      ErrorAlert("error", `Something went wrong! ${error}`);
    }
  };
  render() {
    console.log("Address", this.state.addresses);
    return (
      <div>
        <Form onSubmit={this.editAttributes}>
          <View
            onChange={this.onChange}
            data={this.state}
            customerAttributes={this.props.customerAttributes}
            customerTypes={this.props.customerTypes}
          >
            <div className="row profile-component">
              <ContactSummary />
            </div>

            <GroupList
              customerAttributes={this.props.customerAttributes}
              onChange={this.onChange}
              data={this.state}
            />
          </View>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerAttributes: state.customerAttributes.items,
  groups: state.group.items,
  customerTypes: state.customerTypes.items,
});

export default connect(mapStateToProps, {
  getGroups,
  getCustomerAttributes,
  editCustomerAttributes,
  getCustomerTypes,
})(index);
