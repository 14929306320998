import React, { useEffect, PropTypes } from "react";
import { connect } from "react-redux";
import {
  getCategories,
  deleteCategory,
} from "../../../redux/actions/Ecommerce/CategoryAction";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
// import Delete from "./Delete"
// import DeleteMaster from "./DeleteMaster"
// import AddCategory from "./AddCategory";
import View from "./View";

const Index = (props) => {
  useEffect(() => {
    // Get categories list
    props.getCategories();
    props.getCatalogs()
  }, [props.categories]);

  console.log(props.categories);
  const deleteCategory = (categoryId) => {
    try {
      let response = props.deleteCategory(categoryId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault().props.history.push("/category");
  };

  return (
    <div>
   
      {props.categories && (
        <View
          {...props}
          deleteCategory={deleteCategory}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

Index.propTypes = {};

const mapStateToProps = (state) => ({
  categories: state.categories.items,
  catalogs: state.catalogs.items,
});

export default connect(mapStateToProps, { getCategories, getCatalogs, deleteCategory })(
  Index
);
