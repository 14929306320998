//import React, { useState, useEffect, useContext } from "react";
import React, { useState } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addBranch } from "redux/actions/Ecommerce/BranchAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
  let [state, setState] = useState({
    business_id: "5cc2f00577ebc157b4f1c64c",
    name_en: "",
    name_ar: "",
    location: "",
    type: "",
    description_ar: "",
    description_en: "",
    delivery_options: [],
    errors: {
      nameError: "",
    },
  });

  let [modal, setModal] = useState(false);

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const reset = () => {
    setState(state);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = state;

    if (inputs.name_en === "" || inputs.name_ar === "") {
      errors.nameError = "Sorry, 'Name' in both languages are required!";
    }

    if (errors.nameError === "") {
      addBranch(e);
    } else {
      setState({ errors: errors });
    }
  };

  const addBranch = (e) => {
    e.preventDefault();
    console.log("addBranch", state);
    let branch = `{
      "business_id": "${state.business_id}",
      "name_ar": "${state.name_ar}",
      "name_en": "${state.name_en}",
      "location": "${state.location}",
      "type": "${state.type}",
      "description_ar": "${state.description_ar}",
      "description_en": "${state.description_en}",
      "delivery_options": "${state.delivery_options}"
    }`;

    console.log(branch);

    try {
      props.addBranch(branch);
      SuccessAlert("success", "Sucessfully Added");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    reset();
    toggle();
  };

  return (
    <View
      data={state}
      onChange={onChange}
      onSumbit={addBranch}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
    />
  );
};

const mapStateToProps = (state) => ({
  branches: state.branches.items,
});

export default connect(mapStateToProps, { addBranch })(Index);
