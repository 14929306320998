export const attributeTypes = [
  { label: "Text", value: "text", case: 0 },
  { label: "Textarea", value: "textarea", case: 0 },
  { label: "Integer Number", value: "integer", case: 0 },
  { label: "Decimal Number", value: "decimal", case: 0 },
  { label: "Boolean", value: "boolean", case: 0 },
  { label: "Phone", value: "phone", case: 0 },
  { label: "Email", value: "email", case: 0 },
  { label: "Password", value: "password", case: 0 },
  { label: "URL", value: "url", case: 0 },
  { label: "Date", value: "date", case: 0 },
  { label: "Single Checkbox", value: "singleCheckbox", case: 1 },
  { label: "Dropdown Select", value: "select", case: 2 },
  { label: "Radio Button", value: "radio", case: 2 },
  { label: "Group Checkbox", value: "groupCheckbox", case: 3 },
  { label: "File", value: "file", case: 0 },
];

export const attributeEcomTypes = [
  { label: "Text", value: "text", case: 0 },
  { label: "Textarea", value: "textarea", case: 0 },
  { label: "Integer Number", value: "integer", case: 0 },
  { label: "Decimal Number", value: "decimal", case: 0 },
  { label: "Boolean", value: "boolean", case: 0 },
  { label: "Phone", value: "phone", case: 0 },
  { label: "Email", value: "email", case: 0 },
  { label: "Password", value: "password", case: 0 },
  { label: "URL", value: "url", case: 0 },
  { label: "Date", value: "date", case: 0 },
  { label: "Single Checkbox", value: "singleCheckbox", case: 1 },
  { label: "Dropdown Select", value: "select", case: 2 },
  { label: "Radio Button", value: "radio", case: 2 },
  { label: "Group Checkbox", value: "groupCheckbox", case: 3 },
  { label: "File", value: "file", case: 0 },
  { label: "Measurement", value: "measurement", case: 2 },
];
