import {
  ADD_BRANCH,
  EDIT_BRANCH,
  DELETE_BRANCH,
  GET_BRANCHES,
  FIND_BRANCH,
} from "../../actions/Ecommerce/types"

const initialState = {
  item: null,
  items: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_BRANCH:
    case EDIT_BRANCH:
    case FIND_BRANCH:
    case DELETE_BRANCH:
      return {
        ...state,
        item: action.payload,
      }
    case GET_BRANCHES:
      return {
        ...state,
        items: action.payload,
      }
    default:
      return state
  }
}
