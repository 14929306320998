import React from "react";
import Select from "react-select";
import { FormGroup, Label, Input, CustomInput, FormFeedback } from "reactstrap";
import DatePicker from "react-datepicker";
import Tooltip from "./Tooltip";
import moment from "moment";
import {
  stringToSelectObject,
  arrayToMultiselectObject,
} from "../../helpers/general-functions";
import { required } from "../Validation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css"; // needed for styling the datepicker component

function FormComponent(props) {
  const attr = () => {
    let inputFiled = "";
    switch (props.type) {
      case "text":
        inputFiled = (
          <>
            <Input
              // invalid = {/*props.required &&*/ (!props.value) || (props.value.length < 3) ? true : false}
              type="text"
              name={props.name}
              onChange={(input) =>
                props.onChange(
                  input,
                  props.slug,
                  props.target,
                  props.targetName
                )
              }
              value={props.value}
              disabled={props.disabled}
              placeholder={props.placeholder}
            />
            {/*{(!props.value) || (props.value.length < 3 ) && (
          <FormFeedback> Required </FormFeedback>
         )}*/}
          </>
        );
        break;
      case "textarea":
        inputFiled = (
          <Input
            type="textarea"
            name={props.name}
            onChange={(input) =>
              props.onChange(input, props.slug, props.target, props.targetName)
            }
            value={props.value}
            rows={5}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
        );
        break;
      case "email":
        inputFiled = (
          <>
            <Input
              invalid={props.value === "" ? true : false}
              type="email"
              name={props.name}
              onChange={(input) =>
                props.onChange(
                  input,
                  props.slug,
                  props.target,
                  props.targetName
                )
              }
              value={props.value}
              disabled={props.disabled}
              placeholder={props.placeholder}
            />
            {props.value === "" && <FormFeedback> Required </FormFeedback>}
            {/*{props.error !== "" && (
          <FormFeedback> {props.error} </FormFeedback>
          )}*/}
          </>
        );
        break;
      case "phone":
        inputFiled = (
          <PhoneInput
            country={"sa"}
            name={props.name}
            disabled={props.disabled}
            value={props.value}
            autoFormat={false}
            //required = {true}
            onChange={(input) =>
              props.onChange(
                { target: { name: props.name, value: input } },
                props.slug,
                props.target,
                props.targetName
              )
            }
          />
        );
        break;
      case "password":
        inputFiled = (
          <Input
            type="password"
            name={props.name}
            onChange={(input) =>
              props.onChange(input, props.slug, props.target, props.targetName)
            }
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
        );
        break;
      case "url":
        inputFiled = (
          <Input
            type="url"
            name={props.name}
            onChange={(input) =>
              props.onChange(input, props.slug, props.target, props.targetName)
            }
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
        );
        break;
      case "integer":
        inputFiled = (
          <Input
            type="number"
            name={props.name}
            onChange={(input) =>
              props.onChange(input, props.slug, props.target, props.targetName)
            }
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder}
            min="0"
            step="1"
          />
        );
        break;
      case "decimal":
        inputFiled = (
          <Input
            type="number"
            name={props.name}
            onChange={(input) =>
              props.onChange(input, props.slug, props.target, props.targetName)
            }
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder}
            step="any"
          />
        );
        break;
      case "date":
        console.log(new Date(props.value));
        inputFiled = (
          <DatePicker
            name={props.name}
            onChange={(input) =>
              props.onChangeDate(
                input,
                props.name,
                props.target,
                props.targetName
              )
            }
            selected={props.value ? new Date(props.value) : null}
            placeholderText={
              props.placeholder ? props.placeholder : "DD/MM/YYYY"
            }
            dateFormat="dd/MM/yyyy"
          />
        );

        break;
      case "file":
        {
          /* value={props.value ? props.value : ""} */
        }

        inputFiled = (
          <Input
            type="file"
            name={props.name}
            onChange={(input) => props.onChangeFile(input)}
            disabled={props.disabled}
            placeholder={props.placeholder}
          />
        );
        break;
      case "switch":
        inputFiled = (
          <CustomInput
            type="switch"
            defaultChecked={props.value}
            id={props.id}
            name={props.name}
            onChange={(input) =>
              props.onChangeSwitch(input, props.target, props.targetName)
            }
          />
        );
        break;
      case "select":
        const selectValue = stringToSelectObject(
          props.options,
          props.value,
          props.valueKey
        );

        inputFiled = (
          <>
            <Select
              options={props.options}
              isClearable={false}
              isSearchable={true}
              getOptionValue={(option) => option[props.valueKey]}
              getOptionLabel={(option) => option[props.labelKey]}
              placeholder={props.placeholder}
              className="react-select"
              isDisabled={props.isDisabled}
              validations={[required]}
              selected={selectValue ? selectValue : null}
              value={selectValue ? selectValue : null}
              onChange={(input) => {
                props.onChangeSelect(
                  input,
                  props.name,
                  props.valueKey,
                  props.optional,
                  props.optionalKey,
                  props.storeAllOptionInfo,
                  props.target,
                  props.targetName,
                  props.reset
                );
              }}
            />
            {props.value === "" && <FormFeedback> Required </FormFeedback>}
          </>
        );
        break;
      case "groupCheckbox":
        const selectValues = arrayToMultiselectObject(
          props.options,
          props.value,
          props.valueKey
        );
        inputFiled = (
          <Select
            options={props.options}
            isClearable={false}
            isSearchable={true}
            isMulti={true}
            getOptionValue={(option) => option[props.valueKey]}
            getOptionLabel={(option) => option[props.labelKey]}
            placeholder={props.placeholder}
            className="react-select"
            isDisabled={props.isDisabled}
            validations={[required]}
            value={selectValues}
            defaultValue={selectValues}
            onChange={(input) => {
              props.onChangeMultiSelect(
                input,
                props.name,
                props.valueKey,
                props.target,
                props.targetName
              );
            }}
          />
        );
        break;
      case "singleCheckbox":
        inputFiled = (
          <Input
            type="checkbox"
            name={props.name}
            value={props.value}
            defaultChecked={props.defaultChecked}
            checked={props.value}
            onChange={(input) =>
              props.onChangeCheckbox(input, props.target, props.targetName)
            }
            disabled={props.disabled}
          />
        );
        break;
      case "radio":
        inputFiled = (
          <Input
            type="radio"
            id={props.id}
            name={props.name}
            value={props.value}
            defaultChecked={props.defaultChecked}
            onChange={(input) =>
              props.onChangeRadio(
                input,
                props.index,
                props.radioList,
                props.targetName,
                props.target
              )
            }
            disabled={props.disabled}
          />
        );
        break;
      case "measurement":
        const selectedValue = stringToSelectObject(
          props.options,
          props.unit,
          props.valueKey
        );
        inputFiled = (
          <div className="d-flex justify-content-between">
            <Input
              type="number"
              name={"value"}
              onChange={(input) =>
                props.onChangeMeasurementValue(
                  input,
                  props.target,
                  props.targetName
                )
              }
              value={props.value}
              disabled={props.disabled}
              placeholder={props.placeholder}
              step="any"
            />
            <Select
              options={props.options}
              isClearable={false}
              isSearchable={true}
              isMulti={false}
              getOptionValue={(option) => option[props.valueKey]}
              getOptionLabel={(option) => option[props.labelKey]}
              placeholder={props.placeholder}
              className="react-select"
              isDisabled={props.isDisabled}
              validations={[required]}
              value={selectedValue}
              defaultValue={selectedValue}
              onChange={(input) => {
                props.onChangeMeasurementUnit(
                  input,
                  "unit",
                  props.valueKey,
                  props.target,
                  props.targetName
                );
              }}
            />
          </div>
        );
        break;
      default:
        break;
    }
    return inputFiled;
  };

  return (
    <div>
      {props.type !== "singleCheckbox" && props.type !== "radio" ? (
        <FormGroup className={props.classFormGroup}>
          <Label for="exampleEmail" className={props.classLabel}>
            {props.label}
          </Label>
          <div className="d-flex align-items-center">
            {attr()}
            {props.tooltip && (
              <Tooltip tooltip={props.tooltip} name={props.name} />
            )}
          </div>
        </FormGroup>
      ) : (
        <FormGroup check>
          <Label check>
            {attr()}
            {props.label}
          </Label>
        </FormGroup>
      )}
    </div>
  );
}

export default FormComponent;
