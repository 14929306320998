import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import { deleteGroup } from "../../../../redux/actions/Ecommerce/GroupAction";

class index extends Component {
  state = {
    business_id: "5cc2f00577ebc157b4f1c64c",
  };

  deleteGroup = (e) => {
    e.preventDefault();
    console.log("inside Delete group");
    let group = `
    business_id: "${this.state.business_id}",
    inner_name: "${this.props.group.inner_name}",
    `;
    //console.log(group);
    try {
      let response = this.props.deleteGroup(this.props.group._id);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    return (
      <div>
        <View
          modal={this.props.modal}
          group={this.props.group}
          toggle={this.props.toggle}
          onSumbit={this.deleteGroup}
        />
      </div>
    );
  }
}

export default connect(null, { deleteGroup })(index);
