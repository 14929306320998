import {
  ADD_ORDER,
  EDIT_ORDER,
  DELETE_ORDER,
  GET_ORDERS,
  FIND_ORDER,
} from "./types";
import fetchAPIClass from "../fetchAPI";
import { restfulUrl, API_URL , Order_URL} from "context/url";

export const getOrders = (storeId) => (dispatch) => {
  let url = `${Order_URL}/api/orders/store/${storeId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_ORDERS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addOrder = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/order/add`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_ORDER);
    let response = api.fetchWithoutAPI(dispatch, inputs);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteOrder = (input) => (dispatch) => {
  let url = `${API_URL}/ecommerce/order/delete/${input}`;
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_ORDER);
    api.fetchWithoutAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editOrder = (inputs, OrderId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/order/edit/${OrderId}`;

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_ORDER);
    let response = api.fetchWithoutAPI(dispatch, inputs);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findOrder = (OrderId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/order/find/${OrderId}`;
  let data = "";
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_ORDER);
    api.fetchWithoutAPI(dispatch, data);
  } catch (err) {
    console.log(err);
  }
};
