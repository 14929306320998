import React, { PropTypes , useEffect, useState} from "react";
import Card from "../../../../components/general/Card";
import HeaderInfo from "../../../../components/general/PageDetails/HeaderInfo";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import CollapseList from "../../../../components/general/PageDetails/CollapseList";
import Collapse from "components/general/Collapse"
import _ from "lodash";
import { Row, Col, Button } from "reactstrap";
import AddLanguage from "../../../Settings/Ecommerce/Language/Add"
import Delete from "components/general/Delete";

const View = (props) => {
  const { data, deleteCatalog, editCatalog, countries, addCurrency, deleteCurrency } = props;

  let generalInfoInput = [
    {
      type: "switch",
      name: "status",
      id: "status",
      label: "Catalog Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: data.status,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "catalog ID",
      value: data["_id"],
      default: data["_id"],
      disabled: true,
    },
    /*{
      type: "groupCheckbox",
      options: props.countries,
      name: "country",
      label: "Country",
      value: data["country"],
      default: data["country"],
      valueKey: "_id",
      labelKey: "name_en",
    },*/
  ];

  const staticGroups = [
    {
      id: 0,
      label: "Catalog Information",
      collapseCase: "ListAttribute",
      open: true,
      attributes: [
        {
          label: "Catalog ID",
          name: "_id",
          type: "text",
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          type: "text",
        },
        /*{
          label: "Status",
          name: "status",
          type: "switch",
        },*/
      ],
    },
    {
      id: 1,
      label: "Country",
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          type: "select",
          option: countries,
          name: "country",
          label: "Country",
          value: data["country"],
          valueKey: "_id",
          labelKey: "name_en",
        },
      ]
    },
  ];

  let dynamicGroup = [
    {
      id: 0,
      label: "Tax",
      collapseCase: "ListAttribute",
      open: false,
      groupName: "tax",
      attributes: [
        {
          label: "Tax Name",
          name: 'name',
          type: "text",
          value: data.tax.name
        },
        {
          label: "Percentage",
          name: "percentage",
          type: "text",
          value: data.tax.percentage
        },
        {
          type: "switch",
          name: "show_product_inc_tax",
          id: "show_product_inc_tax",
          label: "show_product_inc_tax",
          classFormGroup: "d-flex",
          classLabel: "mr-3",
          value: data.tax.show_product_inc_tax,
          updateState: true,
        },
        {
          type: "switch",
          name: "cal_tax_with_service",
          id: "cal_tax_with_service",
          label: "cal_tax_with_service",
          classFormGroup: "d-flex",
          classLabel: "mr-3",
          value: data.tax.cal_tax_with_service,
          updateState: true,
        },
        /*{
          label: "Status",
          name: "status",
          type: "switch",
        },*/
      ],
     
    },
    /*{
      id: 3,
      label: "Currencies",
      collapseCase: "ListAttribute",
      open: false,
      groupName: "currencies",
      attributes: [
        {
          label: "English Name",
          name: "name_en",
          type: "text",
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          type: "text",
        },
      ],
    }*/
  ]

  let currencyData = [];
  _.map(data.currencies, (currency, index) => {
    let item = {
      id: index,
      label: 'Currency',
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          label: "Activate Currency",
          name: "status",
          type: "switch",
          value: data.currencies[index]["status"],
          default: data.currencies[index]["status"],
        },
        /*{
          type: "text",
          name: "_id",
          label: "Currency ID",
          value: data["_id"],
          default: data["_id"],
          disabled: true,
        },*/
        {
          label: "English Name",
          name: "name_en",
          type: "text",
          value: data.currencies[index]["name_en"],
          default: data.currencies[index]["name_en"],
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          type: "text",
          value: data.currencies[index]["name_ar"],
          default: data.currencies[index]["name_ar"],
        },
        {
          label: "Factor",
          name: "factor",
          type: "text",
          value: data.currencies[index]["factor"],
          default: data.currencies[index]["factor"],
        },
        {
          label: "Is Default",
          name: "is_default",
          type: "switch",
          value: data.currencies[index]["is_default"],
          default: data.currencies[index]["is_default"],
        }
      ],
    };
    currencyData.push(item);
  });
  console.log(currencyData)



 
  return (
    <Card name="Catalog Details">
      {/* Headr  */}
      <HeaderInfo
        {...props}
        data={data}
        //image={data.media[0]}
        titleLabel={"Catalog Name"}
        onDelete={deleteCatalog}
        onSave={editCatalog}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo
            {...props}
            info={generalInfoInput}
            noDateRagne={true}
            handleOnStatusChange={props.onChange}
            onChange={props.onChange}
          />
        </Col>

        {/* Details Info */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {/* Static Group Attribute */}
            {_.map(staticGroups, (group) => (
              <CollapseList
                {...props}
                group={group}
                collapseCase={group.collapseCase}
                name="name"
                updateState={props.onChange}
              />
            ))}
          </div>
            {/* Static Group Attribute */}
            {_.map(dynamicGroup, (group, key) => (
              <CollapseList
                {...props}
                group={group}
                usingFormContollerList={true}
                target={data[group.groupName]}
                collapseCase={group.collapseCase}
                name="name"
                updateState={props.onChange}
              />
            ))}

          <div>

            <Collapse {...props}
             group={{
              id:3,
              label:"Currencies",
              collapseCase:"ListAttribute",
              open:false,
             }}
            >
              <div className="p-l-25 p-r-25">
                <Row className="text-right justify-content-end d-flex align-items-center m-2">
                <Button color="inherit"  onClick={addCurrency}>
                  {"Add New"}
                </Button>
                </Row>
              {_.map(currencyData, (group, key) => (
                <CollapseList
                  {...props}
                  index={key}
                  titleWithKey={true}
                  //hasButton={key == 0 ? true : false}
                  //onClick={addCurrency}
                  group={group}
                  usingFormContollerList={true}
                  target={data.currencies[key]}
                  collapseCase={group.collapseCase}
                  name="name"
                  updateState={props.onChange}
                >
                  <Row className="text-right justify-content-end d-flex align-items-center m-2">
                    {/*<Button
                      className="fa fa-trash m-r-5"
                      color='danger'
                      onClick={() => deleteCurrency(key)}
                    >
                      delete
                    </Button>*/}
                    <span style={{ fontSize:"25px", color: "red" }} onClick={() => deleteCurrency(key)}>
                      <i className="fa fa-trash" />
                    </span>
                    </Row>
                </CollapseList>
              ))}
              </div>
            </Collapse>
          </div>
            
        </Col>
      </Row>
    </Card>
  );
};

View.propTypes = {};

export default View;
