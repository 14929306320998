import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import { getAttributes } from "redux/actions/Ecommerce/AttributeAction";
const business_id = "5cc2f00577ebc157b4f1c64c";

class index extends Component {
  state = this.initialState;

  /**
   * This handler to reset steps active state,
   * and then set the step in the argument
   */

  get initialState() {
    return {
      // Steps
      currentStep: 1,

      // First Step
      templateId: 1,
      selectedTemplate: null,
      attributeDetails: "",

      isAdded: false, // check later
      // Second Step
      logic: {
        operator: "all",
        logic_list: [
          {
            attribute: {
              system_name: "",
              inner_name: "",
              label: "",
              path: "",
              type: "",
              operator: [],
            },
            operator: "",
            value: "",
            child: {
              // empty: true,
              logic_list: [],
              operator: "all",
            },
          },
        ],
      },

      // Third step
      is_template: false,
      name: "",
      status: false,
      desc: "",
      runLogicTime: "none",
    };
  }

  handleClick = (step) => {
    this.setState({
      currentStep: step,
    });
  };

  updateState = (input) => {
    console.log(input);
    this.setState(input);
    this.forceUpdate();
  };

  componentDidMount() {
    this.props.getAttributes();
  }

  resetState = () => {
    this.setState(this.initialState);
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state);
  };
  /**
   * Methods to add
   * 2. Save > handleSubmit
   */

  render() {
    console.log("Steps index", this.state);
    console.log("Get Template: props - general", this.props);

    return (
      <View
        {...this.state}
        {...this.props}
        handleClick={this.handleClick}
        updateState={this.updateState}
        handleSubmit={this.handleSubmit}
        resetState={this.resetState}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  attributes: state.ecommerceAttributes.items,
  //template: state.segmentations.template
});
export default connect(mapStateToProps, {
  getAttributes,
})(index);
