import React, { PropTypes } from "react";
import Card from "../../../../components/general/Card";
import HeaderInfo from "../../../../components/general/PageDetails/HeaderInfo";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import CollapseList from "../../../../components/general/PageDetails/CollapseList";
import _ from "lodash";
import { Row, Col } from "reactstrap";

const View = (props) => {
  const { data, editShipment } = props;
  console.log(data);

  let generalInfoInput = [
    {
      type: "text",
      name: "status",
      label: "Shipment Status",
      //classFormGroup: "d-flex",
      //classLabel: "mr-3",
      value: data.status,
      default: data.status,
    },
    {
      type: "text",
      name: "customer_id",
      label: "Customer ID:",
      value: data["customer_id"]["_id"],
      default: data["customer_id"]["_id"],
    },
    {
      type: "text",
      name: "ordered_at",
      label: "Creation Date:",
      value: data["ordered_at"],
      default: data["ordered_at"],
    },
    {
      type: "text",
      name: "branch_id",
      label: "Purchase Point:",
      value: data["branch_id"],
      default: data["branch_id"],
    },
    {
      type: "text",
      name: "payment_method",
      label: "Payment Method:",
      value: data["payment_method"].name,
      default: data["payment_method"].name,
    },
    {
      type: "text",
      name: "shipping_method",
      label: "Shipping Method:",
      value: data["shipping_method"],
      default: data["shipping_method"],
    },
  ];

  const staticGroups = [
    {
      id: 0,
      label: "Shipment Status",
      collapseCase: "stepper",
      open: true,
      progressSteps: [
        {
          step: 1,
          title: "New",
        },
        {
          step: 2,
          title: "Payment Received",
        },
        {
          step: 3,
          title: "Fullfilment",
        },
        {
          step: 4,
          title: "Ready for Delivery",
        },
        {
          step: 5,
          title: "In Transit",
        },
        {
          step: 6,
          title: "Delivered",
        },
      ],
    },
    {
      id: 1,
      label: "Shipment Information",
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          label: "Shipment Type:",
          name: "shipment_type",
          type: "text",
          disabled: true,
          value: data.shipment_type,
          //default: data.shipment_type,
        },
        {
          label: "Store ID:",
          name: "_id",
          type: "text",
          disabled: true,
          value: data.store_id._id,
          //default: data.customer_id._id,
        },
        {
          label: "Store Name:",
          name: "name",
          type: "text",
          value: data["store_id"]["name"],
          //default: data.store_id.name,
        },
        {
          label: "Order Time",
          name: "ordered_at",
          type: "text",
          value: data["ordered_at"],
          //default: data.ordered_at,
        },
      ],
    },
    {
      id: 2,
      label: "Address Information",
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          label: "Address ID:",
          name: "_id",
          type: "text",
          disabled: true,
          value: data.address._id,
          default: data.address._id,
        },
        {
          label: "Address Name",
          name: "name",
          type: "text",
          value: data.address.name,
          default: data.address.name,
        },
        {
          label: "Address Line:",
          name: "address_line",
          type: "text",
          value: data.address.address_line,
          default: data.address.address_line,
        },
        {
          label: "Country:",
          name: "country",
          type: "text",
          value: data.address.country,
          default: data.address.country,
        },
        {
          label: "Region:",
          name: "region",
          type: "text",
          value: data.address.region,
          default: data.address.region,
        },
        {
          label: "City:",
          name: "city",
          type: "text",
          value: data.address.city,
          default: data.address.city,
        },
        {
          label: "District:",
          name: "district",
          type: "text",
          value: data.address.district,
          default: data.address.district,
        },
        {
          label: "More Instruction:",
          name: "note",
          type: "textarea",
          value: data.address.note,
          default: data.address.note,
        },
      ],
    },
    {
      id: 3,
      label: "Products",
      collapseCase: "table",
      items: data["products"],
      open: false,
    },
    {
      id: 5,
      label: "Delivery Note",
      collapseCase: "price",
      open: false,
    },
    {
      id: 6,
      label: "Shipment Logs",
      collapseCase: "ListAttribute",
      open: false,
    },
  ];

  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "sku",
      align: "center",
      title: "sku",
    },
    {
      type: "name_en",
      sortable: true,
      title: "Name",
    },
    {
      type: "name_ar",
      title: "Name Ar",
    },
    {
      type: "type",
      title: "Type",
      align: "center",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
  ];

  return (
    <Card name="Shipment Details">
      {/* Headr  */}
      <HeaderInfo
        {...props}
        data={data}
        isOrder={true}
        //image={data.logos[0].source}
        titleLabel={"Shipment Number "}
        //onDelete={deleteShipment}
        onSave={editShipment}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo
            {...props}
            info={generalInfoInput}
            noDateRagne={true}
            handleOnStatusChange={props.onChange}
            onChange={props.onChange}
          />
        </Col>

        {/* Details Info */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {/* Static Group Attribute */}
            {_.map(staticGroups, (group) => (
              <CollapseList
                {...props}
                group={group}
                collapseCase={group.collapseCase}
                name="name"
                updateState={props.onChange}
                headerList={headerList}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

View.propTypes = {};

export default View;
