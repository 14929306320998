import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "components/general/Modal";
import FormComponent from "components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {
  const { onChange, data, modal, catalogs, toggle, errors } = props;
  console.log(data);
  const coverageAreaDemo = [
    {
      value: "district",
      label: "District",
    },
    {
      value: "City",
      label: "City",
    },
    {
      value: "region",
      label: "Region",
    },
    {
      value: "country",
      label: "Country",
    }
  ];
  return (
    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>
      <ModalComponent
        title="Add New Pickup"
        toggle={toggle}
        isOpen={modal}
        size="lg"
      >
        <Form onSubmit={props.onSumbit}>
                   <FormComponent
                    type="switch"
                    id={`activate_${data._id}`}
                    name="isActive"
                    label="Activate"
                    value={data.isActive}
                    updateState={onChange}
                    error={_.get(errors, "nameError")}
                  />
                  <FormComponent
                    type="text"
                    name="nameEN"
                    label="English Name"
                    value={data.nameEN}
                    updateState={onChange}
                    error={_.get(errors, "nameError")}
                  />
                  {!_.isEmpty(_.get(errors, "nameError")) && (
                    <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                  )}
                  <FormComponent
                    type="text"
                    name="nameAR"
                    label="Arabic Name"
                    value={data.nameAR}
                    updateState={onChange}
                    error={_.get(errors, "nameError")}
                  />
                  {!_.isEmpty(_.get(errors, "nameError")) && (
                    <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                  )}
                  <FormComponent
                    type="select"
                    options={_.map(catalogs, (catalog) => ({
                      label: _.get(catalog, "name_en"),
                      value: _.get(catalog, "_id"),
                    }))}
                    name="channels"
                    label="Channel"
                    value={data.channels}
                    valueKey="value"
                    labelKey="label"
                    updateState={onChange}
                  />
                    <FormComponent
                    type="select"
                    options={coverageAreaDemo}
                    name="coverageArea"
                    label="Coverage Area"
                    value={data.coverageArea}
                    valueKey="value"
                    labelKey="label"
                    updateState={onChange}
                  />
                
                      <FormComponent
                          type="integer"
                          name="maximumOptions"
                          label="Maximum Shipments"
                          value={data.maximumOptions}
                          updateState={onChange}
                        />

                   
                  
          <ModalFooter>
            <Button
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
