import React from "react";
import VideoCamera from "./assets/VideoCamera";
const PretBox = () => {
  return (
    <div className="prep-box-main">
      <div className="prep-box-wr">
        <VideoCamera />
        <h2>How to prepare your spreadsheet</h2>
        <p>
          The guide below will walk you through how to <br /> prepare your
          filesfor a successful import.
        </p>
        <button className="prep-button-main">View import guide</button>
      </div>
    </div>
  );
};

export default PretBox;
