import React, { Component } from "react";
import View from "../../../../components/general/Delete";
import { connect } from "react-redux";
import { deleteCustomer } from "../../../../redux/actions/CustomerAction";
//import { deleteCustomer } from "../../../redux/actions/CustomerAction"
import { restfulUrl } from "../../../../context/url";
import fetchApi from "../../../../fetchApi/fetchData";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";

class index extends Component {
  deleteCustomer = () => {
    // FIXME: prevent default & get the result of delete
    try {
      let response = this.props.deleteCustomer(this.props.customerID);

      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  render() {
    return (
      <>
        <View {...this.props} onSubmit={this.deleteCustomer} />
      </>
    );
  }
}

export default connect(null, { deleteCustomer })(index);
