//Settings

//Payment
export const ADD_PAYMENT = "ADD_PAYMENT";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const EDIT_PAYMENT = "EDIT_PAYMENT";
export const FIND_PAYMENT = "FIND_PAYMENT";
export const GET_GATEWAYS = "GET_GATEWAYS";

//Cart
export const ADD_CART = "ADD_CART";
export const GET_CARTS = "GET_CARTS";
export const DELETE_CART = "DELETE_CART";
export const EDIT_CART = "EDIT_CART";
export const FIND_CART = "FIND_CART";

//Delivery
export const ADD_DELIVERY = "ADD_DELIVERY";
export const GET_DELIVERYS = "GET_DELIVERYS";
export const DELETE_DELIVERY = "DELETE_DELIVERY";
export const EDIT_DELIVERY = "EDIT_DELIVERY";
export const FIND_DELIVERY = "FIND_DELIVERY";

//PICKUP
export const ADD_PICKUP = "ADD_PICKUP";
export const GET_PICKUPS = "GET_PICKUPS";
export const DELETE_PICKUP = "DELETE_PICKUP";
export const EDIT_PICKUP = "EDIT_PICKUP";
export const FIND_PICKUP = "FIND_PICKUP";

//CARRIER
export const ADD_CARRIER = "ADD_CARRIER";
export const EDIT_CARRIER = "EDIT_CARRIER";
export const DELETE_CARRIER = "DELETE_CARRIER";
export const GET_CARRIERS = "GET_CARRIERS";
export const FIND_CARRIER = "FIND_CARRIER";
export const GET_CARRIERS_COMPANIES = "GET_CARRIERS_COMPANIES";

//Customer Types
export const ADD_CUSTOMER_TYPE = "ADD_CUSTOMER_TYPE";
export const GET_CUSTOMER_TYPES = "GET_CUSTOMER_TYPES";
export const DELETE_CUSTOMER_TYPE = "DELETE_CUSTOMER_TYPE";
export const EDIT_CUSTOMER_TYPE = "EDIT_CUSTOMER_TYPE";
export const FIND_CUSTOMER_TYPE = "FIND_CUSTOMER_TYPE";
