import {
    ADD_PICKUP,
    EDIT_PICKUP,
    DELETE_PICKUP,
    GET_PICKUPS,
    FIND_PICKUP,
  } from "../types";
  import fetchAPIClass from "../../fetchAPI";
  import { restfulUrl, API_URL , SHIPPING_SETTING_URL} from "context/url";
  
  export const getPickups = (data) => (dispatch) => {
    let url = `${SHIPPING_SETTING_URL}/pickups/all`;
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_PICKUPS);
      api.fetchAPI(dispatch, data);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const addPickup = (inputs) => (dispatch) => {
    let requestBody = inputs;
    let url = `${SHIPPING_SETTING_URL}/pickups`;
    console.log(requestBody);
    try {
      const api = new fetchAPIClass(url, "POST", requestBody, ADD_PICKUP);
      let response = api.fetchAPI(dispatch, inputs);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const deletePickup = (input) => (dispatch) => {
    let url = `${SHIPPING_SETTING_URL}/pickups/${input}`;
    try {
      const api = new fetchAPIClass(url, "DELETE", null, DELETE_PICKUP);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const editPickup = (inputs) => (dispatch) => {
    let requestBody = inputs;
    let url = `${SHIPPING_SETTING_URL}/pickups`;
  
    try {
      const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_PICKUP);
      let response = api.fetchAPI(dispatch, inputs);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const findPickup = (pickupId) => (dispatch) => {
    let url = `${SHIPPING_SETTING_URL}/pickups/${pickupId}`;
    let data = "";
    try {
      const api = new fetchAPIClass(url, "GET", null, FIND_PICKUP);
      api.fetchAPI(dispatch, data);
    } catch (err) {
      console.log(err);
    }
  };
  