import React, { useState } from "react"
import View from "./View"
import { connect } from "react-redux"
import { addBrand } from "../../../../redux/actions/Ecommerce/BrandAction"
import SuccessAlert from "../../../../components/general/SuccessAlert"
import ErrorAlert from "../../../../components/general/ErrorAlert"
import _ from "lodash"

const Index = (props) => {
  let [state, setState] = useState({
    name_en: "",
    name_ar: "",
    external_id: "",
    priority: "",
    status: "",
    description_ar: "",
    description_en: "",
    logos: [],
    number_of_digital_assets: null,
    errors: {
      nameError: "",
      externalError: '',
    },
  })

  let [modal, setModal] = useState(false)

  const onChange = (input) => {
    setState({ ...state, ...input })
  }

  const reset = () => {
    setState(state)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const onValidate = (e) => {
    e.preventDefault()
    let errors = {
      nameError: "",
      externalError: ''
    }

    let inputs = state

    if (inputs.name_en === "" || inputs.name_ar === "") {
      errors.nameError = "Sorry, 'Name' in both languages are required!"
    }

    if (inputs.external_id === "") {
      errors.externalError = "Sorry, 'External Id' is required!"
    }

    if (errors.nameError === "" && errors.externalError === "") {
      addBrand(e)
    } else {
      setState({ errors: errors })
    }
  }

  const addBrand = (e) => {
    e.preventDefault()
    console.log("addBrand", state)
    let Brand = `{
      "business_id": "${state.business_id}",
      "name_ar": "${state.name_ar}",
      "name_en": "${state.name_en}",
      "external_id": "${state.external_id}",
      "location": "${state.location}",
      "type": "${state.type}",
      "description_ar": "${state.description_ar}",
      "description_en": "${state.description_en}",
      "delivery_options": "${state.delivery_options}"
    }`

    console.log(JSON.stringify(state))

    try {
      props.addBrand(JSON.stringify(state))
      SuccessAlert("success", "Sucessfully Added")
    } catch (err) {
      console.log(err)
      ErrorAlert("error", `Something went wrong! ${err}`)
    }
    reset()
    toggle()
  }

  return (
    <View
      data={state}
      onChange={onChange}
      onSumbit={addBrand}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
    />
  )
}

const mapStateToProps = (state) => ({
  brands: state.brands.items,
})

export default connect(mapStateToProps, { addBrand })(Index)
