import React, { useState } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addUser } from "redux/actions/UserManagement/UserActions";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
    let [state, setState] = useState({
        business_id: "5cc2f00577ebc157b4f1c64c",
        name_en: "",
        name_ar: "",
        //is_active: "",
        role_permissions: [],
        email: "",
        phone: "",
        errors: {
            nameError: "",
        },
    });

    let [modal, setModal] = useState(false);

    const onChange = (input) => {
        setState({ ...state, ...input });
    };

    const reset = () => {
        setState(state);
    };

    const toggle = () => {
        setModal(!modal);
    };

    const onValidate = (e) => {
        e.preventDefault();
        let errors = {
            nameError: "",
        };

        let inputs = state;

        if (inputs.name_en === "" || inputs.name_ar === "") {
            errors.nameError = "Sorry, 'Name' in both languages are required!";
        }

        if (errors.nameError === "") {
            addUser(e);
        } else {
            setState({ errors: errors });
        }
    };

    const addUser = () => {
        console.log("addUser", state);
        /*let user = `{
      "business_id": "${state.business_id}",
      "name_ar": "${state.name_ar}",
      "name_en": "${state.name_en}",
      "is_Active": "${state.is_active}",
       "email": "${state.email}",
        "phone": "${state.phone}",
      "role_permissions": ${[state.role_permissions]},
    }`;*/

        console.log(state);

        try {
            //props.addUser(state);//TODO: LINK WITH USER SERVICE API 
            SuccessAlert("success", "Sucessfully Added");
        } catch (err) {
            console.log(err);
            ErrorAlert("error", `Something went wrong! ${err}`);
        }
        reset();
        toggle();
    };

    return (
        <View
            data={state}
            onChange={onChange}
            onSumbit={addUser}
            onValidate={onValidate}
            errors={state.errors}
            modal={modal}
            toggle={toggle}
        />
    );
};

const mapStateToProps = (state) => ({
    users: state.users.items,
});

export default connect(mapStateToProps, { addUser })(Index);
