import React, { useEffect, useForceUpdate } from "react"
import {
  getSegmentaionsTemplates,
  findSegmentaion,
} from "../../../../../redux/actions/SegmentationActions"
import { connect } from "react-redux"
import View from "./View"
const business_id = "5cc2f00577ebc157b4f1c64c"
const Index = (props) => {
  /*useEffect(() => {
    props.getSegmentaionsTemplates(business_id)
  }, [])*/
  // IF update state template
  useEffect(() => {
    if (props.template) {
      console.log("Get Template: Inside use Effect templete", props.template)
      props.updateState({
        logic: props.template.logic,
      })
    }
  }, [props.template])

  //const useForceUpdate =();

  // Write on redux template
  const handleSelectTemplate = (id) => {
    props.updateState({
      templateId: id,
    })
    if (id !== 1) {
      props.getTemplate(id, business_id)
    } else {
      props.resetState()
    }
  }

  return (
    <div>
      <View {...props} handleSelectTemplate={handleSelectTemplate} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  //templates: state.segmentations.templates,
  //template: state.segmentations.template,
})

export default connect(mapStateToProps, {
  getSegmentaionsTemplates,
  findSegmentaion,
})(Index)
