import React from "react";

const Index = (props) => {
  console.log("popup");
  function handleClick() {
    props.toggle();
  }
  return (
    <div className="modal_custom">
      <div className="modal_custom_content">
        <span
          className="close"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          &times;
        </span>
        {props.component}
      </div>
    </div>
  );
};

export default Index;
