import {
  ADD_BRANCH,
  EDIT_BRANCH,
  DELETE_BRANCH,
  GET_BRANCHES,
  FIND_BRANCH,
} from "./types";
import  fetchAPIClass  from "../fetchAPI";
import { restfulUrl , API_URL} from "../../../context/url";

export const getBranches = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/branch/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_BRANCHES);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addBranch = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/branch/add`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_BRANCH);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteBranch = (branchId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/branch/delete/${branchId}`;
  console.log(branchId);
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_BRANCH);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editBranch = (inputs, branchId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/branch/edit/${branchId}`;
  console.log(requestBody);
  console.log(branchId);

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_BRANCH);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findBranch = (branchId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/branch/find/${branchId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_BRANCH);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
