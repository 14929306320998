import React, { useEffect } from "react";
import { deleteAttribute } from "redux/actions/Ecommerce/AttributeAction";
import { getGroups, deleteGroup } from "redux/actions/Ecommerce/GroupAction";
import { connect } from "react-redux";
import View from "./View";
import LoadingComponent from "components/general/Loading";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";

const Index = (props) => {
  useEffect(() => {
    // GET All Attributes
    props.getGroups("ecommerce");
  }, []);

  const deleteAttribute = (attributeID) => {
    console.log("delete Attribute", attributeID);
    try {
      let response = props.deleteAttribute(attributeID);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };
  const deleteGroup = (groupId) => {
    try {
      console.log("Group", groupId);
      let response = props.deleteGroup(groupId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log(props.attributes);
  console.log(props.groups);
  return props.groups ? (
    <div>
      <View
        {...props}
        deleteAction={deleteAttribute}
        deleteGroup={deleteGroup}
      ></View>
    </div>
  ) : (
    <LoadingComponent />
  );
};

const mapStateToProps = (state) => ({
  groups: state.ecommerceGroups.items,
});
export default connect(mapStateToProps, {
  getGroups,
  deleteAttribute,
  deleteGroup,
})(Index);
