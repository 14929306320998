import React, { PropTypes } from "react";
import Table from "components/general/Table";
import FormComponent from "components/general/FormController";
import { Button, Row, Col } from "reactstrap";
import VariantList from "../VariantList";
//import { fromPairs } from "lodash";

const View = (props) => {
  const { uniqueState } = props;
  return (
    <div>
      {/* Add general Price and Quantity */}
      <Row className="align-items-center">
        <Col sm={4}>
          <FormComponent
            type="decimal"
            name="price"
            label="Unique Price"
            value={uniqueState.price}
            updateState={props.updateState}
          />
        </Col>
        <Col sm={2}>
          <Button
            className="btn btn-primary"
            onClick={() => props.handleApply("price")}
          >
            {"Apply"}
          </Button>
        </Col>
      </Row>

      <Row className="align-items-center">
        <Col sm={4}>
          <FormComponent
            type="integer"
            name="quantity"
            label="Unique Qty"
            value={uniqueState.quantity}
            updateState={props.updateState}
          />
        </Col>
        <Col sm={2}>
          <Button
            className="btn btn-primary"
            onClick={() => props.handleApply("quantity")}
          >
            {"Apply"}
          </Button>
        </Col>
      </Row>

      <VariantList {...props} />
    </div>
  );
};

View.propTypes = {};

export default View;
