import React, { PropTypes, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAttributes } from "redux/actions/Ecommerce/ConfigurableAttributeAction";
import View from "./View";
import LoadingComponent from "components/general/Loading";

const Index = (props) => {
  const { selectedAttributes } = props;

  useEffect(() => {}, [props.configAttributes]);

  const handleOnSelect = (row, isSelect, rowIndex) => {
    if (isSelect) {
      props.updateSelectedAttribute([
        ...selectedAttributes,
        props.configAttributes[rowIndex],
      ]);
    } else {
      props.updateSelectedAttribute((selectedAttributes) =>
        selectedAttributes.filter((item) => item._id !== row._id)
      );
    }
    props.updateState({ isUpdate: true });
  };

  return (
    <div>
      {props.configAttributes ? (
        <View {...props} handleOnSelect={handleOnSelect} />
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default Index;
