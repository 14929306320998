//User
export const ADD_USER = "ADD_USER";
export const GET_USERS = "GET_USERS";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";
export const FIND_USER = "FIND_USER";

//Role
export const ADD_ROLE = "ADD_ROLE";
export const GET_ROLES = "GET_ROLES";
export const DELETE_ROLE = "DELETE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const FIND_ROLE = "FIND_ROLE";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
