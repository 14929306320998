import {
  ADD_LANGUAGE,
  EDIT_LANGUAGE,
  DELETE_LANGUAGE,
  GET_LANGUAGES,
  FIND_LANGUAGE,
} from "../../../actions/Ecommerce/types";

const initialState = {
  item: null,
  items: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_LANGUAGE:
    case FIND_LANGUAGE:
    case DELETE_LANGUAGE:
      return {
        ...state,
        item: action.payload,
      };
    case GET_LANGUAGES:
      return {
        ...state,
        items: action.payload,
      };
    case ADD_LANGUAGE:
      return {
        ...state,
        item: action.payload,
        items: [...state.items, action.payload],
      };
    default:
      return state;
  }
}
