import {
    ADD_CATEGORY,
    EDIT_CATEGORY,
    GET_CATEGORIES,
    FIND_CATEGORY,
    DELETE_CATEGORY,
  } from "../../actions/Ecommerce/types"
  
  const initialState = {
    item: null,
    items: null,
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case ADD_CATEGORY:
      case EDIT_CATEGORY:
      case FIND_CATEGORY:
      case DELETE_CATEGORY:
        return {
          ...state,
          item: action.payload,
        }
      case GET_CATEGORIES:
        return {
          ...state,
          items: action.payload,
        }
      default:
        return state
    }
  }
  