import React from "react";
import Table from "../../../components/general/Table";
import Card from "../../../components/general/Card";
import HeaderActions from "../../../components/general/HeaderBtns";
import AddShipment from "./Add";

const View = (props) => {
  let { shipments, deleteShipment, handleCreate } = props;

  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name_en",
      align: "center",
      title: "Customer Name",
    },
    {
      sortable: true,
      type: "createdAt",
      align: "center",
      title: "Creation Date",
    },
    {
      sortable: true,
      type: "purchase_points",
      align: "center",
      title: "Purchase Points",
    },
    {
      sortable: true,
      type: "total",
      align: "center",
      title: "Total",
    },
    {
      sortable: true,
      type: "address",
      align: "center",
      title: "Address",
    },
    {
      sortable: true,
      type: "shipping",
      align: "center",
      title: "Shipping",
    },
    {
      sortable: true,
      type: "payment",
      align: "center",
      title: "Payment",
    },
    {
      sortable: true,
      type: "status",
      align: "center",
      title: "Status",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];

  return (
    <>
      <Card>
        <HeaderActions
          createAction={false}
          importAction={{ title: "Import", onClick: () => {} }}
          ActionList={{ title: "Action", list: "Delete catalogation" }}
        >
          <AddShipment {...props} />
        </HeaderActions>
        <div className="contact-table">
          <Table
            data={shipments}
            isSelectRow={true}
            EDIT_URL={"order/shipment-details"}
            deleteAction={deleteShipment}
            headers={headerList}
            {...props}
          />
        </div>
        <div className="verified-container m-t-10">
          <ul className="list-unstyled">
            <li>
              <i className="fa fa-circle m-r-5"></i>
              {
                "Draft Order: The Order is not saved and can't use with any campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 active"></i>
              {
                "Active Order: The Order is active and currently used with active campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 inactive"></i>
              {
                "Inactive Order: The Order is inactive and currently not used with active campaign."
              }
            </li>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default View;
