import React, { Component } from 'react'
import View from './View'

export default class index extends Component {
    
    render() {
        return (
            <div>
                <View group={this.props.group} {...this.props}/> 
            </div>
        )
    }
}
