import React, { PropTypes } from "react";
import Table from "components/general/Table";
import AddAttribute from "../../Attribute/AddAttribute";
const View = (props) => {
  const { configAttributes, selectedAttributes } = props;

  console.log("Config", props.configAttributes);
  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name_en",
      align: "center",
      title: "Attribute Name",
    },
    {
      sortable: true,
      type: "type",
      align: "center",
      title: "Type",
    },
  ];
  return (
    <div>
      {" "}
      <div className="contact-table">
        <AddAttribute configurable={true} />
        <Table
          data={configAttributes}
          isSelectRow={true}
          noFilter={true}
          noSearch={true}
          headers={headerList}
          selected={selectedAttributes.map((attr) => attr._id)}
          {...props}
        />
      </div>
    </div>
  );
};

View.propTypes = {};

export default View;
