import React from "react";

const context = React.createContext({
  token: null,
  accountID: null,
  login: (token) => {
    this.token = this.context.cookie.auth;
  },
  logout: () => {},
});

export default context;
