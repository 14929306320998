import React, { PropTypes } from "react";
import FormComponent from "components/general/FormController";
import _ from "lodash";

const View = (props) => {
  const { typeName, state } = props;

  const checkRuleType = () => {
    console.log("rule type", state.rule_type);
    return _.includes(
      [
        "the_cheapest_also_for_buy_1_get_1_free",
        "the_most_expensive",
        "get_y_for_each_x_spent",
      ],
      `${state.rule_type}`
    );
  };

  const checkIfPercentage = () => {
    console.log("rule type", state.rule_type);
    return _.includes(
      ["percent_of_product_price_discount", "apply_as_percentage_of_original"],
      `${state.rule_type}`
    );
  };

  console.log(checkRuleType());
  return (
    <div>
      <FormComponent
        type={"select"}
        label={"Rules Type"}
        name={"rule_type"}
        options={
          _.find(props.promotionTypes, (item) => item.type === typeName).rules
        }
        valueKey={"value"}
        labelKey={"label"}
        value={state.rule_type}
        updateState={props.onChange}
      />
      <FormComponent
        type={"decimal"}
        label={
          checkIfPercentage() ? "Discount Amount (in %)" : "Discount Amount"
        }
        name={"discount_amount"}
        value={state.discount_amount}
        updateState={props.onChange}
      />
      <FormComponent
        type={"integer"}
        label={"Maximum Qty Discount is Applied To"}
        name={"max_quantity"}
        value={state.max_quantity}
        updateState={props.onChange}
      />
      {checkRuleType() && (
        <FormComponent
          type={"decimal"}
          label={"Max Amount"}
          name={"max_amount"}
          value={state.max_amount}
          updateState={props.onChange}
        />
      )}
      {typeName !== "catalog" && (
        <FormComponent
          type={"integer"}
          label={
            checkRuleType() === true
              ? "Number of X Product"
              : "Number of Product List"
          }
          name={"x_product_number"}
          value={state.x_product_number}
          updateState={props.onChange}
        />
      )}
      {checkRuleType() && (
        <FormComponent
          type={"integer"}
          label={"Number of Y Product"}
          name={"y_product_number"}
          value={state.y_product_number}
          updateState={props.onChange}
        />
      )}

      <FormComponent
        type={"select"}
        label={"Calculate Discount Based on"}
        name={"discount_based_on"}
        options={[
          { label: "Price (Special Price is Set)", value: "price" },
          {
            label: "Price After Previous Discount",
            value: "price_after_previous_discount",
          },
          { label: "Original Price", value: "original_price" },
        ]}
        valueKey={"value"}
        labelKey={"label"}
        value={state.discount_based_on}
        updateState={props.onChange}
      />
      <FormComponent
        type={"select"}
        label={"Skip Items with Special Price"}
        name={"skip_item_with_sepcial_price"}
        options={[
          { label: "As Default", value: "as_default" },
          { label: "Yes", value: "yes" },
          { label: "No", value: "no" },
          { label: "Skip if Discounted", value: "skip_if_discounted" },
        ]}
        valueKey={"value"}
        labelKey={"label"}
        value={state.skip_item_with_sepcial_price}
        updateState={props.onChange}
      />
      <FormComponent
        type={"switch"}
        label={"Discard Subsequent Rules"}
        id={"discard_subsequent_rules"}
        name={"discard_subsequent_rules"}
        valueKey={"value"}
        labelKey={"label"}
        value={state.discard_subsequent_rules}
        updateState={props.onChange}
      />
      <FormComponent
        type={"switch"}
        label={"Apply to Shipping Amount"}
        id={"apply_to_shipping_amount"}
        name={"apply_to_shipping_amount"}
        valueKey={"value"}
        labelKey={"label"}
        value={state.apply_to_shipping_amount}
        updateState={props.onChange}
      />
    </div>
  );
};

View.propTypes = {};

export default View;
