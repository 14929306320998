import React from "react";
import { Route, Switch } from "react-router-dom";

import ContactDetails from "../views/CRM/ContactProfile/ContactDetails";
import AddContact from "../views/CRM/ContactProfile/AddContact";
import ContactList from "../views/CRM/ContactProfile/ContactList";

import ImportExport from "../components/general/ImportExport";

import ProfileAttribute from "../views/CRM/ProfileAttribute";
import EditAttribute from "../views/CRM/ProfileAttribute/EditAttributes";

import CreateNewSegment from "../views/CRM/segmentation/AddSegmentation";
import SegmentationList from "../views/CRM/segmentation/SegmentationList";
import EditSegmentation from "../views/CRM/segmentation/EditSegmentation";

import TagList from "../views/CRM/tag/tagList";
import EditTag from "../views/CRM/tag/EditTag";
import AddTag from "../views/CRM/tag/AddTag";
import NotFound from "../components/general/NotFound";
import QuickNavigation from "../components/layouts/QuickNavigation";
import BulkuploadView from "../views/CRM/ImportExport/index";
import CustomeTypeList from "views/Settings/CustomerType";

const QuickNavList = [
  {
    title: "Contact List",
    src: "/assets/img/svg/contact-list.svg",
    path: "/crm/contacts",
  },
  {
    title: "Segmentation",
    src: "/assets/img/svg/segmentation.svg",
    path: "/crm/segmentations",
  },
  {
    title: "Tags",
    src: "/assets/img/svg/segmentation.svg",
    path: "/crm/tags",
  },
  {
    title: "Automated Rule",
    src: "/assets/img/svg/automated-rules.svg",
    path: "/#",
  },
  {
    title: "Profile Attribute",
    src: "/assets/img/svg/profile-attribute.svg",
    path: "/crm/profile_attribute",
  },
  {
    title: "Import / Export",
    src: "/assets/img/svg/import-export.svg",
    path: "/crm/import_export",
  },
];
const crmRoutes = () => {
  const currentPath = "/crm";
  return (
    <div>
      <QuickNavigation quickNavs={QuickNavList} />
      <Switch>
        {/* Contacts Routes */}
        {/* <Route path="/crm" component={ContactList} /> */}
        <Route path="/crm/contacts" component={ContactList} />
        <Route path="/crm/new_contact" component={AddContact} />
        <Route path="/crm/contact_details" component={ContactDetails} />

        {/*Import Routes  */}
        <Route path="/crm/import_export" component={BulkuploadView} />
        {/*<Route path="/crm/import_export" component={ImportExport} />*/}

        {/* Attributes Routes */}

        <Route path="/crm/profile_attribute" component={ProfileAttribute} />
        <Route path="/crm/edit_attribute" component={EditAttribute} />

        {/* Segmentation Routes */}
        <Route path="/crm/segmentations" component={SegmentationList} />
        <Route path="/crm/new_segment" component={CreateNewSegment} />
        <Route path="/crm/edit_segment" component={EditSegmentation} />

        {/* Tags Routes */}
        <Route path="/crm/tags" component={TagList} />
        <Route path="/crm/new_tag" component={AddTag} />
        <Route path="/crm/edit_tag" component={EditTag} />

        {/* Settings */}
        {/*<Route path="/crm/customer_type" component={CustomeTypeList} />*/}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default crmRoutes;
