import React from "react";
import { FormGroup, Label, Col, Row, Input, Form } from "reactstrap";
import FormComponent from "../../FormControllerList";
export default function View(props) {
  const {
    option,
    options,
    index,
    updateState,
    onRemoveOption,
    typeCase,
  } = props;

  return (
    <div className="options">
      <Row className="flex-center">
        <Col sm="12" className="flex-center text-center justify-content-evenly">
          <FormGroup className="check" sm="12">
            <Label>{"IS_default"}</Label>
            <FormComponent
              {...props}
              type={typeCase === 2 ? "radio" : "singleCheckbox"}
              name={"is_default"}
              target={options[props.index]}
              radioList={options}
              targetName={"options"}
              value={option.is_default}
              updateState={updateState}
            />
          </FormGroup>

          <FormGroup sm="12">
            <Label>{"System"}</Label>
            <FormComponent
              {...props}
              type={"text"}
              name={"value"}
              target={options[props.index]}
              targetName={"options"}
              value={option.value}
              updateState={updateState}
            />
          </FormGroup>

          <FormGroup sm="12">
            <Label>{"English Label"}</Label>
            <FormComponent
              {...props}
              type={"text"}
              name={"label"}
              target={options[props.index]}
              targetName={"options"}
              value={option.label}
              updateState={updateState}
            />
          </FormGroup>

          <FormGroup>
            <Label>{"Arabic Label"}</Label>
            <FormComponent
              {...props}
              type={"text"}
              name={"arabic_label"}
              target={options[props.index]}
              targetName={"options"}
              value={option.arabic_label}
              updateState={updateState}
            />
          </FormGroup>
          {props.hasAddOption && (
            <i
              className="material-icons m-r-5 pointer"
              onClick={(input) => {
                onRemoveOption(input, index);
              }}
            >
              delete
            </i>
          )}
        </Col>
      </Row>
    </div>
  );
}
