class fetchAPIClass {
  constructor(url, method, data = null, action) {
    this.url = url;
    this.method = method;
    this.data = data;
    this.action = action;
    this.option = data ? this.postOption() : this.getOption();
  }

  postOption() {
    return {
      method: this.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: this.data,
    };
  }

  getOption() {
    return {
      method: this.method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  }

  fetchAPI = (dispatch) => {
    fetch(this.url, this.option)
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: this.action,
          payload: data,
        })
      )
      .catch((err) => console.log({ message: err }));
  };

  fetchWithoutAPI = (dispatch, data) => {
    dispatch({
      type: this.action,
      payload: data,
    }).catch((err) => console.log({ message: err }));
  };
}

export default fetchAPIClass;
