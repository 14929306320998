import React, { useState } from "react";
import Table from "../../../components/general/Table2";
import Card from "../../../components/general/Card";
import HeaderActions from "../../../components/general/HeaderBtns";
import AddProduct from "./Add";
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
//import {Button} from "react"
import { FormGroup, Input, Button } from "reactstrap";
import Search from "../../../components/general/Search"

const View = (props) => {
  let { products, deleteProduct, handleCreate, searchInput, filteredList, productTotal } = props;
  let [isCard, setIsCard] = useState(false);

  const headerList = [
    {
      // type: "_id",
      // isKey: true,
      // hidden: true,
      //title: "ID",
      dataField: "_id",
      text: "ID",
      sort: true,
      hidden: true,
      filter: textFilter()
    },
    {
      // sortable: true,
      // type: "sku",
      // align: "center",
      // title: "SKU",
      dataField: "sku",
      text: "SKU",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "vairent_sq(smart_code)",
      text: "Smart Code",
      filter: textFilter()

    },
    {
      // sortable: true,
      // type: "name_ar",
      // align: "center",
      // title: "Name",
      dataField: "name_en",
      text: "Name",
      sort: true,
      filter: textFilter()
    },
    // {
    //   // sortable: true,
    //   // type: "description_ar",
    //   // align: "center",
    //   // title: "Description",
    //   dataField: "description_ar",
    //   text: "Description",
    //   sort: true
    // },
    {
      // sortable: true,
      // type: "type",
      // align: "center",
      // title: "Type",
      dataField: "type",
      text: "Type",
      sort: true,
      filter: textFilter()
    },
    // {
    //   // sortable: true,
    //   // type: "createdAt",
    //   // align: "center",
    //   // title: "Creation Date",
    //   dataField: "createdAt",
    //   text: "Creation Date",
    //   sort: true
    // },
    // {
    //   // sortable: true,
    //   // type: "updatedAt",
    //   // align: "center",
    //   // title: "Update Date",
    //   dataField: "updatedAt",
    //   text: "Update Date",
    //   sort: true
    // },
    {
      // type: "status",
      // align: "center",
      // title: "Status",
      dataField: "status",
      text: "Status",
      filter: textFilter()

    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   isDummyField: true,
    //   formatter: goToEdit()
    // }
    // {
    //   type: "action",
    //   sortable: true,
    //   align: "center",
    //   export: false,
    //   title: "Action",
    // },
  ];

  return (
    <>
      <Card>
        {/* Product Cards */}
        {isCard ? (
          <>
            <div className="row">
              {products.map((product) => {
                return (
                  <div className="col-lg-3 col-sm-4 col-6 mb-4">
                    <Card
                      image={product.media ? product.media[0] : null}
                      title={product.name_en}
                      subtitle={product.description_en}
                      button={{ title: "Check Now", url: "edit_product" }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="row mt-4">
              <div className="col-12">{props.pagination}</div>
            </div>
          </>
        ) : (
          <>
            {/* Product table */}
            <HeaderActions
              createAction={false}
              importAction={{ title: "Import", onClick: (e) => {e.preventDefault(); props.history.push("/crm/import_export")} }}
              ActionList={{ title: "Action", list: "Delete productation" }}
            >
              <AddProduct /* modal="true" */ />
            </HeaderActions>
            <div className="d-flex flex-wrap justify-content-end">
            <div className="search">
            <FormGroup>
              <Input
                className="form-control filter"
                type="text"
                id="search"
                placeholder="Search"
                //value={props.searchInput}
                onChange={(e)=>props.setSearchInput(e.target.value)}
                style={{ zIndex: 0, width: "500px" }}
              />
            </FormGroup>
          </div>
          <Button className="mx-2 btn btn-primary"style={{ zIndex: 0, height: "40px" }} onClick={props.onsubmitSearch}>Submit</Button>
              </div>
            <div className="contact-table">
              <Table
                data={searchInput && filteredList? filteredList: products}
                productTotal={productTotal}
                isSelectRow={true}
                EDIT_URL={"ecommerce/edit_product"}
                deleteAction={deleteProduct}
                headers={headerList}
                {...props}
              />
            </div>
            <div className="verified-container m-t-10">
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-circle m-r-5"></i>
                  {
                    "Draft product: The product is not saved and can't use with any campaign."
                  }
                </li>
                <li>
                  <i className="fa fa-circle m-r-5 active"></i>
                  {
                    "Active product: The product is active and currently used with active campaign."
                  }
                </li>
                <li>
                  <i className="fa fa-circle m-r-5 inactive"></i>
                  {
                    "Inactive product: The product is inactive and currently not used with active campaign."
                  }
                </li>
              </ul>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default View;
