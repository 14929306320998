import React, { useEffect } from "react";
import {
  getTags,
  getTagsTemplates,
  deleteTag,
} from "../../../../redux/actions/TagsActions";
import View from "./View";
import { connect } from "react-redux";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
//import { business_id } from "../../../context/url"
const business_id = "5cc2f00577ebc157b4f1c64c";

function Index(props) {
  //class index extends Component {

  useEffect(() => {
    props.getTags(business_id);
  }, []);

  const deleteTag = (segmentId) => {
    try {
      let response = props.deleteTag(segmentId);

      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    props.history.push("/crm/new_tag");
  };

  console.log(props.tags);
  //console.log(props.templates)

  return (
    <div>
      <View {...props} deleteTag={deleteTag} handleCreate={handleCreate} />
    </div>
  );
}
const mapStateToProps = (state) => ({
  tags: state.tags.items,
  //templates: state.tags.templates
});

export default connect(mapStateToProps, {
  getTags /*, getTagsTemplates*/,
  deleteTag,
})(Index);
