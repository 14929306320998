import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import { editGroup } from "../../../../redux/actions/Ecommerce/GroupAction";

class index extends Component {
  state = {
    business_id: "5cc2f00577ebc157b4f1c64c",
    inner_name: this.props.group.inner_name || "",
    label: this.props.group.label || "",
    arabic_label: this.props.group.arabic_label || "",
    is_default: this.props.group.is_default || false,
    errors: null,
  };

  onChange = (input) => {
    this.setState(input);
  };

  onValidate = (group) => {
    let errors = {};
    if (!group.label || group.inner_name === "") {
      errors.required = "Pleae fill required inputs";
      this.setState({ errors: errors });
    } else {
      this.editGroup();
    }
  };

  editGroup = () => {
    console.log("inside Edit group");
    let group = {
    //business_id: this.state.business_id,
    label: this.state.label,
    arabic_label: this.state.arabic_label,
    inner_name: this.state.inner_name,
    is_default: this.state.is_default,
    indexing_for: "crm",
    };
    try {
      if (!this.props.isEcomGroup) {
        let response = this.props.editGroup(JSON.stringify(group), this.props.group._id);
        console.log(response);
      } else {
        this.props.editEcomGroup(
          {
            label: this.state.label,
            arabic_label: this.state.arabic_label,
            inner_name: this.state.inner_name,
            is_default: this.state.is_default,
          },
          this.props.group._id
        );
      }
    } catch (err) {
      console.log(err);
    }

    this.props.toggle(group);
  };

  render() {
    console.log("Group", this.props.group);
    return (
      <div>
        <View
          data={this.state}
          modal={this.props.modal}
          group={this.props.group}
          onChange={this.onChange}
          toggle={this.props.toggle}
          onChangeLabel={this.onChangeLabel}
          onSumbit={this.editGroup}
          errors={this.state.errors}
          onValidate={this.onValidate}
        />
      </div>
    );
  }
}

export default connect(null, { editGroup })(index);
