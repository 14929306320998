import React, { PropTypes } from "react";
import Collapse from "components/general/Collapse";
import Form from "components/general/FormControllerList";
import _ from "lodash";

const Index = (props) => {
  const { group, data } = props;
  console.log("Shpping", group);

  data.shipping.width = data.shipping.width ? data.shipping.width : {};
  data.shipping.height = data.shipping.height ? data.shipping.height : {};
  data.shipping.length = data.shipping.length ? data.shipping.length : {};
  data.shipping.weight = data.shipping.weight ? data.shipping.weight : {};

  return (
    <Collapse group={{ label: group.label, id: group._id, open: false }}>
      <Form
        {...props}
        type={"measurement"}
        label={"Width"}
        name={"width"}
        options={[
          { label: "CM", value: "cm" },
          { label: "M", value: "m" },
        ]}
        target={data.shipping.width}
        valueKey={"value"}
        labelKey={"label"}
        value={data.shipping.width.value}
        unit={data.shipping.width.unit}
        targetName={"shipping"}
        updateState={props.onChange}
      />

      <Form
        {...props}
        type={"measurement"}
        label={"Height"}
        name={"height"}
        options={[
          { label: "CM", value: "cm" },
          { label: "M", value: "m" },
        ]}
        target={data.shipping.height}
        valueKey={"value"}
        labelKey={"label"}
        value={data.shipping.height.value}
        unit={data.shipping.height.unit}
        targetName={"shipping"}
        updateState={props.onChange}
      />

      <Form
        {...props}
        type={"measurement"}
        label={"Length"}
        name={"length"}
        options={[
          { label: "CM", value: "cm" },
          { label: "M", value: "m" },
        ]}
        target={data.shipping.length}
        valueKey={"value"}
        labelKey={"label"}
        value={data.shipping.length.value}
        unit={data.shipping.length.unit}
        targetName={"shipping"}
        updateState={props.onChange}
      />

      <Form
        {...props}
        type={"measurement"}
        label={"Weight"}
        name={"weight"}
        options={[
          { label: "Kg", value: "kg" },
          { label: "G", value: "g" },
        ]}
        target={data.shipping.weight}
        valueKey={"value"}
        labelKey={"label"}
        value={data.shipping.weight.value}
        unit={data.shipping.weight.unit}
        targetName={"shipping"}
        updateState={props.onChange}
      />

      {/* 
            <Form 
              type={"measurement"}
              label={"Width"}
              name={"width"}
              options={[{label:"CM", value:'cm'},{label:"M", value:'m'}]}
              target={data.shipping.width}
              valueKey={"value"}
              labelKey={"label"}
              value={data.shipping.width}
              targetName={"shipping"}
              disabled={true}
              updateState={props.onChange}
             /> */}

      {group.attributes.map((attr, index) => {
        if (attr.type === "measurement") {
          data.shipping[attr.inner_name] = data.shipping[attr.inner_name]
            ? data.shipping[attr.inner_name]
            : {};
        }
        return (
          <div className="p-l-25 p-r-25">
            <Form
              {...props}
              type={attr.type}
              label={attr.name_en}
              name={attr.inner_name}
              options={attr.options}
              value={
                attr.type === "measurement"
                  ? data.shipping[attr.inner_name].value
                  : data.shipping[attr.inner_name]
              }
              default={
                attr.type == "measurement"
                  ? data.shipping[attr.inner_name].value
                  : data.shipping[attr.inner_name]
              }
              target={
                attr.type == "measurement"
                  ? data.shipping[attr.inner_name]
                  : data.shipping
              }
              unit={
                attr.type == "measurement"
                  ? data.shipping[attr.inner_name].unit
                  : ""
              }
              targetName="shipping"
              valueKey={"value"}
              labelKey={"label"}
              updateState={props.onChange}
            />
          </div>
        );
      })}
    </Collapse>
  );
};

Index.propTypes = {};

export default Index;
