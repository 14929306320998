import {
  ADD_PROMOTION,
  EDIT_PROMOTION,
  GET_PROMOTIONS,
  FIND_PROMOTION,
  DELETE_PROMOTION,
  GET_TEMPLATE_PROMOTIONS,
  GET_PROMOTION_TYPES,
  SEARCH_PRODUCT_PROMOTION,
} from "./types";
import  fetchAPIClass  from "../fetchAPI";
import { restfulUrl, API_URL } from "../../../../src/context/url";

export const getPromotionsTemplates = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/promotion-template/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_TEMPLATE_PROMOTIONS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getPromotions = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/promotion/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_PROMOTIONS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addPromotion = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/promotion/add`;
  console.log(requestBody);
  console.log(url);

  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_PROMOTION);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deletePromotion = (promotionId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/promotion/delete/${promotionId}`;
  console.log(url);
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_PROMOTION);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editPromotion = (inputs, promotionId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/promotion/edit/${promotionId}`;

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_PROMOTION);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const getPromotionTypes = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/promotion/type/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_PROMOTION_TYPES);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const searchProductPromotion = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/elastic/search-product-promotion?indexed=products`;
  console.log(requestBody);
  console.log(url);

  try {
    const api = new fetchAPIClass(url, "POST", requestBody, SEARCH_PRODUCT_PROMOTION);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }

}
