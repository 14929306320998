import * as React from "react";
const ContactSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 100 100",
    }}
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M61.885 72.24c-12.457-4.491-16.438-8.282-16.438-16.395 0-4.871 3.803-3.28 5.473-12.2.693-3.7 4.053-.06 4.695-8.507 0-3.366-1.834-4.203-1.834-4.203s.932-4.982 1.297-8.816C55.531 17.342 52.289 5 35 5S14.469 17.342 14.922 22.119c.365 3.834 1.297 8.816 1.297 8.816s-1.834.837-1.834 4.203c.643 8.447 4.002 4.807 4.693 8.507 1.672 8.919 5.475 7.329 5.475 12.2 0 8.113-3.98 11.904-16.438 16.395-1.5.54-5.076 1.381-8.115 2.693V95h80V84.474c0-3.133-5.619-7.728-18.115-12.234zM85 45V30H75v15H60v10h15v15h10V55h15V45H85z"
      style={{
        fill: "#030104",
      }}
    />
  </svg>
);
export default ContactSvg;
