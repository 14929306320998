import React from "react";
import makeAnimated from "react-select/animated";
import Select from "./Select.js";
import { components } from "react-select";

const Option = (props) => {
  //console.log("datadatadata2=>", props);

  return (
    <div className="flex">
      <components.Option {...props} className="option-select">
        <label className="select-label">{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <div className="flex selected-value">
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  </div>
);

const animatedComponents = makeAnimated();

const Index = ({
  optionSelected,
  setOptionSelected,
  data,
  allowSelectAll,
  isDisabled,
  value,
  placeholder,
}) => {
  const handleChange = (selected) => {
    setOptionSelected(selected);
  };
  //console.log("datadatadata=>", data);
  return (
    <>
      <Select
        options={data}
        isMulti={allowSelectAll}
        isDisabled={isDisabled}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue, animatedComponents }}
        onChange={handleChange}
        allowSelectAll={allowSelectAll}
        value={optionSelected}
        placeholder={placeholder}
      />
    </>
  );
};
export default Index;
