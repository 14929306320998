import {
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_GOOGLE_SUCCESS,
  USER_LOGOUT,
} from "../actions/Auth/types";

const initialState = {
  token: localStorage.getItem("userToken"),
  isAuthenticated: localStorage.getItem("userToken") ? true : false,
  userId: localStorage.getItem("userId"),
  stores: [],
  selectedStore: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_REGISTER_SUCCESS:
    case USER_LOGIN_SUCCESS:
      localStorage.setItem("userToken", action.payload.token);
      localStorage.setItem("stores", action.payload.profile.stores);
      localStorage.setItem("userId", action.payload.profile.id);
      localStorage.setItem("selectedStore", action.payload.profile.stores[0]);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        token: action.payload.token,
        stores: action.payload.profile.stores,
        selectedStore: action.payload.profile.stores[0],
      };

    case USER_LOGIN_GOOGLE_SUCCESS:
      localStorage.setItem("userToken", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        token: action.payload.token,
      };

    case USER_REGISTER_FAILURE:
    case USER_LOGIN_FAILURE:
    case USER_LOGOUT:
      localStorage.removeItem("userToken");
      localStorage.removeItem("stores");
      localStorage.removeItem("userId");
      localStorage.removeItem("selectedStore");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        stores: [],
        userId: null,
        selectedStore: null,
      };

    default:
      return state;
  }
}
