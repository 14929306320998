import React, { useCallback, useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Button } from "reactstrap";
import { uploadMedia } from "../../../redux/actions/Ecommerce/MediaAction";

function Dropzone(props) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="thumb-inner">
        <img src={file.preview} className="img" />
      </div>
    </div>
  ));

  const updateState = async (mediaList) => {
    let media = props.data[props.name].slice();
    media = media.concat(mediaList);

    props.updateState({ [props.name]: media }, props.index);
  };

  const upload = async () => {
    console.log("Uplaod", files);
    let respons = await props.uploadMedia(files);
    updateState(respons.payload);

    console.log("Res", respons);
  };
  console.log("file: ", files);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: "image/*", multiple: props.multiple });

  //   const style = useMemo(
  //     () => ({
  //       ...baseStyle,
  //       ...(isDragActive ? activeStyle : {}),
  //       ...(isDragAccept ? acceptStyle : {}),
  //       ...(isDragReject ? rejectStyle : {}),
  //     }),
  //     [isDragActive, isDragReject, isDragAccept]
  //   );

  return (
    <section className="container dropzone-container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <span>Drop the files here ...</span>
        ) : (
          <span>Drag 'n' drop some files here, or click to select files</span>
        )}
      </div>
      <aside className="thumbs-container">{thumbs}</aside>

      <Button type="submit" className="btn btn-default" onClick={upload}>
        {"Upload"}
      </Button>
    </section>
  );
}
const mapStateToProps = (state) => ({
  mediaList: state.media.item,
});

export default connect(mapStateToProps, { uploadMedia })(Dropzone);
