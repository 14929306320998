import React, { PropTypes } from "react";
import Modal from "components/general/Modal";
import { Button, ModalFooter, Form, Label } from "reactstrap";
import FormComponent from "components/general/FormController";
import Options from "components/general/Attribute/Options";

const View = (props) => {
  const { toggle, modal, data, onChange, onSubmit } = props;

  return (
    <div>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create Attribute"}
      </Button>
      <Modal
        title={"Create A new Attribute"}
        toggle={toggle}
        isOpen={modal}
        size="lg"
      >
        <Form onSubmit={onSubmit}>
          <FormComponent
            type="text"
            name="name_en"
            label="English Label"
            value={data.name_en}
            updateState={onChange}
            slug={true}
          />

          <FormComponent
            type="text"
            name="name_ar"
            label="Arabic Label"
            value={data.name_ar}
            updateState={onChange}
          />

          <FormComponent
            type="text"
            name="inner_name"
            label="Internal Name"
            value={data.inner_name}
            updateState={onChange}
            disabled={false}
          />
          <FormComponent
            type="textarea"
            name="description_en"
            label="English Description"
            value={data.description_en}
            updateState={onChange}
          />
          <FormComponent
            type="textarea"
            name="description_ar"
            label="Arabic Description"
            value={data.description_ar}
            updateState={onChange}
          />

          {props.groupsOfAttributes ? (
            <div>
              {console.log("Add attr data = ", props.groupsOfAttributes)}
              <FormComponent
                type="select"
                options={props.groupsOfAttributes}
                name="group_id"
                label="Group Atributes"
                value={data.group_id}
                valueKey="_id"
                labelKey="label"
                updateState={onChange}
              />
            </div>
          ) : (
            "Loading"
          )}
          <FormComponent
            type="select"
            options={props.attributeTypes}
            name="type"
            targetLabel="typeLabel"
            label="Type"
            value={data.type}
            valueKey="value"
            labelKey="label"
            optional="typeCase"
            optionalKey="case"
            updateState={onChange}
          />

          {(data.typeCase === 2 || data.typeCase === 3) && (
            <Options
              data = {data}
              updateState={onChange}
              typeCase={data.typeCase}
              options={data.option}
              hasAddOption={true}
            />
          )}

          {/* Single checkbox Option case 1 */}
          {data.typeCase === 1 && (
            <Options
              data = {data}
              updateState={onChange}
              onAddOption={props.onAddOption}
              typeCase={data.typeCase}
              options={data.option}
            />
          )}

          <FormComponent
            type="singleCheckbox"
            name="is_required"
            label="Is required?"
            value={data.is_required}
            defaultChecked={data.is_required}
            updateState={onChange}
          />
          <FormComponent
            type="singleCheckbox"
            name="in_form"
            label="In form?"
            value={data.in_form}
            defaultChecked={data.in_form}
            updateState={onChange}
          />
        </Form>

        <ModalFooter>
          <Button
            //type="submit"
            onClick={onSubmit}
            className="btn btn-primary btn-cons btn-cons"
          >
            {"Create"}
          </Button>
          <Button className="btn btn-white btn-cons" onClick={toggle}>
            {"Cancel"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

View.propTypes = {};

export default View;
