import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Card from "../../../components/general/Card";
import CollapseList from "components/general/PageDetails/CollapseList";
import Delete from "components/general/Delete";
import _, { update } from "lodash";
import AddUser from "./Add";

const View = (props) => {
    const { users, deleteUser, editUser } = props;
    const [state, setState] = useState(users);
    console.log(users)

    let permissions =  [
        {
            name_en: "add product",
            _id: "1"
        },
        {
            name_en: "add to cart",
            _id: "2",

        }
    ]

    let userData = [];
    _.map(users, (data, index) => {
        let item = {
            id: index,
            label: data["name_en"],
            collapseCase: "ListAttribute",
            open: false,
            attributes: [
                {
                    type: "switch",
                    id: `switch${index}`,
                    name: "is_active",
                    label: "Activate User",
                    classFormGroup: "d-flex",
                    classLabel: "mr-3",
                    value: data["is_active"],
                    default: data["is_active"],
                },
                {
                    type: "text",
                    name: "_id",
                    label: "User ID",
                    value: data["_id"],
                    default: data["_id"],
                    disabled: true,
                },
                {
                    label: "English Name",
                    name: "name_en",
                    type: "text",
                    value: data["name_en"],
                    default: data["name_en"],
                },
                {
                    label: "Arabic Name",
                    name: "name_ar",
                    type: "text",
                    value: data["name_ar"],
                    default: data["name_ar"],
                },
                {
                    label: "Email",
                    name: "email",
                    type: "email",
                    value: data["email"],
                    default: data["email"],
                },
                {
                    label: "Mobile",
                    name: "phone",
                    type: "phone",
                    value: data["phone"],
                    default: data["phone"],
                },
                {
                    type: "groupCheckbox",
                    option:  permissions? _.map(permissions, (permission) => ({
                        label: _.get(permission, "name_en"),
                        value: _.get(permission, "_id"),
                      })) : [],
                    name: "role_permissions",
                    label: "Role Permissions",
                    value: data.role_permissions,
                    valueKey: "value",
                    labelKey: "label",
                    //updateState:{onChange}

                },
            ],
        };
        userData.push(item);
    });

    const updateState = (input) => {
        console.log("Input", input);
        setState({ ...state, ...input });
    };

    console.log("State", state);
    console.log("users", users);

    return (
        <div className="setting">
            <Card name="User Management" goBack={props.history.goBack}>
                <div className="contact-table">
                    {/* Add User */}
                    <div className="d-flex justify-content-end">
                        <AddUser />
                    </div>

                    {/* List of users */}
                    <Col xs="12" lg="8" xl="9" className="group-list">
                        {_.map(userData, (group, key) => (
                            <CollapseList
                                {...props}
                                group={group}
                                usingFormContollerList={true}
                                target={users[key]}
                                collapseCase={group.collapseCase}
                                name="name"
                                updateState={updateState}
                            >
                                {/* Role Actions */}
                                <div className="row justify-content-end">
                                    <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                                        <Button
                                            className="btn btn-primary btn-cons btn-cons"
                                            onClick={() =>
                                                editUser(group.attributes[0].value, state[key])
                                            }
                                        >
                                            {"Save"}
                                        </Button>

                                        <Delete
                                            data={group.label}
                                            onSubmit={deleteUser}
                                            customerID={group.attributes[0].value}
                                            actionType="button"
                                            buttonStyle="btn btn-white btn-cons"
                                        />
                                    </div>
                                </div>
                            </CollapseList>
                        ))}
                    </Col>
                </div>
            </Card>
        </div>
    );
};

export default View;
