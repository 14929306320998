import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "components/general/Modal";
import FormComponent from "components/general/FormController";
import { CartPersistenceStrategy } from "@uxautomation/common";
import { enumsToListOFOptions } from "helpers/general-functions";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {
  const { onChange, data, modal, toggle, errors, catalogs, customerTypes } =
    props;
  let cartFunctions = [
    { id: "1", name: "canCheckout", label: "Checkout" /*value:{}*/ },
    { id: "2", name: "canGuestCheckout", label: "Guest Checkout" /*value:{}*/ },
    {
      id: "3",
      name: "canRequestQuotation",
      label: "Request Quotation" /*value:{}*/,
    },
    {
      id: "4",
      name: "canAppliedCoupons",
      label: "Applied Coupons" /*value:{}*/,
    },
    { id: "5", name: "canEditQuantity", label: "Edit Quantity" /*value:{}*/ },
    { id: "6", name: "canRemoveProduct", label: "Remove Product" /*value:{}*/ },
    {
      id: "7",
      name: "canMoveToWishlist",
      label: "Move to WishList" /*value:{}*/,
    },
  ];
  console.log("CartPersistenceStrategy", CartPersistenceStrategy);
  return (
    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>
      <ModalComponent
        size="lg"
        title="Create New Cart"
        toggle={toggle}
        isOpen={modal}
      >
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="switch"
            id="status"
            name="isActive"
            label="Activation"
            value={data.isActive}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          <FormComponent
            type="text"
            name="nameEN"
            label="Cart English Name"
            value={data.nameEN}
            updateState={onChange}
            tooltip={
              "Refer to the ID of the cart and it’s used for a tag this cart with channel and it’s not editable by the business user"
            }
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          <FormComponent
            type="text"
            name="nameAR"
            label="Cart Arabic Name"
            value={data.nameAR}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          <FormComponent
            type="groupCheckbox"
            options={_.map(catalogs, (catalog) => ({
              label: _.get(catalog, "name_en"),
              value: _.get(catalog, "_id"),
            }))}
            name="channels"
            label="Channel"
            value={data.channels}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />

          <FormComponent
            type="groupCheckbox"
            options={_.map(customerTypes, (type) => ({
              label: _.get(type, "name_en"),
              value: _.get(type, "_id"),
            }))}
            name="customerTypes"
            label="Customer Type (Personalized Cart)"
            value={data.customerTypes}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />

          <FormComponent
            type="switch"
            id="isIndependentCartForEveryBranch"
            name="isIndependentCartForEveryBranch"
            label="Independent Cart For every Store"
            value={data.isIndependentCartForEveryBranch}
            updateState={onChange}
          />

          <FormComponent
            type="switch"
            id="canPersist"
            name="canPersist"
            label="Persistence Cart"
            value={data.canPersist}
            updateState={onChange}
          />

          <FormComponent
            type="text"
            name="persistenceLifetimeInDays"
            label="Persestence LifeTime"
            value={data.persistenceLifetimeInDays}
            updateState={onChange}
          />

          <FormComponent
            type="select"
            options={[{ label: "Login User", value: "Login User" }]}
            name="persistenceStrategy"
            label="Persestence Strategy"
            value={data.persistenceStrategy}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />
          <h6 style={{ fontWeight: "bold" }}>Cart Functions (Actions)</h6>
          {_.map(cartFunctions, (item) => (
            <FormComponent
              type="switch"
              id={item.id}
              name={item.name}
              label={item.label}
              value={item.value}
              updateState={onChange}
            />
          ))}

          <ModalFooter>
            <Button
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
