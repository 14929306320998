import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getCatalogs,
  deleteCatalog,
} from "../../../redux/actions/Ecommerce/CatalogAction";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";
import View from "./View";

const Index = (props) => {
  useEffect(() => {
    props.getCatalogs();
  }, [props.catalogs]);

  const deleteCatalog = (catalogId) => {
    try {
      props.deleteCatalog(catalogId);
      SuccessAlert("success", "Sucessfully deleted");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    props.history.push("/catalog");
  };

  return (
    <div>
      {props.catalogs && (
        <View
          {...props}
          deleteCatalog={deleteCatalog}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  catalogs: state.catalogs.items,
});

export default connect(mapStateToProps, {
  getCatalogs,
  deleteCatalog,
})(Index);
