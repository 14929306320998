import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getUsers,
  deleteUser,
  editUser,
} from "../../../redux/actions/UserManagement/UserActions";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";

import View from "./View";

let users = [
    {
        _id: "userId",
        is_active: true,
        name_en: "User 1",
        name_ar: "User 1 Ar",
        email: "demo.email@gmail.com",
        phone: "966501234567",
        role_permissions: ["1"]
    }
]


const Index = (props) => {
  useEffect(() => {
    props.getUsers(users);
  }, []);

  const deleteUser = (userId) => {
    try {
      //let response = props.deleteUser(userId);//TODO: NEED TO BE LINKED WITH ROLE SERVICE API
      //SuccessAlert("success", "Sucessfully deleted");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editUser = (userId, index) => {
      console.log({index, userId})
    try {
      //let response = props.editUser(JSON.stringify(index), userId);//TODO: NEED TO BE LINKED WITH ROLE SERVICE API
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };
 
  return (
    <div>
      {props.users && (
        <View
          {...props}
          deleteUser={deleteUser}
          //handleCreate={handleCreate}
          editUser={editUser}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.items,
});

export default connect(mapStateToProps, {
  getUsers,
  deleteUser,
  editUser,
})(Index);
