import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Card from "../../../../components/general/Card";
import Collapse from "components/general/Collapse";
import Delete from "components/general/Delete";
import AddCarrier from "./Add";
import _ from "lodash"
import FormComponent from "components/general/FormControllerList";
import { Alert } from "reactstrap";
import Options from "../Options";

// FIXME: Status has issues
const View = (props) => {
    let { deleteCarrier, editCarrier, carriers } = props;
    //console.log(props);

    let [state, setCarriers] = useState(carriers);
    let [errors, setError] = useState({
        nameError: "",
    });

    let strategeyOptionHeaders = [
        "Shipping From",
        "Destination",
        "Delivery Time",
        "Cart Weight",
        "cost",
    ]
    let emptyRowOptions = {
        shippingFrom: "",
        shippingTo: "",
        dileveryTime: "",
        cartWeight: '',
        shippingCost: '',
    }

    const updateState = (input) => {
        console.log("Input", input);
        setCarriers({ ...state, ...input });
    };

    console.log("Carriers", carriers);

    return (
        <div className="setting">
            <Card name="Delivery Setting" goBack={props.history.goBack}>
                <div className="contact-table">
                    {/* Add Carrier */}
                    <div className="d-flex justify-content-end">
                        <AddCarrier {...props} updateState={updateState}/>
                    </div>

                    <Col xs="12" lg="8" xl="9" className="group-list">
                        {_.map(carriers, (data, key) => {
                            // List of Attrbiute
                            let attributeList = [];
                            attributeList = [
                                {
                                    name: "shippingFrom",
                                    type: "select",
                                    options: [{ label: 'Jeddah', value: 'jeddah' },
                                    { label: 'UAE', value: 'uae' },
                                    { label: 'WorldWide', value: 'worldwide' }],
                                    target: "shippingCosts",
                                    targetName: "carriers",
                                    value: data.shippingCosts[key]?data.shippingCosts[key].shippingFrom: "",
                                    valueKey: "value",
                                    labelKey: "label",
                                },
                                {
                                    name: "shippingTo",
                                    type: "select",
                                    options: [{ label: 'Jeddah', value: 'jeddah' },
                                    { label: 'UAE', value: 'uae' },
                                    { label: 'WorldWide', value: 'worldwide' }],
                                    target: "shippingCosts",
                                    targetName: "carriers",
                                    value: data.shippingCosts[key]?data.shippingCosts[key].shippingTo: "",
                                    valueKey: "value",
                                    labelKey: "label",
                                },
                                {
                                    name: "dileveryTime",
                                    type: "select",
                                    options: [{ label: '3 - 5 Days', value: '3 - 5 Days' }],
                                    target: "shippingCosts",
                                    targetName: "carriers",
                                    value: data.shippingCosts[key]?data.shippingCosts[key].dileveryTime: "",
                                    valueKey: "value",
                                    labelKey: "label",
                                },
                                {
                                    name: data.shippingCostType === "CART WEIGHT"? "costPerWeight" : "shippingCost",
                                    type: "text",
                                    target: "shippingCosts",
                                    targetName: "carriers",
                                    value: data.shippingCostType === "CART WEIGHT"? data.shippingCosts[key]?data.shippingCosts[key].costPerWeight:"" : data.shippingCosts[key]?data.shippingCosts[key].shippingCost:"" ,
                                },
                            ]
                            {
                                data.shippingCostType === "CART WEIGHT" && (
                                    attributeList.splice(3, 0, ...[{
                                        name: "cartWeight",
                                        type: "text",
                                        target: "shippingCosts",
                                        targetName: "carriers",
                                        value: data.shippingCosts[key]?data.shippingCosts[key].cartWeight:"",
                                    }])
                                )
                            }

                            return (
                                <Collapse
                                    group={{
                                        label: `${data.nameEN}`,
                                        id: key,
                                        open: false,
                                    }}
                                >
                                    {/* Form */}
                                    <FormComponent
                                        type="switch"
                                        id={`activate_${key}`}
                                        name="isActive"
                                        label="Activate"
                                        target={carriers[key]}
                                        targetName="carriers"
                                        value={data.isActive}
                                        updateState={updateState}
                                        error={_.get(errors, "nameError")}
                                    />
                                    <FormComponent
                                        type="text"
                                        name="nameEN"
                                        label="English Name"
                                        target={carriers[key]}
                                        targetName="carriers"
                                        value={data.nameEN}
                                        updateState={updateState}
                                        error={_.get(errors, "nameError")}
                                    />
                                    {!_.isEmpty(_.get(errors, "nameError")) && (
                                        <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                                    )}
                                    <FormComponent
                                        type="text"
                                        name="nameAR"
                                        label="Arabic Name"
                                        value={data.nameAR}
                                        target={carriers[key]}
                                        targetName="carriers"
                                        updateState={updateState}
                                        error={_.get(errors, "nameError")}
                                    />
                                    {!_.isEmpty(_.get(errors, "nameError")) && (
                                        <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                                    )}
                                    <FormComponent
                                        type="groupCheckbox"
                                        options={[{ label: 'KSA', value: 'KSA' }, { label: 'UEA', value: 'UEA' },{ label: 'MA', value: 'MA' }]}
                                        name="channels"
                                        label="Channels"
                                        value={data.channels}
                                        default={data.channels}
                                        target={carriers[key]}
                                        targetName="carriers"
                                        valueKey="value"
                                        labelKey="label"
                                        updateState={updateState}
                                    />
                                    <Collapse
                                        group={{
                                            label: "Shipping Cost Setting",
                                            id: `"data"${key}`,
                                            open: false,
                                        }}
                                    >
                                        <FormComponent
                                            type="select"
                                            options={[{ label: 'Flat Rate', value: "FLAT RATE" },
                                            { label: 'Based on Cart Weight Rate', value: "CART WEIGHT" }
                                            ]}
                                            name="shippingCostType"
                                            label="Shipping Cost Strategy"
                                            value={data.shippingCostType}
                                            target={carriers[key]}
                                            targetName="carriers"
                                            valueKey="value"
                                            labelKey="label"
                                            updateState={updateState}
                                        />
                                        <Options
                                            attributeList={attributeList}
                                            {...props}
                                            data={data}
                                            target={carriers}
                                            targetName={"carriers"}
                                            targetOptionName={"shippingCosts"}
                                            options={data.shippingCosts}
                                            updateState={updateState}
                                            hasAddOption={true}
                                            headers={data.shippingCostType === "CART WEIGHT" ? strategeyOptionHeaders : _.filter(strategeyOptionHeaders, opt => opt !== 'Cart Weight')}
                                            newOptions={data.shippingCostType === "CART WEIGHT" ? emptyRowOptions : _.omit(emptyRowOptions, 'CART WEIGHT')}
                                            addMore={"Add New Destination Cost"}
                                        />
                                    </Collapse>

                                    {/* Language Actions */}
                                    <div className="row justify-content-end">
                                        <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                                            <Button
                                                className="btn btn-primary btn-cons btn-cons"
                                                onClick={() =>
                                                     editCarrier(carriers[key]._id, carriers[key])
                                                }
                                            >
                                                {"Save"}
                                            </Button>

                                            <Delete
                                                data={data.nameEN}
                                                onSubmit={deleteCarrier}
                                                customerID={carriers[key]._id}

                                                actionType="button"
                                                buttonStyle="btn btn-white btn-cons"
                                            />
                                        </div>
                                    </div>
                                </Collapse>
                            );
                        })}
                    </Col>
                </div>
            </Card>
        </div>
    );
};

export default View;
