import React, { useState } from "react";
import _ from "lodash";
import { Button, Card } from "reactstrap";
import Collapse from "../Collapse";
//import Attribute from "./attribute"

import FormComponent from "../FormController";
import FormComponentList from "../FormControllerList";
import MediaList from "../MediaList";
import Table from "../Table";
import ProgressSteps from "../../../components/general/StepNavigation/StepIndecator";
import StepsNavigator from "components/general/StepNavigation/StepNavigator";
import ExportButton  from "components/general/ExportButton"

const CollapseList = (props) => {
  let {
    data,
    group,
    usingFormContollerList,
    headerList,
    target,
    hasButton,
    onClick,
    handleDownloadExcel
  } = props;
  let tableItems = props.items ? props.items : group.items;

  /**FIXME:
   * Call the Callapse component
   * Target: Ethier equal to data  or Equal to specific path
   * such as >> data.seo.[inner-name]
   */

  return (
    <Collapse {...props}>
      {/*{hasButton && (
        <div>
          <Button color="primary" className="d-flex justify-content-end" style={{ float: 'right' , margin: '1%'}} onClick={onClick}>
            {"Add New"}
          </Button>
        </div>
      )}*/}
      {/* For handelling Dynamic and Static Group of attributes */}
      {props.collapseCase === "ListAttribute" &&
        _.map(group.attributes, (attr) =>
          /* Handling which Form Controller should be using */
          usingFormContollerList ? (
            <FormComponentList
              {...props}
              type={attr.type}
              id={attr.id ? attr.id : [props.name]}
              name={attr[props.name]}
              label={props.usingEnName ? attr.name_en : attr.label}
              value={target[attr[props.name]]}
              default={target[attr[props.name]]}
              disabled={attr.disabled}
              options={attr.option}
              valueKey={attr.valueKey ? attr.valueKey : "value"}
              labelKey={attr.labelKey ? attr.labelKey : "label"}
            />
          ) : (
            <FormComponent
              {...props}
              type={attr.type}
              id={attr[props.name]}
              name={attr[props.name]}
              label={props.usingEnName ? attr.name_en : attr.label}
              value={data[attr[props.name]]}
              default={data[attr[props.name]]}
              disabled={attr.disabled}
              options={attr.option}
              valueKey={attr.valueKey ? attr.valueKey : "value"}
              labelKey={attr.labelKey ? attr.labelKey : "label"}
            />
          )
        )}
      {props.collapseCase === "Media" && <MediaList {...props} {...group} />}
      {props.collapseCase === "stepper" && (
        <div className="segmentation">
          <div className="row-fluid">
            <div className="span12">
              <div
                className="row main-content"
                style={{ flexDirection: "column" }}
              >
                <ProgressSteps progressSteps={group.progressSteps} {...props} />
              </div>

              <Button
                color="primary"
                onClick={() => { }}
                className="d-flex justify-content-center"
                style={{ /*width: "100%",*/ marginLeft: "38%" }}
              >
                {"Action Required"}
              </Button>
            </div>
          </div>
        </div>

      )}
      {props.collapseCase === "table" && (
        <>
          {tableItems.length === 0 && (
            <div className="contact-table">There is No items yet !</div>
          )}
          {tableItems.length !== 0 && (
            <div className="contact-table">
         {group.exported && (
          <div className="segmentation my-2 d-flex flex-row-reverse">
          <ExportButton data={handleDownloadExcel()} fileName={`Product Data.xlsx`} />
          </div>
          )}
              <Table
                {...props}
                data={
                  group.label === "Inventory"
                    ? handleInventoryData(tableItems)
                    : tableItems
                }
                cellEdit={true}
                isSelectRow={false}
                noSearch={true}
                headers={headerList}
              />
            </div>
          )}
        </>
      )}
      {props.children}
    </Collapse>
  );
};

export default CollapseList;

function handleInventoryData(branches) {
  let branchList = [];

  _.forEach(branches, (branch) => {
    let item = {
      ...branch,
      branch: branch.branch_id.name_en,
      min: branch.inventory.min_quantity,
      max: branch.inventory.max_quantity,
    };
    branchList.push(item);
  });
  return branchList;
}
