import { ADD_ADDRESS, DELETE_ADRESS, GET_ADDRESSES, FIND_ADDRESS, EDIT_ADRESS } from "../actions/types"

import _ from "lodash"

const initialState = {
    items: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DELETE_ADRESS:
        case EDIT_ADRESS:
        case FIND_ADDRESS:
        case ADD_ADDRESS:
            return {
                ...state,
                item: action.payload
            }
        case GET_ADDRESSES:
            return {
                ...state,
                items: action.payload
            }

        default:
            return state
    }
}
