import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
} from "reactstrap";

import { useHistory } from "react-router-dom";

const GeneralCard = (props) => {
  const history = useHistory();

  const {
    name,
    title,
    subtitle,
    text,
    image,
    button,
    handleOnClick,
    item,
  } = props;
  return (
    <Card
      body
      className={props.className}
      style={props.cardStyle}
      onClick={handleOnClick ? handleOnClick : () => {}}
    >
      {name && (
        <CardTitle className="grid-title">
          <h4
            className={props.titleStyle}
            onClick={() => (props.goBack ? props.goBack() : null)}
          >
            {props.goBack && <i className="fa fa-angle-left f-size-20" />}
            {name}
          </h4>
        </CardTitle>
      )}
      {props.image && (
        <CardImg
          top
          width="100%"
          height="auto"
          src={image}
          alt={title && title}
        />
      )}

      <CardBody className="grid-body" style={props.bodyStyle}>
        {props.children}
        {title && <CardTitle>{title}</CardTitle>}
        {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
        {text && <CardText>{text}</CardText>}
        {button && (
          <button
            class={"btn btn-primary w-100 mt-3"}
            onClick={() => history.push({ pathname: button.url })}
          >
            {button.title}
          </button>
        )}
      </CardBody>
    </Card>
  );
};
export default GeneralCard;
