import * as React from "react";
const PromotionSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1706.667}
    height={1706.667}
    viewBox="0 0 1280 1280"
    {...props}
  >
    <path d="M32.6 2.7C16.2 8.6 6.7 18.9 1.9 36 0 42.8 0 48.5 0 265.6v222.7l2.1 7.1c1.1 3.9 3.2 9.1 4.6 11.6 3.2 5.6 757.6 760.7 764.6 765.1 5.2 3.4 18.4 7.9 23.2 7.9 4.3 0 17.6-4.3 22.7-7.3 6.3-3.7 454.1-452.2 456.6-457.2 2.6-5.3 6.2-17.5 6.2-21.1 0-4.7-4.5-18-7.9-23.1-4.4-7-759.5-761.4-765.1-764.6-2.5-1.4-7.7-3.5-11.6-4.6L488.3 0 263.9.1 39.5.2l-6.9 2.5zM288.8 180c31.9 9.1 55.7 33.8 63.3 65.7 2.8 11.8 2.8 29.6 0 40.5-8.6 33.4-32.5 57.3-65.9 65.9-10.9 2.8-28.7 2.8-40.6 0-32-7.6-56.5-31.4-65.8-63.8-1.9-6.6-2.3-10.1-2.2-22.8.1-13.3.4-16 2.7-23.8 10.3-33.7 36.9-57.4 71.4-63.7 8.1-1.5 28.9-.4 37.1 2z" />
  </svg>
);
export default PromotionSvg;
