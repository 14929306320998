import {
  ADD_MEDIA,
  EDIT_MEDIA,
  GET_MEDIA,
  FIND_MEDIA,
  DELETE_MEDIA,
} from "./types";
import fetchAPIClass  from "../fetchAPI";
import { restfulUrl , API_URL} from "../../../../src/context/url";

export const getAllMedia = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/media/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_MEDIA);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const uploadMedia = (media) => (dispatch) => {
  let url = `${API_URL}/ecommerce/media/upload`;
  try {
    console.log("inside uplaod media", media);
    var formData = new FormData();
    media.map(function (item) {
      formData.append("media", item);
      return item;
    });

    console.log("inside uplaod media", media);

    let responseMedia = fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: ADD_MEDIA,
          payload: data,
        })
      );

    return responseMedia;
  } catch (err) {
    console.log(err);
  }
};
