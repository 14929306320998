import {
  ADD_LANGUAGE,
  EDIT_LANGUAGE,
  DELETE_LANGUAGE,
  GET_LANGUAGES,
  FIND_LANGUAGE,
} from "../types";
import fetchAPIClass  from "../../fetchAPI";
import { restfulUrl, API_URL } from "context/url";

export const getLanguages = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/language/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_LANGUAGES);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addLanguage = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/language/add`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_LANGUAGE);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteLanguage = (languageId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/language/delete/${languageId}`;
  console.log(languageId);
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_LANGUAGE);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editLanguage = (inputs, languageId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/language/edit/${languageId}`;
  console.log(requestBody);
  console.log(languageId);

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_LANGUAGE);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findLanguage = (languageId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/language/find/${languageId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_LANGUAGE);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
