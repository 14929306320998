//import React, { useState, useEffect, useContext } from "react";
import React, { useState, useEffect } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addDelivery } from "redux/actions/settings/Order/delivery";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import { getCarriers } from "redux/actions/settings/Order/carrier";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
  let [state, setState] = useState({
    isActive: true,
    nameEN: "",
    nameAR: "",
    storeId: "61212d9bbb3af02d3f72b2c3",
    channels: [],
    // coverageArea: [],
    carriers: [],
    inventoryType: "",
    splitShipping: true,
    deliveryStrategy: "",
    maximumShipping: "",
    singleCostForAllShipments: "",
    errors: {
      nameError: "",
    },
  });

  let [modal, setModal] = useState(false);

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const reset = () => {
    setState(state);
  };

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    props.getCatalogs();
  }, []);

  useEffect(() => {
    props.getCarriers();
  }, []);

  const onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = state;

    if (inputs.nameEN === "" || inputs.nameAR === "") {
      errors.nameError = "Sorry, 'Name' in both deliverys are required!";
    }

    if (errors.nameError === "") {
      addDelivery(e);
    } else {
      setState({ errors: errors });
    }
  };

  const addDelivery = () => {
    console.log(" addDelivery", state);
    let delivery = state;
    delete delivery.errors;
    try {
      props.addDelivery(JSON.stringify(delivery));
      console.log(" addDelivery", delivery);
      SuccessAlert("success", "Sucessfully Added");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    reset();
    toggle();
  };

  return (
    <View
      {...props}
      data={state}
      onChange={onChange}
      onSumbit={addDelivery}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
    />
  );
};

const mapStateToProps = (state) => ({
  //cartList: state.cartList.items,
  catalogs: state.catalogs.items,
  carriers: state.carriers.items,
});

export default connect(mapStateToProps, {
  addDelivery,
  getCatalogs,
  getCarriers,
})(Index);
