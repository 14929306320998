import {
  ADD_ECOM_ATTRIBUTE,
  EDIT_ECOM_ATTRIBUTE,
  GET_ECOM_ATTRIBUTES,
  FIND_ECOM_ATTRIBUTE,
  DELETE_ECOM_ATTRIBUTE,
  GET_FILTERED_ATTRIBUTES
} from "../../actions/Ecommerce/types";

const initialState = {
  items: null,
  item: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ECOM_ATTRIBUTE:
    case EDIT_ECOM_ATTRIBUTE:
    case DELETE_ECOM_ATTRIBUTE:
    case FIND_ECOM_ATTRIBUTE:
      return {
        ...state,
        item: action.payload,
      };
    case GET_ECOM_ATTRIBUTES:
    case GET_FILTERED_ATTRIBUTES:
      return {
        ...state,
        items: action.payload,
      };

    default:
      return state;
  }
}
