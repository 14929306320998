import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Table from "../../../../components/general/Table";
import Card from "../../../../components/general/Card";
import HeaderActions from "../../../../components/general/HeaderBtns";
import CollapseList from "components/general/PageDetails/CollapseList";
import Delete from "components/general/Delete";
import _ from "lodash";
import AddMap from "./Add";

const View = (props) => {
    let { deleteMap, editMap, map, catalogs } = props;
    console.log(props);

    let [mapList, setMapList] = useState(map);

    let mapData = [];
    _.map(mapList, (data, index) => {
        data.status = data.status === "active" ? true : false;
        let item = {
            id: index,
            label: `Map ${data.name_en}`,
            collapseCase: "ListAttribute",
            open: false,
            attributes: [
                {
                    type: "text",
                    name: "_id",
                    label: "Map ID",
                    value: data["_id"],
                    default: data["_id"],
                    disabled: true,
                },
                {
                    label: "Map English Name",
                    name: "name_en",
                    type: "text",
                    value: data["name_en"],
                    default: data["name_en"],
                },
                {
                    label: "Map Arabic Name",
                    name: "name_ar",
                    system_name: "name_ar",
                    type: "text",
                    value: data["name_ar"],
                    default: data["name_ar"],
                },
                {
                    type: "select",
                    options: catalogs,
                    name: "channel",
                    label: "Channel",
                    value: data["channel"],
                    default: data["channel"],
                    valueKey: "_id",
                    labelKey: "name_en",
                },
                {
                    label: "Google Api Key",
                    name: "google_api_key",
                    system_name: "google_api_key",
                    type: "text",
                    value: data["google_api_key"],
                    default: data["google_api_key"],
                },

            ],
        };
        mapData.push(item);
    });

    const updateState = (input) => {
        console.log("Input", input);
        setMapList([...input.mapList]);
    };


    return (
        <div className="setting">
            <Card name="Map Setting" goBack={props.history.goBack}>
                <p>Setup Map Integration with Google API</p>
                <div className="contact-table">
                    {/* Add Map */}
                    <div className="d-flex justify-content-end">
                        <AddMap {...props} />
                    </div>

                    <Col xs="12" lg="8" xl="9" className="group-list">
                        {_.map(mapData, (group, key) => (
                            <CollapseList
                                {...props}
                                group={group}
                                usingFormContollerList={true}
                                {...mapList}
                                target={mapList[key]}
                                targetName="mapList"
                                collapseCase={group.collapseCase}
                                name="name"
                                updateState={updateState}
                            >
                                {/* Language Actions */}
                                <div className="row justify-content-end">
                                    <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                                        <Button
                                            className="btn btn-primary btn-cons btn-cons"
                                            onClick={() =>
                                                editMap(mapList[key]._id, mapList[key])
                                            }
                                        >
                                            {"Save"}
                                        </Button>

                                        <Delete
                                            data={group.label}
                                            onSubmit={deleteMap}
                                            customerID={mapList[key]._id}
                                            actionType="button"
                                            buttonStyle="btn btn-white btn-cons"
                                        />
                                    </div>
                                </div>
                            </CollapseList>
                        ))}
                    </Col>
                </div>
            </Card>
        </div>
    );
};

export default View;
