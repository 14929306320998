import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";
import _ from "lodash";

import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";

/** * FIXME: 
1- Check status if active or not 
3- Solve the problem of refresh the page 
  >> should show the updated recently data
  >> currently, it shows the old passed data version from location
*/

const Index = (props) => {
  let shipment = {
      _id: 'dummyidfortest',
      status: 'new',
      shipment_type: "Delivery",
      store_id: {
          _id: 'storeId',
          name: "store2"
      },
      customer_id: {
        _id:'1234',
        creation_date: null,
        name: 'testter',
        email: 'customer@gmail.com',
        email_verified: false,
        phone: '0501234567',
        phone_verified: true,
      },
      address: {
          _id: "12345678",
          name: "Home",
          address_line: "address line ...",
          country: "Saudi Arabia",
          region: "Makkah",
          city: "Jeddah",
          district: "fkndvdkjfvn",
          note: "",
      },
      ordered_at: null,
      branch_id: null,
      payment_method: {
          payment_type: "credit card",
          name: 'Mada',
          payment_getway: "credit card",
          card_type: "visa",
          card_number: "xxxxxxxxxxxx8956",
          card_holder: "Mohammed",
          is3D_secure: true,
          status: "Received",
      },
      shipping_method: 'Mada',
      products: [],
  }


  let [state, setState] = useState(shipment);

  useEffect(() => {
    props.getCatalogs();
  }, []);

 

  const updateState = (input) => {
    console.log("Input", input);
    setState({ ...state, ...input });
  };

  const editShipment = async () => {
    try {
      state.status = state.status === true ? "active" : "inactive";

      //let response = props.editBrand(JSON.stringify(state), state._id);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log("State", state);

  return props.catalogs ? (
    <div>
      {" "}
      <View
        {...state}
        data={state}
        {...props}
        editShipment={editShipment}
        onChange={updateState}
      />{" "}
    </div>
  ) : (
    <div></div>
  );
};
const mapStatToProps = (state) => ({
  catalogs: state.catalogs.items,
});
export default connect(mapStatToProps, {
    getCatalogs,
 
})(Index);
