import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "components/general/Modal";
import FormComponent from "components/general/FormController";
import { InventoryType } from "@uxautomation/common";
import { maximumShippingOptions } from "seeder/maximumShipping";
import { enumsToListOFOptions } from "helpers/general-functions";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {
  const { onChange, data, modal, toggle, errors, catalogs, carriers } = props;
  console.log(data);
  // const inventoryTypeDemo = [
  //   {
  //     value: "inevotry",
  //     label: "Inevotry",
  //   },
  //   {
  //     value: "infinity",
  //     label: "Infinity",
  //   }
  // ];

  const deliveryStrategyDemo = [
    {
      value: "Nearest Fulfillment Source",
      label: "Nearest Fulfillment Source",
    },
    {
      value: "Highest Availability & Nearest Fulfillment Source",
      label: "Highest Availability & Nearest Fulfillment Source",
    },
  ];
  console.log("invetory Type", InventoryType);

  return (
    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>
      <ModalComponent
        title="Add New Delivery"
        toggle={toggle}
        isOpen={modal}
        size="lg"
      >
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="switch"
            id={`activate_`}
            name="isActive"
            label="Activate"
            value={data.isActive}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          <FormComponent
            type="text"
            name="nameEN"
            label="English Name"
            value={data.nameEN}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}
          <FormComponent
            type="text"
            name="nameAR"
            label="Arabic Name"
            value={data.nameAR}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}
          <FormComponent
            type="groupCheckbox"
            options={catalogs}
            name="channels"
            label="Channel"
            value={data.channels}
            valueKey="_id"
            labelKey="name_en"
            updateState={onChange}
          />
          {/* <FormComponent
            type="groupCheckbox"
            options={deliveryStrategyDemo}
            name="coverageArea"
            label="Coverage Area"
            value={data.coverageArea}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          /> */}
          <FormComponent
            type="groupCheckbox"
            options={carriers}
            name="carriers"
            label="Carrier Cost"
            value={data.carriers}
            valueKey="_id"
            labelKey="nameEN"
            updateState={onChange}
          />
          <FormComponent
            type="select"
            options={enumsToListOFOptions(InventoryType)}
            name="inventoryType"
            label="Inventory Type"
            value={data.inventoryType}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />
          <FormComponent
            type="switch"
            id={`splitShipping`}
            name="splitShipping"
            label="Split Shipment"
            value={data.splitShipping}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />

          <FormComponent
            type="select"
            options={deliveryStrategyDemo}
            name="deliveryStrategy"
            label="Delivery Strategy"
            value={data.deliveryStrategy}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />
          {data.splitShipping && (
            <div>
              <FormComponent
                type="select"
                options={maximumShippingOptions}
                name="maximumShipping"
                label="Maximum Shipments"
                value={data.maximumShipping}
                valueKey="value"
                labelKey="label"
                updateState={onChange}
              />

              <FormComponent
                type="switch"
                id={`singleCostForAllShipments`}
                name="singleCostForAllShipments"
                label="Calculate Single Cost for All Shipments"
                value={data.singleCostForAllShipments}
                updateState={onChange}
                error={_.get(errors, "nameError")}
              />
            </div>
          )}

          <ModalFooter>
            <Button
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
