import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";
import _ from "lodash";

import {
  deleteProduct,
  editProduct,
  editMasterProduct,
} from "../../../../redux/actions/Ecommerce/ProductAction";
import { getCategories } from "../../../../redux/actions/Ecommerce/CategoryAction";
import { getGroups } from "../../../../redux/actions/Ecommerce/GroupAction";
import { getCatalogs } from "../../../../redux/actions/Ecommerce/CatalogAction";
import { add } from "../../../../redux/actions/Ecommerce/GroupAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import { productTypes } from "seeder/productTypes";

/** * FIXME: 
1- Check status if active or not 
3- Solve the problem of refresh the page 
  >> should show the updated recently data
  >> currently, it shows the old passed data version from location
*/

const Index = (props) => {
  let product = props.location.state.item;
  product.status = _.toLower(product.status) === "active" ? true : false;
  product.seo = product.seo ? product.seo : {};
  product.shipping = product.shipping ? product.shipping : {};
  let [state, setState] = useState(product);
  let [variants, setVariants] = useState(product.variants);
  let [modal, setModal] = useState(false);

  useEffect(() => {
    // GET Group Attribute List
    props.getGroups("ecommerce");
    props.getCategories();
    props.getCatalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [props.product]);

  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "branch",
      align: "center",
      title: "Branch",
    },
    {
      type: "quantity",
      align: "center",
      sortable: true,
      title: "QTY",
      editable: { type: "number" },
    },
    {
      type: "min",
      align: "center",
      sortable: true,
      title: "MIN QTY",
      editable: { type: "number" },
    },
    {
      type: "max",
      align: "center",
      sortable: true,
      title: "MAX QTY",
      editable: { type: "number" },
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
  ];

  let toggle = () => setModal(!modal);

  const deleteProduct = (productID) => {
    try {
      props.deleteProduct(productID);
      SuccessAlert("success", "Sucessfully deleted");
      props.history.push("/product");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const updateState = (input) => {
    console.log("Input", input);
    setState({ ...state, [_.keys(input)]: _.get(input, `${_.keys(input)}`) });
  };

  const setVarinatsProduct = (variants) => {
    setVariants(variants);
  };

  const prepareVariants = () => {
    state.type = "configurable";
    state.variants = variants;
    setState(state);
  };

  const editProduct = async () => {
    try {
      console.log("State product v", variants);
      state.status = state.status === true ? "active" : "inactive";

      if (variants.length !== 0) prepareVariants();

      console.log("State edi product", state);

      let response = props.editProduct(state._id, JSON.stringify(state));
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log("State", state);
  console.log("Group Attributes", props.groupAttributes);

  return props.categories ? (
    props.catalogs && (
      <div>
        {" "}
        <View
          {...state}
          data={state}
          {...props}
          deleteProduct={deleteProduct}
          editProduct={editProduct}
          onChange={updateState}
          variants={variants}
          setVarinats={setVarinatsProduct}
          productTypes={productTypes}
          headerList={headerList}
          modal={modal}
          toggle={toggle}
        />{" "}
      </div>
    )
  ) : (
    <div></div>
  );
};

const mapStateToProps = (state) => ({
  product: state.products.item,
  groupAttributes: state.ecommerceGroups.items,
  categories: state.categories.items,
  catalogs: state.catalogs.items,
});

export default connect(mapStateToProps, {
  deleteProduct,
  editProduct,
  editMasterProduct,
  getGroups,
  getCategories,
  getCatalogs,
})(Index);
