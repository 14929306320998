import React, { useState } from "react";
import { Card, CardTitle } from "reactstrap";
import TypeStep from "components/import-export/TypeStep";
import ProcessingStep from "components/import-export/ProcessingStep";
import UploadStep from "components/import-export/UploadStep";
import MapStep from "components/import-export/MapStep";
import ConfirmedStep from "components/import-export/ConfirmedStep";
import { getFilterdAttributes } from "redux/actions/Ecommerce/AttributeAction";
import ProgressSteps from "./StepNavigation/StepIndecator";
import { connect } from "react-redux";

const ImportExport = ({ onHide, ...props }) => {
  const [step, setStep] = useState("Type");
  const [collapsed, setCollapsed] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [allowUnmatchedColumns, setAllowUnmatchedColumns] = useState(false);
  const [updateMapdData, setMapUpdatedData] = useState(null);
  const [lastProgressPercentage, setLastProgressPercentage] = useState(0);
  const [updateWithProvidedID, setUpdateWithProvidedID] = useState(false);
  const handleUpdate = (data) => {
    setMapUpdatedData(data);
  };
  const handleProcessImportData = (data) => {
    data.updateWithProvidedID = updateWithProvidedID;
    props.bulkProcessImportData(data);
  };

  const handleNextStep = () => {
    switch (step) {
      case "Type":
        if (selectedType) {
          setStep("Upload");
        }
        break;
      case "Upload":
        if (fileUploaded) {
          setStep("Map");
        }
        break;
      case "Map":
        handleProcessImportData(updateMapdData);
        setStep("Processing");
        break;
      case "Processing":
        if (lastProgressPercentage === 100) {
          setStep("Confirmed");
        }
        break;
      case "Confirmed":
        setStep("Complete");
        break;
      default:
        setStep("Type");
    }
  };

  const handlePrevStep = () => {
    switch (step) {
      case "Upload":
        setStep("Type");
        break;
      case "Map":
        setStep("Upload");
        break;
      case "Processing":
        // Prevent going back from Processing step
        break;
      case "Confirmed":
        setStep("Processing");
        break;
      default:
        setStep("Type");
    }
  };

  const handleTypeSelection = (type) => {
    setSelectedType(type);
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleFileUpload = async (file) => {
    let type;
    if (selectedType === "Contact") {
      type = "crm";
    } else if (selectedType === "Product") {
      type = "ecommerce";
    }
    let response = await props.bulkUploadFile(file, type);
    setFileUploaded(true);
  };

  const steps = [
    {
      step: 1,
      title: "Type",
      component: (
        <TypeStep
          onTypeSelection={handleTypeSelection}
          selectedType={selectedType}
          {...props}
        />
      ),
    },
    {
      step: 2,
      title: "Upload",
      component: (
        <UploadStep
          selectedType={selectedType}
          onFileUpload={handleFileUpload}
          isChecked={updateWithProvidedID}
          setIsChecked={setUpdateWithProvidedID}
          {...props}
        />
      ),
    },
    {
      step: 3,
      title: "Map",
      component: (
        <MapStep
          {...props}
          selectedType={selectedType}
          handleUpdate={handleUpdate}
        />
      ),
    },
    {
      step: 4,
      title: "Processing",
      component: (
        <ProcessingStep
          lastProgressPercentage={lastProgressPercentage}
          setLastProgressPercentage={setLastProgressPercentage}
          {...props}
        />
      ),
    },
    { step: 5, title: "Confirmed", component: <ConfirmedStep {...props} /> },
  ];

  return (
    <Card body>
      <CardTitle className="grid-title d-flex justify-content-between">
        <h4 onClick={onHide}>Import Files</h4>
        <i
          className={`fa f-size-20 ${
            collapsed ? "fa-angle-down" : "fa-angle-up"
          }`}
          onClick={handleCollapse}
        />
      </CardTitle>
      {!collapsed && (
        <div className="import-export">
          <div className="clearfix"></div>
          <div className="row-fluid">
            <div className="span12">
              <div className="grid simple">
                <div className="segmentation">
                  <div className="row-fluid">
                    <div className="span12">
                      <div
                        className="row main-content"
                        style={{ flexDirection: "column" }}
                      >
                        <ProgressSteps
                          progressSteps={steps}
                          currentStep={step}
                        />
                        {steps.find(({ title }) => title === step)?.component}

                        <div className="button-group">
                          <div className="btm-wr flex justify-between">
                            <div className="flex w-10 justify-between align-center">
                              {step !== "Type" && (
                                <button
                                  value="prev"
                                  className="flex align-center justify-start btn-prev"
                                  onClick={handlePrevStep}
                                >
                                  <div className="gg-chevron-left icon-mr"></div>
                                  Back
                                </button>
                              )}
                              <button value="cancel" className="btn-cancel">
                                Cancel
                              </button>
                            </div>
                            <div className="flex align-center justify-end">
                              {/* Add new element here */}
                              <div className="flex align-center next-button-imp">
                                {step === "Map" && (
                                  <div className="unmatched-columns">
                                    You have{" "}
                                    {props.uploadedFile.unmatchedHeaders.length}{" "}
                                    unmatched columns. <br /> Do not import data
                                    in unmatched columns <span> </span>
                                    <input
                                      type="checkbox"
                                      checked={allowUnmatchedColumns}
                                      onChange={(e) =>
                                        setAllowUnmatchedColumns(
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                )}

                                <button
                                  value="next"
                                  className="flex align-center justify-end btn-next "
                                  onClick={handleNextStep}
                                >
                                  Next
                                  <div className="gg-chevron-right icon-mr"></div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};
export default ImportExport;
