import React from "react";
import { Label } from "reactstrap";
import FormComponent from "../FormController";
import _ from "lodash";

const Info = (props) => {
  const { onChange, data, handleOnStatusChange, info } = props;

  return (
    <div class="general-info">
      {_.map(info, (item) => (
        <FormComponent
          type={item.type}
          name={item.name}
          id={item.id ? item.id : ""}
          label={item.label}
          options={item.options}
          valueKey={item.valueKey ? item.valueKey : "value"}
          labelKey={item.labelKey ? item.labelKey : "label"}
          classFormGroup={item.classFormGroup ? "d-flex" : ""}
          classLabel={item.classLabel ? "mr-3" : ""}
          value={item.value}
          updateState={item.updateState ? handleOnStatusChange : onChange}
          default={item.default ? item.default : ""}
          disabled={item.disabled ? item.disabled : true}
        />
      ))}
      {!props.noDateRagne && (
        <div class="date-range">
          <Label>Date Range:</Label>
          <DateRange
            classType="from"
            label="From"
            name={"date_from"?? "start_date"}
            value={data["date_from"]? data["date_from"] : data["start_date"]}
            updateState={onChange}
          />
          <DateRange
            classType="to"
            label="To"
            name= {"date_to"?? "end_date"}
            value={data["date_to"]? data["date_to"] : data["end_date"]}
            updateState={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default Info;

const DateRange = ({ classType, label, name, value, updateState }) => {
  return (
    <div class={classType}>
      <Label>{label}</Label>
      <FormComponent
        type="date"
        name={name}
        value={value}
        updateState={updateState}
      />
    </div>
  );
};
