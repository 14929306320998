import React, { Component } from "react"
import View from "./View"
class index extends Component {
  render() {
    return (
      <div>
        <View {...this.props} />
      </div>
    )
  }
}

export default index
