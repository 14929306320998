import React, { useEffect, useState } from "react";
import Card from "components/general/Card";
import { Row, Col, Button } from "reactstrap";
import _ from "lodash";

const Index = (props) => {
  let { updateState, state, promotionTypes } = props;

  const typeList = [
    {
      title: "Cart Rules",
      subtitle:
        "Cart price rules apply discounts to items in the shopping cart, based on a set of conditions. Use for a Complex Promotion",
      name: "cart",
      id: _.find(promotionTypes, (type) => type.type === "cart")._id,
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Catalog Rules",
      subtitle:
        "Catalog price rules can be used to selectively put products on sale under certain conditions.",
      name: "catalog",
      id: _.find(promotionTypes, (type) => type.type === "catalog")._id,
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Coupons",
      subtitle:
        "Discount code or number that can be redeemed on an online channel.",
      name: "coupon",
      id: _.find(promotionTypes, (type) => type.type === "coupon")._id,
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Gift",
      subtitle:
        "You can use promotional gifts as a reward to loyal customers after they've made a purchase for a certain amount,",
      name: "gift",
      id: "gift",
      image: "/assets/img/setting/Catalog.png",
    },
  ];

  return (
    <>
      <p className="d-flex justify-content-center" style={{ fontSize: 30 }}>
        Select the type of Promotion
      </p>
      <Row className="d-flex justify-content-center" style={{ margin: "2%" }}>
        {typeList.map((item) => {
          return (
            <Col lg={3} sm={4} md={4} xs={6} className="text-center settingImg">
              <Card
                image={item.image}
                title={item.title}
                subtitle={item.subtitle}
                className={item.name === state.typeName ? "selected-rules" : ""}
                handleOnClick={() =>
                  updateState({
                    ...state,
                    type: item.id,
                    typeName: item.name,
                  })
                }
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default Index;
