import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getShipments,
  deleteShipment,
} from "redux/actions/OrderMangement/ShipmentAction";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";
import View from "./View";

const demoData = [
  {
    _id: 1234,
    status: "active",
    name_en: "Name 2",
    creation_date: "22 nov 2022",
    purchase_points: "SA website version Arabic",
    address: "Alnakhil, jeddah saudi arabia",
    shipping: "Delivery",
    payment: "Mada",
    total: "1855",
  },
  {
    _id: 1235,
    status: "active",
    name_en: "Name 1",
    creation_date: "22 nov 2022",
    purchase_points: "SA website version Arabic",
    address: "Alnakhil, jeddah saudi arabia",
    shipping: "Delivery",
    payment: "Mada",
    total: "1555",
  },
  {
    _id: 1236,
    status: "active",
    name_en: "Name 1",
    creation_date: "22 nov 2022",
    purchase_points: "SA website version Arabic",
    address: "Alnakhil, jeddah saudi arabia",
    shipping: "Delivery",
    payment: "Mada",
    total: "1555",
  },
];

const Index = (props) => {
  useEffect(() => {
    props.getShipments("61212d9bbb3af02d3f72b2ce");
  }, []);

  const deleteShipment = (orderId) => {
    try {
      props.deleteShipment(orderId);
      SuccessAlert("success", "Sucessfully deleted");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    props.history.push("/shipment");
  };

  return (
    <div>
      {props.shipments && (
        <View
          {...props}
          deleteShipment={deleteShipment}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  shipments: state.shipments.items,
});

export default connect(mapStateToProps, {
  getShipments,
  deleteShipment,
})(Index);
