import {
  ADD_TAG,
  GET_TAGS,
  DELETE_TAG,
  EDIT_TAG,
  FIND_TAG,
  DUPLICATE_TAG,
  ADD_TEMPLATE_TAG,
  GET_TEMPLATE_TAGS,
  DELETE_TEMPLATE_TAG,
  EDIT_TEMPLATE_TAG,
  FIND_TEMPLATE_TAG,
} from "./types"
import { restfulUrl , API_URL} from "../../../src/context/url"
import  fetchAPIClass  from "./fetchAPI"


export const addTag = inputs => dispatch => {
  let requestBody = inputs
  let url = `${API_URL}/tag/add`
  console.log(url)

  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_TAG)
    let response = api.fetchAPI(dispatch)
    //console.log(response)
  } catch (err) {
    console.log(err)
  }
}

export const editTag = (inputs, tagId) => dispatch => {
  let requestBody = inputs
  let url = `${API_URL}/tag/edit/${tagId}`
  console.log(url)

  try {
    const api = new fetchAPIClass(url, "POST", requestBody, EDIT_TAG)
    let response = api.fetchAPI(dispatch)
    //console.log(response)
  } catch (err) {
    console.log(err)
  }
}

export const duplicateTag = (inputs, tagId) => dispatch => {

  let requestBody = inputs
  let url = `${API_URL}/tag/duplicate/${tagId}`
  console.log(url)

  try {
    const api = new fetchAPIClass(url, "POST", requestBody, DUPLICATE_TAG)
    let response = api.fetchAPI(dispatch)
    console.log(response)
  } catch (err) {
    console.log(err)
  }

}

export const getTags = businessId => dispatch => {

  let url = `${API_URL}/tag/all/${businessId}`

  console.log("URL", url)
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_TAGS)
    let response = api.fetchAPI(dispatch)
    console.log(response)
  } catch (err) {
    console.log(err)
  }
}

export const deleteTag = tagId => dispatch => {

  let url = `${API_URL}/tag/delete/${tagId}`
  console.log("URL", url)

  try {
    const api = new fetchAPIClass(url, "POST", null, DELETE_TAG)
    let response = api.fetchAPI(dispatch)
    console.log(response)
  } catch (err) {
    console.log(err)
  }
}

export const findTag = tagId => dispatch => {

  let url = `${API_URL}/tag/find/${tagId}`
  console.log("URL", url)

  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_TAG)
    let response = api.fetchAPI(dispatch)
    console.log(response)
  } catch (err) {
    console.log(err)
  }
}

/** Here their Tags Templates Actions  */
export const getTagsTemplates = businessId => dispatch => {

  let url = `${API_URL}/tag/template/all/${businessId}`

  console.log("URL", url)
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_TEMPLATE_TAGS)
    let response = api.fetchAPI(dispatch)
    console.log(response)
  } catch (err) {
    console.log(err)
  }
}

export const findTemplateTag = (templateId, businessId) => dispatch => {
  let url = `${API_URL}/tag/template/find/${templateId}/${businessId}`
  console.log("inside findTemplateTag, ", templateId, businessId, url);
  
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_TEMPLATE_TAG)
    let response = api.fetchAPI(dispatch)
    console.log(response)
  } catch (err) {
    console.log(err)
  }
}

/*TODO: ADD REMAINS CRUD FUNCTIONS FOR TAGS TEMPLATES */


