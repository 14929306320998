import React from "react";
import { Route, Switch } from "react-router-dom";

import Order from "../views/OrderMangment/Order";
import Shipment from "../views/OrderMangment/Shipment";
import OrderDetails from "../views/OrderMangment/Order/OrderDetails";
import ShipmentDetails from "../views/OrderMangment/Shipment/ShipmentDetails";

import NotFound from "../components/general/NotFound";
import QuickNavigation from "../components/layouts/QuickNavigation";

const QuickNavList = [
  {
    title: "Contact List",
    src: "/assets/img/svg/contact-list.svg",
    path: "/crm/contacts",
  },
  {
    title: "Segmentation",
    src: "/assets/img/svg/segmentation.svg",
    path: "/crm/segmentations",
  },
  {
    title: "Tags",
    src: "/assets/img/svg/segmentation.svg",
    path: "/crm/tags",
  },
  {
    title: "Automated Rule",
    src: "/assets/img/svg/automated-rules.svg",
    path: "/#",
  },
  {
    title: "Profile Attribute",
    src: "/assets/img/svg/profile-attribute.svg",
    path: "/crm/profile_attribute",
  },
  {
    title: "Import / Export",
    src: "/assets/img/svg/import-export.svg",
    path: "/crm/import_export",
  },
];
const orderManageRoutes = () => {
  const currentPath = "/order";
  return (
    <div>
      <QuickNavigation quickNavs={QuickNavList} />
      <Switch>
        {/* Order Managment Routes */}

        <Route path={`${currentPath}/index`} component={Order} />
        <Route path={`${currentPath}/shipment`} component={Shipment} />
        <Route path={`${currentPath}/order-details`} component={OrderDetails} />
        <Route
          path={`${currentPath}/shipment-details`}
          component={ShipmentDetails}
        />

        <Route component={NotFound} />
      </Switch>
    </div>
  );
};
export default orderManageRoutes;
