import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logout from "views/Auth/Logout";

class Header extends Component {
  render() {
    return (
      <div className="header navbar navbar-inverse ">
        <div className="navbar-inner">
          <div className="header-seperation">
            <ul className="nav pull-left notifcation-center visible-xs visible-sm">
              <li className="dropdown">
                <a data-webarch="toggle-left-side">
                  <i className="material-icons">menu</i>
                </a>
              </li>
            </ul>
            <Link to="/">
              <h2 style={{ color: "white", textAlign: "center" }}>
                UX<span className="semi-bold">-Automation</span>
              </h2>
              {/* <img src="assets/img/logo.png" className="logo" alt="" data-src="assets/img/logo.png" data-src-retina="assets/img/logo2x.png" width="106" height="21" /> */}
            </Link>
          </div>
          <div className="header-quick-nav">
            <div className="pull-left">
              <ul className="nav quick-section">
                <li className="quicklinks">
                  <a href="#" className="" id="layout-condensed-toggle">
                    <i className="material-icons">menu</i>
                  </a>
                </li>
              </ul>
              <ul className="nav quick-section">
                <li className="quicklinks">
                  <a
                    href=""
                    className=""
                    id="my-task-list"
                    data-placement="bottom"
                    data-content=""
                    data-toggle="dropdown"
                    data-original-title="Notifications"
                  >
                    <i className="material-icons">notifications_none</i>
                    <span className="badge badge-important bubble-only"></span>
                  </a>
                </li>

                <li className="m-r-10 input-prepend inside search-form no-boarder">
                  <span className="add-on">
                    {" "}
                    <i className="material-icons">search</i>
                  </span>
                  <input
                    name=""
                    type="text"
                    className="no-boarder "
                    placeholder="Search..."
                    style={{ width: "250px" }}
                  />
                </li>
              </ul>
            </div>
            <div id="notification-list" style={{ display: "none" }}>
              <div style={{ width: "300px" }}>
                <div className="notification-messages info">
                  <div className="user-profile">
                    <img
                      src="/assets/img/profiles/d.jpg"
                      alt=""
                      data-src="/assets/img/profiles/d.jpg"
                      data-src-retina="/assets/img/profiles/d2x.jpg"
                      width="35"
                      height="35"
                    />
                  </div>
                  <div className="message-wrapper">
                    <div className="heading">
                      David Nester - Commented on your wall
                    </div>
                    <div className="description">
                      Meeting postponed to tomorrow
                    </div>
                    <div className="date pull-left">A min ago</div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div className="pull-right">
              <ul className="nav quick-section ">
                <li className="quicklinks">
                  <a
                    data-toggle="dropdown"
                    className="dropdown-toggle  pull-right "
                    href="#"
                    id="user-options"
                  >
                    <i className="material-icons">tune</i>
                  </a>
                  <ul
                    className="dropdown-menu  pull-right"
                    role="menu"
                    aria-labelledby="user-options"
                  >
                    <li>
                      <a href="user-profile.html"> My Account</a>
                    </li>
                    <li>
                      <a href="calender.html">My Calendar</a>
                    </li>
                    <li>
                      <a href="email.html">
                        {" "}
                        My Inbox&nbsp;&nbsp;
                        <span className="badge badge-important animated bounceIn">
                          2
                        </span>
                      </a>
                    </li>
                    <li className="divider"></li>
                    <li>
                      <Logout history={this.props.history} />
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="chat-toggler sm">
                <div className="profile-pic">
                  <img
                    src="/assets/img/profiles/avatar_small.jpg"
                    alt=""
                    data-src="/assets/img/profiles/avatar_small.jpg"
                    data-src-retina="/assets/img/profiles/avatar_small2x.jpg"
                    width="35"
                    height="35"
                  />
                  <div className="availability-bubble online"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
