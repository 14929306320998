import React, { useState, useEffect } from "react";
const ProcessingStep = ({
  bulkProgressData,
  lastProgressPercentage,
  setLastProgressPercentage,
}) => {
  console.log("bulkProgressData=>", bulkProgressData);
  useEffect(() => {
    if (
      bulkProgressData &&
      bulkProgressData.hasOwnProperty("progressPercentage")
    ) {
      setLastProgressPercentage(bulkProgressData.progressPercentage);
    }
  }, [bulkProgressData]);

  if (!bulkProgressData) {
    return <div>Loading...</div>;
  }

  const { updatedRows, invalidRows, totalRows } = bulkProgressData;

  return (
    <div className="map-step">
      <h2 className="map-step-title">Processing Data</h2>
      <div className="mapping-progress-wrapper">
        <div style={{ position: "relative" }}>
          <progress value={lastProgressPercentage} max="100" />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -56%)",
              color: lastProgressPercentage >= 50 ? "white" : "black",
            }}
          >
            {lastProgressPercentage}%
          </div>
        </div>
        <div className="box-processing">
          <p>
            Data Processing Units: {updatedRows + invalidRows} / {totalRows}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProcessingStep;
