import {
    ADD_CARRIER,
    EDIT_CARRIER,
    DELETE_CARRIER,
    GET_CARRIERS,
    FIND_CARRIER,
    GET_CARRIERS_COMPANIES
  } from "../types";
  import fetchAPIClass from "../../fetchAPI";
  import { restfulUrl, API_URL, SHIPPING_SETTING_URL } from "context/url";
  
  export const getCarriers = (data) => (dispatch) => {
    let url = `${SHIPPING_SETTING_URL}/carriers/all`
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_CARRIERS);
      api.fetchAPI(dispatch, data);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const getCarrierCompanies = (data) => (dispatch) => {
    let url = `${SHIPPING_SETTING_URL}/carrier-companies`;
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_CARRIERS_COMPANIES);
      api.fetchAPI(dispatch, data);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const addCarrier = (inputs) => (dispatch) => {
    let requestBody = inputs;
    let url = `${SHIPPING_SETTING_URL}/carriers`;
    console.log(requestBody);
    try {
      const api = new fetchAPIClass(url, "POST", requestBody, ADD_CARRIER);
      let response = api.fetchAPI(dispatch, inputs);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const deleteCarrier = (carrierId) => (dispatch) => {
    let url = `${SHIPPING_SETTING_URL}/delete/${carrierId}`;
    try {
      const api = new fetchAPIClass(url, "DELETE", null, DELETE_CARRIER);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const editCarrier = (inputs, carrierId) => (dispatch) => {
    let requestBody = inputs;
    let url = `${SHIPPING_SETTING_URL}/carriers`;
  
    try {
      const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_CARRIER);
      let response = api.fetchAPI(dispatch, inputs);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };