import React, { Component } from "react";
// import NewAttribute from "./NewAttribute"

//import View from "./View";
import View from "./View";


export default class index extends Component {
  render() {

      return (
          <div>
              <View {...this.props} /> 
          </div>
      );
  }
}