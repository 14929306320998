import {
  ADD_SEGMENTATION,
  GET_SEGMENTATIONS,
  DELETE_SEGMENTATION,
  EDIT_SEGMENTATION,
  FIND_SEGMENTATION,
  DUPLICATE_SEGMENTATION,
  ADD_TEMPLATE_SEGMENTATION,
  GET_TEMPLATE_SEGMENTATIONS,
  DELETE_TEMPLATE_SEGMENTATION,
  EDIT_TEMPLATE_SEGMENTATION,
  FIND_TEMPLATE_SEGMENTATION,
} from "./types";
import { restfulUrl, business_id , API_URL} from "../../../src/context/url";
import  fetchAPIClass  from "./fetchAPI";

export const addSegmentation = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/segmentation/add`;
  console.log(url);
  console.log(requestBody);

  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_SEGMENTATION);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const editSegmentation = (inputs, segmentId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/segmentation/edit/${segmentId}`;
  console.log(url);

  try {
    const api = new fetchAPIClass(url, "POST", requestBody, EDIT_SEGMENTATION);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const duplicateSegmentation = (inputs, segmentId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/segmentation/duplicate/${segmentId}`;
  console.log(url);

  try {
    const api = new fetchAPIClass(
      url,
      "POST",
      requestBody,
      DUPLICATE_SEGMENTATION
    );
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const getSegmentaions = (businessId) => (dispatch) => {
  let url = `${API_URL}/segmentation/all/${businessId}`;
  console.log("URL", url);

  try {
    const api = new fetchAPIClass(url, "GET", null, GET_SEGMENTATIONS);
    let response = api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const deleteSegmentation = (segmentId) => (dispatch) => {
  let url = `${API_URL}/segmentation/delete/${segmentId}`;
  console.log("URL", url);

  try {
    const api = new fetchAPIClass(url, "POST", null, DELETE_SEGMENTATION);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findSegmentaion = (segmentId) => (dispatch) => {
  let url = `${API_URL}/segmentation/find/${segmentId}`;
  console.log("URL", url);

  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_SEGMENTATION);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

/** Here their Segmentations Templates Actions  */
export const getSegmentaionsTemplates = (businessId) => (dispatch) => {
  let url = `${API_URL}/segmentation/template/all/${businessId}`;
  console.log("URL", url);

  try {
    const api = new fetchAPIClass(url, "GET", null, GET_TEMPLATE_SEGMENTATIONS);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findTemplateSegmentaion = (templateId, businessId) => (
  dispatch
) => {
  let url = `${API_URL}/segmentation/template/find/${templateId}/${businessId}`;
  console.log("inside findTemplateSegmentaion, ", templateId, businessId, url);

  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_TEMPLATE_SEGMENTATION);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

/*TODO: ADD REMAINS CRUD FUNCTIONS FOR SEGMENTATION TEMPLATES */
