import React, { useState } from "react";
import ModalComponent from "../../../Modal";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";

const View = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { onChange, onSubmit } = props;
  const onClick = () => {
    onSubmit();
    toggle();
  };

  return (
    <div>
      <div onClick={toggle} className="option cta col-xs-4">
        <i className="material-icons m-r-5">playlist_add</i>
        <a href="#">{"Load Options..."}</a>
      </div>
      <ModalComponent title="LoadOptions" toggle={toggle} isOpen={modal}>
        <Form onSubmit={onClick}>
          <div className="row form-row">
            <div className="col-lg-12">
              <label htmlFor="options" className="form-label bold">
                {"Place you options on thier own line"}
              </label>
              <textarea
                name="options"
                className="form-control"
                id="options"
                cols="30"
                rows="10"
                placeholder={`Jeddah \nMakkah \nRiyadh`}
                onChange={onChange}
              />
            </div>
            <div className="col-lg-12">
              <label htmlFor="delimiter" className="form-label bold">
                {"Add delimiter (optional)"}
              </label>
              <input
                type="text"
                name="delimiter"
                id="delimiter"
                className="form-control"
                placeholder="Add delimiter"
                onChange={onChange}
              />
              <p>{`Use a comma, semi-colon, or any custom 
                    text to seperate labels from values
                    (for example, "label 1, value 1" or "label 2; value 2")`}</p>
            </div>
            <div className="col-lg-12">
              <FormGroup tag="fieldset" onChange={onChange}>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" value="add" name="option_action" />
                    {"Add to the current options"}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      value="replace"
                      name="option_action"
                      defaultCheck
                    />
                    {"Replace the current options"}
                  </Label>
                </FormGroup>
              </FormGroup>
            </div>
          </div>
          <div className="modal-footer">
            <Button color="secondary" onClick={toggle}>
              {"Cancel"}
            </Button>
            <Button color="primary" onClick={onClick}>
              {"Create"}
            </Button>
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
};

export default View;
