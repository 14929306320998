import React, { PropTypes } from "react";
import Media from "components/general/MediaList";
import Card from "components/general/Card";
import Form from "components/general/FormController";
import { unset } from "lodash";
const View = (props) => {
  const {
    variants,
    updateMedia,
    updateIsApplyToAll,
    updateUniversalMedia,
    isApplyMediaToAll,
  } = props;

  return (
    <div>
      <Form
        type="singleCheckbox"
        name="isApplyMediaToAll"
        label="Apply Single set of images to all SKUs"
        value={isApplyMediaToAll}
        defaultChecked={isApplyMediaToAll}
        updateState={updateIsApplyToAll}
      />

      {isApplyMediaToAll ? (
        <Card
          name={"Single set of images to all SKUs"}
          cardStyle={{ marginBottom: 25, border: "unset" }}
        >
          <Media
            name="media"
            multiple={true}
            data={variants[0]}
            index={0}
            updateState={updateUniversalMedia}
          />
        </Card>
      ) : (
        variants.map((product, index) => (
          <Card
            name={product.sku}
            cardStyle={{ marginBottom: 25, border: "unset" }}
          >
            <Media
              name="media"
              multiple={true}
              data={product}
              index={index}
              updateState={updateMedia}
            />
          </Card>
        ))
      )}
    </div>
  );
};

View.propTypes = {};

export default View;
