import _ from "lodash"


export function handelLogicAlgorithm(algorithm, attribulteList) {
    let AlgorithmOperator = algorithm.operator /*{ label: algorithm.operator === "all" /*? "All" : "Any", value: algorithm.operator }*/
  
    let handeledLogicList = []
  
    _.map(algorithm.logic_list, logic => {
  
      let attribute = _.find(attribulteList, attr => attr.inner_name === logic.attribute.inner_name) || ""
      let operator = attribute ? _.find(attribute.operator, oper => oper.value === logic.operator) : ""
      let child = !_.isEmpty(logic.child)? handelChildAlgorithm(logic.child,attribulteList) : { logic_list: [], operator: { label: "All", value: "all" } }
  
      handeledLogicList.push({
        attribute: attribute,
        operator: operator.value,
        child: child,
        value: logic.value,
        _id: logic._id
  
      })
  
    })
  console.log(handeledLogicList)
    return {
      operator: AlgorithmOperator,
      _id: algorithm._id,
      logic_list: handeledLogicList
    }
  
  }


 export function handelChildAlgorithm(algorithm, attribulteList) {

    let AlgorithmOperator = algorithm.operator //{ label: algorithm.operator === "all" ? "All" : "Any", value: algorithm.operator }
  
    let handeledLogicList = []
  
    _.map(algorithm.logic_list, logic => {
  
      let attribute = _.find(attribulteList, attr => attr.inner_name === logic.attribute.inner_name) || ""
      let operator = attribute ? _.find(attribute.operator, oper => oper.value === logic.operator) : ""
  
      handeledLogicList.push({
        attribute: attribute,
        operator: operator.value,
        value: logic.value,
        _id: logic._id
  
      })
  
    })
  
    return {
      operator: AlgorithmOperator,
      _id: algorithm._id,
      logic_list: handeledLogicList
    }
  
  }
  