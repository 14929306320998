import {
    ADD_ROLE,
    EDIT_ROLE,
    DELETE_ROLE,
    GET_ROLES,
    FIND_ROLE,
    GET_PERMISSIONS,
  } from "../../actions/UserManagement/types";
  
  const initialState = {
    item: null,
    items: null,
    permissions: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case EDIT_ROLE:
      case FIND_ROLE:
      case DELETE_ROLE:
        return {
          ...state,
          item: action.payload,
        };
      case GET_ROLES:
        return {
          ...state,
          items: action.payload,
        };
      case ADD_ROLE:
        return {
          ...state,
          item: action.payload,
          items: [...state.items, action.payload],
        };
        case GET_PERMISSIONS:
          return {
            ...state,
            permissions: action.payload,
          };
      default:
        return state;
    }
  }
  