import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "../../../../components/general/Modal";
import FormComponent from "../../../../components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {
  const { onChange, data, modal, countries, toggle, errors } = props;
  return (
    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>
      <ModalComponent title="Create New Catalog" toggle={toggle} isOpen={modal} size="lg">
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="text"
            name="name_en"
            label="English Name"
            value={data.name_en}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          <FormComponent
            type="text"
            name="name_ar"
            label="Arabic Name"
            value={data.name_ar}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          {countries ? (
            <FormComponent
              type="select"
              options={_.map(countries, (country) => ({
                label: _.get(country, "name_en"),
                value: _.get(country, "_id"),
              }))}
              name="country"
              label="Country"
              value={data.country}
              valueKey="value"
              labelKey="label"
              updateState={onChange}
            />
          ) : (
            "Loading.."
          )}

          {/*<FormComponent
            type="switch"
            name="status"
            label="Activate Tax"
            id="tax_status"
            classFormGroup="d-flex mb-0"
            classLabel="mr-3 mb-0"
            value={data.status}
            updateState={onChange}
          />*/}
          <FormComponent
            type="text"
            name="currency_en"
            label="Currency English Name"
            value={data.currency_en}
            updateState={onChange}
          />
          <FormComponent
            type="text"
            name="currency_ar"
            label="Currency Arabic Name"
            value={data.currency_ar}
            updateState={onChange}
          />
          <FormComponent
            type="text"
            name="taxName"
            label="Tax Name"
            value={data.taxName}
            updateState={onChange}
          />
          <FormComponent
            type="text"
            name="percentage"
            label="Tax percentage"
            value={data.percentage}
            updateState={onChange}
          />

          <FormComponent
            type="switch"
            name="show_product_inc_tax"
            label="Show Product Inc Tax"
            id="show_product_inc_tax"
            classFormGroup="d-flex mb-0"
            classLabel="mr-3 mb-0"
            value={data.show_product_inc_tax}
            updateState={onChange}
          />

          <FormComponent
            type="switch"
            name="cal_tax_with_service"
            label="Calculate the Taxes with service"
            id="cal_tax_with_service"
            classFormGroup="d-flex mb-0"
            classLabel="mr-3 mb-0"
            value={data.cal_tax_with_service}
            updateState={onChange}
          />

          {/*<FormComponent
            type="switch"
            name="status"
            label="Activate Currency"
            id="currency_status"
            classFormGroup="d-flex mb-0"
            classLabel="mr-3 mb-0"
            value={data.currency_status}
            updateState={onChange}
          />*/}

          <ModalFooter>
            <Button
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
