import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "components/general/Modal";
import FormComponent from "components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";
import Collapse from "components/general/Collapse";
import Options from "../../Options";

const View = (props) => {
  const {
    onChange,
    data,
    modal,
    toggle,
    companies,
    addingModal,
    addToggle,
    errors,
    catalogs,
  } = props;

  let strategeyOptionHeaders = [
    "Shipping From",
    "Destination",
    "Delivery Time",
    "Cart Weight",
    "cost",
  ];
  let emptyRowOptions = {
    shippingFrom: "",
    shippingTo: "",
    dileveryTime: "",
    cartWeight: "",
    shippingCost: "",
  };

  let attributeList = [
    {
      name: "shippingFrom",
      type: "select",
      options: [
        { label: "Jeddah", value: "jeddah" },
        { label: "UAE", value: "uae" },
        { label: "WorldWide", value: "worldwide" },
      ],
      target: "shippingCosts",
      targetName: "carriers",
      valueKey: "value",
      labelKey: "label",
    },
    {
      name: "shippingTo",
      type: "select",
      options: [
        { label: "Jeddah", value: "jeddah" },
        { label: "UAE", value: "uae" },
        { label: "WorldWide", value: "worldwide" },
      ],
      target: "shippingCosts",
      targetName: "carriers",
      valueKey: "value",
      labelKey: "label",
    },
    {
      name: "dileveryTime",
      type: "select",
      options: [{ label: "3 - 5 Days", value: "3 - 5 Days" }],
      target: "shippingCosts",
      targetName: "carriers",
      valueKey: "value",
      labelKey: "label",
    },
    {
      name:
        data.shippingCostType === "CART WEIGHT"
          ? "costPerWeight"
          : "shippingCost",
      type: "text",
      target: "shippingCosts",
      targetName: "carriers",
    },
  ];
  {
    data.shippingCostType === "CART WEIGHT" &&
      attributeList.splice(
        3,
        0,
        ...[
          {
            name: "cartWeight",
            type: "text",
            target: "shippingCosts",
            targetName: "carriers",
          },
        ]
      );
  }

  console.log("Atributes List is : >>>>>>");
  console.log(attributeList);

  return (
    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Add New"}
      </Button>
      <ModalComponent
        title="Choose Carrier Company"
        toggle={toggle}
        isOpen={modal}
      >
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="select"
            options={_.map(companies, (carrier) => ({
              label: _.get(carrier, "name"),
              value: _.get(carrier, "_id"),
            }))}
            name="carrierCompanyID"
            label="Carrier"
            value={data.carrierCompanyID}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />

          <ModalFooter>
            <Button
              onClick={props.onSelect}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Select"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>

      <ModalComponent
        title="Add New Carrier & Cost"
        toggle={addToggle}
        isOpen={addingModal}
        size={"lg"}
      >
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="switch"
            id={`activate_`}
            name="isActive"
            label="Activate"
            value={data.isActive}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          <FormComponent
            type="text"
            name="nameEN"
            label="English Name"
            value={data.nameEN}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}
          <FormComponent
            type="text"
            name="nameAR"
            label="Arabic Name"
            value={data.nameAR}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}
          <FormComponent
            type="groupCheckbox"
            options={[
              { label: "KSA", value: "KSA" },
              { label: "UEA", value: "UEA" },
              { label: "MA", value: "MA" },
            ]}
            name="channels"
            label="Channels"
            value={data.channels}
            default={data.channels}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />
          <Collapse
            group={{
              label: "Shipping Cost Setting",
              id: `"data"shipping`,
              open: false,
            }}
          >
            <FormComponent
              type="select"
              options={[
                { label: "Flat Rate", value: "FLAT RATE" },
                { label: "Based on Cart Weight Rate", value: "CART WEIGHT" },
              ]}
              name="shippingCostType"
              label="Shipping Cost Strategy"
              value={data.shippingCostType}
              valueKey="value"
              labelKey="label"
              updateState={onChange}
            />
            <Options
              attributeList={attributeList}
              {...props}
              data={data}
              targetOptionName={"shippingCosts"}
              options={data.shippingCosts}
              updateState={onChange}
              hasAddOption={true}
              headers={
                data.shippingCostType === "CART WEIGHT"
                  ? strategeyOptionHeaders
                  : _.filter(
                      strategeyOptionHeaders,
                      (opt) => opt !== "Cart Weight"
                    )
              }
              newOptions={
                data.shippingCostType === "CART WEIGHT"
                  ? emptyRowOptions
                  : _.omit(emptyRowOptions, "CART WEIGHT")
              }
              addMore={"Add New Destination Cost"}
            />
          </Collapse>

          <ModalFooter>
            <Button
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={addToggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
