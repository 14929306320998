import React, { PropTypes } from "react";
import FormComponent from "components/general/FormController";
import {Button} from 'reactstrap'
import _ from "lodash";

const View = (props) => {
  const { typeName, state,couponData } = props;

  return (
    <div>
      <FormComponent
        type={"text"}
        label={"Coupon Code"}
        name={"coupons"}
        value={state.coupons[0]}
        updateState={props.onChange}
      />
      <FormComponent
        type={"switch"}
        id="generatedCoupon"
        label={"Use Personalized Generation"}
        name={"useCouponGeneration"}
        value={couponData.useCouponGeneration}
        updateState={props.onChangeCouponData}
      />

      {couponData.useCouponGeneration &&
      <div className="text">
      <FormComponent
        type={"integer"}
        label={"Coupon Qty"}
        name={"coupon_qty"}
        value={couponData.coupon_qty}
        updateState={props.onChangeCouponData}
      />
        
      <FormComponent
        type={"integer"}
        label={"Code Length"}
        name={"code_length"}
        value={couponData.code_length}
        updateState={props.onChangeCouponData}
      />


      <FormComponent
        type={"select"}
        label={"Code Format"}
        name={"code_format"}
        options={[
          { label: "Alphanumeric", value: "alphanumeric" },
          { label: "Alphabetical", value: "alphabetic" },
          { label: "Numeric", value: "numbers" },
        ]}
        valueKey={"value"}
        labelKey={"label"}
        value={couponData.code_format}
        updateState={props.onChangeCouponData}
      />
      <FormComponent
        type={"text"}
        label={"Code Prefix"}
        name={"code_prefix"}
        value={couponData.code_prefix}
        updateState={props.onChangeCouponData}
      />
      <FormComponent
        type={"text"}
        label={"Code Suffix"}
        name={"code_suffix"}
        value={couponData.code_suffix}
        updateState={props.onChangeCouponData}
      />
      <Button
        className="btn btn-primary"
        onClick={() => props.generateCoupons()}>
            {"Generate Coupons"}
        </Button>
      </div>
      }
    </div>
  );
};

View.propTypes = {};

export default View;
