import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getSegmentaionsTemplates,
  findTemplateSegmentaion,
  addSegmentation,
} from "../../../../redux/actions/SegmentationActions";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
import LogicSteps from "../../../../components/general/Logic/Steps";
const business_id = "5cc2f00577ebc157b4f1c64c";

const progressSteps = [
  {
    step: 1,
    title: "Segment Template",
  },
  {
    step: 2,
    title: "Segment Logic",
  },
  {
    step: 3,
    title: "Segment Information",
  },
];

const Index = (props) => {
  useEffect(() => {
    props.getSegmentaionsTemplates(business_id);
  }, []);

  //
  const handelGetTemplate = (templateId, businessId) => {
    console.log("Get Template: before fetch", templateId, businessId);

    props.findTemplateSegmentaion(templateId, businessId);
    // forceUpdate()
    return props.template;
  };
  console.log("Get Template: props - add segment", props);

  const inputsToJSON = (inputs) => {
    return JSON.stringify(inputs);
  };

  const addSegmentation = (segmentation) => {
    try {
      console.log("on sumbit", segmentation);
      let inputs = `{
      "name": "${segmentation.name}",
      "desc": "${segmentation.desc}",
      "is_template":${segmentation.is_template},
      "status":${segmentation.status},
      "business_id": "${business_id}",
      "logic":${inputsToJSON(segmentation.logic)}
      }`;
      let response = props.addSegmentation(inputs);

      SuccessAlert("success", "The segmentation have been added sucessfully.");
      console.log(response);
      //props.history.push("/segmentations"); //redirect to SegmentationList
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  return (
    <div>
      <LogicSteps
        {...props}
        getTemplate={handelGetTemplate}
        progressSteps={progressSteps}
        pageName="Segment"
        onSubmit={addSegmentation}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  template: state.segmentations.template,
  templates: state.segmentations.templates,
});

export default connect(mapStateToProps, {
  getSegmentaionsTemplates,
  findTemplateSegmentaion,
  addSegmentation,
})(Index);
