import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getBranches,
  deleteBranch,
} from "redux/actions/Ecommerce/BranchAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";

import View from "./View";

const Index = (props) => {
  useEffect(() => {
    props.getBranches();
  }, [props.branches]);

  const deleteBranch = (branchId) => {
    try {
      let response = props.deleteBranch(branchId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    props.history.push("/branch");
  };

  return (
    <div>
      {props.branches && (
        <View
          {...props}
          deleteBranch={deleteBranch}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  branches: state.branches.items,
});

export default connect(mapStateToProps, {
  getBranches,
  deleteBranch,
})(Index);
