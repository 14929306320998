import {
    ADD_USER,
    EDIT_USER,
    DELETE_USER,
    GET_USERS,
    FIND_USER,
  } from "../../actions/UserManagement/types";
  
  const initialState = {
    item: null,
    items: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case EDIT_USER:
      case FIND_USER:
      case DELETE_USER:
        return {
          ...state,
          item: action.payload,
        };
      case GET_USERS:
        return {
          ...state,
          items: action.payload,
        };
      case ADD_USER:
        return {
          ...state,
          item: action.payload,
          items: [...state.items, action.payload],
        };
      default:
        return state;
    }
  }
  