import React from "react"
import _ from "lodash"

const Summary = (props) => {

    const { result } = props

    return _.map(result, (item, key) => (
        <div className="summary-item col-lg-3 col-xs-6">
            <div className="tiles m-b-10">
                <div className="tiles-body">
                    <div className="widget-stats">
                        <div className="wrapper transparent">
                            <span className="heading">{key}</span>
                            <span className="item-count animate-number semi-bold">
                                {item.total}
                            </span>
                        </div>
                    </div>
                    <div className="widget-stats">
                        <div className="icon">
                            <span className="item-count animate-number semi-bold" data-animation-duration={1000}>
                                {item.percentage}
                            </span>
                        </div>
                        <div
                            className="progress-bar progress-bar animate-progress-bar"
                            data-percentage={item.percentage}
                            style={{ width: item.percentage }}
                        />
                    </div>
                </div>
            </div>
        </div>
    ))
}
export default Summary
