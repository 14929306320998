import React, { useState } from "react";
import _ from "lodash";
import { Collapse } from "reactstrap";
import Delete from "./Delete";
//import Attribute from "./attribute"

export default function CollabseComponent(props) {
  let { group , index , titleWithKey } = props;
  const [isOpen, setIsOpen] = useState(group.open);
  const toggle = () => setIsOpen(!isOpen);

  /**FIXME: Make the collapse component as modal compontent with using props.childern
   * Rescutuce page detalis make as general where now it just for segmnatation case
   */
  return (
    <div className="attribute-list">
      <div key={group.id} className="panel-group">
        <div className="panel">
          <div className="panel-heading d-flex justify-content-between">
            <h4 className="panel-title">
              <a
                className={
                  isOpen
                    ? "d-flex sub-title bold"
                    : "collapsed expnad d-flex sub-title bold"
                }
                onClick={toggle}
                aria-expanded="true"
              >
                {titleWithKey? `${group.label} (${index})` : group.label}
              </a>
            </h4>

            {props.groupAction && (
              <ul className="d-flex  justify-content-between action-groups">
                <li>
                  <a
                    onClick={() => props.toggleEditGroup(group)}
                    href="#"
                    className="pointer"
                  >
                    {" Edit Group "}
                  </a>
                </li>
                <li>
                  <Delete
                    data={group.label}
                    onSubmit={props.deleteGroup}
                    customerID={group._id}
                    actionType={"link"}
                    linkTitle={"Delete Group"}
                  />
                </li>
              </ul>
            )}
          </div>
          <div className="panel-body">
            <Collapse isOpen={isOpen}>{props.children}</Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}
