import {
  GET_ECOM_GROUPS,
  EDIT_ECOM_GROUP,
  DELETE_ECOM_GROUP,
  FIND_ECOM_GROUP,
  ADD_ECOM_GROUP,
} from "./types";
import  fetchAPIClass  from "../fetchAPI";
import { restfulUrl , API_URL} from "../../../context/url";

export const getGroups = (type) => (dispatch) => {
  let url = `${API_URL}/ecommerce/group/attribute/all?indexing_for=${type}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_ECOM_GROUPS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getGroup = (groupId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/group/attribute/find/${groupId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_ECOM_GROUP);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addGroup = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/group/attribute/add`;
  console.log("Inside Add Group Redux");
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_ECOM_GROUP);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const editGroup = (inputs, groupId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/group/attribute/edit/${groupId}`;

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_ECOM_GROUP);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteGroup = (groupId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/group/attribute/delete/${groupId}`;
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_ECOM_GROUP);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};
