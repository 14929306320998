import React, { Component } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

export class Layout extends Component {
  render() {
    console.log("History", this.props);
    return (
      <div id="main">
        <Header {...this.props} />
        <Sidebar />
        <Footer />
      </div>
    );
  }
}

export default Layout;
