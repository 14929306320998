import React, { PropTypes } from "react";
import Table from "components/general/Table";
const Index = (props) => {
  const { variants, selectedAttributes, deleteProduct } = props;

  const headerList = () => {
    let headrs = [
      {
        type: "_id",
        isKey: true,
        hidden: true,
        title: "ID",
      },
      {
        sortable: true,
        type: "sku",
        align: "center",
        title: "SKU",
        editable: { type: "text" },
      },
    ];

    if (props.withMedia) {
      headrs = [
        ...headrs,
        {
          sortable: false,
          type: "media",
          align: "center",
          title: "Media",
        },
      ];
    }

    let variantsAttributes = selectedAttributes.map((attribute) => {
      return {
        sortable: true,
        type: attribute.system_name,
        align: "center",
        title: attribute.name_en,
      };
    });

    headrs = [...headrs, ...variantsAttributes];
    headrs = [
      ...headrs,
      ...[
        {
          sortable: true,
          type: "price",
          align: "center",
          title: "Price",
          editable: { type: "number" },
        },
        {
          sortable: true,
          type: "quantity",
          align: "center",
          title: "QTY",
          editable: { type: "number" },
        },
        {
          type: "status",
          align: "center",
          title: "Status",
        },
        {
          type: "action",
          sortable: true,
          align: "center",
          export: false,
          title: "Action",
        },
      ],
    ];
    return headrs;
  };

  return (
    <div className="contact-table">
      <Table
        data={variants}
        deleteAction={deleteProduct}
        noFilter={true}
        noSearch={true}
        headers={headerList()}
        cellEdit={true}
        noEdit={true}
        deleteByRowIndex={true}
        {...props}
      />
    </div>
  );
};

export default Index;
