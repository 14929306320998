//import React, {useState, useContext, useReducer } from "react"
import React, { Component } from "react";
import "./App.css";
import AuthContext from "./context/auth";
// import { Provider } from "react-redux";
import { Route, Redirect, Switch, IndexRoute } from "react-router-dom";
import Singup from "./views/Auth/Registration";
import Login from "./views/Auth/Login";
import Layout from "./components/layouts/Layout";
import QuickNavigation from "./components/layouts/QuickNavigation";
import { connect } from "react-redux";
//Home temporary
//import ContactList from "./views/CRM/ContactProfile/ContactList";

//CRM Routes
import crmRoutes from "./routes/crm";
//Ecommerce Routes
import ecommerceRoutes from "./routes/ecommerce";
import settingRoutes from "./routes/setting";
import orderManageRoutes from "./routes/order";
import userManageRoutes from "./routes/user";
//
// import reduxStore from "./redux/store";
import NotFound from "./components/general/NotFound";
import { singup } from "redux/actions/Auth/user";
// function App(props) {
class App extends Component {
  render() {
    console.log("this.props.isAuthenticated", this.props.isAuthenticated);
    console.log("this.props.isAuthenticated", localStorage.getItem("stores"));
    console.log("test");

    return (
      <React.Fragment>
        {this.props.isAuthenticated && <Layout history={this.props.history} />}

        <div className="page-content">
          <div className="content">
            <Switch>
              {/** Auth Routes */}

              {!this.props.isAuthenticated && (
                <Redirect from="/" to="/login" exact />
              )}
              {/* {!this.props.isAuthenticated && <Redirect from="*" to="/login" exact />} */}

              {!this.props.isAuthenticated && (
                <Route path="/login" component={Login} exact />
              )}

              {!this.props.isAuthenticated && (
                <Route path="/singup" component={Singup} />
              )}

              {!this.props.isAuthenticated && <Redirect to="/login" exact />}

              {!this.props.isAuthenticated && (
                <Redirect from="/singup" to="/crm/contacts" exact />
              )}
              {/* {this.props.isAuthenticated && (
                <Route
                  exact
                  path="/login"
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: "/crm/contacts",
                        // state: this.props.location.state
                      }}
                    />
                  )}
                />
              )} */}

              {this.props.isAuthenticated && (
                <Redirect from="/login" to="/crm/contacts" />
              )}

              {this.props.isAuthenticated && (
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: "/crm/contacts",
                        // state: this.props.location.state
                      }}
                    />
                  )}
                />
              )}

              {/*<Redirect from="/login" to="/contacts" exact />*/}
              {this.props.isAuthenticated && (
                <Route path="/crm" component={crmRoutes} />
              )}

              {/* CRM Routes */}

              {/*this.props.isAuthenticated &&*/
              /*ecommerceRoutes()*/}
              {/* Ecommerce Routes */}

              {this.props.isAuthenticated && (
                <Route path="/ecommerce" component={ecommerceRoutes} />
              )}

              {this.props.isAuthenticated && (
                <Route path="/order" component={orderManageRoutes} />
              )}

              {this.props.isAuthenticated && (
                <Route path="/setting" component={settingRoutes} />
              )}
              {this.props.isAuthenticated && (
                <Route path="/user" component={userManageRoutes}></Route>
              )}
              {/*this.props.isAuthenticated && crmRoutes()*/}
              {this.props.isAuthenticated && (
                <Route path="*" component={NotFound} />
              )}
            </Switch>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, null)(App);
