import {
  ADD_BRAND,
  EDIT_BRAND,
  DELETE_BRAND,
  GET_BRANDS,
  FIND_BRAND,
} from "../../actions/Ecommerce/types"

const initialState = {
  item: null,
  items: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_BRAND:
    case EDIT_BRAND:
    case FIND_BRAND:
    case DELETE_BRAND:
      return {
        ...state,
        item: action.payload,
      }
    case GET_BRANDS:
      return {
        ...state,
        items: action.payload,
      }
    default:
      return state
  }
}
