import React, { useState } from "react";
import { Button, Form } from "reactstrap";
import ModalComponent from "../../../../components/general/Modal";
import FormComponent from "../../../../components/general/Form";
import { Alert } from "reactstrap";

const View = (props) => {
  const {
    onChange,
    data,
    onChangeLabel,
    errors,
    onValidate,
    modal,
    toggle,
  } = props;
  return (
    <div>
      <Button className="btn btn-white btn-cons" onClick={toggle}>
        {"Create Attribute Group"}
      </Button>
      <ModalComponent title="Test" toggle={toggle} isOpen={modal}>
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="text"
            name="label"
            label="Label"
            value={data.label}
            onChange={onChangeLabel}
          />
          <FormComponent
            type="text"
            name="arabic_label"
            label="Arabic Label"
            value={data.arabic_label}
            onChange={onChange}
          />
          <FormComponent
            type="text"
            name="inner_name"
            label="Inernal Name"
            value={data.inner_name}
            onChange={onChange}
            disabled={true}
          />
          {errors && <Alert color="danger"> {errors.required} </Alert>}
          <Button /*type="submit"*/ onClick={onValidate}>Submit</Button>
        </Form>
      </ModalComponent>
    </div>
  );
};
export default View;
