import {
  GET_CUSTOMER_ATTRIBUTES,
  ADD_CUSTOMER_ATTRIBUTES,
  EDIT_CUSTOMER_ATTRIBUTES,
  FIND_CUSTOMER_ATTRIBUTE,
  DELETE_CUSTOMER_ATTRIBUTE
} from "./types"
import  fetchAPIClass  from "./fetchAPI"
import {restfulUrl , API_URL} from "../../../src/context/url"

export const getCustomerAttributes = (customerId, businessId) => dispatch => {
  let url = `${API_URL}/customer/getCustomrAttribute/${customerId}/${businessId}`
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_CUSTOMER_ATTRIBUTES)
    api.fetchAPI(dispatch)
  } catch (err) {
    console.log(err)
  }
}

export const editCustomerAttributes = (customerId, inputs) => dispatch => {
  let url = `${API_URL}/customer/edit/${customerId}`
  let requestBody = inputs
  console.log('Inside Action: ');
  console.log(inputs);
  
  try {
    const api = new fetchAPIClass(
      url,
      "POST",
      requestBody,
      EDIT_CUSTOMER_ATTRIBUTES
    )
    api.fetchAPI(dispatch)
  } catch (err) {
    console.log(err)
  }
}
