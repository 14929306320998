import React, { useState } from "react";
import { connect } from "react-redux";
// import { Redirect } from "react-router-dom";
import { logout } from "redux/actions/Auth/user";

const Index = (props) => {
  //   const history = Redirect();
  const handleLogout = (e) => {
    props.logout();
    // props.history.push("/login");
  };

  return (
    <a onClick={handleLogout}>
      <i className="material-icons">power_settings_new</i>
      &nbsp;&nbsp;Log Out
    </a>
  );
};

export default connect(null, { logout })(Index);
