import Swal from "sweetalert2"

function SuccessAlert(type, message) {

    const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000
    })

    Toast.fire({
        icon: type,
        title: message
    })

}

export default SuccessAlert
