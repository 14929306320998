import React, { useState } from "react";
import _ from "lodash";
import { Collapse, Col } from "reactstrap";
//import Attribute from "./attribute"
import SegmentResult from "../SegmentResult";
import Summary from "./Summary";
import FormComponent from "../FormController";
import Table from "../Table";

import Algorithm from "../../general/Logic/Algorithm";
import CollabseTest from "../../general/Collapse";
export default function CollabseComponent(props) {
  let { group } = props;
  const [isOpen, setIsOpen] = useState(group.open);
  const toggle = () => setIsOpen(!isOpen);

  /**FIXME:
   * 1- @aysha Make the collapse component as modal compontent with using props.childern (Now I made it you can improve)
   * 2- Restructure page detalis making as general where now it is just for segmnatation case
   */
  return (
    <div className="attribute-list">
      <div key={group.id} className="panel-group">
        <div className="panel">
          <div className="panel-heading d-flex justify-content-between">
            <h4 className="panel-title">
              <a
                className={
                  isOpen
                    ? "d-flex sub-title bold"
                    : "collapsed expnad d-flex sub-title bold"
                }
                onClick={toggle}
                // href={"#" + group.id}
                aria-expanded="true"
              >
                {group.label}
              </a>
            </h4>
          </div>
          <div className="panel-body">
            <Collapse isOpen={isOpen}>
              {/*{props.children}*/}
              {group.label === "Summary" && (
                <div className="row profile-component">
                  <Summary result={props.result} />
                </div>
              )}

              {group.label === "Information" && (
                <div className="form-container col-xs-12">
                  {/* Name Input */}
                  <FormComponent
                    type="text"
                    name="name"
                    label={`${props.titleLabel} Name`}
                    value={props.data.name}
                    updateState={props.onChange}
                    disabled={true}
                  />
                  {/* Description Input */}
                  <FormComponent
                    type="textarea"
                    name="desc"
                    label={`${props.titleLabel} Description`}
                    value={props.data.desc}
                    updateState={props.onChange}
                    placeholder="Help me to understand the objective from this segment"
                    className="form-control medium-input"
                  />
                </div>
              )}
              {/* Display users info that belong to the segmment */}
              {group.label === "Users" && (
                <>
                  {props.customers.length === 0 && (
                    <div className="contact-table">
                      There is No users yet in this segment!
                    </div>
                  )}
                  {props.customers.length !== 0 && (
                    <div className="contact-table">
                      <Table
                        data={props.customers}
                        isSelectRow={false}
                        noSearch={true}
                        headers={props.headerList}
                      />
                    </div>
                  )}
                </>
              )}

              {group.label === "Algorithm" && (
                <div className="segmentation">
                  <Algorithm {...props} />
                </div>
              )}

              {/*//TODO: THIS WILL BE CHANGE TO DIFFERENT COMPONENT & REDESIGN TO FIT THE REQUIRED DESIGN */}
              {group.label === "Run Logic Automatically" && (
                <div className="logic-wrapper">
                  <div style={{ flexDirection: "row" }}>
                    <FormComponent
                      type="switch"
                      name="runLogic"
                      id="runLogic"
                      label="Run Tag Logic every"
                      classFormGroup={"d-flex"}
                      classLabel={"mr-3"}
                      value={props.runLogic}
                      updateState={props.handleOnStatusChange}
                    />
                    <FormComponent
                      type={"select"}
                      options={[
                        { label: "Daily", value: "day" },
                        { label: "Weekly", value: "week" },
                        { label: "Monthly", value: "month" },
                        { label: "A quarter", value: "quarter" },
                        { label: "Year", value: "annual" },
                        { label: "None", value: "none" },
                      ]}
                      name={"runLogicTime"}
                      label=""
                      value={props.data["runLogicTime"]}
                      updateState={props.onChange}
                      target="runLogicTime"
                      targetName="runLogicTime"
                      valueLabel={props.data["runLogicTime"]}
                      valueKey="value"
                      labelKey="label"
                    />
                  </div>
                  <p>
                    {" "}
                    NOTE: if you activate the dynamic logic the system will run
                    this tag on (time selected) and remove or add contacts that
                    match the criteria
                  </p>
                </div>
              )}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}
