export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * To add commas as thousands separators
 * For more formatting: add package https://www.npmjs.com/package/react-number-format
 */
export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/*
 * To add commas as thousands separators
 **/
export const formatLongDigit = (digit) => {
  return digit.toLocaleString();
};

export const prefix = (number, prefix) => {
  return `${prefix}${number}`;
};

export const suffix = (number, suffix) => {
  return `${number}${suffix}`;
};

export const stringToSelectObjectOLD = (key, value, label) => {
  return { [key]: value, label: label };
};

export const stringToSelectObject = (options, value = null, key) => {
  if (value) {
    return options.map((option) => {
      if(typeof value === "string") {
        if (option[key] === value)return option
      }
      else {
        if (option[key] === value[key]) return option;
      }
    });
  }
};

export const arrayToMultiselectObject = (options, values = null, key) => {
  if (values) {
    let returnValues = [];
    options.forEach((option) => {
      values.forEach((value) => {
        if(typeof value === "string") {
          if (option[key] === value) returnValues.push(option);
        }
        else {
          if (option[key] === value[key]) returnValues.push(option);
        }
      });
    });
    return returnValues;
  }
};

export const getTypeCase = (attributeType) => {
  if (attributeType === "singleCheckbox") return 1;
  else if (attributeType === "select") return 2;
  else if (attributeType === "groupCheckbox") return 3;
};

export const calculatePriceWithTax = (price, tax) => {
  return price + price * (tax / 100);
};

export const enumsToListOFOptions = (enums) => {
  let values = Object.values(enums);
  let options = [];
  values.map((value) => options.push({ label: value, value: value }));
  return options;
};
