import React from "react";
import _ from "lodash";

const ProgressSteps = (props) => {
  let { progressSteps } = props;
  return (
    <div className="progress-steps-container">
      <ul>
        {_.map(progressSteps, (step) => (
          <li className={step.step < props.currentStep ? "prev" : step.step === props.currentStep? "active":""}>
            <button id="step1">
              <div class="step">{step.step}</div>
              <div class="title">{step.title}</div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ProgressSteps;
