
  import {
    ADD_TAG,
    GET_TAGS,
    DELETE_TAG,
    EDIT_TAG,
    FIND_TAG,
    DUPLICATE_TAG,
    ADD_TEMPLATE_TAG,
    GET_TEMPLATE_TAGS,
    DELETE_TEMPLATE_TAG,
    EDIT_TEMPLATE_TAG,
    FIND_TEMPLATE_TAG, 
  } from "../actions/types"
  
  const initialState = {
    items: [],
    item: null,
    templates: [],
    template: null,
  }
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case ADD_TAG:
      case EDIT_TAG:
      case DELETE_TAG:
      case FIND_TAG:
      case DUPLICATE_TAG:
        return {
          ...state,
          item: action.payload
        }
      case GET_TAGS:
        return {
          ...state,
          items: action.payload
        }
        case GET_TEMPLATE_TAGS:
          return {
            ...state,
            templates: action.payload
          }
      case FIND_TEMPLATE_TAG:
        return {
          ...state,
          template: action.payload,
        }
      default:
        return state
    }
  }
  