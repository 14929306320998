import {
  ADD_BRAND,
  EDIT_BRAND,
  DELETE_BRAND,
  GET_BRANDS,
  FIND_BRAND,
} from "./types";
import  fetchAPIClass  from "../fetchAPI";
import { restfulUrl , API_URL} from "../../../context/url";

export const getBrands = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/brand/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_BRANDS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addBrand = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/brand/add`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_BRAND);
    let response = api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const deleteBrand = (brandId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/brand/delete/${brandId}`;
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_BRAND);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editBrand = (inputs, brandId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/brand/edit/${brandId}`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, EDIT_BRAND);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findBrand = (brandId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/brand/find/${brandId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_BRAND);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
