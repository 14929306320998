import { ADD_GROUP, EDIT_GROUP, GET_GROUPS, FIND_GROUP, DELETE_GROUPS } from "../actions/types"


const initialState = {
  items: null,
  item: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_GROUP:
    case EDIT_GROUP:
    case FIND_GROUP:
    case DELETE_GROUPS:
      return {
        ...state,
        item: action.payload
      }
    case GET_GROUPS:
      return {
        ...state,
        items: action.payload
      }
    default:
      return state
  }
}
