import React, { Component } from "react";
import moment from "moment";
import FormComponent from "../../../FormController";
import _ from "lodash";

class index extends Component {
  logicStatement = (logic) => {
    return `${logic.attribute.label} ${logic.operator} ${
      logic.type === "AttrDateType"
        ? moment(logic.value).format("DD/MM/YYYY")
        : logic.value
    }`;
  };

  headerOfLogicList = (logic, type) => {
    console.log("Logic Operator", logic);
    return (
      <p>
        {type === 1
          ? "Queue count will only inculde contacts that match"
          : " it will combin with contacts that match only"}
        <strong className="strong">
          {" "}
          {logic.operator === "all" ? "All" : "Any"}{" "}
        </strong>
        {"of the following conditions:"}
      </p>
    );
  };

  render() {
    return (
      <div
        className="segment-information mr-5 ml-5"
        style={{ alignItems: "center" }}
      >
        {/* Segment Info */}
        <div className="form-container col-sm-8 mx-auto">
          {/* Status Input */}
          <FormComponent
            type={"switch"}
            name={"status"}
            label={"Status: "}
            id={"status"}
            classFormGroup="d-flex"
            classLabel="mr-3"
            value={this.props.status}
            updateState={this.props.updateState} //onChange
          />
          {/* Name Input */}
          <FormComponent
            type="text"
            name="name"
            label={`${this.props.pageName} Name`}
            value={this.props.name}
            updateState={this.props.updateState} //onChange
          />
          {/* Description Input */}
          <FormComponent
            type="textarea"
            name="desc"
            label={`${this.props.pageName} Description`}
            value={this.props.desc}
            updateState={this.props.updateState} //onChange
            placeholder={`Help me to understand the objective from this ${this.props.pageName}`}
            // className="form-control medium-input"
          />
          {/* Save as template */}
          <FormComponent
            type={"switch"}
            name={"is_template"}
            label={"Save as template: "}
            id={"is_template"}
            classFormGroup="d-flex"
            classLabel="mr-3"
            value={this.props.is_template}
            updateState={this.props.updateState}
          />

          {this.props.pageName === "Tag" && (
            <div style={{ flexDirection: "row" }}>
              {" "}
              <FormComponent
                type="switch"
                name="runLogic"
                id="runLogic"
                label="Run Tag Logic every"
                classFormGroup={"d-flex"}
                classLabel={"mr-3"}
                value={this.props.runLogic}
                updateState={this.props.updateState}
              />
              <FormComponent
                type={"select"}
                options={[
                  { label: "Daily", value: "day" },
                  { label: "Weekly", value: "week" },
                  { label: "Monthly", value: "month" },
                  { label: "A quarter", value: "quarter" },
                  { label: "Year", value: "annual" },
                  { label: "None", value: "none" },
                ]}
                name={"runLogicTime"}
                label=""
                value={this.props.runLogicTime}
                updateState={this.props.updateState}
                target="runLogicTime"
                targetName="runLogicTime"
                valueKey="value"
                labelKey="label"
              />
            </div>
          )}
        </div>

        {/* Summary of logic */}
        <div className="logic-detalis-container col-sm-9 mx-auto">
          <p> {"You're Saving a segment with the following criteria :"}</p>
          {this.headerOfLogicList(this.props.logic, 1)}
          <ol className="list">
            {this.props.logic.logic_list.map((logic, index) => {
              return (
                <li key={index}>
                  {this.logicStatement(logic)}
                  {logic.child && logic.child.logic_list.length !== 0 && (
                    <div>
                      {this.headerOfLogicList(logic.child, 2)}
                      <ol className="list">
                        {logic.child &&
                          logic.child.logic_list.map(
                            (logicChild, indexChild) => {
                              return (
                                <li key={`child${index}${indexChild}`}>
                                  {this.logicStatement(logicChild)}
                                </li>
                              );
                            }
                          )}
                      </ol>
                    </div>
                  )}
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    );
  }
}
export default index;
