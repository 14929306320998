import React from "react";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import Card from "../../../../components/general/Card";
import { Row, Col } from "reactstrap";
import TitleWithBtns from "../../../../components/general/PageDetails/HeaderInfo";
import _ from "lodash";
import CollabseComponent from "../../../../components/general/PageDetails/CollabseComponent";
import PromotionInfo from "../Add/FourthStep";
import Collapse from "components/general/Collapse";
import FormComponent from "components/general/FormController";
import Algorithm from "components/general/Logic/Algorithm";
import Table from "components/general/Table";
import PromotionRules from "../PromotionRules";

const View = (props) => {
  let generalInfoInput = [
    {
      type: "switch",
      name: "status",
      id: "status",
      label: "Promotion Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: _.toLower(props.state.status) === "active" ? true : false,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "Promotion ID",
      value: props.state["_id"],
      default: props.state["_id"],
      disabled: true,
    },
    {
      type: "text",
      name: "type",
      label: "Promotion Type",
      value: props.state["type"],
      default: props.state["type"],
      disabled: true,
    },
  ];
  const detailsInfo = [
    {
      id: 1,
      label: "Logic Summary",
      open: true,
    },
    {
      id: 2,
      label: "Promotions Rules Information",
      open: false,
    },
    {
      id: 3,
      label: "Promotions Rules",
      open: false,
    },
    {
      id: 4,
      label: `${
        props.state.type === "catalog" ? "Product List" : "Buy List (Product X)"
      }`,
      open: false,
    },
    {
      id: 5,
      label: "Get List (Product Y)",
      open: false,
    },
    {
      id: 6,
      label: "Promotions Messages & Labels",
      open: false,
    },
    {
      id: 7,
      label: "Highlit Promotions",
      open: false,
    },
  ];

  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "sku",
      align: "center",
      title: "sku",
    },
    {
      type: "name_en",
      sortable: true,
      title: "Name",
    },
    {
      type: "name_ar",
      title: "Name Ar",
    },
    {
      type: "type",
      title: "Type",
      align: "center",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
  ];

  return (
    <Card name="Promotion Logic">
      {/* Title */}
      <TitleWithBtns
        {...props}
        titleLabel="Promotion Logic Name"
        data={props.state}
        onDelete={props.deletePromo}
        onDuplicate={props.duplicatePromo}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo data={props.state} {...props} info={generalInfoInput} />
        </Col>

        {/*Main content of details */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {_.map(detailsInfo, (group) =>
              group.id === 2 ? (
                <Collapse group={group} {...props}>
                  <PromotionInfo {...props} />
                </Collapse>
              ) : group.id === 3 ? (
                <Collapse group={group} {...props}>
                  <PromotionRules {...props} />
                </Collapse>
              ) : group.id === 4 ? (
                <Collapse group={group} {...props}>
                  <div className="segmentation">
                    <Algorithm
                      {...props}
                      logic={props.state.logic_x}
                      attributes={props.attributeList}
                      targetName="logic_x"
                    />
                  </div>
                  {props.state.x_products.length === 0 &&
                    !_.isEmpty(props.state.logic_x) && (
                      <div className="contact-table">
                        There is No product yet in this promotion!
                      </div>
                    )}
                  {props.state.x_products.length !== 0 && (
                    <div className="contact-table">
                      <Table
                        data={props.state.x_products}
                        isSelectRow={false}
                        noSearch={true}
                        headers={headerList}
                      />
                    </div>
                  )}
                </Collapse>
              ) : props.state.type === "cart" && group.id === 5 ? (
                <Collapse group={group} {...props}>
                  <div className="segmentation">
                    <Algorithm
                      {...props}
                      logic={props.state.logic_y}
                      attributes={props.attributeList}
                      targetName="logic_y"
                    />
                  </div>
                  {props.state.x_products.length === 0 &&
                    !_.isEmpty(props.state.logic_y) && (
                      <div className="contact-table">
                        There is No product yet in this promotion!
                      </div>
                    )}
                  {props.state.y_products.length !== 0 && (
                    <div className="contact-table">
                      <Table
                        data={props.state.y_products}
                        isSelectRow={false}
                        noSearch={true}
                        headers={headerList}
                      />
                    </div>
                  )}
                </Collapse>
              ) : group.id === 7 ? (
                <Collapse group={group} {...props}>
                  <div>
                    <FormComponent
                      type={"text"}
                      label={"English Message"}
                      name={"message_en"}
                      value={props.state.message_en}
                      updateState={props.onChange}
                    />
                    <FormComponent
                      type={"text"}
                      label={"Arabic Message"}
                      name={"message_ar"}
                      value={props.state.message_ar}
                      updateState={props.onChange}
                    />

                    <FormComponent
                      type={"text"}
                      label={"English Label"}
                      name={"label_en"}
                      value={props.state.label_en}
                      updateState={props.onChange}
                    />

                    <FormComponent
                      type={"text"}
                      label={"Arabic Label"}
                      name={"label_ar"}
                      value={props.state.label_ar}
                      updateState={props.onChange}
                    />
                  </div>
                </Collapse>
              ) : (
                group.id !== 5 &&
                group.id !== 4 && (
                  <CollabseComponent
                    {...props}
                    group={group}
                    data={props.state}
                    onChange={props.onChange}
                    titleLabel={"Promotion"}
                  />
                )
              )
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};
export default View;
