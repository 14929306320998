import React, { Component } from "react";
import moment from "moment";
import FormComponent from "components/general/FormController";
import { Label, Row, Col } from "reactstrap";
import _ from "lodash";

const Index = (props) => {
  let { state, pageName, catalogList, onChange, isFromEdit } = props;

  return (
    <div
      className="segment-information mr-5 ml-5"
      style={{ alignItems: "center" }}
    >
      {/* Promotion Info */}
      <div
        className={
          !_.isEmpty(isFromEdit) ? "form-container col-sm-8 mx-auto" : ""
        }
      >
        {/* Status Input */}
        <FormComponent
          type={"switch"}
          name={"status"}
          label={"Status: "}
          id={"status"}
          classFormGroup="d-flex"
          classLabel="mr-3"
          value={state.status}
          updateState={onChange}
        />
        {/* Name Input */}
        <FormComponent
          type="text"
          name="name_en"
          label={`${pageName} English Name`}
          value={state.name_en}
          updateState={onChange}
        />
        {/* Name Input */}
        <FormComponent
          type="text"
          name="name_ar"
          label={`${pageName} Arabic Name`}
          value={state.name_ar}
          updateState={onChange}
        />
        {/* Description Input */}
        <FormComponent
          type="textarea"
          name="description_en"
          label={`${pageName} English Description`}
          value={state.description_en}
          updateState={onChange}
          placeholder={`Help me to understand the objective from this ${pageName}`}
        />
        {/* Description Input */}
        <FormComponent
          type="textarea"
          name="description_ar"
          label={`${pageName} Arabic Description`}
          value={state.description_ar}
          updateState={onChange}
        />
        {/* Catalogs Input */}
        <FormComponent
          type="groupCheckbox"
          options={_.map(catalogList, (catalog) => ({
            label: _.get(catalog, "name_en"),
            value: _.get(catalog, "_id"),
          }))}
          name="catalog_id"
          label="Catalogs"
          value={state.catalog_id}
          valueKey="value"
          labelKey="label"
          updateState={onChange}
        />
        {/* Date Range Input */}
        <DateRangeInput {...props} />

        <FormComponent
          type="integer"
          name="priority"
          label={"Priority"}
          value={state.priority}
          updateState={onChange}
        />
        <FormComponent
          type="integer"
          name="uses_per_customer"
          label={"Uses per Customer"}
          value={state.uses_per_customer}
          updateState={onChange}
        />
        <FormComponent
          type={"switch"}
          name={"hide_with_higher_priority"}
          label={"Hide if promotion with higher priority is applied "}
          id={"hide_with_higher_priority"}
          classFormGroup="d-flex"
          classLabel="mr-3"
          value={state.hide_with_higher_priority}
          updateState={onChange}
        />

        <FormComponent
          type={"switch"}
          name={"out_of_stock"}
          label={`Display "Out-of-Stock" Products`}
          id={"out_of_stock"}
          classFormGroup="d-flex"
          classLabel="mr-3"
          value={state.out_of_stock}
          updateState={onChange}
        />
      </div>
    </div>
  );
};

export default Index;

const DateRangeInput = (props) => {
  return (
    <>
      <Row>
        <Label>{`Start & End Dates: `}</Label>
      </Row>
      <Row>
        <Col>
          <FormComponent
            type="date"
            name="start_date"
            label="From"
            value={props.state.start_date}
            updateState={props.onChange}
          />
        </Col>
        <Col>
          <FormComponent
            type="date"
            name="end_date"
            label="End"
            value={props.state.end_date}
            updateState={props.onChange}
          />
        </Col>
      </Row>
    </>
  );
};
