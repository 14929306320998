import React, { PropTypes, useState, useEffect } from "react";
import Card from "../../../../components/general/Card";
import HeaderInfo from "../../../../components/general/PageDetails/HeaderInfo";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import CollapseList from "../../../../components/general/PageDetails/CollapseList";
import _ from "lodash";
import { Row, Col, Button, Label } from "reactstrap";
import { Treebeard } from "react-treebeard";
import { categoryHandler } from "../handler";
import AddCategory from "../AddCategory";

const View = (props) => {
  const {
    data,
    catalogs,
    deleteCategory,
    editCategory,
    groupAttributes,
    groupAttribute,
    categories,
    //catList,
    //onToggle,
    updateSelectedCategory,
    headerList,
    attributes
  } = props;
  console.log(props);

  const [catList, setCatList] = useState([]);
  const [cursor, setCursor] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);

  const indexing_for = "ecommerce"
  let custom_attributes = _.filter(attributes, attr => attr.class === "custom" && attr.indexing_for === indexing_for)

let header = _.uniq(_.flattenDeep(_.map(props.products, d =>_.keys(d))))

    if(_.includes(header, "brand_id")){
      const index = _.indexOf(header, "brand_id")
      header.splice(index, 0, "brand_en", "brand_ar");
    }
    else 
    header.push(...["brand_id","brand_en","brand_ar"])
  
    header.push(...["categories", "categories_en", "categories_ar"])

  _.map(custom_attributes, cattr => {
    const index = _.indexOf(header, cattr.system_name)
      if(_.includes(header, cattr.system_name) && !_.isEmpty(cattr.option)){
        header.splice(index, 0, `${cattr.system_name}_en`, `${cattr.system_name}_ar`);
    }
    
  })

  useEffect(() => {
    let { selectedCat } = props;

    setSelectedCatalog(selectedCat);
  }, [props, selectedCatalog]);

  useEffect(() => {
    // Get categories list
    console.log(catList);
    let cats = props.categories;
    if (!_.isEmpty(selectedCatalog)) {
      cats = _.filter(cats, (cat) => _.map(cat.catalogs, catalog => `${catalog._id}`).includes(selectedCatalog));
      console.log(cats);
    }

    //if (/*!_.isEmpty(cats) &&*/ _.isEmpty(catList)) {
    let handledCategory = categoryHandler(cats);
    console.log(handledCategory);
    setCatList(handledCategory);

    //}
  }, [props.categories, /*catList,*/ selectedCatalog]);
  console.log(catList);

  const onToggle = (node, toggled) => {
    console.log("inside onToggle");
    console.log({ node, toggled, cursor });
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    node.seo = !_.isEmpty(node.seo) ? node.seo : {};
    updateSelectedCategory(node);
    setCursor(node);
    //setCatList(Object.assign({}, catList))
    setCatList(catList);
  };

  let openModal = () => {
    setOpenAddModal(!openAddModal);
  };

  //TODO: change catalogsList to be readed from catalogs reducer after merge updated commits
  //REMOVE ME
  /* let catalogsList = _.map(catalogs, (catalog) => ({
    label: _.get(catalog, "name_en"),
    value: _.get(catalog, "_id"),
  }));*/

  //TODO: REMOVE ME
  /*let seoAttribute = _.filter(
    groupAttributes,
    (group) => group.inner_name === "seo"
  );*/

  const handleExportData = () => {
    let exportedList = [];
  
    props.products.forEach((d) => {
      let row = {};
  
      const categories = _.flattenDeep(_.map(d.products_catalogs, c => c.categories ));
      const categoryValues = _.map(categories, c =>  {
        return {
        external_id: c.external_id,
        categories_en: c.name_en,
        categories_ar: c.name_ar
        }
      });

  
      header.forEach((h) => {
        let matchedAttribute = attributes.find(
          (attribute) => attribute.system_name.toLowerCase() === h.toLowerCase()
        );
  
        if (matchedAttribute) {
          if (matchedAttribute.system_name === "base_product") {
            row = {
              ...row,
              [h]: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].sku : ""
            };
          } else if (matchedAttribute.system_name === "brand_id") {
            row = {
              ...row,
              [h]: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].external_id : "",
              brand_en: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].name_en : "",
              brand_ar: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].name_ar : ""
            };
          } else if (matchedAttribute.system_name === "categories") {
            row = {
              ...row,
              [h]: _.map(categoryValues, c => c.external_id).join(','),
              [`${h}_en`]: _.map(categoryValues, c => c.categories_en).join(','),
              [`${h}_ar`]: _.map(categoryValues, c => c.categories_ar).join(',')
            };
          }
          else if (matchedAttribute.class === "custom") {
            if(!_.isEmpty(matchedAttribute.option)){
              let selectedOption = _.find(matchedAttribute.option, op => op.value === d[h])
              row = {
                ...row,
                [h]: selectedOption?.value?? "",
                [`${h}_en`]: selectedOption?.label?? "",
                [`${h}_ar`]: selectedOption?.arabic_label?? ""
              };

            }
            else if(matchedAttribute.system_name !== "categories_ar" && matchedAttribute.system_name !== "categories_en"){
              row = {
                ...row,
                [h]: d[h] ?? ""
              };

            }
            
          }
           else {
            row = {
              ...row,
              [matchedAttribute.system_name]: d[matchedAttribute.system_name] ?? ""
            };
          }
        } else {
          row = {
            ...row,
            [h]: d[h] ?? ""
          };
        }
      });
  
      exportedList.push(row);
    });
  
    return exportedList;
  };

  function handleDownloadExcel() {
    const exportedList = handleExportData();
  
    // downloadExcel({
    //   fileName: `${selectedType} Data`,
    //   sheet: selectedType,
    //   tablePayload: {
    //     header,
    //     body: exportedList,
    //   },
    // });
    return exportedList
  }

  let generalInfoInput = [
    /*{
      type: "switch",
      name: "status",
      id: "status",
      label: "Category Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: _.toLower(data.status) === "active" ? true : false,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "category ID",
      value: data["_id"],
      default: data["_id"],
      disabled: true,
    },*/
    {
      type: "select",
      options: catalogs,
      name: "selectedCat",
      label: "Catalogs",
      value: data["selectedCat"]??_.get(data.catalogs[0], "_id", ""),
      default: data["selectedCat"]??_.get(data.catalogs[0], "_id", ""),
      valueKey: "_id",
      labelKey: "name_en",
    },
  ];

  const staticGroups = [
    {
      id: 0,
      label: "Product Information",
      collapseCase: "ListAttribute",
      open: true,
      attributes: [
        {
          type: "switch",
          name: "status",
          id: "status",
          label: "Category Status",
          classFormGroup: "d-flex",
          classLabel: "mr-3",
          value: _.toLower(data.status) === "active" ? true : false,
          updateState: true,
        },
        {
          type: "switch",
          name: "isShownInMenu",
          id: "isShownInMenu",
          label: "Include in Menu",
          classFormGroup: "d-flex",
          classLabel: "mr-3",
          value: _.toLower(data.isShownInMenu) === true ? true : false,
          updateState: true,
        },
        {
          type: "text",
          name: "_id",
          label: "category ID",
          value: data["_id"],
          default: data["_id"],
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          system_name: "name_ar",
          type: "text",
        },
        {
          label: "External Id",
          name: "external_id",
          system_name: "external_id",
          type: "text",
        },
        {
          label: "English Description",
          name: "description_en",
          type: "textarea",
        },
        {
          label: "Arabic Description",
          name: "description_ar",
          type: "textarea",
        },
      ],
    },
    {
      id: 1,
      label: "Catalog",
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          type: "groupCheckbox",
          option: catalogs,
          name: "catalogs",
          label: "Catalogs",
          value: data["catalogs"],
          default: data["catalogs"],
          valueKey: "_id",
          labelKey: "name_en",
        },
      ],
    },
    {
      id: 2,
      label: "Categories",
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          type: "groupCheckbox",
          option: categories,
          name: "parent",
          label: "Parents",
          value: data["parent"],
          //default: data["parent"],
          valueKey: "_id",
          labelKey: "name_en",
        },
        {
          type: "groupCheckbox",
          option: categories,
          name: "childs",
          label: "Childs",
          value: data["childs"],
          //default: data["childs"],
          valueKey: "_id",
          labelKey: "name_en",
        },
      ],
    },
    {
      id: 3,
      label: "Content",
      collapseCase: "content",
      open: false,
    },
    {
      id: 4,
      label: "Display Setting",
      collapseCase: "dislpaySetting",
      open: false,
    },
    {
      id: 5,
      label: "Product List",
      collapseCase: "table",
      open: false,
      items: props.products??[],
      exported: true
    },
    {
      id: 6,
      label: "Search Engine Optimization",
      collapseCase: "ListAttribute",
      inner_name: "seo",
      open: false,
      attributes: [
        {
          type: "text",
          name: "url_key",
          label: "url key",
        },
        {
          type: "text",
          name: "meta_title_en",
          label: "Meta Title",
        },
        {
          label: "Meta Keywords",
          name: "meta_keyword_en",
          type: "text",
        },
        {
          label: "Meta Keywords Arabic",
          name: "meta_keyword_ar",
          system_name: "meta_keyword_ar",
          type: "text",
        },
        {
          label: "Meta Description",
          name: "meta_desc_en",
          type: "textarea",
        },
        {
          label: "Meta Description Arabic",
          name: "meta_desc_ar",
          type: "textarea",
        },
      ],
    },
  ];

  return (
    <Card name="Category Details">
      {/* Headr  */}
      <HeaderInfo
        {...props}
        data={data}
        titleLabel={"Category Name"}
        onDelete={deleteCategory}
        onSave={editCategory}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo
            {...props}
            info={generalInfoInput}
            noDateRagne={true}
            handleOnStatusChange={props.onChange}
            onChange={props.onChange}
          />
          <div>
            <Label>Category</Label>
            <Treebeard
              data={catList}
              onToggle={onToggle}
              /*decorators={decorators}*/
              style={{
                tree: {
                  base: {
                    listStyle: "none",
                    backgroundColor: "white",
                    margin: 0,
                    padding: 0,
                    color: "#9DA5AB",
                    fontFamily:
                      "lucida grande ,tahoma,verdana,arial,sans-serif",
                    fontSize: "14px",
                  },
                  node: {
                    activeLink: {
                      background: "white",
                    },
                  },
                },
              }}
            />
            <Row style={{ marginTop: "5%" }}>
              <Col className="text-right justify-content-end d-flex align-items-center">
                <Button
                  color="secondary"
                  onClick={openModal}
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  {"Create new Category"}
                </Button>
              </Col>
            </Row>
            {openAddModal && <AddCategory {...props} isFromEdit={true} />}
          </div>
        </Col>

        {/* Details Info */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {/* Static Group Attribute */}
            {_.map(staticGroups, (group) => (
              <CollapseList
                //{...props}
                headerList={headerList}
                catalogs={catalogs}
                data={data}
                group={group}
                usingFormContollerList={
                  group.inner_name === "seo" ? true : false
                }
                target={
                  group.inner_name === "seo" ? data[group.inner_name] : data
                }
                collapseCase={group.collapseCase}
                name="name"
                updateState={props.onChange}
                handleDownloadExcel = {handleDownloadExcel}
              />
            ))}

            {/* Dynamic Group Attribute */}
            {/* {_.map(seoAttribute, (group) => (
              <CollapseList
                {...props}
                group={group}
                usingFormContollerList={
                  group.inner_name === "seo" ? true : false
                }
                target={
                  group.inner_name === "seo" ? data[group.inner_name] : data
                }
                collapseCase="ListAttribute"
                name="inner_name"
                usingEnName={true}
                targetName={group.inner_name}
                updateState={props.onChange}
              />
              ))}*/}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

View.propTypes = {};

export default View;
