import React from "react";
import FormComponent from "../../../../../../components/general/FormController";

const View = (props) => {
  let { attribute, onChange, data } = props;
  console.log("Attribute = ", attribute);

  return (
    <div>
      <FormComponent
        type={attribute.type}
        options={attribute.option}
        name={attribute.system_name}
        label={attribute.label}
        value={data[attribute.system_name]}
        default={data[attribute.system_name]}
        updateState={onChange}
        valueLabel={data[attribute.system_name]}
        valueKey="value"
        labelKey="label"
      />
    </div>
  );
};

export default View;
