import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getLanguages,
  deleteLanguage,
  editLanguage,
} from "../../../../redux/actions/Ecommerce/settings/LanguageAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import CollapseList from "components/general/PageDetails/CollapseList";

import View from "./View";

const Index = (props) => {
  useEffect(() => {
    props.getLanguages();
  }, []);

  const deleteLanguage = (languageId) => {
    try {
      let response = props.deleteLanguage(languageId);
      //SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editLanguage = (languagesId, index) => {
    try {
      let response = props.editLanguage(JSON.stringify(index), languagesId);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  return (
    <div>
      {/*<AddBrand {...props} />*/}
      {props.languages && (
        <View
          {...props}
          deleteLanguage={deleteLanguage}
          //handleCreate={handleCreate}
          editLanguage={editLanguage}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  languages: state.languages.items,
});

export default connect(mapStateToProps, {
  getLanguages,
  deleteLanguage,
  editLanguage,
})(Index);
