import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import { getOneListAttribute } from "../../../../redux/actions/AttributeAction";
import {
  getTagsTemplates,
  addTag,
  findTemplateTag,
} from "../../../../redux/actions/TagsActions";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
import _ from "lodash";
import AddSteps from "../../../../components/general/Logic/Steps";
const business_id = "5cc2f00577ebc157b4f1c64c";

const progressSteps = [
  {
    step: 1,
    title: "Tag Template",
  },
  {
    step: 2,
    title: "Tag Logic",
  },
  {
    step: 3,
    title: "Tag Information",
  },
];
class index extends Component {
  /**
   * This handler to reset steps active state,
   * and then set the step in the argument
   */
  handleClick = (set) => {
    this.setState({
      firstStep: false,
      secondStep: false,
      thirdStep: false,
      [set]: true,
    });
  };

  updateState = (input) => {
    console.log(input);
    this.setState(input);
    this.forceUpdate();
  };

  componentDidMount() {
    this.props.getOneListAttribute(business_id);
    this.props.getTagsTemplates(business_id);
  }

  handelGetTemplate = (templateId, businessId) => {
    console.log("Get Template: before fetch", templateId, businessId);

    this.props.findTemplateTag(templateId, businessId);
    return this.props.template;
  };

  inputsToJSON = (inputs) => {
    return JSON.stringify(inputs);
  };

  handelUpdatedInputs = (algorithm) => {
    let logicAlgorithm = { operator: algorithm.operator /*.value*/ };
    let logic_list = _.map(algorithm.logic_list, (logic) => ({
      attribute: logic.attribute.inner_name || "",
      child:
        !_.isEmpty(logic.child) && !_.isEmpty(logic.child.logic_list)
          ? this.handelUpdatedChildInputs(logic.child)
          : null,
      operator: logic.operator /*.value*/,
      value: logic.value,
    }));

    //let logic =
    return { ...logicAlgorithm, logic_list };
    /*console.log({
        ...Tag,
        logic
      })
    return {
        ...Tag,
        logic
    }*/
  };

  handelUpdatedChildInputs = (childLogic) => {
    let algorithm = { operator: childLogic.operator /*.value*/ };
    let logic_list = _.map(childLogic.logic_list, (logic) => ({
      attribute: logic.attribute.inner_name || "",
      operator: logic.operator /*.value*/,
      value: logic.value,
    }));

    return {
      ...algorithm,
      logic_list,
    };
  };

  addTag = (Tag) => {
    //e.preventDefault()
    try {
      let inputs =
        //this.handelUpdatedInputs(Tag)
        `{
        "name": "${Tag.name}",
        "desc": "${Tag.desc}",
        "is_template":${Tag.is_template},
        "status":"${Tag.status ? "active" : "inactive"}",
        "runLogicTime":"${Tag.runLogicTime}",
        "business_id": "${business_id}",
        "logic":${this.inputsToJSON(Tag.logic)}
        }`;
      console.log(inputs);
      let response = this.props.addTag(inputs);

      SuccessAlert("success", "The Tag have been added sucessfully.");
      //this.props.history.push("/crm/tags"); //redirect to TagList
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  /**
   * Methods to add
   * 2. Save > handleSubmit
   */

  render() {
    return (
      <div>
        <AddSteps
          {...this.props}
          progressSteps={progressSteps}
          pageName="Tag"
          getTemplate={this.handelGetTemplate}
          onSubmit={this.addTag}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  attributes: state.attribute.oneList,
  templates: state.tags.templates,
  template: state.tags.template,
});
export default connect(mapStateToProps, {
  getOneListAttribute,
  getTagsTemplates,
  addTag,
  findTemplateTag,
})(index);
