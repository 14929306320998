import React, { useState } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import Card from "components/general/Card";

import EditAttribute from "views/Ecommerce/Attribute/EditAttribute";
import Modal from "components/general/Modal";
const View = (props) => {
  const { selectedAttributes, toggleOption } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  console.log("Second Steps", selectedAttributes);
  return selectedAttributes.map((attribute, attributeIndex) => {
    return (
      <Card name={attribute.name_en} cardStyle={{ marginBottom: 25 }}>
        <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
          {"Setup Attribute"}
        </Button>

        <Modal
          title={"Create A new Attribute"}
          toggle={toggle}
          isOpen={modal}
          size="xl"
        >
          <EditAttribute
            item={attribute}
            id={attribute._id}
            updateConfig={true}
          />
        </Modal>

        {attribute.option.map((option, optionIndex) => (
          <FormGroup check>
            <Label check>
              <Input
                type={"checkbox"}
                value={option.value}
                checked={option.checked}
                onChange={() => toggleOption(attributeIndex, optionIndex)}
              />
              {option.label}
            </Label>
          </FormGroup>
        ))}
      </Card>
    );
  });
};

View.propTypes = {};

export default View;
