import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Table from "../../../../components/general/Table";
import Card from "../../../../components/general/Card";
import HeaderActions from "../../../../components/general/HeaderBtns";
import Collapse from "components/general/Collapse";
import Delete from "components/general/Delete";
import _, { method } from "lodash";
import AddPayment from "./Add";

import FormComponent from "components/general/FormControllerList";
import { Alert } from "reactstrap";
import index from "views/CRM/ContactProfile/ContactDetails/general-info";
import Options from "../Options";

// FIXME: Status has issues
const View = (props) => {
  let { deletePayment, editPayment, payments, paymentMethodsDemo, gateways } = props;
  console.log(props);

  let [state, setPayments] = useState(payments);
  let [errors, setError] = useState({
    nameError: "",
  });

  const updateState = (input) => {
    console.log("Input", input);
    setPayments({ ...state, ...input });
  };

  console.log("Paymnets", payments);

  return (
    <div className="setting">
      <Card name="Payment Setting" goBack={props.history.goBack}>
        <div className="contact-table">
          {/* Add Country */}
          <div className="d-flex justify-content-end">
            <AddPayment {...props} updateState={updateState}/>
          </div>

          <Col xs="12" lg="8" xl="9" className="group-list">
            {_.map(payments, (data, key) => {
              // List of Attrbiute
              let attributeList = [];
              data.paymentGatewayID === "6110084812e95d6371d98de0"
                ? (attributeList = [
                    {
                      name: "method",
                      type: "select",
                      options: paymentMethodsDemo,
                      target: "banks",
                      targetName: "payments",
                      valueKey: "value",
                      labelKey: "label",
                    },
                    {
                      name: "description_en",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "description_ar",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                  ])
                : (attributeList = [
                    {
                      name: "bank_id",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "description_en",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "description_ar",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "account_number",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "iban",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                  ]);

              data.feesEnabled &&
                attributeList.push({
                  name: "fee",
                  type: "decimal",
                  target: "banks",
                  targetName: "payments",
                });

              attributeList.push({
                name: "icon",
                type: "file",
                target: "banks",
                targetName: "payments",
              });

              return (
                <Collapse
                  group={{
                    label: `${data.nameEN} Getway`,
                    id: key,
                    open: false,
                  }}
                >
                  {/* Form */}
                  <FormComponent
                    type="switch"
                    id={`isActive_${key}`}
                    name="isActive"
                    label="Activate"
                    target={payments[key]}
                    targetName="payments"
                    value={data.isActive}
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  <FormComponent
                    type="text"
                    name="nameEN"
                    label="English Name"
                    target={payments[key]}
                    targetName="payments"
                    value={data.nameEN}
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  {!_.isEmpty(_.get(errors, "nameError")) && (
                    <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                  )}
                  <FormComponent
                    type="text"
                    name="nameAR"
                    label="Arabic Name"
                    value={data.nameAR}
                    target={payments[key]}
                    targetName="payments"
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  {!_.isEmpty(_.get(errors, "nameError")) && (
                    <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                  )}
                   {props.catalogs && (
                  <FormComponent
                    type="groupCheckbox"
                    options={props.catalogs}
                    name="channels"
                    label="Channel"
                    value={data.channels}
                    target={payments[key]}
                    targetName="payments"
                    valueKey="_id"
                    labelKey="name_en"
                    updateState={updateState}
                  />
                   )} 
                  <FormComponent
                    type="switch"
                    id={`feesEnabled_${key}`}
                    name="feesEnabled"
                    label="Get Additional Fee Per Transaction"
                    target={payments[key]}
                    targetName="payments"
                    value={data.feesEnabled?? true}
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  {data.feesEnabled && (
                    <div>
                      <FormComponent
                        {...props}
                        type="radio"
                        id={`${key}value_fees`}
                        name={`${key}feesType`}
                        label="value fees"
                        target={payments[key]}
                        radioList={payments}
                        targetName={"payments"}
                        value={"VALUE"}
                        updateState={updateState}
                      />

                      <FormComponent
                        {...props}
                        type="radio"
                        id={`${key}percentage_fees`}
                        name={`${key}feesType`}
                        label="percentage fees"
                        target={payments[key]}
                        radioList={payments}
                        targetName={"payments"}
                        value={"PERCENTAGE"}
                        updateState={updateState}
                      />

                      {data.paymentGatewayID === "610430ac20bfbb068114e8fc" && (
                        <FormComponent
                          type="decimal"
                          name="feesValue"
                          label="Fee"
                          value={data.feesValue}
                          target={payments[key]}
                          targetName="payments"
                          updateState={updateState}
                        />
                      )}
                    </div>
                  )}
                  {data.paymentGatewayID === "6110084812e95d6371d98de0" && (
                    <Collapse
                      group={{
                        label: "Payment Method Options Setting",
                        id: `"data"${key}`,
                        open: false,
                      }}
                    >
                      <Options
                        attributeList={attributeList}
                        {...props}
                        data={data}
                        target={"banks"}
                        targetName={"payments"}
                        options={data.banks}
                        updateState={updateState}
                        hasAddOption={true}
                        headers={[
                          "Method",
                          "English Description",
                          "Arabic Description",
                          "Fee",
                          "Icon",
                        ]}
                        newOptions={{
                          method: "",
                          description_en: "",
                          description_ar: "",
                          fee: "",
                          icon: "",
                        }}
                        addMore={"Add New Payment Method Options"}
                      />
                    </Collapse>
                  )}

                  {data.type === 3 && (
                    <Collapse
                      group={{
                        label: "Bank List",
                        id: `"data"${key}`,
                        open: false,
                      }}
                    >
                      {/*<Options
                        attributeList={attributeList}
                        {...props}
                        data={data}
                        target={"banks"}
                        targetName={"payments"}
                        options={data.banks}
                        updateState={updateState}
                        hasAddOption={true}
                        headers={[
                          "Bank Id",
                          "English Description",
                          "Arabic Description",
                          "Account Number",
                          "IBAN",
                          "Fee",
                          "Icon",
                        ]}
                        newOptions={{
                          bank_id: "",
                          description_en: "",
                          description_ar: "",
                          account_number: "",
                          iban: "",
                          fee: "",
                          icon: "",
                        }}
                        addMore={"Add New Payment Method Options"}
                      />*/}
                    </Collapse>
                  )}
                  {/* Language Actions */}
                  <div className="row justify-content-end">
                    <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                      <Button
                        className="btn btn-primary btn-cons btn-cons"
                        onClick={() =>
                          editPayment(payments[key]._id, payments[key])
                        }
                      >
                        {"Save"}
                      </Button>

                      <Delete
                        data={data.nameEN}
                        onSubmit={deletePayment}
                        customerID={payments[key]._id}

                        actionType="button"
                        buttonStyle="btn btn-white btn-cons"
                      />
                    </div>
                  </div>
                </Collapse>
              );
            })}
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default View;
