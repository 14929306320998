import React, { useEffect, useState, useForceUpdate } from "react";
import { connect } from "react-redux";
import View from "./view";
import _ from "lodash";
const Index = (props) => {
  console.log(props);

  let { state, templates } = props;

  let [templateList, setTemplateList] = useState(templates);

  useEffect(() => {
    if (!_.isEmpty(state.type)) {
      let temps = _.filter(
        templates,
        (template) => template.promotion_type._id === state.type
      );
      setTemplateList(temps);
    }
  }, []);

  useEffect(() => {
    if (props.template) {
      if (props.typeName === "cart")
        props.updateState({
          logic_x: props.template.logic_x? props.template.logic_x : state.logic_x,//FIXME: need to remove the condetion bcz the logic must be required 
          logic_y: props.template.logic_y? props.template.logic_y : state.logic_y,
        })
      else
        props.updateState({
          logic_x: props.template.logic_x? props.template.logic_x : state.logic_x,
        })

    }
  }, [props.template])

  // Write on redux template
  const handleSelectTemplate = (id, template) => {
    props.updateState({
      ...state,
      templateId: id,
      template,
    });
    /*if (id !== 1) {
          props.getTemplate(id, business_id)
        } else {
          props.resetState()
        }*/
  };

  return (
    <div>
      <View
        {...props}
        templateList={templateList}
        handleSelectTemplate={handleSelectTemplate}
      />
    </div>
  );
};

export default Index;
