import React, { ProptypeNames } from "react";
import CollabseComponent from "components/general/Collapse";
import { Col } from "reactstrap";
import PromotionRules from "../../PromotionRules";
import Coupon from "../../Coupon";
import FormComponent from "components/general/FormController";
import Algorithm from "components/general/Logic/Algorithm";

const Index = (props) => {
  const { state } = props;
  const generalCollapse = [
    {
      id: "1",
      label: "Promotion Analysis",
      open: true,
    },
    {
      id: "2",
      label: "Promotion Logic",
      open: true,
    },
  ];
  const getRulesCollapse = () => {
    let list = [
      { id: "rule-1", label: "Promotion Rules" },
      { id: "rule-2", label: "Product List" },
      { id: "rule-3", label: "Promotion Message & label" },
    ];

    if (props.state.typeName === "cart")
      list = [
        { id: "rule-1", label: "Promotion Rules" },
        { id: "rule-2", label: "Buy List  ( Product X)" },
        { id: "rule-4", label: "Get List  ( Product Y)" },
        { id: "rule-3", label: "Promotion Message & label" },
      ];

    if (props.state.typeName === "coupon") {
      list[2] = { id: "rule-5", label: "Coupon" };
      list[3] = { id: "rule-3", label: "Promotion Message & label" };
    }

    return list;
  };
  console.log("Attribute list", props.attributes);
  return (
    <div>
      <div className="steps-container setting">
        <div className="templates-container">
          {generalCollapse.map((group) => (
            <Col xs="12" lg="8" xl="12" className="group-list">
              <CollabseComponent group={group}>
                {group.id === "2" &&
                  getRulesCollapse().map((rule) => (
                    <CollabseComponent group={rule}>
                      {rule.id === "rule-1" && <PromotionRules {...props} />}
                      {rule.id === "rule-2" && <Algorithm {...props} logic={props.state.logic_x} attributes={props.attributeList} targetName="logic_x"/>}
                      {rule.id === "rule-5" && <Coupon {...props} />}

                      {rule.id === "rule-4" && <Algorithm {...props} rule_id={"rule-4"} logic={props.state.logic_y}attributes={props.attributeList} targetName="logic_y" />}
                      {rule.id === "rule-3" && (
                        <div>
                          <FormComponent
                            type={"text"}
                            label={"English Message"}
                            name={"message_en"}
                            value={state.message_en}
                            updateState={props.onChange}
                          />
                          <FormComponent
                            type={"text"}
                            label={"Arabic Message"}
                            name={"message_ar"}
                            value={state.message_ar}
                            updateState={props.onChange}
                          />

                          <FormComponent
                            type={"text"}
                            label={"English Label"}
                            name={"label_en"}
                            value={state.label_en}
                            updateState={props.onChange}
                          />

                          <FormComponent
                            type={"text"}
                            label={"Arabic Label"}
                            name={"label_ar"}
                            value={state.label_ar}
                            updateState={props.onChange}
                          />
                        </div>
                      )}
                    </CollabseComponent>
                  ))}
              </CollabseComponent>
            </Col>
          ))}
        </div>
      </div>
    </div>
  );
};

Index.proptypeNames = {};

export default Index;
