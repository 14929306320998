import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Card from "../../../components/general/Card";
import CollapseList from "components/general/PageDetails/CollapseList";
import Delete from "components/general/Delete";
import _, { update } from "lodash";
import AddRole from "./Add";

const View = (props) => {
    const { roles, deleteRole, editRole , permissions} = props;
    const [state, setState] = useState(roles);
    console.log(roles)

    let roleData = [];
    _.map(roles, (data, index) => {
        let item = {
            id: index,
            label: data["name"] ||  data["nameEN"],
            collapseCase: "ListAttribute",
            open: false,
            attributes: [
                {
                    type: "switch",
                    id: `switch${index}`,
                    name: "is_active",
                    label: "Activate Role",
                    classFormGroup: "d-flex",
                    classLabel: "mr-3",
                    value: data["is_active"],
                    default: data["is_active"],
                },
                {
                    type: "text",
                    name: "_id",
                    label: "Role ID",
                    value: data["_id"],
                    default: data["_id"],
                    disabled: true,
                },
                {
                    label: "English Name",
                    name: data["nameEN"]? "nameEN" : "name" ,
                    type: "text",
                    value: data["nameEN"] || data["name"],
                    default: data["nameEN"] || data["name"],
                },
                {
                    label: "Arabic Name",
                    name: "nameAR",
                    type: "text",
                    value: data["nameAR"],
                    default: data["nameAR"],
                },
                {
                    label: "Description English",
                    name: "descriptionEN",
                    type: "text",
                    value: data["descriptionEN"],
                    default: data["descriptionEN"],
                },
                {
                    label: "Description Arabic",
                    name: "descriptionAR",
                    type: "text",
                    value: data["descriptionAR"],
                    default: data["descriptionAR"],
                },
                {
                    type: "groupCheckbox",
                    option: permissions,
                    name: "permissions",
                    label: "Role Permissions",
                    value: data.permissions,
                    //default: _.map(data.permissions, permission => permission._id),
                    valueKey: "_id",
                    labelKey: "name",
                    //updateState:{onChange}

                },
            ],
        };
        roleData.push(item);
    });

    const updateState = (input) => {
        console.log("Input", input);
        setState({ ...state, ...input });
    };

    console.log("State", state);
    console.log("roles", roles);

    return (
        <div className="setting">
            <Card name="Roles & Permissions " goBack={props.history.goBack}>
                <div className="contact-table">
                    {/* Add Role */}
                    <div className="d-flex justify-content-end">
                        <AddRole {...props} />
                    </div>

                    {/* List of roles */}
                    <Col xs="12" lg="8" xl="9" className="group-list">
                        {_.map(roleData, (group, key) => (
                            <CollapseList
                                {...props}
                                group={group}
                                usingFormContollerList={true}
                                target={roles[key]}
                                collapseCase={group.collapseCase}
                                name="name"
                                updateState={updateState}
                            >
                                {/* Role Actions */}
                                <div className="row justify-content-end">
                                    <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                                        <Button
                                            className="btn btn-primary btn-cons btn-cons"
                                            onClick={() =>
                                                editRole(group.attributes[0].value, state[key])
                                            }
                                        >
                                            {"Save"}
                                        </Button>

                                        <Delete
                                            data={group.label}
                                            onSubmit={deleteRole}
                                            customerID={group.attributes[1].value}
                                            actionType="button"
                                            buttonStyle="btn btn-white btn-cons"
                                        />
                                    </div>
                                </div>
                            </CollapseList>
                        ))}
                    </Col>
                </div>
            </Card>
        </div>
    );
};

export default View;
