import {
    ADD_CARRIER,
    EDIT_CARRIER,
    DELETE_CARRIER,
    GET_CARRIERS,
    FIND_CARRIER,
    GET_CARRIERS_COMPANIES
  } from "../../../actions/settings/types";
  
  const initialState = {
    item: null,
    items: null,
    companies: null
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case EDIT_CARRIER:
      case FIND_CARRIER:
      case DELETE_CARRIER:
        return {
          ...state,
          item: action.payload,
        };
      case GET_CARRIERS:
        return {
          ...state,
          items: action.payload,
        };
        case GET_CARRIERS_COMPANIES: 
        return {
          ...state,
          companies: action.payload
        };
      case ADD_CARRIER:
        return {
          ...state,
          item: action.payload,
          items: [...state.items, action.payload],
        };
      default:
        return state;
    }
  }
  