import {
  BULK_UPLOAD_FILE,
  BULK_IMPORT_PROGRESS_DATA,
  BULK_IMPORT_GET_DATA,
} from "../../actions/Ecommerce/types";

const initialState = {
  item: null,
  items: null,
  progress_data: null,
};

export default function (state = initialState, action) {
  console.log("progress init state=>", action);

  switch (action.type) {
    case BULK_UPLOAD_FILE:
      return {
        ...state,
        item: action.payload,
      };
    case BULK_IMPORT_PROGRESS_DATA:
      console.log("progress data state=>", action);
      return {
        ...state,
        progress_data: action.payload,
      };
    case BULK_IMPORT_GET_DATA:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
}
