import React from "react";
import GeneralInfo from "./general-info";
import Card from "../../../../components/general/Card";
import { Row, Col, Button } from "reactstrap";
import Delete from "../ContactDelete";

const View = (props) => {
  //let { customerAttributes } = props
  return (
    <Card name={"Contact Details"}>
      {/* Title */}
      <Row className="contact-header">
        <Col xs="12" sm="8" className="text-left">
          <h5 className="title bold">
            {"Contact Name: "}
            {props.customerAttributes &&
              props.customerAttributes[0].attributes[0].value}
          </h5>
        </Col>
        <Col xs="12" sm="4" className="text-right justify-content-end d-flex">
          <Delete
            data={props.data.name}
            customerID={props.data.contact_id}
            actionType={"button"}
          />
          <Button color="primary" style={{ width: "100%" }}>
            {"Save"}
          </Button>
        </Col>
      </Row>

      {/* Body */}
      <Row className="contact-body">
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo {...props} />
        </Col>
        <Col xs="12" lg="8" xl="9" className="group-list">
          {props.children}
        </Col>
      </Row>
    </Card>
  );
};
export default View;
