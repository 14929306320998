import * as React from "react";
const AddImageSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-2h24v24H-3z" />
      <path
        fill="#1D1D1D"
        d="M18.02 3H16V.98c0-.54-.44-.98-.98-.98h-.03c-.55 0-.99.44-.99.98V3h-2.01c-.54 0-.98.44-.99.98v.03c0 .55.44.99.99.99H14v2.01c0 .54.44.99.99.98h.03c.54 0 .98-.44.98-.98V5h2.02c.54 0 .98-.44.98-.98v-.04c0-.54-.44-.98-.98-.98ZM13 7.01V6h-1.01c-.53 0-1.03-.21-1.41-.58-.37-.38-.58-.88-.58-1.44 0-.36.1-.69.27-.98H2C.9 3 0 3.9 0 5v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8.72c-.3.17-.64.28-1.02.28A2 2 0 0 1 13 7.01ZM12.96 17H3a.5.5 0 0 1-.4-.8l1.98-2.63c.21-.28.62-.26.82.02L7 16l2.61-3.48c.2-.26.59-.27.79-.01l2.95 3.68c.26.33.03.81-.39.81Z"
      />
    </g>
  </svg>
);
export default AddImageSvg;
