import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCustomerTypes,
  deleteCustomerType,
  editCustomerType,
} from "redux/actions/settings/customerTypeAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";
import CollapseList from "components/general/PageDetails/CollapseList";

import View from "./View";

const Index = (props) => {
  useEffect(() => {
    props.getCustomerTypes();
  }, []);

  const deleteCustomerType = (customerTypeId) => {
    try {
      let response = props.deleteCustomerType(customerTypeId);
      //SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editCustomerType = (customerTypeId, index) => {
    try {
      let response = props.editCustomerType(JSON.stringify(index), customerTypeId);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  return (
    <div>
      {/*<AddBrand {...props} />*/}
      {props.customerTypes && (
        <View
          {...props}
          deleteCustomerType={deleteCustomerType}
          //handleCreate={handleCreate}
          editCustomerType={editCustomerType}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerTypes: state.customerTypes.items,
});

export default connect(mapStateToProps, {
  getCustomerTypes,
  deleteCustomerType,
  editCustomerType,
})(Index);
