import React, { PropTypes } from "react";
import Card from "../../../../components/general/Card";
import HeaderInfo from "../../../../components/general/PageDetails/HeaderInfo";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import CollapseList from "../../../../components/general/PageDetails/CollapseList";
import _ from "lodash";
import { Row, Col, Button } from "reactstrap";

const View = (props) => {
  const { data, deleteBrand, editBrand, groupAttributes,attributes} = props;

  const indexing_for = "ecommerce"
  let custom_attributes = _.filter(attributes, attr => attr.class === "custom" && attr.indexing_for === indexing_for)

let header = _.uniq(_.flattenDeep(_.map(props.products, d =>_.keys(d))))

    if(_.includes(header, "brand_id")){
      const index = _.indexOf(header, "brand_id")
      header.splice(index, 0, "brand_en", "brand_ar");
    }
    else 
    header.push(...["brand_id","brand_en","brand_ar"])
  
    header.push(...["categories", "categories_en", "categories_ar"])

  _.map(custom_attributes, cattr => {
    const index = _.indexOf(header, cattr.system_name)
      if(_.includes(header, cattr.system_name) && !_.isEmpty(cattr.option)){
        header.splice(index, 0, `${cattr.system_name}_en`, `${cattr.system_name}_ar`);
    }
    
  })


  const handleExportData = () => {
    let exportedList = [];
  
    props.products.forEach((d) => {
      let row = {};
  
      const categories = _.flattenDeep(_.map(d.products_catalogs, c => c.categories ));
      const categoryValues = _.map(categories, c =>  {
        return {
        external_id: c.external_id,
        categories_en: c.name_en,
        categories_ar: c.name_ar
        }
      });

  
      header.forEach((h) => {
        let matchedAttribute = attributes.find(
          (attribute) => attribute.system_name.toLowerCase() === h.toLowerCase()
        );
  
        if (matchedAttribute) {
          if (matchedAttribute.system_name === "base_product") {
            row = {
              ...row,
              [h]: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].sku : ""
            };
          } else if (matchedAttribute.system_name === "brand_id") {
            row = {
              ...row,
              [h]: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].external_id : "",
              brand_en: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].name_en : "",
              brand_ar: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].name_ar : ""
            };
          } else if (matchedAttribute.system_name === "categories") {
            row = {
              ...row,
              [h]: _.map(categoryValues, c => c.external_id).join(','),
              [`${h}_en`]: _.map(categoryValues, c => c.categories_en).join(','),
              [`${h}_ar`]: _.map(categoryValues, c => c.categories_ar).join(',')
            };
          }
          else if (matchedAttribute.class === "custom") {
            if(!_.isEmpty(matchedAttribute.option)){
              let selectedOption = _.find(matchedAttribute.option, op => op.value === d[h])
              row = {
                ...row,
                [h]: selectedOption?.value?? "",
                [`${h}_en`]: selectedOption?.label?? "",
                [`${h}_ar`]: selectedOption?.arabic_label?? ""
              };

            }
            else if(matchedAttribute.system_name !== "categories_ar" && matchedAttribute.system_name !== "categories_en"){
              row = {
                ...row,
                [h]: d[h] ?? ""
              };

            }
            
          }
           else {
            row = {
              ...row,
              [matchedAttribute.system_name]: d[matchedAttribute.system_name] ?? ""
            };
          }
        } else {
          row = {
            ...row,
            [h]: d[h] ?? ""
          };
        }
      });
  
      exportedList.push(row);
    });
  
    return exportedList;
  };

  function handleDownloadExcel() {
    const exportedList = handleExportData();
  
    return exportedList
  }

  let generalInfoInput = [
    {
      type: "switch",
      name: "status",
      id: "status",
      label: "Brand Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: data.status,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "Brand ID",
      value: data["_id"],
      default: data["_id"],
      disabled: true,
    },
    {
      type: "groupCheckbox",
      options: props.catalogs,
      name: "catalogs",
      label: "Catalogs",
      value: data["catalogs"],
      default: data["catalogs"],
      valueKey: "_id",
      labelKey: "name_en",
    },
  ];

  const staticGroups = [
    {
      id: 0,
      label: "Brand Information",
      collapseCase: "ListAttribute",
      open: true,
      attributes: [
        {
          label: "Brand ID",
          name: "_id",
          type: "text",
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          type: "text",
        },
        {
          label: "External Id",
          name: "external_id",
          type: "text",
        },
        {
          label: "Priority",
          name: "priority",
          type: "text",
        },
      ],
    },
    {
      id: 1,
      label: "Brand Logo",
      collapseCase: "Media",
      open: false,
      name: "logos",
      multiple: false,
      listOfObject: true,
      srcKey: "source",
      labelKey: "size",
    },
    {
      id: 3,
      label: "Brand Assets",
      collapseCase: "price",
      open: false,
    },
    {
      id: 4,
      label: "Product List",
      collapseCase: "table",
      items: props.products??[],
      exported: true,
      open: false,
    },
  ];

  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "sku",
      align: "center",
      title: "sku",
    },
    {
      type: "name_en",
      sortable: true,
      title: "Name",
    },
    {
      type: "type",
      title: "Type",
      align: "center",
    },
    {
      type: "base_product.name_en",
      title: "Base Product",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
  ];

  return (
    <Card name="Product Details">
      {/* Headr  */}
      <HeaderInfo
        {...props}
        data={data}
        image={data.logos[0].source}
        titleLabel={"Brand Name"}
        onDelete={deleteBrand}
        onSave={editBrand}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo
            {...props}
            info={generalInfoInput}
            noDateRagne={true}
            handleOnStatusChange={props.onChange}
            onChange={props.onChange}
          />
        </Col>

        {/* Details Info */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {/* Static Group Attribute */}
            {_.map(staticGroups, (group) => (
              <CollapseList
                {...props}
                group={group}
                collapseCase={group.collapseCase}
                name="name"
                updateState={props.onChange}
                headerList={headerList}
                handleDownloadExcel = {handleDownloadExcel}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

View.propTypes = {};

export default View;
