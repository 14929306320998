import {
  ADD_PAYMENT,
  EDIT_PAYMENT,
  DELETE_PAYMENT,
  GET_PAYMENTS,
  FIND_PAYMENT,
  GET_GATEWAYS
} from "../../../actions/settings/types";

const initialState = {
  item: null,
  items: null,
  gateways: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_PAYMENT:
    case FIND_PAYMENT:
    case DELETE_PAYMENT:
      return {
        ...state,
        item: action.payload,
      };
    case GET_PAYMENTS:
      return {
        ...state,
        items: action.payload,
      };
      case  GET_GATEWAYS: 
      return {
        ...state,
        gateways: action.payload
      };
    case ADD_PAYMENT:
      return {
        ...state,
        item: action.payload,
        items: [...state.items, action.payload],
      };
    default:
      return state;
  }
}
