import React, { useEffect, useState } from "react";
import View from "./View";
import { getAttributes } from "../../../../redux/actions/Ecommerce/AttributeAction";
import {getGroups} from "redux/actions/Ecommerce/GroupAction"
import { connect } from "react-redux";
const business_id = `id: "5cc2f00577ebc157b4f1c64c"`;

function Index(props) {
  let { groups, getAttributes  , getGroups } = props;

  const [groupList, setGroupList] = useState();

  /*componentWillMount() {
    const business_id = `id: "5cc2f00577ebc157b4f1c64c"`;
    this.props.getAttributes(business_id);
  }*/
  // componentWillReceiveProps(newProps){
  // if(this.props != newProps){
  //   const business_id = `id: "5cc2f00577ebc157b4f1c64c"`;
  //   this.props.getAttributes(business_id);
  // }
  // }

  useEffect(() => {
    getGroups("crm")
    if (groups) {
      setGroupList(groups);
    }
  }, []);

  /*useEffect(() => {
    if (groups)
      setGroupList(groups.data.businessAccount.attributes)

  }, [groups])*/

  const accountID = "5cc2f00577ebc157b4f1c64c";

  return (
    <div>
      {groupList &&
        groupList.map((group, index) => {
          return (
            <View
              group={group}
              index={index}
              setGroupList={setGroupList}
              business_id={accountID /*business_id*/}
              {...props}
            />
          );
        })}
    </div>
  );
}
const mapStateToProps = (state) => ({
  groups: state.ecommerceGroups.items,
});

export default connect(mapStateToProps, { getAttributes , getGroups})(Index);
