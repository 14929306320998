import {
  ADD_PROMOTION,
  EDIT_PROMOTION,
  GET_PROMOTIONS,
  FIND_PROMOTION,
  DELETE_PROMOTION,
  GET_TEMPLATE_PROMOTIONS,
  GET_PROMOTION_TYPES,
  SEARCH_PRODUCT_PROMOTION
} from "../../actions/Ecommerce/types";

const initialState = {
  item: null,
  items: null,
  templates: null,
  promotion_types: null,
  products_promotion: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PROMOTION:
    case EDIT_PROMOTION:
    case FIND_PROMOTION:
    case DELETE_PROMOTION:
      return {
        ...state,
        item: action.payload,
      };
    case GET_PROMOTIONS:
      return {
        ...state,
        items: action.payload,
      };
    case GET_TEMPLATE_PROMOTIONS:
      return {
        ...state,
        templates: action.payload,
      };

    case GET_PROMOTION_TYPES:
      return {
        ...state,
        promotion_types: action.payload,
      };
      case SEARCH_PRODUCT_PROMOTION: 
      return {
        ...state,
        products_promotion: action.payload
      }
    default:
      return state;
  }
}
