import React from "react";
import { FormGroup, Label, Col, Row, Input, Form } from "reactstrap";
import attribute from "redux/reducers/attribute";
import { attributeTypes } from "seeder/attributeTypes";
import FormComponent from "components/general/FormControllerList";
export default function View(props) {
  const { option, options, index, updateState, onRemoveOption, typeCase } =
    props;
  console.log(props.attributeList, "Attributes Options");
  return (
    <div className="options">
      <Row className="flex-center">
        <Col sm="12" className="flex-center text-center justify-content-evenly">
          {props.attributeList.map((attr, key) => (
            <div className="">
              <FormComponent
                {...props}
                type={attr.type}
                name={attr.name}
                target={props.data[attr.target][index]}
                valueKey={attr.valueKey}
                labelKey={attr.labelKey}
                options={attr.options}
                targetName={attr.targetName}
                value={option[attr.name]}
                updateState={props.updateState}
              />
            </div>
          ))}

          {props.hasAddOption && (
            <i
              className="material-icons m-r-5 pointer"
              onClick={(input) => {
                onRemoveOption(input, index);
              }}
            >
              delete
            </i>
          )}
        </Col>
      </Row>
    </div>
  );
}
