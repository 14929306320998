import React, { PropTypes } from "react";
import { connect } from "react-redux";
import AddGroup from "views/CRM/ProfileAttribute/AddGroup";
import { addGroup } from "redux/actions/Ecommerce/GroupAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";

const Index = (props) => {
  const addGroup = (group) => {
    try {
      let response = props.addGroup(JSON.stringify(group));
      SuccessAlert("success", "Sucessfully Added");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };
  return <AddGroup addEcomGroup={addGroup} isEcomGroup={true} />;
};

export default connect(null, { addGroup })(Index);
