import React, { Component } from "react";
import View from "./View";
import _ from "lodash";
import Address from "./Address";

class index extends Component {
  render() {
    return (
      <div>
        {this.props.customerAttributes &&
          _.map(this.props.customerAttributes, (group) => (
            <View
              group={group}
              data={this.props.data}
              onChange={this.props.onChange}
            />
          ))}
        <Address
          addresses={this.props.data.addresses}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

export default index;
