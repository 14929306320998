import React, { PropTypes } from "react";
import { Row, Col } from "reactstrap";
import { fromPairs } from "lodash";
import MediaLibrary from "../MediaLibrary";
import { restfulUrl } from "../../../context/url";

import Card from "../Card";

const Single = (props) => {
  const { listOfObject, media } = props;
  console.log("single Media", media[1]);

  const handleDelete = (pic, index, e) => {
    e.preventDefault();
    console.log(index);
    let media = props.data[props.name];
    media[index][props.srcKey] = null;
    props.updateState({ media: media }, props.index);
  };

  return listOfObject ? (
    media.map((mediaObj, index) => (
      <div>
        <Row className="d-flex ">
          <div className="modal-body d-flex justify-content-evenly">
            <h6 className="semi-bold flex-1">{mediaObj[props.labelKey]}</h6>
            <div className="w-75">
              {mediaObj[props.srcKey] ? (
                <Row lg="3" sm="6" md="3">
                  <Col className="mb-4">
                    <Card
                      cardStyle={{ height: 255 }}
                      image={restfulUrl + "/" + mediaObj[props.srcKey]}
                    >
                      <div className="d-flex">
                        <button class={"btn w-100 mt-3 mr-1"}>{"Edit"}</button>
                        <button
                          class={"btn btn-primary w-100 mt-3"}
                          onClick={(e) => handleDelete(mediaObj, index, e)}
                        >
                          {"Delete"}
                        </button>
                      </div>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <MediaLibrary {...props} target={mediaObj} />
              )}
            </div>
          </div>
        </Row>
      </div>
    ))
  ) : (
    <MediaLibrary {...props} />
  );
};

export default Single;
