import React, { useState } from "react";
import { Card, CardTitle } from "reactstrap";
import ImportExport from "components/general/ImportExport";
import ExportPage from "./Export";
import PretBox from "components/import-export/PretBox";
import SampleBox from "components/import-export/SampleBox";
import ImportTableComponent from "components/general/ImportTableComponent";
const View = (props) => {
  console.log("view props=>", props);
  const [showImportExport, setShowImportExport] = useState(false);
  const [showExport, setShowExport] = useState(false);


  const handleImportClick = () => {
    setShowImportExport(true);
  };
  const handleExportClick = () => {
    setShowExport(true);
    console.log({
      showExport,
      showImportExport
    })
  };

  return showImportExport ? (
    <ImportExport {...props} />
  ) : showExport ? (<ExportPage {...props} />) : (
    <div className="main-card-import">
      <Card body>
        <CardTitle className="grid-title d-flex justify-content-between">
          <h4>Import / Export</h4>
        </CardTitle>
        <div className="imp-exp-main-cont">
          <div className="btns-impexp">
            <div className="btn-wr-impexp">
              <button
                value="next"
                className="imp-btn btn-next"
                onClick={handleImportClick}
              >
                Import
              </button>
              <button
                value="prev"
                className="exp-btn btn-prev"
                onClick={handleExportClick}
              >
                Export
              </button>
            </div>
            <div className="boxes-imp-wrap">
              <PretBox />
              <SampleBox />
            </div>
          </div>
        </div>
      </Card>
      {props.bulkImportData ? <ImportTableComponent {...props} /> : <></>}
    </div>
  );
};

export default View;
