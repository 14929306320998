import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getPayments,
  getPaymentGateways,
  editPayment,
  deletePayment,
} from "../../../../redux/actions/settings/Order/paymnet";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import CollapseList from "components/general/PageDetails/CollapseList";
//import AddBrand from "./Add";
import View from "./View";

const demoData = [
  {
    _id: 1,
    isActive: true,
    name_en: "Hyper Pays",
    name_ar: "hyper Pays",
    type: 1,
    channel: "",
    additional_fees: "",
    fees_type: "",
    icon: "",
    options: [
      {
        method: "mada",
        description_en: "Mada use your dibet card",
        description_ar: "Mada use your dibet card",
        fee: "12",
        icon: "",
      },
      {
        method: "mastercard",
        description_en: "MastarCard use your dibet card",
        description_ar: "MastarCard use your dibet card",
        fee: "12",
        icon: "",
      },
      {
        method: "visa",
        description_en: "VISA use your dibet card",
        description_ar: "VISA use your dibet card",
        fee: "12",
        icon: "",
      },
    ],
  },
  {
    _id: 2,
    isActive: true,
    name_en: "COD Delivery",
    name_ar: "COD Delivery",
    type: 2,
    channel: "",
    additional_fees: "",
    fees_type: "",
    icon: "",
    options: [],
  },
];

const paymentMethodsDemo = [
  {
    value: "visa",
    label: "VISA",
  },
  {
    value: "mastercard",
    label: "Mastercard",
  },
  {
    value: "mada",
    label: "Mada",
  },
];

const Index = (props) => {
  useEffect(() => {
    props.getPayments();
    props.getPaymentGateways();
  }, []);

  useEffect(() => {
    props.getCatalogs();
  }, []);

  const deletePayment = (paymentId) => {
    try {
      let response = props.deletePayment(paymentId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editPayment = (paymentId, input) => {
    try {
      console.log(input)
      input.storeId = "610430ac20bfbb068114e8fc"
      let response = props.editPayment(JSON.stringify(input), paymentId);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log(props.payments);
  return (
    <div>
      {props.payments && (
        <View
          {...props}
          deletePayment={deletePayment}
          editPayment={editPayment}
          paymentMethodsDemo={paymentMethodsDemo}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  payments: state.payments.items,
  gateways: state.payments.gateways,
  catalogs: state.catalogs.items,
});

export default connect(mapStateToProps, {
  getPayments,
  getPaymentGateways,
  deletePayment,
  editPayment,
  getCatalogs,
})(Index);
