//import React, { useState, useEffect, useContext } from "react";
import React, { useState } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addCountry } from "redux/actions/Ecommerce/settings/countryAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
  console.log(props)
  let [state, setState] = useState({
    status: false,
    name_en: "",
    name_ar: "",
    channel: null,    
    customerTypes: null,
    productTypes: null,
    paymentMethods: null,
    shippingMethods: null,
    options: [
      {
        action_status: "",
        description: "",
        customer_status: "",
        is_cancelation: false,
        is_return: false,
        is_refund: false,
        is_pending: '',
      }],
    errors: {
      nameError: "",
    },
  });

  let [modal, setModal] = useState(false);

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const reset = () => {
    setState(state);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = state;

    if (inputs.name_en === "" || inputs.name_ar === "") {
      errors.nameError = "Sorry, 'Name' in both countrys are required!";
    }

    if (errors.nameError === "") {
      addFlow(e);
    } else {
      setState({ errors: errors });
    }
  };

  const addFlow = () => {
    console.log(" addFlow", state);

    try {
      //props.addCountry(country);
      SuccessAlert("success", "Sucessfully Added");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    reset();
    toggle();
  };

  return (
    <View
      data={state}
      catalogs={props.catalogs}
      customerTypes={props.customerTypes}
      productTypes={props.productTypes}
      onChange={onChange}
      onSumbit={addFlow}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
    />
  );
};

const mapStateToProps = (state) => ({
  //flows: state.flows.items,
});

export default connect(mapStateToProps, {  })(Index);
