import React, { useEffect, useState } from "react";
import Card from "components/general/Card";
import { Row, Col } from "reactstrap";

const Index = (props) => {
  const settingList = [
    {
      title: "Country",
      subtitle: "Set different country that will support within the channel",
      url: "/setting/country",
      image: "/assets/img/setting/Country.png",
    },
    {
      title: "Language",
      subtitle: "Set different language that will support within the channel",
      url: "/setting/language",
      image: "/assets/img/setting/Language.png",
    },
    {
      title: "Catalog",
      subtitle: "Set different catalog that will support within the channel",
      url: "/setting/catalog",
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Customer Types",
      subtitle:
        "Set different Customer Types that will support within the channel",
      url: "/setting/customer_type",
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Cart",
      subtitle: "Manage the Cart behavior e.g. persistent cart and icon.",
      url: "/setting/cart",
      image: "/assets/img/setting/Country.png",
    },
    {
      title: "Payment",
      subtitle:
        "Setup Payment methods with different predefined payment gatway.",
      url: "/setting/payment",
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Delivery",
      subtitle:
        "Setup Delivery methods with different logic to support Omni-Channel, Marketplace.",
      url: "/setting/delivery",
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Pickup",
      subtitle:
        "Setup Delivery methods with different logic to support Omni-Channel, Marketplace.",
      url: "/setting/pickup",
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Carrier & Cost",
      subtitle:
        "Setup Shipping Carrier integration & the cost of shipment.",
      url: "/setting/carrier&cost",
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Order",
      subtitle:
        "Manage the order flow with customize status accourding to business needs.",
      url: "/setting/order-flow",
      image: "/assets/img/setting/Catalog.png",
    },
    {
      title: "Map",
      subtitle: "Setup map integration with Google API .",
      url: "/setting/map",
      image: "/assets/img/setting/Country.png",
    },
  ];
  return (
    <Card name="UX Automation Setting">
      <Row className="d-flex">
        {settingList.map((item) => {
          return (
            <Col lg={4} sm={4} md={4} xs={6} className="text-center settingImg mb-3">
              <Card
                image={item.image}
                title={item.title}
                subtitle={item.subtitle}
                button={{ title: "Setup", url: item.url }}
                className={"mb-4"}
              />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default Index;
