import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TabPane, Button, ModalFooter, Col, Row } from "reactstrap";
import MedaiUploader from "./MediaUploader";
import Gallary from "./Gallary";

import ModalComponent from "../Modal";
import Tabs from "../Tabs";
import Card from "../Card";

import { getAllMedia } from "../../../redux/actions/Ecommerce/MediaAction";
import { restfulUrl } from "../../../context/url";

/**
 * General Media Library Component
 *  @component
 * @prop {string} urlPath - The path of media folder
 *
 */
const Index = (props) => {
  const [mediaModal, setMediaModal] = useState(false);
  const toggle = () => setMediaModal(!mediaModal);

  //FIXME: get it from the props
  const urlPath = restfulUrl + "/upload/";

  useEffect(() => {
    props.getAllMedia();
  }, []);

  console.log("Media ", props.mediaList);

  return (
    <div>
      <Button
        color="secondary"
        style={{ width: "100%", marginRight: "10px" }}
        className="btn btn-primary"
        onClick={toggle}
      >
        {"Add new Image"}
      </Button>

      <ModalComponent
        title="Media Library"
        toggle={toggle}
        isOpen={mediaModal}
        size="xl"
      >
        {/* Modal Body */}
        <Tabs
          tabsList={[
            { id: 1, name: "Upload" },
            { id: 2, name: "Library" },
          ]}
          defualtActiveID={1}
        >
          {/* TODO: Upload Library  */}
          {/* Upload Compenent */}
          <TabPane tabId={1}>
            <MedaiUploader {...props} toggle={toggle} />
          </TabPane>

          {/* Library Compenent */}
          <TabPane tabId={2}>
            <Gallary {...props} toggle={toggle} urlPath={urlPath} />
          </TabPane>
        </Tabs>

        {/* Modal Footer */}
        <ModalFooter>
          <Button className="btn btn-secondary" onClick={toggle}>
            {"Close"}
          </Button>
        </ModalFooter>
      </ModalComponent>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mediaList: state.media.items,
});

export default connect(mapStateToProps, { getAllMedia })(Index);
