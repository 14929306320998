import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import reduxStore from "./redux/store";
const client = new ApolloClient({
  uri: process.env.UX_GRAPHQL_PROXY_URL + "/graphql",
  opts: {
    credentials: "include",
  },
  // defaultOptions: {
  //     watchQuery: {
  //       fetchPolicy: 'network-only',
  //       errorPolicy: 'all',
  //     },
  //     query: {
  //       fetchPolicy: 'network-only',
  //       errorPolicy: 'all',
  //     },
  //     mutate: {
  //      fetchPolicy: 'network-only',
  //       errorPolicy: 'all',
  //     },
  //   },
});

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    {/* <ApolloProvider client={client}> */}
    <Provider store={reduxStore}>
      <App />
    </Provider>
    {/* </ApolloProvider> */}
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
