import React, { useState, useEffect } from "react";
import Select from "components/order-flow/components/select/Index";
import CheckIcon from "./assets/CheckIcon";
import TimesIcon from "./assets/TimesIcon";

const MapStep = ({ uploadedFile, ...props }) => {
  console.log("map props props=>", props);

  const {
    headers: initialHeaders,
    unmatchedHeaders: initialUnmatchedHeaders,
    selected_attributes,
    file_headers,
    preview,
  } = uploadedFile;

  const attributeOptions = selected_attributes.map((attribute) => ({
    value: attribute.system_name,
    label: attribute.name_en,
    id: attribute.id,
  }));

  const [selectedValues, setSelectedValues] = useState({});
  const [headers, setHeaders] = useState(initialHeaders);
  const [unmatchedHeaders, setUnmatchedHeaders] = useState(
    initialUnmatchedHeaders
  );

  const handleSelectChange = (header, value) => {
    // Update selected values
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [header]: value,
    }));

    // Update headers and unmatched headers
    if (value) {
      // Add header to matched headers
      setHeaders((prevHeaders) => [
        ...prevHeaders,
        { header: header, attribute: { name: value.value, id: value.id } },
      ]);

      // Remove header from unmatched headers
      setUnmatchedHeaders((prevUnmatchedHeaders) =>
        prevUnmatchedHeaders.filter((h) => h !== header)
      );
    } else {
      // Remove header from matched headers
      setHeaders((prevHeaders) =>
        prevHeaders.filter((h) => h.header !== header)
      );

      // Add header to unmatched headers
      setUnmatchedHeaders((prevUnmatchedHeaders) => [
        ...prevUnmatchedHeaders,
        header,
      ]);
    }
  };
  useEffect(() => {
    // Create new object with updated data
    const updatedData = {
      store_id: "61212d9bbb3af02d3f72b2ce",
      headers: headers,
      unmatchedHeaders: unmatchedHeaders,
      file_headers: file_headers,
      filePaths: uploadedFile.filePaths,
      invalidRows: uploadedFile.invalidRows,
      selectedType: props.selectedType,
    };

    // Pass updated data to parent component or function
    props.handleUpdate(updatedData);
  }, [headers, unmatchedHeaders]);

  return (
    <div className="map-step">
      <h2 className="map-step-title">
        Map columns in your file to contact properties
      </h2>
      <p className="map-step-subtitle">
        Each column header below should be mapped to a contact property in
        UX-automation. Some of these have already been mapped <br />
        <span style={{ display: "block", textAlign: "center" }}>
          based on their names. Anything that hasn't been mapped yet can be
          manually mapped to a contact property <br />
          with the dropdown menu. You can always create a new property or choose
          "Don't import column".
        </span>
      </p>
      <div className="table-container">
        <table className="map-step-table">
          <thead>
            <tr>
              <th>MATCHED</th>
              <th>COLUMN HEADERS FROM FILE</th>
              <th>PREVIEW program INFORMATION</th>
              <th>UX-AUTOMATION PROPERTIES</th>
            </tr>
          </thead>
          <tbody>
            {file_headers.map((header, index) => {
              const matchedHeader = headers.find(
                (item) => item.header === header
              );
              const isMatched = matchedHeader !== undefined;
              const matchedAttribute = isMatched
                ? attributeOptions.find(
                    (attribute) => attribute.id === matchedHeader.attribute.id
                  )
                : null;
              return (
                <tr key={header}>
                  <td>
                    {isMatched ? (
                      <div className="right-check-icon">
                        {" "}
                        <CheckIcon />{" "}
                      </div>
                    ) : (
                      <div className="wrong-icon">
                        <TimesIcon />{" "}
                      </div>
                    )}
                  </td>
                  <td>{header}</td>
                  <td>{preview[0][index]}</td>
                  <td>
                    {/* Add dropdown menu here */}
                    <div className="select-attr-imp w-full">
                      <Select
                        data={attributeOptions}
                        optionSelected={
                          selectedValues[header] || matchedAttribute
                        }
                        isDisabled={true}
                        setOptionSelected={(value) =>
                          handleSelectChange(header, value)
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MapStep;
