import React, { PropTypes } from "react";
import LoadOptions from "./LoadOptions";
import { Col } from "reactstrap";
import ExportButton  from "components/general/ExportButton"

const Action = (props) => {
  return (
    <div>
      <Col md="8" className="flex-center mx-auto justify-content-evenly">
        <div onClick={props.onClickAddOption} className="option cta col-xs-4">
          <i className="material-icons m-r-5">add</i>
          <a href="#">{"Add option"}</a>
        </div>

        <div onClick={props.onClearOption} className="option cta col-xs-4">
          <i className="material-icons m-r-5">delete</i>
          <a href="#">{"Clear all"}</a>
        </div>

        <LoadOptions onLoadOptions={props.onLoadOptions} />

{props.handleDownloadExcel && (
          <ExportButton data={props.handleDownloadExcel()} fileName={`Attributes Options Data.xlsx`} style={true}/>
)}

      </Col>
    </div>
  );
};

Action.propTypes = {};

export default Action;
