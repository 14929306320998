import React from "react";
import _ from "lodash";

const View = (props) => {
  const { handleSelectTemplate, templateId, templates , pageName } = props;
  return (
    <div>
      <div className="steps-container">
        <div className="templates-container">
          {/* New Segment*/}
          <div className="tiles">
            <div className="tiles-body">
              <div className="tiles-heading">
                <div className="heading"> {`Create New ${pageName}`} </div>
              </div>
              <div
                className="description"
                style={{ "-webkit-box-orient": "vertical" }}
              >
                {"Create your won template"}
              </div>

              <div>
                <button
                  className={`${templateId === 1 ? ` btn selected ` : `btn`}`}
                  onClick={() => handleSelectTemplate(1)}
                >
                  {"Select"}
                </button>
              </div>
            </div>
          </div>

          {/* Templates */}
          {_.map(templates, (template) => {
            return (
              <div className="tiles">
                <div className="tiles-body">
                  <div className="tiles-heading">
                    <div className="heading"> {template.name? template.name : template.name_en} </div>
                  </div>
                  <div
                    className="description"
                    style={{ "-webkit-box-orient": "vertical" }}
                  >
                    {template.desc? template.desc : template.description_en}
                  </div>

                  <div>
                    <button
                      className={`${
                        templateId === template._id ? ` btn selected ` : `btn`
                      }`}
                      onClick={() =>
                        handleSelectTemplate(template._id, template)
                      }
                    >
                      {"Select"}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default View;
