import React, { PropTypes } from 'react';
import Variant from '..';
import VariantList from '../VariantList'
const View = props => {
    return (
        <div>
             <VariantList {...props} withMedia={true}/>
        </div>
    );
};

View.propTypes = {
    
};

export default View;