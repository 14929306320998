import {
  ADD_CATALOG,
  EDIT_CATALOG,
  FIND_CATALOG,
  DELETE_CATALOG,
  GET_CATALOGS,
} from "./types";
import  fetchAPIClass  from "../fetchAPI";
import { restfulUrl , API_URL} from "../../../../src/context/url";

export const getCatalogs = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/catalog/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_CATALOGS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addCatalog = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/catalog/add`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_CATALOG);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteCatalog = (catalogId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/catalog/delete/${catalogId}`;
  console.log(catalogId);
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_CATALOG);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editCatalog = (inputs, catalogId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/catalog/edit/${catalogId}`;
  console.log(requestBody);
  console.log(catalogId);

  try {
    const api = new fetchAPIClass(url, "POST", requestBody, EDIT_CATALOG);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};
