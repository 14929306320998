import {
  ADD_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCTS,
  SET_PRODUCT,
  FIND_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT_MASTER,
  GET_ALL_PRODUCTS,
  SEARCH_PRODUCTS,
  FILTER_PRODUCTS,
  FILTER_PRODUCTS_BRAND,
  FILTER_PRODUCTS_CATEGORY
} from "./types";
import fetchAPIClass from "../fetchAPI";
import { restfulUrl ,CRM_LOCAL_TEST,  API_URL} from "../../../../src/context/url";

export const getProducts = (limit = 10, page = 1) => (dispatch) => {
  let url = `${API_URL}/ecommerce/product/all?limit=${limit}&page=${page}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_PRODUCTS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getAllProducts = () => (dispatch) => {
  let url = `${API_URL}/ecommerce/product/export-info`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_ALL_PRODUCTS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const searchProducts = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/product/search`;
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, SEARCH_PRODUCTS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const filterProducts = (inputs) => (dispatch) => {
  let requestBody = inputs;
  console.log("inside actions")
  console.log(requestBody)
  let url = `${API_URL}/ecommerce/product/filter`;
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, FILTER_PRODUCTS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getProductsBrand = (inputs) => (dispatch) => {
  let requestBody = inputs;
  console.log("inside actions")
  console.log(requestBody)
  let url = `${API_URL}/ecommerce/product/brand`;
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, FILTER_PRODUCTS_BRAND);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getProductsCategories = (inputs) => (dispatch) => {
  let requestBody = inputs;
  console.log("inside actions")
  console.log(requestBody)
  let url = `${API_URL}/ecommerce/product/category`;
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, FILTER_PRODUCTS_CATEGORY);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addProduct = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/product/add`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_PRODUCT);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const editProduct = (productID, inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/product/edit/${productID}`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, EDIT_PRODUCT);
    let response = api.fetchAPI(dispatch);

    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteProduct = (productID) => (dispatch) => {
  console.log("product Delete redux ", productID);
  let url = `${API_URL}/ecommerce/product/delete/${productID}`;
  try {
    const api = new fetchAPIClass(url, "POST", null, DELETE_PRODUCT);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const deleteMasterProduct = (productID) => (dispatch) => {
  let url = `${API_URL}ecommerce/product/master/delete/${productID}`;
  try {
    const api = new fetchAPIClass(url, "POST", null, DELETE_PRODUCT);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};


export const editMasterProduct = (productID,inputs) => (dispatch) => {
  let requestBody = inputs;
  console.log("EditMaster", requestBody);
  let url = `${API_URL}/ecommerce/product/master/edit/${productID}`;
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, EDIT_PRODUCT_MASTER);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const setProduct = (product) => (dispatch) => {
  try {
    dispatch({
      type: SET_PRODUCT,
      payload: product,
    });
  } catch (err) {
    console.log(err);
  }
};

export const findProduct = (productID) => (dispatch) => {
  let url = `${API_URL}/ecommerce/product/find/${productID}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_PRODUCT);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
