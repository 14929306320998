import {
  ADD_ATTRIBUTE,
  GET_ATTRIBUTES,
  FIND_ATTRIBUTE,
  EDIT_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  GET_ONE_LIST_ATTRIBUTES,
} from "../actions/types";

const initialState = {
  items: null,
  oneList: null,
  item: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ATTRIBUTE:
    case EDIT_ATTRIBUTE:
    case DELETE_ATTRIBUTE:
    case FIND_ATTRIBUTE:
      return {
        ...state,
        item: action.payload,
      };
    case GET_ATTRIBUTES:
      return {
        ...state,
        items: action.payload,
      };
    case GET_ONE_LIST_ATTRIBUTES:
      return {
        ...state,
        oneList: action.payload,
      };
    default:
      return state;
  }
}
