import React, { useState } from "react";

import { Col, Button } from "reactstrap";
import Card from "../../../../components/general/Card";
import Collapse from "components/general/Collapse";
import FormComponent from "components/general/FormControllerList";
import { Alert } from "reactstrap";
import Delete from "components/general/Delete";
import _ from "lodash";

import { InventoryType } from "@uxautomation/common";
import { maximumShippingOptions } from "seeder/maximumShipping";
import { enumsToListOFOptions } from "helpers/general-functions";

import AddDelivery from "./Add";

// FIXME: Status has issues
const View = (props) => {
  let { deleteDelivery, editDelivery, deliverys, deliveryStrategyDemo } = props;
  console.log(props);

  let [state, setDeliverys] = useState(deliverys);
  let [errors, setError] = useState({
    nameError: "",
  });

  const updateState = (input) => {
    console.log("Input", input);
    setDeliverys({ ...state, ...input });
  };

  console.log("Deliverys", deliverys);

  return (
    <div className="setting">
      <Card name="Delivery Setting" goBack={props.history.goBack}>
        <div className="contact-table">
          {/* Add Country */}
          <div className="d-flex justify-content-end">
            <AddDelivery />
          </div>

          <Col xs="12" lg="8" xl="9" className="group-list">
            {_.map(deliverys, (data, key) => {
              // List of Attrbiute
              return (
                <Collapse
                  group={{
                    label: `${data.nameEN}`,
                    id: key,
                    open: false,
                  }}
                >
                  {/* Form */}
                  <FormComponent
                    type="switch"
                    id={`isActive${key}`}
                    name="isActive"
                    label="Activate"
                    target={deliverys[key]}
                    targetName="deliverys"
                    value={data.isActive}
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  <FormComponent
                    type="text"
                    name="nameEN"
                    label="English Name"
                    target={deliverys[key]}
                    targetName="deliverys"
                    value={data.nameEN}
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  {!_.isEmpty(_.get(errors, "nameError")) && (
                    <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                  )}
                  <FormComponent
                    type="text"
                    name="nameAR"
                    label="Arabic Name"
                    value={data.nameAR}
                    target={deliverys[key]}
                    targetName="deliverys"
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  {!_.isEmpty(_.get(errors, "nameError")) && (
                    <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                  )}
                  {props.catalogs && (
                    <FormComponent
                      type="groupCheckbox"
                      options={props.catalogs}
                      name="channels"
                      label="Channel"
                      value={data.channels}
                      target={deliverys[key]}
                      targetName="deliverys"
                      valueKey="_id"
                      labelKey="name_en"
                      updateState={updateState}
                    />
                  )}
                  {/* <FormComponent
                    type="select"
                    options={deliveryStrategyDemo}
                    name="coverage_area"
                    label="Coverage Area"
                    value={data.coverage_area}
                    target={deliverys[key]}
                    targetName="deliverys"
                    valueKey="value"
                    labelKey="label"
                    updateState={updateState}
                  /> */}
                  {props.carriers && (
                    <FormComponent
                      type="groupCheckbox"
                      options={props.carriers}
                      name="carriers"
                      label="Carrier Cost"
                      value={data.carriers}
                      target={deliverys[key]}
                      targetName="deliverys"
                      valueKey="_id"
                      labelKey="nameEN"
                      updateState={updateState}
                    />
                  )}
                  <FormComponent
                    type="select"
                    options={enumsToListOFOptions(InventoryType)}
                    name="inventoryType"
                    label="Inventory Type"
                    value={data.inventoryType}
                    target={deliverys[key]}
                    targetName="deliverys"
                    valueKey="value"
                    labelKey="label"
                    updateState={updateState}
                  />
                  <FormComponent
                    type="switch"
                    id={`splitShipping${key}`}
                    name="splitShipping"
                    label="Split Shipment"
                    target={deliverys[key]}
                    targetName="deliverys"
                    value={data.splitShipping}
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />

                  <FormComponent
                    type="select"
                    options={deliveryStrategyDemo}
                    name="deliveryStrategy"
                    label="Delivery Strategy"
                    value={data.deliveryStrategy}
                    target={deliverys[key]}
                    targetName="deliverys"
                    valueKey="value"
                    labelKey="label"
                    updateState={updateState}
                  />
                  {data.splitShipping && (
                    <div>
                      <FormComponent
                        type="select"
                        options={maximumShippingOptions}
                        name="maximumShipping"
                        label="Maximum Shipments"
                        value={data.maximumShipping}
                        target={deliverys[key]}
                        targetName="deliverys"
                        valueKey="value"
                        labelKey="label"
                        updateState={updateState}
                      />

                      <FormComponent
                        type="switch"
                        id={`singleCostForAllShipments${key}`}
                        name="singleCostForAllShipments"
                        label="Calculate Single Cost for All Shipments"
                        target={deliverys[key]}
                        targetName="deliverys"
                        value={data.singleCostForAllShipments}
                        updateState={updateState}
                        error={_.get(errors, "nameError")}
                      />
                    </div>
                  )}

                  {/* Language Actions */}
                  <div className="row justify-content-end">
                    <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                      <Button
                        className="btn btn-primary btn-cons btn-cons"
                        onClick={() =>
                          editDelivery(deliverys[key]._id, deliverys[key])
                        }
                      >
                        {"Save"}
                      </Button>

                      <Delete
                        data={data.nameEN}
                        onSubmit={deleteDelivery}
                        customerID={deliverys[key]._id}
                        actionType="button"
                        buttonStyle="btn btn-white btn-cons"
                      />
                    </div>
                  </div>
                </Collapse>
              );
            })}
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default View;
