import React from "react";
import Card from "../../../components/general/Card";
import AddAttribute from "./AddAttribute";
import AddGroup from "./AddGroup";
import Search from "../../../components/general/Search";
import GroupsList from "./GroupsList";
const View = (props) => {
  return (
    <div>
      <Card name="Profile Attribute">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <AddAttribute />
            <AddGroup />
          </div>
          <Search />
        </div>
        <GroupsList {...props} />
      </Card>
    </div>
  );
};
export default View;
