import React from "react";
import Card from "../../../../components/general/Card";
import FirstStep from "./FirstStep";

import SecondStep from "./SecondStep";
import FourthStep from "./FourthStep";
import ThirdStep from "./ThirdStep";

import ProgressSteps from "../../../../components/general/StepNavigation/StepIndecator";
import StepsNavigator from "components/general/StepNavigation/StepNavigator";

const View = (props) => {
  console.log(props);

  const progressSteps = [
    {
      step: 1,
      title: "Promotion Type",
    },
    {
      step: 2,
      title: "Promotion Template",
    },
    {
      step: 3,
      title: "Promotion Information",
    },
    {
      step: 4,
      title: "Promotion Rule",

    },
  ];

  return (
    <Card name={"Create a New Promotion"}>
      <div className="segmentation">
        <div className="row-fluid">
          <div className="span12">
            <Card>
              {/* <div className="grid simple">
                <div className="grid-body"> */}
              <div
                className="row main-content"
                style={{ flexDirection: "column" }}
              >
                <ProgressSteps progressSteps={progressSteps} {...props} />

                <div className="steps">
                  {props.currentStep === 1 && <FirstStep {...props} />}
                  {props.currentStep === 2 && <SecondStep {...props} />}
                  {props.currentStep === 3 && <FourthStep {...props} />}
                  {props.currentStep === 4 && <ThirdStep {...props} />}
                </div>
              </div>

              <StepsNavigator {...props} totalSteps={4} />
            </Card>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default View;
