import React, { useState } from "react";
import _ from "lodash";
import { Collapse } from "reactstrap";
import Attribute from "./attribute";

export default function View(props) {
  let { group } = props;
  const [isOpen, setIsOpen] = useState(group.open);
  const toggle = () => setIsOpen(!isOpen);

  return (
    // _.map(groups, group => (
    <div className="attribute-list">
      <div key={group.id} className="panel-group">
        <div className="panel">
          <div className="panel-heading d-flex justify-content-between">
            <h4 className="panel-title">
              <a
                className={
                  isOpen
                    ? "d-flex sub-title bold"
                    : "collapsed expnad d-flex sub-title bold"
                }
                onClick={toggle}
                //href={"#" + group.id}
                aria-expanded="true"
              >
                {group.label}
              </a>
            </h4>
          </div>
          <div className="panel-body">
            <Collapse isOpen={isOpen}>
              {group.attributes &&
                _.map(group.attributes, (attribute) => (
                  <Attribute attribute={attribute} {...props} />
                ))}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}
