import React from "react";
const TimesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#fff"
    viewBox="0 0 16 16"
  >
    <path
      d="M11.354 4.646a.5.5 0 1 1 .708-.708l-7 7a.5.5 0 1 1-.708-.708l7-7z"
      strokeWidth="2"
    />
    <path
      d="M4.646 4.646a.5.5 0 1 1 .708-.708l7 7a.5.5 0 1 1-.708.708l-7-7z"
      strokeWidth="2"
    />
  </svg>
);
export default TimesIcon;
