import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getRoles,
  deleteRole,
  editRole,
  getPermissions,
} from "../../../redux/actions/UserManagement/RoleActions";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";

import View from "./View";

const Index = (props) => {

  useEffect(() => {
    props.getRoles();
  }, []);


  useEffect(() => {
    props.getPermissions();
  }, []);


  const deleteRole = (roleId) => {
    
    console.log(roleId)
    try {
      let response = props.deleteRole(roleId);
      SuccessAlert("success", "Sucessfully deleted");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editRole = (roleId, role) => {
      console.log({role, roleId})
      console.log(JSON.stringify(role))
    try {
      let response = props.editRole(JSON.stringify(role), roleId);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };
 
  return (
    <div>
      {props.roles && props.permissions &&(
        <View
          {...props}
          deleteRole={deleteRole}
          //handleCreate={handleCreate}
          editRole={editRole}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  roles: state.roles.items,
  permissions: state.roles.permissions,
});

export default connect(mapStateToProps, {
  getRoles,
  deleteRole,
  editRole,
  getPermissions
})(Index);
