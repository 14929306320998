import React, { Component } from "react";
import Table from "../../../../components/general/Table";
import Card from "../../../../components/general/Card";
import HeaderActions from "../../../../components/general/HeaderBtns";

//class List extends Component {
const View = (props) => {
  let { segmentations, deleteSegment, handleCreate } = props;

  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Name",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Users",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Campaign",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Journey",
    },
    {
      sortable: true,
      type: "createdAt",
      align: "center",
      title: "Creation Date",
    },
    {
      sortable: true,
      type: "updatedAt",
      align: "center",
      title: "Update Date",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];
  return (
    <Card name="Simple Segmentation">
      <HeaderActions
        createAction={{ title: "Create", onClick: handleCreate }}
        importAction={{ title: "Import", onClick: () => {} }}
        ActionList={{ title: "Action", list: "Delete Segmentation" }}
      />
      <div className="contact-table">
        <Table
          data={segmentations}
          isSelectRow={true}
          EDIT_URL={"crm/edit_segment"}
          deleteAction={deleteSegment}
          headers={headerList}
          {...props}
        />
      </div>
      <div className="verified-container m-t-10">
        <ul className="list-unstyled">
          <li>
            <i className="fa fa-circle m-r-5"></i>
            {
              "Draft Segment: The segment is not saved and can't use with any campaign."
            }
          </li>
          <li>
            <i className="fa fa-circle m-r-5 active"></i>
            {
              "Active Segment: The segment is active and currently used with active campaign."
            }
          </li>
          <li>
            <i className="fa fa-circle m-r-5 inactive"></i>
            {
              "Inactive Segment: The segment is inactive and currently not used with active campaign."
            }
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default View;
