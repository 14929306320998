import React from "react";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import Card from "../../../../components/general/Card";
import { Row, Col, Button } from "reactstrap";
//import Delete from "../../general/Delete"
//import Delete from "../DeleteSegment"
import TitleWithBtns from "../../../../components/general/PageDetails/HeaderInfo";
import _ from "lodash";
import CollabseComponent from "../../../../components/general/PageDetails/CollabseComponent";

const View = (props) => {
  let generalInfoInput = [
    {
      type: "switch",
      name: "status",
      id: "status",
      label: "Tags Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: _.toLower(props.data.status) === "active" ? true : false,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "Tag ID",
      value: props.data["_id"],
      default: props.data["_id"],
      disabled: true,
    },
  ];
  const detailsInfo = [
    {
      id: 0,
      label: "Summary",
      open: true,
    },
    {
      id: 1,
      label: "Information",
      open: true,
    },
    {
      id: 2,
      label: "Run Logic Automatically",
      open: true,
    },
    {
      id: 3,
      label: "Algorithm",
      open: false,
    },
    {
      id: 4,
      label: "Journey",
      open: false,
    },
    {
      id: 5,
      label: "Users",
      open: false,
    },
  ];

  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Name",
    },
    {
      type: "email",
      sortable: true,
      formatExtraData: {
        className: "fa-envelope-o",
        verified: "email_verified",
      },
      title: "Email",
    },
    {
      type: "phone",
      title: "Phone",
      formatExtraData: {
        className: "fa-phone",
        verified: "phone_verified",
      },
    },
    {
      type: "last_login",
      sortable: true,
      title: "Last Login",
      align: "center",
    },
    {
      type: "country",
      title: "Country",
      formatExtraData: "country",
      align: "center",
    },
    {
      type: "city",
      title: "City",
      align: "center",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
  ];

  return (
    <Card name="Tags">
      {/* Title */}
      <TitleWithBtns
        {...props}
        titleLabel="Tag Name"
        data={props.data}
        onDelete={props.deleteTag}
        onDuplicate={props.duplicateTag}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo {...props} info={generalInfoInput} />
        </Col>

        {/*Main content of details */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          {/*{props.children}*/}
          <div>
            {_.map(detailsInfo, (group, key) => (
              <CollabseComponent
                {...props}
                group={group}
                data={props.data}
                onChange={props.onChange}
                titleLabel="Tag"
                headerList={headerList}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Card>
  );
};
export default View;
