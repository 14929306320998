import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getPromotionsTemplates,
  getPromotionTypes,
  addPromotion,
  searchProductPromotion,
} from "../../../../redux/actions/Ecommerce/PromotionActions";
import View from "./view";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";
import { getAttributes } from "redux/actions/Ecommerce/AttributeAction";
import { getCategories } from "redux/actions/Ecommerce/CategoryAction";
import { getBrands } from "redux/actions/Ecommerce/BrandAction";
//import { handleProductList } from "../handler"

const Index = (props) => {
  // States
  let [currentStep, setcurrentStep] = useState(1);
  let [state, setState] = useState({
    type: null,
    templateId: 1,
    coupons: [],

    logic_x: {
      operator: "all",
      logic_list: [
        {
          attribute: {
            system_name: "",
            inner_name: "",
            label: "",
            path: "",
            type: "",
            operator: [],
          },
          operator: "",
          value: "",
          child: {
            logic_list: [],
            operator: "all",
          },
        },
      ],
    },
    logic_y: {
      operator: "all",
      logic_list: [
        {
          attribute: {
            system_name: "",
            inner_name: "",
            label: "",
            path: "",
            type: "",
            operator: [],
          },
          operator: "",
          value: "",
          child: {
            logic_list: [],
            operator: "all",
          },
        },
      ],
    },
  });
  let [couponData, setCouponData] = useState({});
  let [attributeList, setAttributeList] = useState([]);

  useEffect(() => {
    // Get promotions templates list
    //FIXME: should based on type
    props.getPromotionsTemplates();
  }, [props.promotionTemplates]);

  useEffect(() => {
    props.getPromotionTypes();
  }, []);

  useEffect(() => {
    // Get catalogs list
    props.getCatalogs();
  }, []);

  useEffect(() => {
    // Get attribute list
    props.getAttributes("ecommerce");
  }, []);

  useEffect(() => {
    // Get categories list
    props.getCategories();
    props.getBrands();
  }, []);

  useEffect(() => {
    if (props.attributes) {
      let handledAttribute = [];

      _.map(props.attributes, (attr) => {
        if (attr.inner_name === "categories") {
          let filteredCategories = _.filter(props.categories, (category) =>
            _.intersection(_.map(category.catalogs, "_id"), state.catalog_id)
          );
          console.log(filteredCategories);

          attr = {
            ...attr,
            data: _.map(filteredCategories, (cat) => ({
              label: cat.name_en,
              value: cat._id,
            })),
          };

          handledAttribute.push(attr);
        } else if (attr.inner_name === "brand_id") {
          let filteredBrands = _.filter(props.brands, (brand) =>
            _.intersection(_.map(brand.catalogs, "_id"), state.catalog_id)
          );
          console.log(filteredBrands);

          attr = {
            ...attr,
            data: _.map(filteredBrands, (brand) => ({
              label: brand.name_en,
              value: brand._id,
            })),
          };

          handledAttribute.push(attr);
        } else handledAttribute.push(attr);
      });
      setAttributeList([...handledAttribute]);
    }
  }, [props.attributes, state.catalog_id]);

  console.log(attributeList);

  let handleClick = (setp) => {
    setcurrentStep(setp);
  };

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const onChangeCouponData = (input) => {
    setCouponData({ ...couponData, ...input });
  };

  const perparedLogic = (logic) => {
    let logicConverted = {
      operator: logic.operator,
      logic_list: [],
    };
    logic.logic_list.map((condition) => {
      let combination = {};
      let convertedCondition = {
        attribute: condition.attribute,
        operator: condition.operator,
        value: condition.value,
      };
      if (condition.child && condition.child.logic_list.length !== 0) {
        combination.operator = condition.child.operator;
        combination.logic_list = [];
        condition.child.logic_list.map((logiChild) => {
          combination.logic_list.push({
            attribute: logiChild.attribute,
            operator: logiChild.operator,
            value: logiChild.value,
          });
        });
        convertedCondition.child = combination;
      }
      logicConverted.logic_list.push(convertedCondition);
    });
    console.log("Mapped  Logic", logicConverted);
    return logicConverted;
  };

  const addPromotion = async (e) => {
    e.preventDefault();
    console.log("addPromotion", state);
    //let productList_x = [], productList_y = []
    let promotion = {
      ...state,
      status: state.status === true ? "active" : "inactive",
      promotion_type: state.type,
    };
    console.log(JSON.stringify(promotion));

    try {
      promotion = {
        ...promotion,
        logic_x: promotion.logic_x,
        logic_y: promotion.logic_y,
      };

      if (promotion.typeName === "catalog")
        promotion = _.omit(promotion, "logic_y");
      console.log(promotion);

      console.log(JSON.stringify(promotion));

      await props.addPromotion(JSON.stringify(promotion));

      SuccessAlert("success", "Sucessfully Added");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log("satate", state);
  console.log("Attributes List", props.attributes);
  //console.log(JSON.stringify(perparedLogic(state.logic)));
  console.log("Props", props);

  return (
    props.promotionTypes && (
      <View
        {...props}
        currentStep={currentStep}
        handleClick={handleClick}
        {...state}
        state={state}
        attributeList={attributeList}
        couponData={couponData}
        catalogList={props.catalogs}
        updateState={onChange}
        onChange={onChange}
        onChangeCouponData={onChangeCouponData}
        pageName="Promotion"
        handleSubmit={addPromotion}
      ></View>
    )
  );
};

const mapStateToProps = (state) => ({
  templates: state.promotions.templates,
  catalogs: state.catalogs.items,
  categories: state.categories.items,
  promotionTypes: state.promotions.promotion_types,
  attributes: state.ecommerceAttributes.items,
  productsPromotions: state.promotions.products_promotion,
  brands: state.brands.items,
});

export default connect(mapStateToProps, {
  getPromotionsTemplates,
  getCatalogs,
  getCategories,
  getPromotionTypes,
  addPromotion,
  getAttributes,
  searchProductPromotion,
  getBrands,
})(Index);
