export const graphqlUrl = process.env.UX_GRAPHQL_PROXY_URL + "/graphql";
export const restfulUrl = process.env.UX_GRAPHQL_PROXY_URL;
export const business_id = `business_id: "5cc2f00577ebc157b4f1c64c"`;
export const AddCustomerUrl = "https://crm.dev.uxapp360.com/customer/add";
export const API_URL = "https://crm.dev.uxapp360.com";

export const Order_URL = "https://order.dev.uxapp360.com";
export const SHIPPING_URL = "https://shipment.dev.uxapp360.com/api/shipments";

export const AUTH_ORDER_TEMP_URL = "https://auth.dev.uxapp360.com";
export const AUTH_URL = "https://auth.dev.uxapp360.com/auth-service/api";
// Temporary using
export const PAYMENT_SETTING_URL =
  "https://payment.dev.uxapp360.com/api/settings";
export const SHIPPING_SETTING_URL =
  "https://shipment.dev.uxapp360.com/api/settings";

export const CRM_LOCAL_TEST = "http://localhost:5000";
