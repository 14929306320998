import React, { PropTypes } from "react";
import Card from "../../../../components/general/Card";
import HeaderInfo from "../../../../components/general/PageDetails/HeaderInfo";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import CollapseList from "../../../../components/general/PageDetails/CollapseList";
import Table from "components/general/Table";
import _ from "lodash";
import { Row, Col, Button } from "reactstrap";

const View = (props) => {
  const { data, deleteBranch, editBranch, deleteProduct, products } = props;

  let generalInfoInput = [
    {
      type: "switch",
      name: "status",
      id: "status",
      label: "Branch Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: data.status,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "Branch ID",
      value: data["_id"],
      default: data["_id"],
      disabled: true,
    },
  ];

  const staticGroups = [
    {
      id: 0,
      label: "Branch Information",
      collapseCase: "ListAttribute",
      open: true,
      attributes: [
        {
          label: "Branch ID",
          name: "_id",
          type: "text",
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          type: "text",
        },
        {
          label: "English Description",
          name: "description_en",
          type: "textarea",
        },
        {
          label: "Arabic Description",
          name: "description_ar",
          type: "textarea",
        },
        {
          label: "Location",
          name: "location",
          type: "text",
        },
        {
          label: "Type",
          name: "type",
          type: "text",
        },
        {
          label: "Delivery options",
          name: "delivery_options",
          type: "groupCheckbox",
          option: [
            { value: "2-Day", label: "Tow Days Delivery" },
            { value: "No_Delivery", label: "No Delivery" },
          ],
        },
        {
          label: "Categories",
          name: "categories",
          type: "groupCheckbox",
          option: props.categories,
          value: data["categories"],
          default: data["categories"],
          valueKey: "_id",
          labelKey: "name_en",
        },
      ],
    },
    {
      id: 1,
      label: "Products",
      collapseCase: "Table",
      open: true,
    },
  ];

  let productsHeaders = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "sku",
      align: "center",
      title: "SKU",
    },
    {
      sortable: true,
      type: "media",
      align: "center",
      title: "Medai",
    },
    {
      sortable: true,
      type: "name_en",
      align: "center",
      title: "Name",
    },
    {
      sortable: true,
      type: "quantity",
      align: "center",
      title: "Qty",
      // editable: { type: "number" },
      nested: true,
    },
    {
      sortable: true,
      type: "min_quantity",
      align: "center",
      title: "Min Quantity",
      // editable: { type: "number" },
      nested: true,
    },
    {
      sortable: true,
      type: "max_quantity",
      align: "center",
      title: "Max Quantity",
      // editable: { type: "number" },
      nested: true,
    },
  ];
  console.log("Products inv", products);
  return (
    <Card name="Product Details">
      {/* Headr  */}
      <HeaderInfo
        {...props}
        data={data}
        //image={data.media[0]}
        titleLabel={"Branch Name"}
        onDelete={deleteBranch}
        onSave={editBranch}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo
            {...props}
            info={generalInfoInput}
            noDateRagne={true}
            handleOnStatusChange={props.onChange}
            onChange={props.onChange}
          />
        </Col>

        {/* Details Info */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {/* Static Group Attribute */}
            {_.map(staticGroups, (group) =>
              group.label === "Products" ? (
                <Table
                  data={products}
                  deleteAction={deleteProduct}
                  noFilter={true}
                  noSearch={true}
                  headers={productsHeaders}
                  cellEdit={true}
                  noEdit={true}
                  deleteByRowIndex={true}
                />
              ) : (
                <CollapseList
                  {...props}
                  group={group}
                  collapseCase={group.collapseCase}
                  name="name"
                  updateState={props.onChange}
                />
              )
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

View.propTypes = {};

export default View;
