import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Card from "../../../../components/general/Card";
import CollapseList from "components/general/PageDetails/CollapseList";
import Delete from "components/general/Delete";
import _, { update } from "lodash";
import AddLanguage from "./Add";

const View = (props) => {
  const { languages, deleteLanguage, editLanguage } = props;
  const [state, setState] = useState(languages);

  let languageData = [];
  _.map(languages, (data, index) => {
    let item = {
      id: index,
      label: data["name_en"],
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          type: "text",
          name: "_id",
          label: "Language ID",
          value: data["_id"],
          default: data["_id"],
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
          value: data["name_en"],
          default: data["name_en"],
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          type: "text",
          value: data["name_ar"],
          default: data["name_ar"],
        },
        {
          label: "Icon",
          name: "icon",
          type: "text",
          value: data["icon"],
          default: data["icon"],
        },
      ],
    };
    languageData.push(item);
  });

  const updateState = (input) => {
    console.log("Input", input);
    setState({ ...state, ...input });
  };

  console.log("State", state);
  console.log("languages", languages);

  return (
    <div className="setting">
      <Card name="Language Setting" goBack={props.history.goBack}>
        <div className="contact-table">
          {/* Add Lanaguage */}
          <div className="d-flex justify-content-end">
            <AddLanguage />
          </div>

          {/* List of Languages */}
          <Col xs="12" lg="8" xl="9" className="group-list">
            {_.map(languageData, (group, key) => (
              <CollapseList
                {...props}
                group={group}
                usingFormContollerList={true}
                target={languages[key]}
                collapseCase={group.collapseCase}
                name="name"
                updateState={updateState}
              >
                {/* Language Actions */}
                <div className="row justify-content-end">
                  <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                    <Button
                      className="btn btn-primary btn-cons btn-cons"
                      onClick={() =>
                        editLanguage(group.attributes[0].value, state[key])
                      }
                    >
                      {"Save"}
                    </Button>

                    <Delete
                      data={group.label}
                      onSubmit={deleteLanguage}
                      customerID={group.attributes[0].value}
                      actionType="button"
                      buttonStyle="btn btn-white btn-cons"
                    />
                  </div>
                </div>
              </CollapseList>
            ))}
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default View;
