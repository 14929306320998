import React, { PropTypes, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";
//import { getRulesTypes } from "redux/actions/Ecommerce/PromotionActions";

const Index = (props) => {
  return <View {...props} />;
};

export default Index;
