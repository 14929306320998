import { ADD_CUSTOMER, DELETE_CUSTOMER, GET_CUSTOMERS, FILTER_CUSTOMERS } from "../actions/types"

import _ from "lodash"

const initialState = {
  items: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case DELETE_CUSTOMER:
    case ADD_CUSTOMER:
      return {
        ...state,
        item: action.payload
      }
    case GET_CUSTOMERS:
    case FILTER_CUSTOMERS:
      return {
        ...state,
        items: action.payload
      }

    default:
      return state
  }
}
