import React, { useState } from "react";
import { Collapse, Button, CardBody, Card, CardHeader } from "reactstrap";
import EditGroup from "../EditGroup";
import DeleteGroup from "../DeleteGroup";
import AttributeList from "./AttributeList";

const View = props => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [group, setGroup] = useState(props.group);

  const toggleEditGroup = group => {
    console.log("in close");
    setModalEdit(!modalEdit);
    if (group) setGroup(group);
    else setGroup(props.group);
    console.log("Group Modla Edit ", group);
  };

  const toggleDeleteGroup = group => {
    console.log("in delete Group");
    setModalDelete(!modalDelete);
    if (group) setGroup(group);
    else setGroup(props.group);
    console.log("Group Modla Delete", group);
  };

  // const { group } = props;
  console.log("Group : ",group)
  return (
    <div className="attribute-list">
      <div key={group.id} className="panel-group">
        <div className="panel">
          <div className="panel-heading d-flex justify-content-between">
            <h4 className="panel-title">
              <a
                className={isOpen ? "d-flex" : "collapsed expnad d-flex"}
                onClick={toggle}
                href={"#" + group.id}
                aria-expanded="true"
              >
                {group.label}
              </a>
            </h4>
            <ul className="d-flex  justify-content-between action-groups">
              {group.is_default ? (
                <ul className="d-flex  justify-content-between action-groups">
                  <li
                    data-toggle="tooltip"
                    title="Can not edit group, It is created by system!"
                  >
                    <a className="disabeled">{" Edit Group "}</a>
                  </li>
                  <li
                    data-toggle="tooltip"
                    title="Can not delete group, It is created by system!"
                  >
                    <a className="disabeled">{" Delete Group "}</a>
                  </li>
                </ul>
              ) : (
                <ul className="d-flex  justify-content-between action-groups">
                  <li>
                    <a
                      onClick={() => toggleEditGroup(group)}
                      href="#"
                      className="pointer"
                    >
                      {" Edit Group "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => toggleDeleteGroup(group)}
                      href="#"
                      className="pointer"
                    >
                      {" Delete Group "}
                    </a>
                  </li>
                </ul>
              )}

              <EditGroup
                group={group}
                modal={modalEdit}
                toggle={toggleEditGroup}
              />
              <DeleteGroup
                group={group}
                modal={modalDelete}
                toggle={toggleDeleteGroup}
              />
            </ul>
          </div>
          <div className="panel-body">
            <Collapse isOpen={isOpen}> 
            <AttributeList group={group} business_id={props.business_id} {...props}/> 
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};
export default View;
