import React from "react";
import FirstStep from "./First";
import SecondStep from "./Second";
import ThirdStep from "./Third";
import ProgressSteps from "../../StepNavigation/StepIndecator";
import Card from "../../Card";
import StepsNavigator from "../../StepNavigation/StepNavigator";

const View = (props) => {
  /*const progressSteps = [
    {
      step: 1,
      title: "Segment Template",
    },
    {
      step: 2,
      title: "Segment Logic",
    },
    {
      step: 3,
      title: "Segment Information",
    },
  ];*/
  console.log("props in View", props.firstStep);
  return (
    <Card name={`Create a new ${props.pageName}`}>
      <div className="segmentation">
        <div className="row-fluid">
          <div className="span12">
            <div className="grid simple">
              <div className="grid-body">
                <div
                  className="row main-content"
                  style={{ flexDirection: "column" }}
                >
                  <ProgressSteps
                    progressSteps={props.progressSteps}
                    {...props}
                  />

                  <div className="steps">
                    {props.currentStep === 1 && (
                      <FirstStep
                        {...props}
                        //id={props.idTemplate}
                      />
                    )}
                    {props.currentStep === 2 && (
                      <SecondStep
                        {...props}
                        /*id={id}
                      logics={logics}*/
                        //segmentInfo={segmentInfo}
                      />
                    )}
                    {props.currentStep === 3 && (
                      <ThirdStep
                        {...props}
                        /*id={id}
                          logics={logics}
                          segmentInfo={segmentInfo}*/
                      />
                    )}
                  </div>
                </div>

                <StepsNavigator {...props} totalSteps={3} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default View;
