import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";

import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import View from "./View";

const Index = (props) => {
 

  useEffect(() => {
    props.getCatalogs();
  }, [props.catalogs]);

  const deleteMap = (maptId) => {
    try {

      /** TODO: HERE WILL BE THE LINK WITH API */

      SuccessAlert("success", "Sucessfully deleted");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editMap = (mapId, input) => {
    try {

       /** TODO: HERE WILL BE THE LINK WITH API */

      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  return (
    <div>
        <View
          {...props}
          deleteMap={deleteMap}
          editMap={editMap}
        />
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  catalogs: state.catalogs.items,
});

export default connect(mapStateToProps, {
  getCatalogs
})(Index);
