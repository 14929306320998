import React, { useState } from "react";
import ProgressSteps from "components/general/StepNavigation/StepIndecator";
import Modal from "components/general/Modal";
import Collapse from "components/general/Collapse";
import StepsNavigator from "components/general/StepNavigation/StepNavigator";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from './FifthStep'
import Card from "components/general/Card";
import VariantList from './VariantList'
import { Button } from "reactstrap";

const View = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const progressSteps = [
    {
      step: 1,
      title: "Configurable Attributes",
    },
    {
      step: 2,
      title: "Attributes Setting",
    },
    {
      step: 3,
      title: "Product Information",
    },
    {
      step: 4,
      title: "Media",
    },
    {
      step: 5,
      title: "Review",
    },
  ];

  return (
    <Collapse group={{ label: "Variants", id: 7, open: false }}>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
      { props.variants.length ===0 ? "Create New Configurable" : "Edit New Configurable" }
      </Button>
      <Modal
        title={"Create A new Attribute"}
        toggle={toggle}
        isOpen={modal}
        size="xl"
      >
        <div className="segmentation">
          <div className="row-fluid">
            <div className="span12">
              <Card>
                {/* <div className="grid simple">
                <div className="grid-body"> */}
                <div
                  className="row main-content"
                  style={{ flexDirection: "column" }}
                >
                  <ProgressSteps progressSteps={progressSteps} {...props} />

                  <div className="steps">
                    {props.currentStep === 1 && <FirstStep {...props} />}
                    {props.currentStep === 2 && <SecondStep {...props} />}
                    {props.currentStep === 3 && <ThirdStep {...props} />}
                    {props.currentStep === 4 && <FourthStep {...props} />}
                    {props.currentStep === 5 && <FifthStep {...props}/> }
                  </div>
                </div>

                <StepsNavigator {...props} totalSteps={5} />
              </Card>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}
      </Modal>
      { props.variants.length !==0 &&
        <VariantList {...props}  withMedia={true} /> }
    
    </Collapse>
  );
};

export default View;
