import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "components/general/Modal";
import FormComponent from "components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";
import Collapse from "components/general/Collapse";
import Options from "../../Options";


const View = (props) => {
  const { onChange, data, modal, toggle, errors, catalogs, customerTypes, payments, productTypes } = props;

  let optionsHeaders = [
    "Actions Status",
    "Description",
    "Customer Status",
    "Cancelation",
    "Return",
    "Refund",
    "Pending"
]
let emptyRowOptions = {
    action_status: "",
    description: "",
    customer_status: "",
    is_cancelation: false,
    is_return: false,
    is_refund: false,
    is_pending: '',
}

let attributeList = [
  {
      name: "action_status",
      type: "select",
      options: [{ label: 'New', value: 'new' }],
      target: "options",
      targetName: "payments",
      valueKey: "value",
      labelKey: "label",
  },
  {
      name: "description",
      type: "text",
      target: "options",
      targetName: "payments",
  },
  {
      name: "customer_status",
      type: "select",
      options: [{ label: 'New', value: 'new' }],
      target: "options",
      targetName: "payments",
      valueKey: "value",
      labelKey: "label",
  },
  {
      name: "is_cancelation",
      type: "switch",
      id:`is_cancelation`,
      target: "options",
      targetName: "payments",
  },
  {
      name: "is_return",
      type: "switch",
      id:`is_return`,
      target: "options",
      targetName: "payments",
  },
  {
      name: "is_refund",
      type: "switch",
      id:`is_refund`,
      target: "options",
      targetName: "payments",
  },
  {
      name: "is_pending",
      type: "text",
      target: "options",
      targetName: "payments",
  },
]


  return (

    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>
      <ModalComponent title="Create New Order Flow" toggle={toggle} isOpen={modal} size={"xl"}>
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="switch"
            id="status"
            name="status"
            label="Activation"
            value={data.status}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          <FormComponent
            type="text"
            name="name_en"
            label="Cart English Name"
            value={data.name_en}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          <FormComponent
            type="text"
            name="name_ar"
            label="Cart Arabic Name"
            value={data.name_ar}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          <FormComponent
            type="groupCheckbox"
            options={_.map(catalogs, catalog => ({ label: _.get(catalog, 'name_en'), value: _.get(catalog, '_id') }))}
            name="channel"
            label="Channel"
            value={data.channel}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />

          <FormComponent
            type="groupCheckbox"
            options={_.map(customerTypes, type => ({ label: _.get(type, 'name_en'), value: _.get(type, '_id') }))}
            name="customerTypes"
            label="Customer Type"
            value={data.customerTypes}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />

          <FormComponent
            type="groupCheckbox"
            options={productTypes}
            name="productTypes"
            label="Product Type"
            value={data.productTypes}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />
          <FormComponent
            type="groupCheckbox"
            options={[]}
            name="paymentMethods"
            label="Payment Method"
            value={data.paymentMethods}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />
          <FormComponent
            type="groupCheckbox"
            options={[]}
            name="shippingMethods"
            label="Shipping Method"
            value={data.shippingMethods}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />

          <Collapse
            group={{
              label: "Order Flow / Status Setting",
              id: `"data"`,
              open: false,
            }}
          >
            <Options
              attributeList={attributeList}
              {...props}
              data={data}
              target={payments}
              targetName={"payments"}
              options={data.options}
              updateState={onChange}
              hasAddOption={true}
              headers={optionsHeaders}
              newOptions={emptyRowOptions}
              addMore={"Add New Action Flow"}
            />
          </Collapse>

          <ModalFooter>
            <Button
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
