//import React, { useState, useEffect, useContext } from "react";
import React, { useState } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addCarrier } from "redux/actions/settings/Order/carrier";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
  console.log(props);
  let [state, setState] = useState({
    nameEN: "",
    nameAR: "",
    carrierCompanyID: "",
    channels: [],
    isActive: false,
    shippingCostType: "",
    shippingCosts: [],
    storeId: "store now",
  });

  let [modal, setModal] = useState(false);
  let [AddingModal, setAddingModal] = useState(false);

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const reset = () => {
    setState(state);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const addToggle = () => {
    setAddingModal(!AddingModal);
  };

  const onSelectCarrierCompany = (e) => {
    e.preventDefault();
    let inputs = state;
    console.log(inputs);

    if (inputs.carrierCompanyID === "") {
      return ErrorAlert("error", `You Should select Carrier Type to Continue!`);
    } else {
      props.updateState(state);
      toggle();
      addToggle();
    }
  };
  const onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = state;
    console.log(inputs);

    if (inputs.nameEN === "" || inputs.nameAR === "") {
      errors.nameError = "Sorry, 'Name' in both countrys are required!";
    }
    if (errors.nameError === "") {
      addCarrier(e);
    } else {
      setState({ errors: errors });
    }
  };

  const addCarrier = () => {
    console.log(" addCarrier", state);
    console.log(JSON.stringify(state));
    try {
      props.addCarrier(JSON.stringify(state));
      SuccessAlert("success", "Sucessfully Added");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    addToggle();
    reset();
  };

  return (
    <View
      data={state}
      catalogs={props.catalogs}
      companies={props.companies}
      onChange={onChange}
      onSelect={onSelectCarrierCompany}
      onSumbit={addCarrier}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
      addingModal={AddingModal}
      addToggle={addToggle}
    />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { addCarrier })(Index);
