import React, { PropTypes } from "react";
import Form from "./Form";

const FormControllerList = (props) => {
  const onChange = (e, slug = null, target, targetName) => {
    let targetInput = target;
    console.log(
      "Value: ",
      e.target.value,
      "Name: ",
      e.target.name,
      "Target Name: ",
      targetName,
      "Target : ",
      target
    );
    //attribute
    targetInput[e.target.name] = e.target.value;

    //update object
    props.updateState({ [targetName]: props[targetName] });

    // For future case may need to add slug
    //   if (slug) {
    //     let inner_name_slug = slugify(e.target.value, { delimiter: "_" });
    //     props.updateState({ inner_name: inner_name_slug });
    //   }
  };

  const onChangeSwitch = (e, target, targetName) => {
    let targetInput = target;
    console.log("Before Checked", targetInput);
    targetInput[e.target.name] = e.target.checked;
    console.log("After Check", targetInput);
    console.log("Target Nmae", props[targetName]);
    props.updateState({ [targetName]: props[targetName] });

    // console.log("name: ", e.target.name, "value: ", e.target.checked);
    // props.updateState({ [e.target.name]: e.target.checked });
  };

  const onChangeDate = (date, name, target, targetName) => {
    let targetInput = target;
    targetInput[name] = date;
    props.updateState({ [targetName]: props[targetName] });
    // props.updateState({
    //   [name]: date,
    // });
  };

  const onChangeCheckbox = (e, target, targetName) => {
    // props.updateState({ [e.target.name]: !JSON.parse(e.target.value) });
    let targetInput = target;
    if (targetInput[e.target.name] == "") {
      targetInput[e.target.name] = false;
    }

    targetInput[e.target.name] = !targetInput[e.target.name];
    props.updateState({ [targetName]: props[targetName] });
  };

  // const onChangeRadio = (e, index = 0, radioList = [], targetName) => {
  //   console.log("onChangeRadio", radioList);
  //   radioList.map(
  //     (radio, key) => (radio[e.target.name] = key === index ? true : false)
  //   );
  //   props.updateState({ [targetName]: props[targetName] });
  // };

  const onChangeRadio = (e, index = 0, radioList = [], targetName, target) => {
    console.log("onChangeRadio", radioList);
    // radioList.map(
    //   (radio, key) => (radio[e.target.name] = key === index ? true : false)
    // );
    target[e.target.name] = e.target.value;
    props.updateState({ [targetName]: props[targetName] });
  };

  const onChangeMultiSelect = (e, name, key, target, targetName) => {
    console.log({ e, name, key, target, targetName });
    console.log(e);
    let selected = [];
    if (e) {
      e.map((input) => {
        selected.push(input[key]);
      });
    }
    let targetInput = target;
    targetInput[name] = selected;
    props.updateState({ [targetName]: props[targetName] });
  };

  const onChangeSelect = (
    e,
    name,
    key,
    optional = null, // It will not used here
    optionalKey = null,
    storeAllOptionInfo = null, // we need to used for segemnation attribue
    target,
    targetName,
    reset = null
  ) => {
    console.log("props", props.targetName);
    let targetInput = target;
    //   To reset some state when change the attribute
    if (reset) {
      reset.map((item) => {
        targetInput[item] = "";
      });
    }
    targetInput[name] = storeAllOptionInfo ? e : e[key];
    console.log("After logic", targetInput);
    props.updateState({ [targetName]: props[targetName] });
  };

  const onChangeFile = (e, name) => {
    props.updateState({
      [name]: e.target.files[0],
    });
  };

  const onChangeMeasurementUnit = (e, name, key, target, targetName) => {
    console.log("mesur", key, target);
    let targetInput = target;
    console.log(targetInput);
    targetInput[name] = e[key];
    console.log(targetInput);
    //update object
    props.updateState({ [targetName]: props[targetName] });
  };

  //  const onChange = (e, slug = null, target, targetName) => {
  //   let targetInput = target;
  //   console.log(
  //     "Value: ",
  //     e.target.value,
  //     "Name: ",
  //     e.target.name,
  //     "Target Name: ",
  //     targetName,
  //     "Target : ",
  //     target
  //   );

  const onChangeMeasurementValue = (e, target, targetName) => {
    console.log("mesur", e.target.value, target);
    let targetInput = target;
    console.log(targetInput);
    targetInput[e.target.name] = e.target.value;
    console.log(targetInput);
    //update object
    props.updateState({ [targetName]: props[targetName] });
  };

  return (
    <div>
      <Form
        {...props}
        onChange={onChange}
        onChangeDate={onChangeDate}
        onChangeMultiSelect={onChangeMultiSelect}
        onChangeCheckbox={onChangeCheckbox}
        onChangeRadio={onChangeRadio}
        onChangeSwitch={onChangeSwitch}
        onChangeSelect={onChangeSelect}
        onChangeFile={onChangeFile}
        onChangeMeasurementUnit={onChangeMeasurementUnit}
        onChangeMeasurementValue={onChangeMeasurementValue}
      />
    </div>
  );
};
export default FormControllerList;
