import React from "react";
import AddImageSvg from "./assets/AddImage";
import ContactSvg from "./assets/Contact";
import ProductSvg from "./assets/Product";
import PromotionSvg from "./assets/Promotion";

const TypeStep = ({ onTypeSelection, selectedType }) => {
  const boxes = [
    {
      title: "Contact",
      content: "Upload list of customers with all information required.",
      Icon: ContactSvg,
    },
    {
      title: "Product",
      content: "Upload list of products with all attributes required.",
      Icon: ProductSvg,
    },
    {
      title: "Promotion",
      content:
        "Upload list of promotions with all promo logic and attribute required.",
      Icon: PromotionSvg,
    },
    {
      title: "Image",
      content: "Upload list of image recognition for product or banners.",
      Icon: AddImageSvg,
    },
  ];

  return (
    <div className="type-step">
      <h2 className="type-step-header">
        Select an object you would like to import
      </h2>
      <div className="type-step-boxes">
        {boxes.map(({ title, content, Icon }) => (
          <div
            key={title}
            className={`type-step-box ${
              selectedType === title ? "active-selected-imp" : ""
            }`}
            onClick={() => onTypeSelection(title)}
          >
            <Icon className="type-step-box-icon" width={50} height={50} />
            <h3 className="type-step-box-title">{title}</h3>
            <p className="type-step-box-content">{content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypeStep;
