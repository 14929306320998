import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import ReactSVG from "react-svg";
class QuickNavigation extends Component {
  componentDidMount() {
    // owl Carousel Create
    window.$(".general-carousel").owlCarousel({
      loop: false,
      responsiveClass: true,
      nav: true,
      margin: 10,
      stagePadding: 60,
      items: 5,
      dots: false,
      navText: [
        '<span class="fa-stack"><i class="fa fa-stack-1x"></i><i class="fa fa-chevron-left fa-stack-1x"></i></span>',
        '<span class="fa-stack"><i class="fa fa-stack-1x"></i><i class="fa fa-chevron-right fa-stack-1x"></i></span>',
      ],
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        500: {
          items: 2,
          nav: true,
        },
        992: {
          items: 3,
          nav: true,
          loop: false,
        },
        1200: {
          items: 4,
          nav: true,
          loop: false,
        },
      },
    });
    // Apply the keyborad navagation
    window.$(document).ready(function () {
      var owl = window.$(".general-carousel");
      window.$(document.documentElement).keyup(function (event) {
        if (event.keyCode == 37) {
          /*left key*/
          owl.trigger("prev.owl.carousel", [700]);
        } else if (event.keyCode == 39) {
          /*right key*/
          owl.trigger("next.owl.carousel", [700]);
        }
      });
    });
    // // Apply the keyborad navagation
    // 	 if (window.$('.general-carousel a').hasClass('clicked')){
    // 		window.$('.general-carousel a').not(this).children('.tiles').removeClass('clicked');
    // 		window.$('.general-carousel a.clicked').children('.tiles').addClass('clicked');
    // 	 }
  }
  render() {
    return (
      <div className="quick-nav">
        <div className="general-carousel owl-carousel">
          {this.props.quickNavs.map((nav) => (
            <div>
              <NavLink activeClassName="clicked" to={nav.path}>
                <div className="tiles m-b-10">
                  <div className="tiles-body">
                    <i
                      className="fa fa-info-circle info"
                      aria-hidden="true"
                    ></i>
                    <div className="widget-stats">
                      <div className="wrapper transparent">
                        <div className="img-container">
                          {/* <img src="http://localhost:3000/assets/img/svg/contact-list.svg" alt=""/> */}
                          <ReactSVG src={nav.src} />
                        </div>
                        <span className="item-title semi-bold">
                          {nav.title}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          ))}

          {/* <a href="#">
							<div className="tiles m-b-10">
								<div className="tiles-body">
									<div className="widget-stats">
										<div className="wrapper transparent">
											<span className="item-title semi-bold"></span>
										</div>
									</div>
								</div>
							</div>
						</a> */}
        </div>
      </div>
    );
  }
}

export default QuickNavigation;
