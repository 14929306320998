import React from "react";

const ConfirmedStep = ({ ...props }) => {
  console.log("confirm data props", props);
  return (
    <div className="map-step" style={{ width: "60%", margin: "0 auto" }}>
      <h2 className="map-step-title" style={{ textAlign: "center" }}>
        Confirmed Upload
      </h2>
      <div style={{ textAlign: "left" }} className="confirmed-step-data">
        <div className="confirmed-step-wr">
          <p>
            <span className="confirmed-step-data-header">Import Name:</span>{" "}
            <span className="confirmed-step-data-res">
              {props?.bulkProgressData?._doc?.file_name}
            </span>
          </p>
          <p>
            <span className="confirmed-step-data-header">Success Upload:</span>{" "}
            <span className="confirmed-step-data-res">
              {props?.bulkProgressData?.updatedRows}
            </span>
          </p>
          <p>
            <span className="confirmed-step-data-header">Failed Upload:</span>{" "}
            <span className="confirmed-step-data-res">
              {props?.bulkProgressData?.invalidRows}
            </span>
          </p>
          <p>
            <span className="confirmed-step-data-header">
              Upload Date & Time:
            </span>{" "}
            <span className="confirmed-step-data-res">
              {props?.bulkProgressData?._doc?.createdAt}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmedStep;
