//import React, { useState, useContext, useEffect } from 'react'
import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import {
  getCustomers,
  deleteCustomer,
} from "../../../../redux/actions/CustomerAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
class index extends Component {
  componentDidMount() {
    this.props.getCustomers();
  }

  deleteCustomer = (customerID) => {
    // FIXME: prevent default & get the result of delete
    try {
      let response = this.props.deleteCustomer(customerID);

      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  render() {
    return (
      <div>
        <View
          data={this.props.customers}
          deleteCustomer={this.deleteCustomer}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.customer.items,
});
export default connect(mapStateToProps, { getCustomers, deleteCustomer })(
  index
);
