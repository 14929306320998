import {
  ADD_DELIVERY,
  EDIT_DELIVERY,
  DELETE_DELIVERY,
  GET_DELIVERYS,
  FIND_DELIVERY,
} from "../types";
import fetchAPIClass from "../../fetchAPI";
import { General_URL, SHIPPING_SETTING_URL } from "context/url";

export const getDeliverys = (data) => (dispatch) => {
  let url = `${SHIPPING_SETTING_URL}/deliveries/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_DELIVERYS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addDelivery = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${SHIPPING_SETTING_URL}/deliveries`;//`${General_URL}/shipping/api/settings/deliveries`
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_DELIVERY);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteDelivery = (input) => (dispatch) => {
  let url = `${SHIPPING_SETTING_URL}/deliveries/${input}`;//`${General_URL}/shipping/api/settings/deliveries/${input}`
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_DELIVERY);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editDelivery = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${SHIPPING_SETTING_URL}/deliveries`;//`${General_URL}/shipping/api/settings/deliveries`

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_DELIVERY);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findActiveDelivery = () => (dispatch) => {
  let url = `${SHIPPING_SETTING_URL}/deliveries/active`;
  try {
    const api = new fetchAPIClass(url, "POST", null, FIND_DELIVERY);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
