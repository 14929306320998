import React, { Component } from "react"
import CollapseComponent from "../../../Collapse"
import { Row, Col, Button } from "reactstrap"
import Algorithm from "../../Algorithm/"
import { getListAttribute } from "../../../../../redux/actions/AttributeAction"
import { connect } from "react-redux"
const business_id = "5cc2f00577ebc157b4f1c64c"

const index = (props) => {
  const group = {
    id: 1,
    label: "Algorithm",
    open: true,
  }

  return (
    <div className="steps-container">
      <div className="templates-container">
        <Col
          xs="12"
          lg="12"
          xl="12"
          className="group-list"
          style={{ backgroundColor: "#fff" }}>
          <CollapseComponent group={group}>
            <Algorithm {...props} />
          </CollapseComponent>
        </Col>
      </div>
    </div>
  )
}

export default index
