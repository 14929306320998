import React from "react";
import Form from "./Form";
import slugify from "react-slugify";
import _ from "lodash";

const FormController = (props) => {
  const onChange = (e, slug = null) => {
    console.log("name: ", e.target.name, "value: ", e.target.value);
    props.updateState({ [e.target.name]: e.target.value });
    if (slug) {
      let inner_name_slug = slugify(e.target.value, { delimiter: "_" });
      props.updateState({ inner_name: inner_name_slug });
    }
  };

  const onChangeSwitch = (e) => {
    console.log("name: ", e.target.name, "value: ", e.target.checked);
    props.updateState({ [e.target.name]: e.target.checked });
  };

  const onChangeDate = (date, name) => {
    props.updateState({
      [name]: date,
    });
  };

  const onChangeCheckbox = (e) => {
    props.updateState({ [e.target.name]: !JSON.parse(e.target.value) });
  };

  const onChangeRadio = (e, index = 0, radioList = []) => {
    radioList.map((radio, key) => (radio.value = key === index ? true : false));
  };

  const onChangeMultiSelect = (e, name, key) => {
    console.log(e);
    let selected = [];
    if (e) {
      e.map((input) => {
        selected.push(input[key]);
      });
    }
    props.updateState({ [name]: selected });
  };

  const onChangeSelect = (
    e,
    name,
    key,
    optional,
    optionalKey,
    storeAllOptionInfo
  ) => {
    console.log("onChangeSelect = ", name, e[key]);
    if (optional !== undefined) {
      props.updateState({
        [name]: e[key],
        [optional]: e[optionalKey],
      });
    } else if (storeAllOptionInfo) {
      console.log({ name, e, key });
      props.updateState({ [name]: e });
    } else {
      console.log({ name, e, key });
      props.updateState({ [name]: !_.isEmpty(e) ? e[key] : "" });
    }
  };

  const onChangeFile = (e) => {
    console.log("OnchangeFile", e, e.target.name, e.target.files[0], e.target);
    console.log(e.target.files[0]);
    props.updateState({
      [e.target.name]: e.target.files[0],
    });
  };

  const onChangeMeasurementUnit = (e) => {
    console.log(e);
  };

  const onChangeMeasurementValue = (e) => {
    console.log(e);
  };

  return (
    <div>
      <Form
        {...props}
        onChange={onChange}
        onChangeDate={onChangeDate}
        onChangeMultiSelect={onChangeMultiSelect}
        onChangeCheckbox={onChangeCheckbox}
        onChangeRadio={onChangeRadio}
        onChangeSwitch={onChangeSwitch}
        onChangeSelect={onChangeSelect}
        onChangeFile={onChangeFile}
        onChangeMeasurementUnit={onChangeMeasurementUnit}
        onChangeMeasurementValue={onChangeMeasurementValue}
      />
    </div>
  );
};
export default FormController;
