import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";
import _, { merge } from "lodash";

import { deleteBranch, editBranch } from "redux/actions/Ecommerce/BranchAction";
import { getCategories } from "redux/actions/Ecommerce/CategoryAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";
import product from "redux/reducers/Ecommerce/product";
import Delete from "components/general/Delete";
import Product from "views/Ecommerce/Product";

/** * FIXME: 
1- Check status if active or not 
3- Solve the problem of refresh the page 
  >> should show the updated recently data
  >> currently, it shows the old passed data version from location
*/

const Index = (props) => {
  let branch = props.location.state.item;
  branch.status = _.toLower(branch.status) === "active" ? true : false;
  let [state, setState] = useState(branch);

  let branchprod=  branch.products.map((product) => {
    product= Object.assign({}, product,product.inventory );
    delete product.inventory

    //  let id = product._id
    //  let productId = product.product_id._id
    product= Object.assign({}, product,product.product_id );
    // product._id= id
    // product.product_id=productId 
    return product
    
  }) 
  let [products, setProducts] = useState(branchprod);

  useEffect(() => {
    props.getCategories();
  }, []);

  const deleteBranch = (branchID) => {
    try {
      props.deleteBranch(branchID);
      SuccessAlert("success", "Sucessfully deleted");
      props.history.push("branch");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const deleteProduct = (branchID) => {
    try {
      // props.deleteBranch(branchID);
      // SuccessAlert("success", "Sucessfully deleted");
      // props.history.push("branch");
      console.log(branchID)
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const updateState = (input) => {
    console.log("Input", input);
    setState({ ...state, ...input });
  };

  const editBranch = async () => {
    try {
      state.status = state.status === true ? "active" : "";
      delete state.products
      let response = props.editBranch(JSON.stringify(state), state._id);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log("State", state);

  return props.categories ? (
    <div>
      {" "}
      <View
        {...state}
        data={state}
        {...props}
        deleteProduct={deleteProduct}
        products={products}
        deleteBranch={deleteBranch}
        editBranch={editBranch}
        onChange={updateState}
      />{" "}
    </div>
  ) : (
    <div></div>
  );
};
const mapStatToProps = (state) => ({
  categories: state.categories.items,
});
export default connect(mapStatToProps, {
  deleteBranch,
  editBranch,
  getCategories,
})(Index);
