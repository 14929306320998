import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getDeliverys,
  editDelivery,
  deleteDelivery,
} from "../../../../redux/actions/settings/Order/delivery";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import { getCarriers } from "redux/actions/settings/Order/carrier";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import CollapseList from "components/general/PageDetails/CollapseList";
//import AddBrand from "./Add";
import View from "./View";

const deliveryStrategyDemo = [
  {
    value: "Nearest Fulfillment Source",
    label: "Nearest Fulfillment Source",
  },
  {
    value: "Highest Availability & Nearest Fulfillment Source",
    label: "Highest Availability & Nearest Fulfillment Source",
  },
];

const Index = (props) => {
  useEffect(() => {
    props.getDeliverys();
  }, []);

  useEffect(() => {
    props.getCarriers();
  }, []);

  useEffect(() => {
    props.getCatalogs();
  }, []);

  const deleteDelivery = (deliveryId) => {
    try {
      let response = props.deleteDelivery(deliveryId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editDelivery = (deliveryId, input) => {
    try {
      let response = props.editDelivery(JSON.stringify(input));
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log(props.deliverys);
  return (
    <div>
      {props.deliverys && (
        <View
          {...props}
          deleteDelivery={deleteDelivery}
          editDelivery={editDelivery}
          deliveryStrategyDemo={deliveryStrategyDemo}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  deliverys: state.deliverys.items,
  catalogs: state.catalogs.items,
  carriers: state.carriers.items,
});

export default connect(mapStateToProps, {
  getDeliverys,
  deleteDelivery,
  editDelivery,
  getCatalogs,
  getCarriers,
})(Index);
