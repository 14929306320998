import {
  ADD_MEDIA,
  EDIT_MEDIA,
  GET_MEDIA,
  FIND_MEDIA,
  DELETE_MEDIA,
} from "../../actions/Ecommerce/types";

const initialState = {
  item: null,
  items: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_MEDIA:
    case EDIT_MEDIA:
    case FIND_MEDIA:
    case DELETE_MEDIA:
      return {
        ...state,
        item: action.payload,
      };
    case GET_MEDIA:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
}
