//ORDER
export const ADD_ORDER = "ADD_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const DELETE_ORDER = "DELETE_ORDER";
export const EDIT_ORDER = "EDIT_ORDER";
export const FIND_ORDER = "FIND_ORDER";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const ORDER_NO_SHOW = "ORDER_NO_SHOW";
//MERCHANT

export const ADD_MERCHANT = "ADD_MERCHANT";
export const GET_MERCHAS = "GET_MERCHAS";
export const DELETE_MERCHANT = "DELETE_MERCHANT";
//CREATE ORDER MULTI STEPS
export const ADD_CREATE_ORDER_DETAILS = "ADD_CREATE_ORDER_DETAILS";
//Shipment
export const ADD_SHIPMENT = "ADD_SHIPMENT";
export const GET_SHIPMENTS = "GET_SHIPMENTS";
export const DELETE_SHIPMENT = "DELETE_SHIPMENT";
export const EDIT_SHIPMENT = "EDIT_SHIPMENT";
export const FIND_SHIPMENT = "FIND_SHIPMENT";
export const GET_SHIPMENT_BY_ORDER_ID = "GET_SHIPMENT_BY_ORDER_ID";
export const PRINT_SHIPMENT = "PRINT_SHIPMENT";
export const DISPATCH_SHIPMENT = "DISPATCH_SHIPMENT";
export const CHECK_DELIVERY_SOURCING = "CHECK_DELIVERY_SOURCING";
export const UPDATE_SHIPMENT_STATUS = "UPDATE_SHIPMENT_STATUS";
// realocate shipment
export const ADD_REALLOCATE_SHIPMENT = "ADD_REALLOCATE_SHIPMENT";
