import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";

import {
  editPromotion,
  deletePromotion,
  getPromotionTypes,
  // findPromotion,
  //duplicatePromotion,
} from "../../../../redux/actions/Ecommerce/PromotionActions";
import { Form } from "reactstrap";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
import _ from "lodash";
import { handelLogicAlgorithm } from "../../../../helpers/logicHandler";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import { getAttributes } from "redux/actions/Ecommerce/AttributeAction";
import { getCategories } from "redux/actions/Ecommerce/CategoryAction";
import { getBrands } from "redux/actions/Ecommerce/BrandAction";
//import { handleProductList } from "../handler"

const Index = (props) => {
  let promotion = props.location.state.item;
  console.log(promotion);

  const [isChecked, setIsChecked] = useState(
    _.toLower(promotion.status) === "active" ? true : false
  );
  let [state, setState] = useState(promotion);
  let [attributeList, setAttributeList] = useState([]);

  const [result, setResult] = useState({
    users: {
      total: 0,
      percentage: "0%",
      className: "user",
    },
    sessions: {
      total: 0,
      percentage: "0%",
      className: "session",
    },
    pageviews: {
      total: 0,
      percentage: "0%",
      className: "page-view",
    },
    sales: {
      total: 0,
      percentage: "0%",
      className: "sale",
    },
  });
  console.log(state);
  useEffect(() => {
    props.getPromotionTypes();
  }, []);

  useEffect(() => {
    // Get catalogs list
    props.getCatalogs();
  }, []);

  useEffect(() => {
    // Get attribute list
    props.getAttributes();
  }, []);

  useEffect(() => {
    // Get categories list
    props.getCategories();
    props.getBrands();
  }, []);

  useEffect(() => {
    if (props.attributes) {
      let handledAttribute = [];

      _.map(props.attributes, (attr) => {
        if (attr.inner_name === "categories") {
          let filteredCategories = _.filter(props.categories, (category) =>
            _.intersection(_.map(category.catalogs, "_id"), state.catalog_id)
          );
          console.log(filteredCategories);

          attr = {
            ...attr,
            data: _.map(filteredCategories, (cat) => ({
              label: cat.name_en,
              value: cat._id,
            })),
          };

          handledAttribute.push(attr);
        } else if (attr.inner_name === "brand_id") {
          let filteredBrands = _.filter(props.brands, (brand) =>
            _.intersection(_.map(brand.catalogs, "_id"), state.catalog_id)
          );
          console.log(filteredBrands);

          attr = {
            ...attr,
            data: _.map(filteredBrands, (brand) => ({
              label: brand.name_en,
              value: brand._id,
            })),
          };

          handledAttribute.push(attr);
        } else handledAttribute.push(attr);
      });
      setAttributeList([...handledAttribute]);
    }
  }, [props.attributes, state.catalog_id]);

  let [, setRender] = useState(); //for forcing to re render

  function handleUpdate() {
    //passing empty object will re-render the component
    setRender({});
  }

  const inputsToJSON = (inputs) => {
    return JSON.stringify(inputs);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      let inputs = { ...state };
      console.log(inputs);

      inputs = {
        ...inputs,
        logic_x: inputs.logic_x,
        logic_y: inputs.logic_y,
      };

      if (inputs.typeName === "catalog" || inputs.type === "catalog")
        inputs = _.omit(inputs, "logic_y");
      console.log(inputs);

      inputs = inputsToJSON(inputs);
      let response = props.editPromotion(inputs, promotion._id);

      SuccessAlert("success", "Sucessfully updated");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const duplicatePromo = (e) => {
    e.preventDefault();

    try {
      let inputs = inputsToJSON(state);
      let response = props.duplicatePromotion(inputs, promotion._id);

      SuccessAlert("success", "Sucessfully duplicated");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleOnStatusChange = () => {
    setIsChecked(!isChecked);
    onChange({ status: !isChecked ? "Active" : "Inactive" });
  };

  const deletePromo = () => {
    try {
      let response = props.deletePromotion(promotion._id);

      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
      props.history.push("/ecommerce/promotion"); //redirect to PromoList
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const onChange = (input) => {
    setState({ ...state, ...input });
  };
  console.log(attributeList);
  return (
    <div>
      {props.catalogs && (
        <Form onSubmit={handleOnSubmit}>
          <View
            {...props}
            onChange={onChange}
            state={state}
            {...state}
            attributeList={attributeList}
            catalogList={props.catalogs}
            isFromEdit={true}
            isChecked={isChecked}
            result={result}
            pageName="Promotion"
            deletePromo={deletePromo}
            handleOnStatusChange={handleOnStatusChange}
            duplicatePromo={duplicatePromo}
            updateState={onChange}
          ></View>
        </Form>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  promotions: state.promotions.items,
  catalogs: state.catalogs.items,
  promotionTypes: state.promotions.promotion_types,
  attributes: state.ecommerceAttributes.items,
  categories: state.categories.items,
  brands: state.brands.items,
});

export default connect(mapStateToProps, {
  editPromotion,
  deletePromotion,
  getCatalogs,
  getPromotionTypes,
  getAttributes,
  // duplicatePromotion,
  getBrands,
  getCategories,
})(Index);
