import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getCarriers,
  getCarrierCompanies,
  editCarrier,
  deleteCarrier,
} from "../../../../redux/actions/settings/Order/carrier";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
//import AddBrand from "./Add";
import View from "./View";


const Index = (props) => {
  useEffect(() => {
    props.getCarriers();
    props.getCarrierCompanies();
  }, []);

  useEffect(() => {
   
  }, [props.carriers]);

  console.log(props.carriers)
  console.log(props.companies)


  const deleteCarrier = (carrierId) => {
    try {
      let response = props.deleteCarrier(carrierId);
      SuccessAlert("success", "Sucessfully deleted");
      //console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editCarrier = (carrierId, input) => {
    console.log(JSON.stringify(input))
    try {
      let response = props.editCarrier(JSON.stringify(input));
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log(props.carriers);
  return (
    <div>
      {props.carriers && (
        <View
          {...props}
          deleteCarrier={deleteCarrier}
          editCarrier={editCarrier}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  carriers: state.carriers.items,
  companies: state.carriers.companies
});

export default connect(mapStateToProps, {
  getCarriers,
  getCarrierCompanies,
  editCarrier,
  deleteCarrier,
})(Index);
