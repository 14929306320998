import React from "react"
import { BeatLoader } from 'react-spinners';

const Loading = () => {
  //#36d7b7
  return (
    <div className="segmentation">
      <BeatLoader color="#003c63" size={20} style={{alignItems: "center", justifyContent: "center"}}/>
    </div>
  );
};

export default Loading
