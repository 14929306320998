import * as React from "react";
const ProductSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1706.667}
    height={1177.333}
    viewBox="0 0 1280 883"
    {...props}
  >
    <path d="M383.5 1.1c-20.9 3-35.7 13.3-44 30.4-4.3 8.9-5.6 14.9-5.6 25.3 0 9.6.5 13 3.3 22.7 2 7.2 3.6 11.4 20.3 55.5 9 23.7 15.5 40.9 43.5 115 5.9 15.7 16.7 44.2 24 63.5 7.3 19.2 18.1 47.8 24 63.5 30.8 81.7 37.4 98.9 43.4 115 9.7 25.6 16.8 37.8 30.3 52.1 15.4 16.3 34.4 27.5 57.8 34.1l8 2.3 213 .3c151.3.2 215.3 0 221-.8 36-5 70.2-29.5 87.9-63 2.5-4.8 8.8-19.9 14-33.6 5.2-13.7 14-37.1 19.6-51.9 5.6-14.9 14-37 18.6-49.3 19.6-51.9 25.9-68.4 34-90 4.7-12.5 13-34.4 18.4-48.7 5.4-14.3 15.5-41.1 22.5-59.5 43.8-115.9 41.2-108.5 42.2-122.4 1.2-17-4.8-33.8-16-44.8-6.2-6.2-15.9-11.5-26.2-14.5-5.7-1.7-27.8-1.8-427-1.9-234.3-.1-423.7.2-427 .7z" />
    <path d="M65 157c-19.2.5-22.1.8-27.2 2.9C21.4 166.4 8 180.4 2.4 197c-2.9 8.4-2.6 26.3.4 35.2 5 14.5 16 27.1 29.6 33.7 4.5 2.2 10.8 4.5 14 5 3.9.7 37.1 1.1 96.7 1.1h90.7l42.2 98.7c58.9 137.7 99.2 231.7 117.2 273l15.3 35.2 6 4.7c3.3 2.6 11.2 8.1 17.5 12.2 6.3 4.1 14.4 9.6 18 12.1l6.5 4.6 41-.3 41-.4-6.5 4.2c-7.2 4.7-18 15.2-22.7 22.1-5.2 7.5-11 19.9-13.9 29.4-2.5 8.1-2.8 10.5-2.8 25-.1 16.2.4 19.8 4.5 31.3 2.8 7.9 5.5 12.8 11.9 21.7 9.7 13.5 19.5 21.6 34.3 28.5 35 16.3 78.3 9.3 104.3-16.7 27.2-27.1 34.4-64.4 19.5-100.6-2.1-5.1-5.5-11.8-7.5-15.1-5.3-8.1-17.7-20.8-25-25.6l-6.1-4h329l-5 2.9c-2.7 1.6-8 5.6-11.7 9-26.3 24.2-36.9 64.1-25.8 97.4 4.5 13.1 10.7 23.3 21.4 34.6 27.7 29.1 75.9 35.4 112.6 14.8 8.7-4.9 24.6-20.1 30.5-29.1 5-7.7 10.1-20.4 12.1-30.2 2.2-10.6 1.5-31.6-1.5-42.9-2.6-10.1-9.5-24-15.9-32.6-5.8-7.5-15.6-16.8-22.2-20.7l-4.5-2.7 12-.6c18.2-1 27.3-3.7 38.3-11.6 11-7.8 19.5-21.3 22.2-35.1 1.7-8.4.8-22.1-2-30.1-4.7-14.1-16.5-27.3-29.5-33.2-13.9-6.3 6.7-5.9-305.3-5.9H499.3L474 536.7c-13.9-32.5-39.4-92.1-56.6-132.2-17.1-40.2-44.5-104.3-60.9-142.5-16.3-38.2-30.8-71-32.3-72.9-2.6-3.5-37.1-26.7-45.2-30.5-4.5-2.1-5.3-2.1-98.5-2.2-51.7 0-103.7.2-115.5.6z" />
  </svg>
);
export default ProductSvg;
