import React, { useState } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addRole } from "redux/actions/UserManagement/RoleActions";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
    let [state, setState] = useState({
        business_id: "5cc2f00577ebc157b4f1c64c",
        name_en: "",
        name_ar: "",
        description_en: "",
        description_ar: "",
        name: "",
        is_active: true,
        permissions: [],
        errors: {
            nameError: "",
        },
    });

    let [modal, setModal] = useState(false);

    const onChange = (input) => {
        setState({ ...state, ...input });
    };

    const reset = () => {
        setState(state);
    };

    const toggle = () => {
        setModal(!modal);
    };

    const onValidate = (e) => {
        e.preventDefault();
        let errors = {
            nameError: "",
        };

        let inputs = state;

        if (inputs.name_en === "" || inputs.name_ar === "") {
            errors.nameError = "Sorry, 'Name' in both languages are required!";
        }

        if (errors.nameError === "") {
            addRole(e);
        } else {
            setState({ errors: errors });
        }
    };

    const addRole = () => {
        console.log("addRole", state);

        //TODO: TO BE CHENGED
        let role = `{
      "storeId": "5efbacb731179ebef0823f86",
      "nameEN": "${state.name_en}",
      "nameAR": "${state.name_ar}",
      "descriptionEN": "${state.description_en}",
      "descriptionAR": "${state.description_ar}",
      "createdBy": "${localStorage.getItem("userId")}",
      "permissions": ${JSON.stringify(state.permissions)}
    }`;

        console.log(role);

        try {
            props.addRole(role)
            SuccessAlert("success", "Sucessfully Added");
        } catch (err) {
            console.log(err);
            ErrorAlert("error", `Something went wrong! ${err}`);
        }
        reset();
        toggle();
    };

    return (
        <View
            data={state}
            onChange={onChange}
            onSumbit={addRole}
            onValidate={onValidate}
            errors={state.errors}
            modal={modal}
            toggle={toggle}
            permissions={props.permissions}
        />
    );
};

const mapStateToProps = (state) => ({
    roles: state.roles.items,
});

export default connect(mapStateToProps, { addRole })(Index);
