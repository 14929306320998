import { combineReducers } from "redux";

// --------- CRM ----------
import attribute from "./attribute";
import group from "./group";
import customer from "./customer";
import customerAttributes from "./CustomerAttributes";
import segmentations from "./segmentations";
import tags from "./tags";
import address from "./address";

// ------ Settings --------
import customerTypes from "./settings/customerType";
import payments from "./settings/Order/payment";
import deliverys from "./settings/Order/delivery";
import pickups from "./settings/Order/pickup";
import carts from "./settings/Order/cart";
import carriers from "./settings/Order/carrier";
//import orderFlow from "./settings/Order/orderFlow";

// ------ Ecommerce --------
import products from "./Ecommerce/product";
import branches from "./Ecommerce/branch";
import catalogs from "./Ecommerce/catalog";
import categories from "./Ecommerce/category";
import brands from "./Ecommerce/brand";
import media from "./Ecommerce/media";
import ecommerceAttributes from "./Ecommerce/attribute";
import ecommerceGroups from "./Ecommerce/group";
import promotions from "./Ecommerce/promotion";
import languages from "./Ecommerce/settings/language";
import countries from "./Ecommerce/settings/country";
import configurableAttributes from "./Ecommerce/configurableAttribute";
import bulkUpload from "./Ecommerce/bulkUpload";
// ----------- Order Mangament ---------
import orders from "./OrderMangement/order";
//import merchant from "./OrderMangement/merchant";
//import shipments from "./OrderMangement/shipment";
//import orderSteps from "./OrderMangement/orderSteps";
//import realocate from "./OrderMangement/realocate";
//---------------Auth---------------
import auth from "./auth";
// ----------- User Mangament ---------
import users from "./UserManagement/user";
import roles from "./UserManagement/role";

export default combineReducers({
  attribute,
  group,
  customer,
  customerAttributes,
  segmentations,
  tags,
  address,

  //Setting
  customerTypes,
  payments,
  carts,
  deliverys,
  pickups,
  carriers,
  // orderFlow,
  products,
  branches,
  catalogs,
  categories,
  brands,
  media,
  promotions,
  ecommerceAttributes,
  ecommerceGroups,
  configurableAttributes,
  bulkUpload,
  //Ecommerce Settings
  languages,
  countries,

  // Order managment
  orders,

  //Auth
  auth,
  //User Management
  roles,
  users,
});
