import React, { PropTypes , useState} from "react";
import Collapse from "components/general/Collapse";
import Form from "components/general/FormControllerList";
import Table from "components/general/Table";
import _ from "lodash";
import Modal from "components/general/Modal"
import {Button, ModalFooter} from "reactstrap"

const ProductInventory = (props) => {

    const { branches , modal, toggle} = props;

let changeProductQty = (branches) => {
    _.forEach(branches, branch => (
        branch.inventory.min_quantity = branch.min,
        branch.inventory.max_quantity = branch.max
    ))

    toggle()

}
    return (
        
        <Collapse group={{ label: "Inventory", id: 31, open: false }}>
            {! _.isEmpty(branches) && (
                <div className="p-l-25 p-r-25">
                        <div>
                            <Form
                                {...props}
                                type={"decimal"}
                                label={"Quantity"}
                                name={"quantity"}
                                value={_.head(branches).inventory.quantity}
                                disabled = {branches.length > 1 ? true : false}
                                default={_.head(branches).inventory.quantity}
                                target={_.head(branches).inventory}
                                targetName="branches"
                                updateState={props.onChange}
                            />
                            <Form
                                {...props}
                                type={"decimal"}
                                label={"Min Quantity"}
                                name={"min_quantity"}
                                value={_.head(branches).inventory.min_quantity}
                                disabled = {branches.length > 1 ? true : false}
                                default={_.head(branches).inventory.min_quantity}
                                target={_.head(branches).inventory}
                                targetName="branches"
                                updateState={props.onChange}
                            />
                            <Form
                                {...props}
                                type={"decimal"}
                                label={"Max Quantity"}
                                name={"max_quantity"}
                                value={_.head(branches).inventory.max_quantity}
                                disabled = {branches.length > 1 ? true : false}
                                default={_.head(branches).inventory.max_quantity}
                                target={_.head(branches).inventory}
                                targetName="branches"
                                updateState={props.onChange}
                            />
                        </div>
                    <Button color="link" onClick={toggle}>
                        {"Advanced Inventory"}
                    </Button>
                <Modal title="Advanced Inventory" toggle={toggle} isOpen={modal} size="lg">
                    <div className="contact-table">
                        <Table
                            {...props}
                            data={handleInventoryData(branches)}
                            cellEdit={true}
                            isSelectRow={false}
                            noSearch={true}
                            headers={props.headerList}
                        />
                    </div>
                    <ModalFooter>
                        <Button
                            onClick={() => changeProductQty(branches)}
                            className="btn btn-primary btn-cons btn-cons"
                        >
                            {"Save"}
                        </Button>
                        <Button className="btn btn-white btn-cons" onClick={toggle}>
                            {"Cancel"}
                        </Button>
                    </ModalFooter>
                </Modal>
                </div>
         
            )}
        </Collapse>
    );
};

ProductInventory.propTypes = {};

export default ProductInventory;


function handleInventoryData(branches) {

    _.forEach(branches, branch => (
        branch.branch = branch.branch_id.name_en,
        branch.min = branch.inventory.min_quantity,
        branch.max = branch.inventory.max_quantity
    ))
    return branches
}
