import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Card from "components/general/Card";
import CollapseList from "components/general/PageDetails/CollapseList";
import Delete from "components/general/Delete";
import _, { update } from "lodash";
import AddCustomerType from "./Add";

const View = (props) => {
  const { customerTypes, deleteCustomerType, editCustomerType } = props;
  const [state, setState] = useState(customerTypes);

  let customerTypeData = [];
  _.map(customerTypes, (data, index) => {
    let item = {
      id: index,
      label: data["name_en"],
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          type: "text",
          name: "_id",
          label: "Customer Type ID",
          value: data["_id"],
          default: data["_id"],
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
          value: data["name_en"],
          default: data["name_en"],
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          type: "text",
          value: data["name_ar"],
          default: data["name_ar"],
        },
      ],
    };
    customerTypeData.push(item);
  });

  const updateState = (input) => {
    console.log("Input", input);
    setState({ ...state, ...input });
  };

  console.log("State", state);
  console.log("customerTypes", customerTypes);

  return (
    <div className="setting">
      <Card name="Customer Types Setting" goBack={props.history.goBack}>
        <div className="contact-table">
          {/* Add Lanaguage */}
          <div className="d-flex justify-content-end">
            <AddCustomerType />
          </div>

          {/* List of customerTypes */}
          <Col xs="12" lg="8" xl="9" className="group-list">
            {_.map(customerTypeData, (group, key) => (
              <CollapseList
                {...props}
                group={group}
                usingFormContollerList={true}
                target={customerTypes[key]}
                collapseCase={group.collapseCase}
                name="name"
                updateState={updateState}
              >
                {/* Language Actions */}
                <div className="row justify-content-end">
                  <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                    <Button
                      className="btn btn-primary btn-cons btn-cons"
                      onClick={() =>
                        editCustomerType(group.attributes[0].value, state[key])
                      }
                    >
                      {"Save"}
                    </Button>

                    <Delete
                      data={group.label}
                      onSubmit={deleteCustomerType}
                      customerID={group.attributes[0].value}
                      actionType="button"
                      buttonStyle="btn btn-white btn-cons"
                    />
                  </div>
                </div>
              </CollapseList>
            ))}
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default View;
