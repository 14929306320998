import React, { useState, useEffect } from "react";
import _ from "lodash"
import ExportButton  from "components/general/ExportButton"


const ProcessingStep = ({
  bulkProgressData,
  lastProgressPercentage,
  setLastProgressPercentage,
  ...props
}) => {
  console.log("bulkProgressData=>", bulkProgressData);
  const {customers,  products, attributes, selectedType} = props

  const data = selectedType === "Contact"? customers : products.products
  const indexing_for = selectedType === "Contact"? "crm": "ecommerce"
  console.log(data)
  let custom_attributes = _.filter(attributes, attr => attr.class === "custom" && attr.indexing_for === indexing_for)
  //custom_attributes = _.map(custom_attributes, cattr => cattr.system_name)

let header = _.uniq(_.flattenDeep(_.map(data, d =>_.keys(d))))
  if(selectedType === "Product"){
    if(_.includes(header, "brand_id")){
      const index = _.indexOf(header, "brand_id")
      header.splice(index, 0, "brand_en", "brand_ar");
    }
    else 
    header.push(...["brand_id","brand_en","brand_ar"])
  
    header.push(...["categories", "categories_en", "categories_ar"])

  }
  _.map(custom_attributes, cattr => {
    const index = _.indexOf(header, cattr.system_name)
      if(_.includes(header, cattr.system_name) && !_.isEmpty(cattr.option)){
        header.splice(index, 0, `${cattr.system_name}_en`, `${cattr.system_name}_ar`);
    }
    
  })
  useEffect(() => {
    if (
      bulkProgressData &&
      bulkProgressData.hasOwnProperty("progressPercentage")
    ) {
      setLastProgressPercentage(bulkProgressData.progressPercentage);
    }
  }, [bulkProgressData]);

  //if (!bulkProgressData) {
  //  return <div>Loading...</div>;
  //}

  //const { updatedRows, invalidRows, totalRows } = bulkProgressData;

  const handleExportData = (data, attributes) => {
    let exportedList = [];
  
    data.forEach((d) => {
      let row = {};
  
      const categories = _.flattenDeep(_.map(d.products_catalogs, c => c.categories ));
      const categoryValues = _.map(categories, c =>  {
        return {
        external_id: c.external_id,
        categories_en: c.name_en,
        categories_ar: c.name_ar
        }
      });

  
      header.forEach((h) => {
        let matchedAttribute = attributes.find(
          (attribute) => attribute.system_name.toLowerCase() === h.toLowerCase()
        );
  
        if (matchedAttribute) {
          if (matchedAttribute.system_name === "base_product") {
            row = {
              ...row,
              [h]: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].sku : ""
            };
          } else if (matchedAttribute.system_name === "brand_id") {
            row = {
              ...row,
              [h]: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].external_id : "",
              brand_en: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].name_en : "",
              brand_ar: d[matchedAttribute.system_name] ? d[matchedAttribute.system_name].name_ar : ""
            };
          } else if (matchedAttribute.system_name === "categories") {
            row = {
              ...row,
              [h]: _.map(categoryValues, c => c.external_id).join(','),
              [`${h}_en`]: _.map(categoryValues, c => c.categories_en).join(','),
              [`${h}_ar`]: _.map(categoryValues, c => c.categories_ar).join(',')
            };
          }
          else if (matchedAttribute.class === "custom") {
            if(!_.isEmpty(matchedAttribute.option)){
              let selectedOption = _.find(matchedAttribute.option, op => op.value === d[h])
              row = {
                ...row,
                [h]: selectedOption?.value?? "",
                [`${h}_en`]: selectedOption?.label?? "",
                [`${h}_ar`]: selectedOption?.arabic_label?? ""
              };

            }
            else if(matchedAttribute.system_name !== "categories_ar" && matchedAttribute.system_name !== "categories_en"){
              row = {
                ...row,
                [h]: d[h] ?? ""
              };

            }
            
          }
           else {
            row = {
              ...row,
              [matchedAttribute.system_name]: d[matchedAttribute.system_name] ?? ""
            };
          }
        } else {
          row = {
            ...row,
            [h]: d[h] ?? ""
          };
        }
      });
  
      exportedList.push(row);
    });
  
    return exportedList;
  };
  
  function handleDownloadExcel() {
    const exportedList = handleExportData(data, attributes);
  
    // downloadExcel({
    //   fileName: `${selectedType} Data`,
    //   sheet: selectedType,
    //   tablePayload: {
    //     header,
    //     body: exportedList,
    //   },
    // });
    return exportedList
  }

  return (
    <div className="map-step">
      <h2 className="map-step-title">Processing Data</h2>
      <div className="mapping-progress-wrapper">
        <div style={{ position: "relative" }}>
          <progress value={lastProgressPercentage} max="100" />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -56%)",
              color: lastProgressPercentage >= 50 ? "white" : "black",
            }}
          >
            {lastProgressPercentage}%
          </div>
        </div>
        </div>
        {/*<div className="box-processing">
          {/* <p>
            Data Processing Units: {updatedRows + invalidRows} / {totalRows}
          </p> 
        </div>*/}
         <div className="segmentation">
         <ExportButton data={handleDownloadExcel()} fileName={`${selectedType} Data.xlsx`} />
        </div> 
      {/* </div> */}
    </div>
  );
};

export default ProcessingStep;
