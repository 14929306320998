import {
    ADD_USER,
    EDIT_USER,
    DELETE_USER,
    GET_USERS,
    FIND_USER,
  } from "./types";
  import fetchAPIClass from "../fetchAPI";
  import { restfulUrl, API_URL } from "context/url";
  
  export const getUsers = (data) => (dispatch) => {
    let url = `${API_URL}/ecommerce/order/all`;
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_USERS);
      api.fetchWithoutAPI(dispatch, data);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const addUser = (inputs) => (dispatch) => {
    let requestBody = inputs;
    let url = `${API_URL}/ecommerce/order/add`;
    console.log(requestBody);
    try {
      const api = new fetchAPIClass(url, "POST", requestBody, ADD_USER);
      let response = api.fetchWithoutAPI(dispatch, inputs);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const deleteUser = (input) => (dispatch) => {
    let url = `${API_URL}/ecommerce/order/delete/${input}`;
    try {
      const api = new fetchAPIClass(url, "DELETE", null, DELETE_USER);
      api.fetchWithoutAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const editUser = (inputs, UserId) => (dispatch) => {
    let requestBody = inputs;
    let url = `${API_URL}/ecommerce/order/edit/${UserId}`;
  
    try {
      const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_USER);
      let response = api.fetchWithoutAPI(dispatch, inputs);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const findUser = (UserId) => (dispatch) => {
    let url = `${API_URL}/ecommerce/order/find/${UserId}`;
    let data = "";
    try {
      const api = new fetchAPIClass(url, "GET", null, FIND_USER);
      api.fetchWithoutAPI(dispatch, data);
    } catch (err) {
      console.log(err);
    }
  };
  