import React, { useState } from "react";
import { Tooltip } from "reactstrap";

function TooltipComponenet(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div>
      <i
        href="#"
        className="material-icons m-l-5"
        id={`TooltipExample${props.name}`}
      >
        info
      </i>
      <Tooltip
        flip
        target={`TooltipExample${props.name}`}
        isOpen={isOpen}
        toggle={toggle}
      >
        {props.tooltip}
      </Tooltip>
    </div>
  );
}

export default TooltipComponenet;
