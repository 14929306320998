import React from "react";
import { Route, Switch } from "react-router-dom";

// Ecommerce Setting
import Catalog from "../views/Ecommerce/Catalog";
import EditCatalog from "../views/Ecommerce/Catalog/Edit";

import Settings from "../views/Settings/index";

//General Settings
import CountrySetting from "../views/Settings/Ecommerce/Country";
import LanguageSetting from "../views/Settings/Ecommerce/Language";

//CRM Setting
import CustomerTypes from "../views/Settings/CustomerType";

//Order Managment Setting
import CartSetting from "views/Settings/Order/Cart";
import OrderFlowSetting from "views/Settings/Order/OrderFlow";
import MapSetting from "views/Settings/Order/Map";
import CarrierSetting from "views/Settings/Order/Carrier";
import PaymentSetting from "../views/Settings/Order/Payment";
import DeliverySetting from "../views/Settings/Order/Delivery";
import PickupSetting from "../views/Settings/Order/Pickup";

const settingRoutes = () => {
  const currentPath = "/setting";
  return (
    <div>
      <Switch>
        <Route path={`${currentPath}/catalog`} component={Catalog} />
        <Route path={`${currentPath}/edit_catalog`} component={EditCatalog} />

        {/* <Route path={`${currentPath}/`} component={Settings} /> */}
        <Route path={`${currentPath}/index`} component={Settings} />
        <Route path={`${currentPath}/country`} component={CountrySetting} />
        <Route path={`${currentPath}/language`} component={LanguageSetting} />

        <Route
          path={`${currentPath}/customer_type`}
          component={CustomerTypes}
        />

        <Route path={`${currentPath}/cart`} component={CartSetting} />

        <Route path={`${currentPath}/payment`} component={PaymentSetting} />
        <Route path={`${currentPath}/delivery`} component={DeliverySetting} />
        <Route path={`${currentPath}/pickup`} component={PickupSetting} />
        <Route
          path={`${currentPath}/carrier&cost`}
          component={CarrierSetting}
        />

        <Route
          path={`${currentPath}/order-flow`}
          component={OrderFlowSetting}
        />

        <Route path={`${currentPath}/map`} component={MapSetting} />
      </Switch>
    </div>
  );
};

export default settingRoutes;
