import React, { useEffect, useState, PropTypes } from "react";
import { connect } from "react-redux";
import {
  getProducts,
  getAllProducts,
  deleteProduct,
  setProduct,
  searchProducts
} from "../../../redux/actions/Ecommerce/ProductAction";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";
import LoadingComponent from "../../../components/general/Loading";
import PaginationComponent from "../../../components/general/Pagination";
import AddProduct from "./Add";
import View from "./View";

const Index = (props) => {

  const [isLoading,setIsLoading] = useState(false)
  const [productsList,setProductsList] = useState([])
  const [pageCounter,setPageCounter] = useState(1)
  const [productTotal,setProductTotal] = useState(0)
  const [searchInput, setSearchInput] = useState("")
  const [filteredList,setFilteredList] = useState([])

  useEffect(() => {
    //props.getProducts(100, 1);
    if(productsList.length !== 0) return;
    getProductsList()
  }, [props.products]);

  useEffect(() => {
    if(searchInput == "") return;

    getFilteredList()
    
  }, [props.filteredList, searchInput]);

  useEffect(() => {
    //props.getAllProducts()
    getProductsList()
  }, [isLoading, pageCounter]);

  const updatePageCounter = (page) => {
    console.log("the page is ")
    console.log(page)

      setPageCounter(page+1)
  }

  const onChngeSearchInput = (input) => {
   
      setSearchInput(input)
  }

  const onsubmitSearch = async () => {
    //call search api and set the filtered products
    try {
      const body = {
        "sku":searchInput
      }
      let response = await props.searchProducts(JSON.stringify(body));
      console.log(response);

      setFilteredList(props.filteredList.products)

    } catch (err) {
      console.log(err);
    }
    finally{
    }
  }

  const getProductsList = () => {
    setIsLoading(true)

    try {
      if(productsList.length === 0 ){
        props.getProducts(1000, 1)
        setProductTotal(props.products.count)
        setProductsList(props.products.products)
        setIsLoading(false)
      }
      else {
        //props.getAllProducts()
        props.getProducts(200, pageCounter)
        setProductTotal(props.products.count)
        setProductsList([...props.products.products,...props.products.products] )
        setIsLoading(false)
      }
       
       //setIsLoading(false)
    } catch (err) {
      console.log(err);
    }finally {
      setIsLoading(false)
    }
  };

  const getFilteredList = () => {

    setFilteredList(props.filteredList?props.filteredList.products:[])
      
  
       
   
  };

  const deleteProduct = (productID) => {
    try {
      let response = props.deleteProduct(productID);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  // To be used later instead of importing <AddProduct /> to be consistant.
  const handleCreate = (e) => {
    e.preventDefault();
    // NOTE:
    // A workaround soltuion for this is,
    // change modal state to true, and send it to the <AddProduct /> component as prop
  };

  const handleEdit = (item, id) => {
    props.setProduct(item);
    props.history.push({
      pathname: `/edit_product`,
      state: {
        id: id,
        item: item,
      },
    });
  };

  return (
    <div>
      {!isLoading && props.products ? (
        <>
          <View
            {...props}
            products={productsList}
            productTotal={productTotal}
            deleteProduct={deleteProduct}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            pageCounter={pageCounter}
            updatePageCounter={updatePageCounter}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            onsubmitSearch={onsubmitSearch}
          />
        </>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

Index.propTypes = {};

const mapStateToProps = (state) => ({
  products: state.products.items,
  filteredList: state.products.filtered_products
});

export default connect(mapStateToProps, {
  getProducts,
  deleteProduct,
  setProduct,
  getAllProducts,
  searchProducts
})(Index);
