import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";
import _ from "lodash";

import {
  deleteCatalog,
  editCatalog,
} from "redux/actions/Ecommerce/CatalogAction";
import { getCountries } from "redux/actions/Ecommerce/settings/countryAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";

const Index = (props) => {
  let catalog = props.location.state.item;
  console.log(catalog);
  catalog.status = _.toLower(catalog.status) === "active" ? true : false;
  let [state, setState] = useState(catalog);

  useEffect(() => {}, [props.catalog]);
  useEffect(() => {
    props.getCountries();
  }, []);

  const deleteCatalog = (catalogId) => {
    try {
      props.deleteCatalog(catalogId);
      SuccessAlert("success", "Sucessfully deleted");
      props.history.push("catalog");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const updateState = (input) => {
    console.log("Input", input);
    setState({ ...state, ...input });
  };
  const addCurrency = () => {
    let { currencies } = state;
    console.log(currencies);
    currencies.push({
      _id: "",
      status: false,
      name_en: "",
      name_ar: "",
      factor: 1,
      is_default: false,
    });
    setState({ ...state, currencies: currencies });
  };

  const deleteCurrency = (index) => {
    let { currencies } = state;
    currencies = _.filter(currencies, (item, key) => key !== index);
    setState({ ...state, currencies: currencies });
  };

  const editCatalog = async () => {
    try {
      state.status = state.status === true ? "active" : "inactive";

      let response = props.editCatalog(JSON.stringify(state), state._id);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log("State", state);

  return props.countries ? (
    <div>
      {" "}
      <View
        {...state}
        data={state}
        {...props}
        deleteCatalog={deleteCatalog}
        editCatalog={editCatalog}
        onChange={updateState}
        addCurrency={addCurrency}
        deleteCurrency={deleteCurrency}
      />{" "}
    </div>
  ) : (
    <div></div>
  );
};
const mapStatToProps = (state) => ({
  countries: state.countries.items,
});
export default connect(mapStatToProps, {
  deleteCatalog,
  editCatalog,
  getCountries,
})(Index);
