import React, { PropTypes } from "react";
import View from "./View";

const Index = (props) => {
  return (
    <div>
      <View {...props} />
    </div>
  );
};

Index.propTypes = {};

export default Index;
