import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Table from "../../../../components/general/Table";
import Card from "../../../../components/general/Card";
import HeaderActions from "../../../../components/general/HeaderBtns";
import CollapseList from "components/general/PageDetails/CollapseList";
import Delete from "components/general/Delete";
import _ from "lodash";
import { addCountry } from "redux/actions/Ecommerce/settings/countryAction";
import AddCountry from "./Add";

// FIXME: Status has issues
const View = (props) => {
  let { deleteCountry, editCountry } = props;
  console.log(props);

  let [countries, setCountries] = useState(props.countries);

  let countryData = [];
  _.map(countries, (data, index) => {
    data.status = data.status === "active" ? true : false;
    let item = {
      id: index,
      label: `Country ${data.name_en}`,
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          type: "switch",
          id: `switch${index}`,
          name: "status",
          label: "Activate Country",
          classFormGroup: "d-flex",
          classLabel: "mr-3",
          value: data["status"],
          default: data["status"],
        },
        {
          type: "text",
          name: "_id",
          label: "Country ID",
          value: data["_id"],
          default: data["_id"],
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
          value: data["name_en"],
          default: data["name_en"],
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          system_name: "name_ar",
          type: "text",
          value: data["name_ar"],
          default: data["name_ar"],
        },
      ],
    };
    countryData.push(item);
  });

  const updateState = (input) => {
    console.log("Input", input);
    setCountries([...input.countries]);
  };

  console.log("Counters", countries);

  return (
    <div className="setting">
      <Card name="Country Setting" goBack={props.history.goBack}>
        <div className="contact-table">
          {/* Add Country */}
          <div className="d-flex justify-content-end">
            <AddCountry />
          </div>

          <Col xs="12" lg="8" xl="9" className="group-list">
            {_.map(countryData, (group, key) => (
              <CollapseList
                {...props}
                group={group}
                usingFormContollerList={true}
                {...countries}
                target={countries[key]}
                targetName="countries"
                collapseCase={group.collapseCase}
                name="name"
                updateState={updateState}
              >
                {/* Language Actions */}
                <div className="row justify-content-end">
                  <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                    <Button
                      className="btn btn-primary btn-cons btn-cons"
                      onClick={() =>
                        editCountry(countries[key]._id, countries[key])
                      }
                    >
                      {"Save"}
                    </Button>

                    <Delete
                      data={group.label}
                      onSubmit={deleteCountry}
                      customerID={countries[key]._id}
                      actionType="button"
                      buttonStyle="btn btn-white btn-cons"
                    />
                  </div>
                </div>
              </CollapseList>
            ))}
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default View;
