import {
    ADD_PICKUP,
    EDIT_PICKUP,
    DELETE_PICKUP,
    GET_PICKUPS,
    FIND_PICKUP,
  } from "../../../actions/settings/types";
  
  const initialState = {
    item: null,
    items: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case EDIT_PICKUP:
      case FIND_PICKUP:
      case DELETE_PICKUP:
        return {
          ...state,
          item: action.payload,
        };
      case GET_PICKUPS:
        return {
          ...state,
          items: action.payload,
        };
      case ADD_PICKUP:
        return {
          ...state,
          item: action.payload,
          items: [...state.items, action.payload],
        };
      default:
        return state;
    }
  }
  