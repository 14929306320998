import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getGroups } from "../../../../redux/actions/Ecommerce/GroupAction";
import { addAttribute } from "../../../../redux/actions/Ecommerce/AttributeAction";
import { attributeTypes } from "../../../../seeder/attributeTypes";
import AddAttributeView from "./View";
import { stringToSelectObject } from "../../../../helpers/general-functions";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";

const initialState = {
  //business_id: "5cc2f00577ebc157b4f1c64c",
  label: "",
  arabic_label: "",
  name_en: "",
  name_ar: "",
  description_en: "",
  description_ar: "",
  type: "",
  typeCase: "",
  group_id: "",
  inner_name: "",
  system_name: "",
  options: [
    {
      value: "",
      label: "",
      arabic_label: "",
      is_default: true,
    },
  ],
  is_required: true,
  in_form: false,
  to_search: false,
  class: "custom",
  errors: null,
  modal: false,
};
class index extends Component {
  state = initialState;

  componentWillMount() {
    //const business_id = `business_id: "5cc2f00577ebc157b4f1c64c"`;
    this.props.getGroups("crm");
  }

  reset() {
    this.setState(initialState);
  }

  onAddOption = (options) => {
    this.setState({ options });
  };

  onClickAddOption(e) {
    e.preventDefault();
  }

  onChange = (e) => {
    this.setState(e);
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  /*OptionsToGraphQL = () => {
    let data = JSON.stringify(this.state.options);
    data = data.replace(/"([^(")"]+)":/g, "$1:");
    return data;
  };*/

  getAttribute = () => {
    console.log("Inside Get Attribute");
    //let options = this.OptionsToGraphQL();
    let option = _.isEmpty(_.head(this.state.options).label)? [] : this.state.options
    return {
    //business_id: this.state.business_id,
    group_id: this.state.group_id,
    label: this.state.name_en,
    name_en: this.state.name_en,
    name_ar: this.state.name_ar,
    system_name: this.state.inner_name,
    inner_name: `attr_#${this.state.type}_#${this.state.inner_name}`,
    path: `attr_#${this.state.type}_#${this.state.inner_name}`,
    description_en: this.state.description_en,
    description_ar: this.state.description_ar,
    is_required: this.state.is_required,
    in_form: this.state.in_form,
    to_search: this.state.to_search,
    updated_by: "ux-automation",
    option: option,
    class: this.state.class,
    type: this.state.type,
    typeCase: this.state.typeCase,
    indexing_for: "crm",
    last_update: new Date(),
    }
  };

  validateInputs = (e) => {
    e.preventDefault();
    console.log(this.state);
    let {
      inner_name,
      label,
      description_en,
      description_ar,
      arabic_label,
      type,
      options,
      business_id,
      group_label,
      is_required,
      to_search,
      in_form,
    } = this.state;
    if (
      inner_name === "" ||
      arabic_label === "" ||
      type === "" ||
      this.state.class === ""
    ) {
      //ErrorAlert("error", "inner name , arabic label, type and class are required inputs")
      let errors = [];
      errors.push(
        "inner name , arabic label, type and class are required inputs"
      );
      this.setState(errors);
    } else {
      this.addAttribute();
    }
  };

  onValidate = (e) => {
    e.preventDefault();
    let errors = [];
    let inputs = this.state;
    if (
      !inputs.label ||
      inputs.inner_name === "" ||
      inputs.arabic_label === ""
    ) {
      // errors.required = 'Pleae fill required inputs';
      errors.push("Pleae fill required inputs");
      this.setState({ errors: errors });
    }
    if (inputs.group_id === "" || inputs.type === "") {
      errors.push("have to select a group & type for attribute");
      this.setState({ errors: errors });
    } else {
      this.addAttribute(e);
    }
  };

  addAttribute = (e) => {
    e.preventDefault();

    const attribute = this.getAttribute();
    try {
      this.props.addAttribute(JSON.stringify(attribute));
      SuccessAlert("success", "Sucessfully Added");
    } catch (error) {
      ErrorAlert("error", `Something went wrong! ${error}`);
    }
    this.reset();
    this.toggle();
  };

  render() {
    return (
      <div>
        <AddAttributeView
          onChange={this.onChange}
          data={this.state}
          groupList={this.props.groups}
          attributeTypes={attributeTypes}
          onChangeSelect={this.onChangeSelect}
          onChangeCheckbox={this.onChangeCheckbox}
          addAttribute={this.addAttribute}
          options={this.state.options}
          onAddOption={this.onAddOption}
          onClickAddOption={this.onClickAddOption}
          validateInputs={this.validateInputs}
          onValidate={this.onValidate}
          errors={this.state.errors}
          modal={this.state.modal}
          toggle={this.toggle}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: state.ecommerceGroups.items,
  attribute: state.attribute.items,
});

export default connect(mapStateToProps, { getGroups, addAttribute })(index);
