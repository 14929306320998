import React from "react";

const NotFound = () => {
  return (
    <div className="my-5 pt-5">
      <img
        src="/assets/img/NotFound.png"
        alt="{'Loading...'}"
        className="d-block m-auto w-25"
      />
    </div>
  );
};

export default NotFound;
