import React, { useState } from "react";
import CollapseList from "components/general/PageDetails/CollapseList";
import EditGroup from "../EditGroup";

const Index = (props) => {
  const [modalEdit, setModalEdit] = useState(false);
  const [group, setGroup] = useState(props.group);

  const toggleEditGroup = (group) => {
    console.log("in close");
    setModalEdit(!modalEdit);
    if (group) setGroup(group);
    else setGroup(props.group);
    console.log("Group Modla Edit ", group);
  };

  return (
    <div className="attribute-list">
      <CollapseList
        {...props}
        group={props.group}
        groupAction={true}
        items={props.group.attributes}
        collapseCase="table"
        EDIT_URL={"ecommerce/edit_attribute"}
        isSelectRow={true}
        noFilter={true}
        toggleEditGroup={toggleEditGroup}
      />
      <EditGroup group={group} modal={modalEdit} toggle={toggleEditGroup} />
    </div>
  );
};

Index.propTypes = {};

export default Index;
