import {
  ADD_COUNTRY,
  EDIT_COUNTRY,
  DELETE_COUNTRY,
  GET_COUNTRIES,
  FIND_COUNTRY,
} from "../../../actions/Ecommerce/types";

const initialState = {
  item: null,
  items: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_COUNTRY:
    case FIND_COUNTRY:
    case DELETE_COUNTRY:
      return {
        ...state,
        item: action.payload,
      };
    case GET_COUNTRIES:
      return {
        ...state,
        items: action.payload,
      };
    case ADD_COUNTRY:
      return {
        ...state,
        item: action.payload,
        items: [...state.items, action.payload],
      };
    default:
      return state;
  }
}
