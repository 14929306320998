import React, { Component } from "react";
import {
  getSegmentaions,
  getSegmentaionsTemplates,
  deleteSegmentation,
} from "../../../../redux/actions/SegmentationActions";
import View from "./View";
import { connect } from "react-redux";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
//import { business_id } from "../../../context/url"
const business_id = "5cc2f00577ebc157b4f1c64c";

//function Index(props) {
class index extends Component {
  componentDidMount() {
    this.props.getSegmentaions(business_id);
    //this.props.getSegmentaionsTemplates(business_id);
  }

  deleteSegment = (segmentId) => {
    try {
      let response = this.props.deleteSegmentation(segmentId);

      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  handleCreate = (e) => {
    e.preventDefault();
    this.props.history.push("/crm/new_segment");
  };

  render() {
    console.log(this.props.segmentations);
    //console.log(this.props.templates)

    return (
      <div>
        <View
          {...this.props}
          deleteSegment={this.deleteSegment}
          handleCreate={this.handleCreate}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  segmentations: state.segmentations.items,
  //templates: state.segmentations.templates
});

export default connect(mapStateToProps, {
  getSegmentaions /*, getSegmentaionsTemplates*/,
  deleteSegmentation,
})(index);
