//import React, { useState, useEffect, useContext } from "react";
import React, { useState } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addPickup } from "redux/actions/settings/Order/pickup";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
  let [state, setState] = useState({
    storeId: "61212d9bbb3af02d3f72b2ce",
    isActive: true,
      nameEN: "",
      nameAR: "",
      channels: ["61212d9bbb3af02d3f72b3ce"],
      coverageArea: "",
      maximumOptions :"",
    errors: {
      nameError: "",
    },
  });

  let [modal, setModal] = useState(false);

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const reset = () => {
    setState(state);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = state;

    if (inputs.nameEN === "" || inputs.nameAR === "") {
      errors.nameError = "Sorry, 'Name' in both pickups are required!";
    }

    if (errors.nameError === "") {
      addPickup(e);
    } else {
      setState({ errors: errors });
    }
  };

  const addPickup = () => {
    console.log(" addPickup", state);
    let pickup = state;
    delete pickup.errors;
   
    pickup.maximumOptions = _.toNumber(pickup.maximumOptions)

    console.log(JSON.stringify(pickup));

    try {
      props.addPickup(JSON.stringify(pickup));
      console.log(" addPickup", pickup);
      SuccessAlert("success", "Sucessfully Added");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    reset();
    toggle();
  };

  return (
    <View
      data={state}
      catalogs={props.catalogs}
      onChange={onChange}
      onSumbit={addPickup}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
    />
  );
};

// const mapStateToProps = (state) => ({
//   countries: state.countries.items,
// });

export default connect(null, { addPickup })(Index);
