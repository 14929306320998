import React, { useState, useEffect } from "react";
import View from "./View";
import { connect } from "react-redux";
import { singup } from "redux/actions/Auth/user";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
  let [state, setState] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    storeName: "",
    errors: {
      nameError: "",
    },
  });

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = state;

    if (
      inputs.email === "" ||
      inputs.password === "" ||
      inputs.firstName === "" ||
      inputs.lastName === ""
    ) {
      errors.nameError = "Sorry, This field is required!";
    }

    if (errors.nameError === "") {
      sumbit(e);
    } else {
      setState({ errors: errors });
    }
  };

  const handleSwitchToLogin = (e) => {
    e.preventDefault();
    props.history.push("/login");
  };


  const sumbit = (e) => {
    e.preventDefault();
    let user = `{
        "email": "${state.email}",
        "firstName": "${state.firstName}",
        "lastName": "${state.lastName}",
        "phone": "${state.phone}",
        "password": "${state.password}",
        "storeName":"${state.storeName}"
      }`;

    console.log(user);

    try {
      props.singup(user);
      SuccessAlert("success", "Sucessfully Added");
      //props.history.push("/crm/contacts");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  return (
    <View
      data={state}
      onChange={onChange}
      onSumbit={sumbit}
      onValidate={onValidate}
      handleSwitchToLogin={handleSwitchToLogin}
      errors={state.errors}
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, { singup })(Index);
