import {
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAILURE,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGIN_GOOGLE_SUCCESS,
  USER_LOGOUT,
} from "./types";
import fetchAPIClass from "../fetchAPI";
import {
  restfulUrl,
  API_URL,
  General_URL,
  AUTH_ORDER_TEMP_URL,
} from "../../../context/url";

export const login = (inputs) => (dispatch) => {
  let requestBody = inputs;
  // let url = `${General_URL}/auth-service/api/uxauth/signin`;
  let url = `${AUTH_ORDER_TEMP_URL}/api/uxauth/signin`;
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, USER_LOGIN_SUCCESS);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_LOGIN_FAILURE,
    });
  }
};

export const loginWithGoogle = (inputs) => (dispatch) => {
  let requestBody = inputs;
  // let url = `${General_URL}/auth-service/api/uxauth/signup-google`;
  let url = `${AUTH_ORDER_TEMP_URL}/auth-service/api/uxauth/signup-google`;
  try {
    const api = new fetchAPIClass(
      url,
      "POST",
      requestBody,
      USER_LOGIN_GOOGLE_SUCCESS
    );
    let response = api.fetchAPI(dispatch);
    // console.log(response);
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_LOGIN_FAILURE,
    });
  }
};

export const singup = (inputs) => (dispatch) => {
  let requestBody = inputs;
  // let url = `${General_URL}/auth-service/api/uxauth/signup`;
  let url = `${AUTH_ORDER_TEMP_URL}/api/uxauth/signup`;
  try {
    const api = new fetchAPIClass(
      url,
      "POST",
      requestBody,
      USER_REGISTER_SUCCESS
    );
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_REGISTER_FAILURE,
    });
  }
};

export const logout = () => {
  return {
    type: USER_LOGOUT,
  };
};

// export const editBranch = (inputs, branchId) => (dispatch) => {
//   let requestBody = inputs;
//   let url = `${API_URL}/ecommerce/branch/edit/${branchId}`;
//   console.log(requestBody);
//   console.log(branchId);

//   try {
//     const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_BRANCH);
//     let response = api.fetchAPI(dispatch);
//     console.log(response);
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const findBranch = (branchId) => (dispatch) => {
//   let url = `${API_URL}/ecommerce/branch/find/${branchId}`;
//   try {
//     const api = new fetchAPIClass(url, "GET", null, FIND_BRANCH);
//     api.fetchAPI(dispatch);
//   } catch (err) {
//     console.log(err);
//   }
// };
