//import React, { useState, useEffect, useContext } from "react";
import React, { useState } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addCustomerType } from "redux/actions/settings/customerTypeAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
  let [state, setState] = useState({
    business_id: "5cc2f00577ebc157b4f1c64c",
    name_en: "",
    name_ar: "",
    errors: {
      nameError: "",
    },
  });

  let [modal, setModal] = useState(false);

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const reset = () => {
    setState(state);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = state;

    if (inputs.name_en === "" || inputs.name_ar === "") {
      errors.nameError = "Sorry, 'Name' in both customerTypes are required!";
    }

    if (errors.nameError === "") {
      addCustomerType(e);
    } else {
      setState({ errors: errors });
    }
  };

  const addCustomerType = () => {
    console.log("addCustomerType", state);
    let customerType = `{
      "business_id": "${state.business_id}",
      "name_ar": "${state.name_ar}",
      "name_en": "${state.name_en}"
    }`;

    console.log(customerType);

    try {
      props.addCustomerType(customerType);
      SuccessAlert("success", "Sucessfully Added");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    reset();
    toggle();
  };

  return (
    <View
      data={state}
      onChange={onChange}
      onSumbit={addCustomerType}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
    />
  );
};

 
export default connect(null, { addCustomerType })(Index);
