import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Card from "../../../../components/general/Card";
import Collapse from "components/general/Collapse";
import Delete from "components/general/Delete";
import _ from "lodash";
import AddCart from "./Add";
import FormComponent from "components/general/FormControllerList";
import { Alert } from "reactstrap";

const View = (props) => {
  let { deleteCart, editCart, catalogs, carts, customerTypes } = props;

  let [state, setCarts] = useState(carts);
  let [errors, setError] = useState({
    nameError: "",
  });

  const onChange = (input) => {
    console.log("Input", input);
    setCarts({ ...state, ...input });
  };

  console.log(props);
  let cartFunctions = [
    { id: "1", name: "canCheckout", label: "Checkout" /*value:{}*/ },
    { id: "2", name: "canGuestCheckout", label: "Guest Checkout" /*value:{}*/ },
    {
      id: "3",
      name: "canRequestQuotation",
      label: "Request Quotation" /*value:{}*/,
    },
    {
      id: "4",
      name: "canAppliedCoupons",
      label: "Applied Coupons" /*value:{}*/,
    },
    { id: "5", name: "canEditQuentity", label: "Edit Quantity" /*value:{}*/ },
    { id: "6", name: "canRemoveProduct", label: "Remove Product" /*value:{}*/ },
    {
      id: "7",
      name: "canMoveToWishlist",
      label: "Move to WishList" /*value:{}*/,
    },
  ];

  return (
    <div className="setting">
      <Card name="Cart Setting" goBack={props.history.goBack}>
        <div className="contact-table">
          {/* Add Cart */}
          <div className="d-flex justify-content-end">
            <AddCart {...props} />
          </div>

          <Col xs="12" lg="8" xl="9" className="group-list">
            {_.map(carts, (data, key) => (
              <Collapse
                group={{
                  label: `${data.nameEN}`,
                  id: key,
                  open: false,
                }}
              >
                <FormComponent
                  type="switch"
                  id={`status${key}`}
                  name="isActive"
                  label="Activation"
                  target={carts[key]}
                  targetName="carts"
                  value={data.isActive}
                  updateState={onChange}
                  error={_.get(errors, "nameError")}
                />
                <FormComponent
                  type="text"
                  name="nameEN"
                  label="Cart English Name"
                  target={carts[key]}
                  targetName="carts"
                  value={data.nameEN}
                  updateState={onChange}
                  tooltip={
                    "Refer to the ID of the cart and it’s used for a tag this cart with channel and it’s not editable by the business user"
                  }
                  error={_.get(errors, "nameError")}
                />
                {!_.isEmpty(_.get(errors, "nameError")) && (
                  <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                )}

                <FormComponent
                  type="text"
                  name="nameAR"
                  label="Cart Arabic Name"
                  target={carts[key]}
                  targetName="carts"
                  value={data.nameAR}
                  updateState={onChange}
                  error={_.get(errors, "nameError")}
                />
                {!_.isEmpty(_.get(errors, "nameError")) && (
                  <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                )}

                <FormComponent
                  type="groupCheckbox"
                  options={_.map(catalogs, (catalog) => ({
                    label: _.get(catalog, "name_en"),
                    value: _.get(catalog, "_id"),
                  }))}
                  name="channels"
                  label="Channel"
                  value={data.channels}
                  target={carts[key]}
                  targetName="carts"
                  valueKey="value"
                  labelKey="label"
                  updateState={onChange}
                />

                <FormComponent
                  type="groupCheckbox"
                  options={_.map(customerTypes, (type) => ({
                    label: _.get(type, "name_en"),
                    value: _.get(type, "_id"),
                  }))}
                  name="customerTypes"
                  label="Customer Type (Personalized Cart)"
                  value={data.customerTypes}
                  target={carts[key]}
                  targetName="carts"
                  valueKey="value"
                  labelKey="label"
                  updateState={onChange}
                />

                <FormComponent
                  type="switch"
                  id={`isIndependentCartForEveryBranch${key}`}
                  name="isIndependentCartForEveryBranch"
                  target={carts[key]}
                  targetName="carts"
                  label="Independent Cart For every Store"
                  value={data.isIndependentCartForEveryBranch}
                  updateState={onChange}
                />

                <FormComponent
                  type="switch"
                  id={`canPersist${key}`}
                  name="canPersist"
                  target={carts[key]}
                  targetName="carts"
                  label="Persistence Cart"
                  value={data.canPersist}
                  updateState={onChange}
                />

                <FormComponent
                  type="text"
                  name="persistenceLifetimeInDays"
                  target={carts[key]}
                  targetName="carts"
                  label="Persestence LifeTime"
                  value={data.persistenceLifetimeInDays}
                  updateState={onChange}
                  tooltip={
                    "Refer to the number of days that cart will be saved and the business user will be required to set up this"
                  }
                />

                <FormComponent
                  type="select"
                  options={[{ label: "Login User", value: "Login User" }]}
                  name="persistenceStrategy"
                  label="Persestence Strategy"
                  value={data.persistenceStrategy}
                  target={carts[key]}
                  targetName="carts"
                  valueKey="value"
                  labelKey="label"
                  updateState={onChange}
                />
                <h6 style={{ fontWeight: "bold" }}>Cart Functions (Actions)</h6>
                {_.map(cartFunctions, (item) => (
                  <FormComponent
                    type="switch"
                    id={`item${item.id}${key}`}
                    name={item.name}
                    label={item.label}
                    value={data[item.name]}
                    target={carts[key]}
                    targetName="carts"
                    updateState={onChange}
                  />
                ))}
                {/* Language Actions */}
                <div className="row justify-content-end">
                  <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                    <Button
                      className="btn btn-primary btn-cons btn-cons"
                      onClick={() => editCart(carts[key]._id, carts[key])}
                    >
                      {"Save"}
                    </Button>

                    <Delete
                      data={data.nameEN}
                      onSubmit={deleteCart}
                      customerID={carts[key]._id}
                      actionType="button"
                      buttonStyle="btn btn-white btn-cons"
                    />
                  </div>
                </div>
              </Collapse>
            ))}
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default View;
