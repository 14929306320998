import React from 'react'
import { FormGroup, Input } from "reactstrap";
const View = (props) => {
    return (
       
            <div className="search">
            <FormGroup>
              <Input
                className="form-control filter"
                type="text"
                id="search"
                placeholder="Search"
                value={props.searchInput}
                onChange={(input)=>props.setSearchInput(input)}
                style={{ zIndex: 0, width: "500px" }}
              />
            </FormGroup>
          </div>
        
    )
}
export default View