import {
  ADD_CONFIG_ATTRIBUTE,
  EDIT_CONFIG_ATTRIBUTE,
  EDIT_CONFIG_ATTRIBUTES,
  GET_CONFIG_ATTRIBUTES,
  FIND_CONFIG_ATTRIBUTE,
  DELETE_CONFIG_ATTRIBUTE,
  GET_FILTERED_ATTRIBUTES,
} from "../../actions/Ecommerce/types";

const initialState = {
  items: null,
  item: null,
  list: null,
};

export default function (state = initialState, action) {
  console.log("attributes action payload=>", action, state);
  switch (action.type) {
    case ADD_CONFIG_ATTRIBUTE:
    case EDIT_CONFIG_ATTRIBUTE:
    case DELETE_CONFIG_ATTRIBUTE:
    case FIND_CONFIG_ATTRIBUTE:
      return {
        ...state,
        item: action.payload,
      };
    case GET_CONFIG_ATTRIBUTES:
      return {
        ...state,
        items: action.payload,
      };
    case EDIT_CONFIG_ATTRIBUTES:
      return {
        ...state,
        list: action.payload,
      };
    case GET_FILTERED_ATTRIBUTES:
      console.log("get filtered attributes=>", action);
      const newState = {
        ...state,
        items: action.payload,
      };
      console.log("newState=>", newState);
      return newState;
    default:
      return state;
  }
}
