import React, { useState } from "react";

const FileUpload = ({ onFileUpload }) => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onFileUpload(file);
    setFileName(file.name);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    onFileUpload(file);
    setFileName(file.name);
  };

  return (
    <div
      className="file-upload"
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="file-upload-icon-container">
        <svg
          className="file-upload-icon"
          fill="black"
          xmlns="http://www.w3.org/2000/svg"
          width={50}
          height={50}
          viewBox="0 0 20 20"
        >
          <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
      </div>
      <div className="file-upload-content">
        <p className="file-upload-title">
          <span>Drag and drop or choose a file to upload your contacts.</span>
        </p>
        <p className="file-upload-title">
          All .csv, .xlsx, and .xls files types are supported.
        </p>
        {fileName && <p>Uploaded file: {fileName}</p>}
      </div>
      <input
        type="file"
        onChange={handleFileChange}
        id="bulk-upload-comp"
        className="file-upload-input"
      />
    </div>
  );
};

export default FileUpload;
