import React, { useState, useEffect } from "react";
import Select from "components/order-flow/components/select/Index";
import CheckIcon from "components/import-export/assets/CheckIcon";
import TimesIcon from "components/import-export/assets/TimesIcon";
import {Input} from "reactstrap"
import _ from "lodash"
import Loading from "components/general/Loading";


const CriteriaStep = ({ uploadedFile, onFileUpload,
  selectedType,
  isChecked,
  setIsChecked,
  handleFilters,
  filters,
   ...props }) => {
  console.log("criteria props props=>", props);

  // const {
  //   headers: initialHeaders,
  //   unmatchedHeaders: initialUnmatchedHeaders,
  //   selected_attributes,
  //   file_headers,
  //   preview,
  // } = uploadedFile;
  const {attributes, categories, brands,catalogs, isLoading, setIsLoading, isLoadingCustomers, setIsLoadigCustomers} = props

  const attributeOptions = attributes.map((attribute) => ({
    value: attribute.system_name,
    label: attribute.name_en,
    id: attribute.id,
  }));

  const [selectedValues, setSelectedValues] = useState({});
  const [headers, setHeaders] = useState([]/*initialHeaders*/);
  const [unmatchedHeaders, setUnmatchedHeaders] = useState(
    //initialUnmatchedHeaders
    []
  );

  useEffect(() => {
    if(!selectedType) return;

    if(props.attributes) setIsLoading(false)

  }, [props.attributes]);

  useEffect(() => {
    if(!selectedType) return;
    if(selectedType !== "Contact") return;


    if(props.customers) setIsLoadigCustomers(false)

  }, []);

  useEffect(() => {
    if(!selectedType) return;
    if(selectedType !== "Product") return;

    if(props.products) setIsLoadigCustomers(false)

  }, [props.products]);

  const handleSelectChange = (header, value) => {
    console.log("handle select")
    console.log({header,value})
    // Update selected values
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [header.system_name]: value,
    }));
    handleFilters((prevValues) => ({
      ...prevValues,
      [header.system_name]: _.isArray(value)?_.map(value, v => v.value): value,
    }));
    

    // Update headers and unmatched headers
    // if (value) {
    //   // Add header to matched headers
    //   setHeaders((prevHeaders) => [
    //     ...prevHeaders,
    //     { header: header.system_name, attribute: { name: value.value, id: value.id } },
    //   ]);

    //   // Remove header from unmatched headers
    //   setUnmatchedHeaders((prevUnmatchedHeaders) =>
    //     prevUnmatchedHeaders.filter((h) => h !== header.system_name)
    //   );
    // } else {
    //   // Remove header from matched headers
    //   setHeaders((prevHeaders) =>
    //     prevHeaders.filter((h) => h.header !== header.system_name)
    //   );

    //   // Add header to unmatched headers
    //   setUnmatchedHeaders((prevUnmatchedHeaders) => [
    //     ...prevUnmatchedHeaders,
    //     header.system_name,
    //   ]);
    // }
  };

  const getAttributesOptions = (header) => {
    if(header.system_name === "categories") {
      return categories.map((item) => ({
        value: item._id,
        label: item.name_en,
        id: item._id,
      }))
    }
    else if (header.system_name === "brand_id") {
      return brands.map((item) => ({
        value: item._id,
        label: item.name_en,
        id: item._id,
      }))
    }

    else if (header.system_name === "catalogs") {
      return catalogs.map((item) => ({
        value: item._id,
        label: item.name_en,
        id: item._id,
      }))
    }
    else 
    return header.option.map((opt) => ({
      value: opt.value,
      label: opt.label,
      id: opt.id,
    }))

  }
  // useEffect(() => {
  //   // Create new object with updated data
  //   const updatedData = {
  //     store_id: "61212d9bbb3af02d3f72b2ce",
  //     headers: headers,
  //     unmatchedHeaders: unmatchedHeaders,
  //     //file_headers: file_headers,
  //     //filePaths: uploadedFile.filePaths,
  //     //invalidRows: uploadedFile.invalidRows,
  //     selectedType: props.selectedType,
  //   };

  //   // Pass updated data to parent component or function
  //   props.handleUpdate(updatedData);
  // }, [headers, unmatchedHeaders]);

  return (
    <div className="map-step">
      <h2 className="map-step-title">
      Seeting Your Export File Criteria
      </h2>
      <p className="upload-step-subtitle">
       Search for segement or tag or you can build a new criteria. If you would like to get all list left empty{" "}
       
      </p>
      {isLoading && (
        <Loading />
      )}

      {!isLoading && (
              <div className="table-container">
              <table className="map-step-table">
                <thead>
                  <tr>
                    <th>MATCHED</th>
                    {/* <th>COLUMN HEADERS FROM FILE</th>
                    <th>PREVIEW program INFORMATION</th> */}
                    <th>UX-AUTOMATION PROPERTIES</th>
                  </tr>
                </thead>
                 <tbody>
                  {attributes.map((header, index) => {
                    // const matchedHeader = headers.find(
                    //   (item) => item.header === header
                    // );
                    // const isMatched = matchedHeader !== undefined;
                    // const matchedAttribute = isMatched
                    //   ? attributeOptions.find(
                    //       (attribute) => attribute.id === matchedHeader.attribute.id
                    //     )
                    //   : null;
                    return (
                      <tr key={header.id}>
                        {/* <td>
                          {isMatched ? (
                            <div className="right-check-icon">
                              {" "}
                              <CheckIcon />{" "}
                            </div>
                          ) : (
                            <div className="wrong-icon">
                              <TimesIcon />{" "}
                            </div>
                          )}
                        </td> */}
                        <td>{header.name_en}</td>
                        {/* <td>{preview[0][index]}</td> */}
                        <td>
                          {/* Add dropdown menu here */}
                          <div className="select-attr-imp w-full">
                            {header.typeCase !== 0 && (
                              <Select
                              data={getAttributesOptions(header)}
                              isMulti={true}
                              allowSelectAll={true}
                              optionSelected={
                                selectedValues[header] //|| matchedAttribute
                              }
                              isDisabled={false}
                              setOptionSelected={(value) =>
                                handleSelectChange(header, value)
                              }
                            />
                            )}
                             {header.typeCase === 0 && (
                              <Input
                              type={header.type}
                              name={header.name_en}
                              onChange={(e) =>
                                handleSelectChange(header,e.target.value)
                                 }
                              value={selectedValues[header]}
                              placeholder={`write ${header.name_en}...`}
                              />
                             )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
        
      )}

    </div>
  );
};

export default CriteriaStep;
  
