//import React, { useState, useEffect, useContext } from "react";
import React, { useState, useEffect } from "react"
import View from "./View"
import { connect } from "react-redux"
import { addCatalog } from "../../../../redux/actions/Ecommerce/CatalogAction"
import { getCountries } from "../../../../redux/actions/Ecommerce/settings/countryAction"
import SuccessAlert from "../../../../components/general/SuccessAlert"
import ErrorAlert from "../../../../components/general/ErrorAlert"
import _ from "lodash"

const Index = (props) => {

  useEffect(() => {
    if(_.isEmpty(props.countries)) props.getCountries()
  }, [props.countries])

  let [state, setState] = useState({
    business_id: "5cc2f00577ebc157b4f1c64c",
    name_en: "",
    name_ar: "",
    country: "",
    //currency: [],
      taxName: "",
      percentage: null,
      //status: "active",
      show_product_inc_tax: true,
      cal_tax_with_service: true,
      currency_en: "",
      currency_ar: "",
      //currency_status: "active",
    
    ecommerce_id: "5efbacb731179ebef0823f86",
    errors: {
      nameError: "",
    },
  })

  let [modal, setModal] = useState(false)

  const onChange = (input) => {
    console.log("Input: ", input, _.get(input))
    setState({ ...state, ...input })
    console.log("State, ", state)
  }

  const reset = () => {
    setState(state)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const onValidate = (e) => {
    e.preventDefault()
    let errors = {
      nameError: "",
    }

    let inputs = state

    if (inputs.name_en === "" || inputs.name_ar === "") {
      errors.nameError = "Sorry, 'Name' in both languages are required!"
    }

    if (errors.nameError === "") {
      addCatalog(e)
    } else {
      setState({ errors: errors })
    }
  }

  const addCatalog = (e) => {
    e.preventDefault()
    console.log("addCatalog", state)
    let { taxName, percentage, /*status,*/ show_product_inc_tax, cal_tax_with_service , currency_en , currency_ar} = state
    let tax = `{ "name": "${taxName}", "percentage": ${_.toNumber(percentage)} , "show_product_inc_tax": ${show_product_inc_tax},"cal_tax_with_service": ${cal_tax_with_service}}`
    let currency = `{"name_en": "${currency_en}" , "name_ar": "${currency_ar}", "is_default": ${true}}`
    let catalog = `{
      "business_id": "${state.business_id}",
      "ecommerce_id": "${state.ecommerce_id}",
      "name_ar": "${state.name_ar}",
      "name_en": "${state.name_en}",
      "country": "${state.country}",
      "currencies": ${currency},
      "tax": ${tax}
    }`

    console.log(catalog)

    try {
      let response = props.addCatalog(catalog)
      SuccessAlert("success", "Sucessfully Added")
      console.log(response)
    } catch (err) {
      console.log(err)
      ErrorAlert("error", `Something went wrong! ${err}`)
    }
    reset()
    toggle()
  }
console.log(props.countries)
  return (
    <View
    {...props}
      data={state}
      onChange={onChange}
      onSumbit={addCatalog}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
    />
  )
}

const mapStateToProps = (state) => ({
  catalogs: state.catalogs.items,
  countries: state.countries.items
})

export default connect(mapStateToProps, { addCatalog , getCountries })(Index)
