import React, { useState, useContext } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import DeleteAttribute from "../GroupsList/DeleteAttribute";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-select";
import authContext from "../../../../context/auth";
import FormComponent from "../../../../components/general/FormController";
import Options from "../../../../components/general/Attribute/Options";
import {
  email,
  phone,
  Button,
  required,
} from "../../../../components/Validation";
import { Alert } from "reactstrap";
import _ from "lodash"

const View = (props) => {
  let {
    onChange,
    data,
    attributeTypes,
    editAttribute,
    errors,
    onValidate,
    handleDownloadExcel
  } = props;

  const [deleteModal, setDeleteModal] = useState(false);

  const toggleDeleteAttribute = () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <div id="contact-form">
      <div className="row">
        <div className="form-wrapper col-md-12">
          <div className="grid simple">
            <div className="grid-title">
              <h4
                className="cursor-pointer"
                onClick={() => props.history.goBack()}
              >
                <i className="fa fa-angle-left f-size-20" />
                {"Back to Profile Attribute"}
              </h4>
            </div>
            <div className="grid-body">
              <Form /*onSubmit={editAttribute}*/>
                <div className="row">
                  <div className="col-md-8">
                    <div className="modal-body">
                      <div className="row form-row">
                        <div className="col-lg-12">
                          <FormComponent
                            type="text"
                            name="name_en"
                            label="Enblish Label"
                            value={data.name_en}
                            updateState={onChange}
                            slug={false}
                          />
                           <FormComponent
                            type="text"
                            name="name_ar"
                            label="Arabic Label"
                            value={data.name_ar}
                            updateState={onChange}
                            slug={false}
                          />
                          {/*{this.state.repeatedError && (
                              <span className="form-error is-visible d-block m-b-10">
                                Name is already taken! Please change the
                                label.
                              </span>
                            )}*/}
                        </div>
                        <div className="col-lg-12">
                          <FormComponent
                            type="text"
                            name="inner_name"
                            label="Internal Name"
                            value={data.inner_name}
                            updateState={onChange}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="row form-row">
                        <div className="col-lg-12">
                          <FormComponent
                            type="textarea"
                            name="description_en"
                            label="English Description"
                            value={data.description_en}
                            updateState={onChange}
                          />
                        </div>
                      </div>
                      <div className="row form-row">
                        <div className="col-lg-12">
                          <FormComponent
                            type="textarea"
                            name="description_ar"
                            label="Arabic Description"
                            value={data.description_ar}
                            updateState={onChange}
                          />
                        </div>
                      </div>
                      <div className="row form-row">
                        {props.groupList ? (
                          <div className="col-lg-12">
                            <FormComponent
                              type="select"
                              options={props.groupList}
                              name="group_id"
                              label="Group Atributes"
                              value={data.group_id}
                              valueKey="_id"
                              labelKey="label"
                              updateState={onChange}
                              isDisabled={_.isEmpty(data.class) ? true : false}
                            />
                          </div>
                        ) : (
                          "Loading"
                        )}
                      </div>
                      <div className="row form-row">
                        <div className="col-lg-12 m-t-30">
                          <FormComponent
                            type="select"
                            options={attributeTypes}
                            name="type"
                            targetLabel="typeLabel"
                            label="Type"
                            value={data.type}
                            valueLabel={data.type}
                            valueKey="value"
                            labelKey="label"
                            optional="typeCase"
                            optionalKey="case"
                            updateState={onChange}
                            isDisabled={data.class === null ? true : false}
                          />
                        </div>
                      </div>

                      {/*checkbox Group options case 2 */}

                      {(data.typeCase === 2 || data.typeCase === 3) && (
                        <Options
                          onAddOption={props.onAddOption}
                          typeCase={data.typeCase}
                          options={props.options}
                          hasAddOption={true}
                          updateState={onChange}
                          handleDownloadExcel={handleDownloadExcel}
                        />
                      )}

                      {/* Single checkbox Option case 1 */}
                      {data.typeCase === 1 && (
                        <Options
                          onAddOption={props.onAddOption}
                          typeCase={data.typeCase}
                          options={props.options}
                          updateState={onChange}
                        />
                      )}

                      <div className="row form-row">
                        <div className="col-lg-12 m-t-30">
                          <FormComponent
                            type="singleCheckbox"
                            name="addContact"
                            label="Add to contact form"
                            value={data.in_form}
                            defaultChecked={data.in_form}
                            updateState={onChange}
                          />
                        </div>
                      </div>
                      <div className="row form-row">
                        <div className="col-lg-12">
                          <FormComponent
                            type="singleCheckbox"
                            name="to_search"
                            label="Make it searchable"
                            value={data.to_search}
                            defaultChecked={data.to_search}
                            updateState={onChange}
                          />
                        </div>
                      </div>
                      {errors && (
                        <Alert color="danger"> {errors.required} </Alert>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="btn-cta-group flex-center p-t-30">
                      <div className="submit-btn m-r-5">
                        <Button
                          /*type="submit"*/ onClick={onValidate}
                          className="btn btn-primary"
                        >
                          {"Save"}
                        </Button>
                      </div>
                      <div className="delete-cta">
                        <Button
                          type="button"
                          className="btn btn-secondary"
                          onClick={toggleDeleteAttribute}
                        >
                          {"Delete"}
                        </Button>
                        <DeleteAttribute
                          attr={data}
                          modal={deleteModal}
                          toggle={toggleDeleteAttribute}
                          {...props}
                        />
                      </div>
                    </div>
                    <div className="details m-t-50">
                      <div className="contact flex-center flex-column">
                        <h2 className="num bold">{"1"}</h2>
                        <h4 className="bold m-t-0">{"Contact with value"}</h4>
                        <span className="text-gray">
                          {"Out of 250 contact"}
                        </span>
                      </div>
                      <div className="segment flex-center flex-column m-t-30">
                        <h2 className="num bold">{"1"}</h2>
                        <h4 className="bold m-t-0">{"Segment"}</h4>
                      </div>
                      <div className="segment flex-center flex-column m-t-30">
                        <h2 className="num bold">{"1"}</h2>
                        <h4 className="bold m-t-0">{"Automation Rules"}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default View;
