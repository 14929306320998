import {
  ADD_SHIPMENT,
  EDIT_SHIPMENT,
  DELETE_SHIPMENT,
  GET_SHIPMENTS,
  FIND_SHIPMENT,
} from "./types";
import fetchAPIClass from "../fetchAPI";
import { restfulUrl, API_URL, SHIPPING_URL } from "context/url";

export const getShipments = (storeId) => (dispatch) => {
  let url = `${SHIPPING_URL}/store/${storeId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_SHIPMENTS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addShipment = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${SHIPPING_URL}`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_SHIPMENT);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteShipment = (shipmentId) => (dispatch) => {
  let url = `${SHIPPING_URL}/${shipmentId}/cancel`;
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_SHIPMENT);
    api.apifetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editShipment = (inputs, ShipmentId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${SHIPPING_URL}`;

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_SHIPMENT);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findShipment = (orderId) => (dispatch) => {
  let url = `${SHIPPING_URL}/order/${orderId}`;
  let data = "";
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_SHIPMENT);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
