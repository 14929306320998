import React from "react";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import Card from "../../../../components/general/Card";
import { Row, Col, Button } from "reactstrap";
//import Delete from "../../general/Delete"
import Delete from "../DeleteSegment";
import Table from "../../../../components/general/Table";
import TitleWithBtns from "../../../../components/general/PageDetails/HeaderInfo";
import _ from "lodash";
import CollabseComponent from "../../../../components/general/PageDetails/CollabseComponent";

const View = (props) => {
  let generalInfoInput = [
    {
      type: "switch",
      name: "status",
      id: "status",
      label: "Segmentation Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: _.toLower(props.data.status) === "active" ? true : false,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "Segment ID",
      value: props.data["_id"],
      default: props.data["_id"],
      disabled: true,
    },
  ];
  const detailsInfo = [
    {
      id: 0,
      label: "Summary",
      open: true,
    },
    {
      id: 1,
      label: "Information",
      open: true,
    },
    {
      id: 2,
      label: "Algorithm",
      open: false,
    },
    {
      id: 3,
      label: "Journey",
      open: false,
    },
    {
      id: 4,
      label: "Users",
      open: false,
    },
  ];

  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Name",
    },
    {
      type: "email",
      sortable: true,
      formatExtraData: {
        className: "fa-envelope-o",
        verified: "email_verified",
      },
      title: "Email",
    },
    {
      type: "phone",
      title: "Phone",
      formatExtraData: {
        className: "fa-phone",
        verified: "phone_verified",
      },
    },
    {
      type: "last_login",
      sortable: true,
      title: "Last Login",
      align: "center",
    },
    {
      type: "country",
      title: "Country",
      formatExtraData: "country",
      align: "center",
    },
    {
      type: "city",
      title: "City",
      align: "center",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
    /* {
         type: "action",
         sortable: true,
         align: "center",
         export: false,
         title: "Action"
     },*/
  ];

  return (
    <Card name="Simple Segmentation">
      {/* Title */}
      <TitleWithBtns
        {...props}
        titleLabel="Segment Name"
        data={props.data}
        onDelete={props.deleteSegment}
        onDuplicate={props.duplicateSegment}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo {...props} info={generalInfoInput} />
        </Col>

        {/*Main content of details */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          {/*{props.children}*/}
          <div>
            {_.map(detailsInfo, (group) => (
              <CollabseComponent
                {...props}
                group={group}
                data={props.data}
                onChange={props.onChange}
                headerList={headerList}
                titleLabel={"Segment"}
              />
            ))}
          </div>
        </Col>
      </Row>
      {/* Display users info that belong to the segmment */}
      {/*{props.customers.length !== 0 && (
          <div className="contact-table">
            <Table
              data={props.customers}
              isSelectRow={false}
              noSearch = {true}
              headers={headerList}
            />
          </div>
      )}*/}
    </Card>
  );
};
export default View;
