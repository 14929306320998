import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "components/general/Modal";
import FormComponent from "components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";
import Collapse from "components/general/Collapse";
import Options from "../../Options";

const View = (props) => {
  const { onChange, data, gateways, modal, catalogs, paymentMethodsDemo, toggle,  addingModal,
    addToggle, errors } = props;
  console.log(data);
  let attributeList = [];

  data.paymentGatewayID === "6110084812e95d6371d98de0"
                ? (attributeList = [
                    {
                      name: "method",
                      type: "select",
                      options: paymentMethodsDemo,
                      target: "banks",
                      targetName: "payments",
                      valueKey: "value",
                      labelKey: "label",
                    },
                    {
                      name: "description_en",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "description_ar",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                  ])
                : (attributeList = [
                    {
                      name: "bank_id",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "description_en",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "description_ar",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "account_number",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                    {
                      name: "iban",
                      type: "text",
                      target: "banks",
                      targetName: "payments",
                    },
                  ]);

              data.feesEnabled &&
                attributeList.push({
                  name: "fee",
                  type: "decimal",
                  target: "banks",
                  targetName: "payments",
                });

              attributeList.push({
                name: "icon",
                type: "file",
                target: "banks",
                targetName: "payments",
              });
  return (
    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>
      <ModalComponent
        title="Choose Payment Gateway"
        toggle={toggle}
        isOpen={modal}
      >
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="select"
            options={_.map(gateways, (gateway) => ({
              label: _.get(gateway, "name"),
              value: _.get(gateway, "_id"),
            }))}
            name="paymentGatewayID"
            label="Payment Getway"
            value={data.paymentGatewayID}
            valueKey="value"
            labelKey="label"
            //storeAllOptionInfo={true}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}
          <ModalFooter>
            <Button
              onClick={props.onSelect}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Select"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>

      <ModalComponent
        title="Add New Payment"
        toggle={addToggle}
        isOpen={addingModal}
        size={"lg"}
      >
        <Form onSubmit={props.onSumbit}>
        <FormComponent
            type="switch"
            id={`isActive_`}
            name="isActive"
            label="Activate"
            value={data.isActive}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          <FormComponent
            type="text"
            name="nameEN"
            label="English Name"
            value={data.nameEN}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}
          <FormComponent
            type="text"
            name="nameAR"
            label="Arabic Name"
            value={data.nameAR}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}
          <FormComponent
            type="groupCheckbox"
            options={catalogs}
            name="channels"
            label="Channels"
            value={data.channels}
            default={data.channels}
            valueKey="_id"
            labelKey="name_en"
            updateState={onChange}
          />
            <FormComponent
                    type="switch"
                    id={`feesEnabled_`}
                    name="feesEnabled"
                    label="Get Additional Fee Per Transaction"
                    //target={payments[key]}
                    //targetName="payments"
                    value={data.feesEnabled?? true}
                    updateState={onChange}
                    error={_.get(errors, "nameError")}
                  />
                  {data.feesEnabled && (
                    <div>
                      <FormComponent
                        {...props}
                        type="radio"
                        id={`value_fees`}
                        name={`feesType`}
                        label="value fees"
                        //target={payments[key]}
                        //radioList={payments}
                        //targetName={"payments"}
                        value={"VALUE"}
                        updateState={onChange}
                      />

                      <FormComponent
                        {...props}
                        type="radio"
                        id={`percentage_fees`}
                        name={`feesType`}
                        label="percentage fees"
                        //target={payments[key]}
                        //radioList={payments}
                        //targetName={"payments"}
                        value={"PERCENTAGE"}
                        updateState={onChange}
                      />

                      {data.paymentGatewayID === "610430ac20bfbb068114e8fc" && (
                        <FormComponent
                          type="decimal"
                          name="feesValue"
                          label="Fee"
                          value={data.feesValue}
                          //target={payments[key]}
                          //targetName="payments"
                          updateState={onChange}
                        />
                      )}
                    </div>
                  )}
                  {data.paymentGatewayID === "6110084812e95d6371d98de0" && (
                    <Collapse
                      group={{
                        label: "Payment Method Options Setting",
                        id: `"data`,
                        open: false,
                      }}
                    >
                      <Options
                        attributeList={attributeList}
                        {...props}
                        data={data}
                        //target={"banks"}
                        //targetName={"payments"}
                        options={data.banks}
                        updateState={onChange}
                        hasAddOption={true}
                        headers={[
                          "Method",
                          "English Description",
                          "Arabic Description",
                          "Fee",
                          "Icon",
                        ]}
                        newOptions={{
                          method: "",
                          description_en: "",
                          description_ar: "",
                          fee: "",
                          icon: "",
                        }}
                        addMore={"Add New Payment Method Options"}
                      />
                    </Collapse>
                  )}

                  {data.type === 3 && (
                    <Collapse
                      group={{
                        label: "Bank List",
                        id: `"data"`,
                        open: false,
                      }}
                    >
                      <Options
                        attributeList={attributeList}
                        {...props}
                        data={data}
                        //target={"banks"}
                        //targetName={"payments"}
                        options={data.banks}
                        updateState={onChange}
                        hasAddOption={true}
                        headers={[
                          "Bank Id",
                          "English Description",
                          "Arabic Description",
                          "Account Number",
                          "IBAN",
                          "Fee",
                          "Icon",
                        ]}
                        newOptions={{
                          bank_id: "",
                          description_en: "",
                          description_ar: "",
                          account_number: "",
                          iban: "",
                          fee: "",
                          icon: "",
                        }}
                        addMore={"Add New Payment Method Options"}
                      />
                      </Collapse>
                  )}
          <ModalFooter>
            <Button
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Save"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={addToggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
