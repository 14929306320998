import React, { PropTypes } from "react";
import { Button } from "reactstrap";
import Delete from "../Delete";

const RightSideDetails = (props) => {
  const { onSubmit, item, title, deleteAction } = props;
  return (
    <div>
      <div className="btn-cta-group flex-center p-t-30">
        <div className="submit-btn m-r-5">
          <Button onClick={onSubmit} className="btn btn-primary">
            {"Save"}
          </Button>
        </div>
        <div className="delete-cta">
          <Delete
            data={item.name}
            onSubmit={deleteAction}
            customerID={item.id}
            actionType={"button"}
          />
        </div>
      </div>
      <div className="details m-t-50">
        <div className="contact flex-center flex-column">
          <h2 className="num bold">{"1"}</h2>
          <h4 className="bold m-t-0">{`${props.title} with value`}</h4>
          <span className="text-gray">{`Out of 250 ${props.title}`}</span>
        </div>
        <div className="segment flex-center flex-column m-t-30">
          <h2 className="num bold">{"1"}</h2>
          <h4 className="bold m-t-0">{"Segment"}</h4>
        </div>
        <div className="segment flex-center flex-column m-t-30">
          <h2 className="num bold">{"1"}</h2>
          <h4 className="bold m-t-0">{"Automation Rules"}</h4>
        </div>
      </div>
    </div>
  );
};

RightSideDetails.propTypes = {};

export default RightSideDetails;
