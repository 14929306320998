import React from "react";
import _ from "lodash";
import { Row, Col, Button, Media } from "reactstrap";
import Delete from "../Delete";
//import Delete from "../DeleteSegment"
import { restfulUrl } from "context/url";
const HeaderInfo = (props) => {
  const { data, titleLabel, image, onDelete, onDuplicate, onSave , isOrder } = props;
  const name = isOrder? data._id : data.name ? data.name : `${data.name_en} | ${data.name_ar}`;
  return (
    <Row className="contact-header">
      <Col xs="12" sm="8" className="text-left d-flex align-items-center">
        {/* <Media
          object
          data-src={`${image}`}
          //   style={{ maxHeight: 64, maxWidth: 64 }}
          alt="Generic placeholder image"
        /> */}
        {image && (
          <div class="pb-4" style={{ maxWidth: "80px" }}>
            <img
              src={restfulUrl + "/" + image}
              alt={data.name_en}
              width="100%"
            />
          </div>
        )}
        <h5 className="title bold">{titleLabel + ": " + name}</h5>
      </Col>
      <Col
        xs="12"
        sm="4"
        className="text-right justify-content-end d-flex align-items-center"
      >
        <Delete
          {...props}
          data={name}
          onSubmit={onDelete}
          actionType="button"
          customerID={data._id}
          fromDetails={true}
        />
        <Button
          color="primary"
          onClick={onSave}
          style={{ width: "100%", marginRight: "10px" }}
        >
          {"Save"}
        </Button>
        {onDuplicate && (
          <Button style={{ width: "100%" }} onClick={onDuplicate}>
            {"Duplicate"}
          </Button>
        )}
      </Col>
    </Row>
  );
};
export default HeaderInfo;
