import {
  ADD_CUSTOMER_TYPE,
  EDIT_CUSTOMER_TYPE,
  DELETE_CUSTOMER_TYPE,
  GET_CUSTOMER_TYPES,
  FIND_CUSTOMER_TYPE,
} from "./types";
import fetchAPIClass from "../fetchAPI";
import { restfulUrl, API_URL } from "context/url";

export const getCustomerTypes = () => (dispatch) => {
  let url = `${API_URL}/crm/customer/type/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_CUSTOMER_TYPES);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addCustomerType = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/crm/customer/type/add`;
  console.log(requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_CUSTOMER_TYPE);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteCustomerType = (customerTypeId) => (dispatch) => {
  let url = `${API_URL}/crm/customer/type/delete/${customerTypeId}`;
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_CUSTOMER_TYPE);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editCustomerType = (inputs, customerTypeId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/crm/customer/type/edit/${customerTypeId}`;

  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_CUSTOMER_TYPE);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const findCustomerType = (customerTypeId) => (dispatch) => {
  let url = `${API_URL}/crm/customer/type/find/${customerTypeId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_CUSTOMER_TYPE);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
