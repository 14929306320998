import {
    ADD_COUNTRY,
    EDIT_COUNTRY,
    DELETE_COUNTRY,
    GET_COUNTRIES,
    FIND_COUNTRY,
  } from "../types";
  import  fetchAPIClass  from "../../fetchAPI";
  import { restfulUrl , API_URL} from "../../../../context/url";
  
  export const getCountries = () => (dispatch) => {
    let url = `${API_URL}/ecommerce/country/all`;
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_COUNTRIES);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const addCountry = (inputs) => (dispatch) => {
    let requestBody = inputs;
    let url = `${API_URL}/ecommerce/country/add`;
    console.log(requestBody);
    try {
      const api = new fetchAPIClass(url, "POST", requestBody, ADD_COUNTRY);
      let response = api.fetchAPI(dispatch);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const deleteCountry = (countryId) => (dispatch) => {
    let url = `${API_URL}/ecommerce/country/delete/${countryId}`;
    try {
      const api = new fetchAPIClass(url, "DELETE", null, DELETE_COUNTRY);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const editCountry = (inputs, countryId) => (dispatch) => {
    let requestBody = inputs;
    let url = `${API_URL}/ecommerce/country/edit/${countryId}`;
  
    try {
      const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_COUNTRY);
      let response = api.fetchAPI(dispatch);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };
  
  export const findCountry = (countryId) => (dispatch) => {
    let url = `${API_URL}/ecommerce/country/find/${countryId}`;
    try {
      const api = new fetchAPIClass(url, "GET", null, FIND_COUNTRY);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  