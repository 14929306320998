import { ADD_CUSTOMER, GET_CUSTOMERS, DELETE_CUSTOMER, FILTER_CUSTOMERS } from "./types"

import fetchAPIClass  from "./fetchAPI"
import { graphqlUrl, restfulUrl , API_URL } from "../../context/url"


export const addCustomer = (inputs) => (dispatch) => {
  let requestBody = inputs
  let url = `${API_URL}/customer/add`
  console.log(url)
  try {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: requestBody,
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: ADD_CUSTOMER,
          payload: data,
        })
      )
    console.log("succed")
  } catch (err) {
    console.log(err)
  }
}

export const getCustomers = () => (dispatch) => {
  let url = `${API_URL}/customer/all`
  try {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: GET_CUSTOMERS,
          payload: data,
        })
      )
  } catch (err) {
    console.log(err)
  }
}

export const filterCustomers = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/customer/filter`
  try {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: requestBody
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: FILTER_CUSTOMERS,
          payload: data,
        })
      )
  } catch (err) {
    console.log(err)
  }
}
export const deleteCustomer = (customerID) => (dispatch) => {
  let url = `${API_URL}/customer/delete/${customerID}`
  console.log("URL", url)
  try {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: DELETE_CUSTOMER,
          payload: data,
        })
      )
    console.log("Customer has been successfully deleted.")
  } catch (err) {
    console.log(err)
  }
}
