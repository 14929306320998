import {
  ADD_CART,
  EDIT_CART,
  DELETE_CART,
  GET_CARTS,
  FIND_CART,
} from "../types";
import fetchAPIClass from "../../fetchAPI";
import { General_URL, Order_URL } from "context/url";

export const getCarts = (data) => (dispatch) => {
  let url = `${Order_URL}/api/settings/cart-functions/all`;//`${General_URL}/order/api/settings/cart-functions/all`
  let requestBody = data;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_CARTS);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addCart = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${Order_URL}/api/settings/cart-functions`;//`${General_URL}/order/api/settings/cart-functions`
  console.log("Request Body", requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_CART);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteCart = (cartId) => (dispatch) => {
  let url = `${Order_URL}/api/settings/cart-functions/${cartId}`;//`${General_URL}/order/api/settings/cart-functions/${cartId}`
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_CART);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const editCart = (inputs) => (dispatch) => {
  let requestBody = inputs;
  console.log(inputs);
  let url = `${Order_URL}/api/settings/cart-functions`;//`${General_URL}/order/api/settings/cart-functions`
  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_CART);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

// export const findCart = (cartId) => (dispatch) => {
//   let url = `${General_URL}/ecommerce/payment/find/${cartId}`;
//   let data = "";
//   try {
//     const api = new fetchAPIClass(url, "GET", null, FIND_CART);
//     api.fetchWithoutAPI(dispatch, data);
//   } catch (err) {
//     console.log(err);
//   }
// };
