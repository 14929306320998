import React, { useEffect } from "react";
import FileUpload from "./FileUpload";

const UploadStep = ({
  onFileUpload,
  selectedType,
  isChecked,
  setIsChecked,
  ...props
}) => {
  console.log("step selectedType=>", props);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="upload-step">
      <h2 className="upload-step-title">Upload your files</h2>
      <p className="upload-step-subtitle">
        Before you upload your files below, make sure your file{" "}
        <a href="https://www.example.com/ready-to-import">
          is ready to be imported.
        </a>
      </p>
      <FileUpload onFileUpload={onFileUpload} {...props} />
      <div className="flex update-type-checkbox">
        <label htmlFor="update-existing-checkbox">
          Update existing {selectedType} using {selectedType}Id
        </label>
        <input
          type="checkbox"
          id="update-existing-checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </div>
    </div>
  );
};

export default UploadStep;
