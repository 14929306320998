//import React, { useState, useEffect, useContext } from "react";
import React, { useState, useEffect } from "react";
import View from "./View";
import { connect } from "react-redux";
import { getPaymentGateways, addPayment } from "redux/actions/settings/Order/paymnet";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

const Index = (props) => {
  let [state, setState] = useState({
    paymentGatewayID: "",
    nameEN: "",
    nameAR: "",
    channels: [],
    isActive: false,
    feesEnabled: true,
    storeId: "610430ac20bfbb068114e8fc",
    paymentMethod: "COD",
    errors: {
      nameError: "",
    },
  });

  const paymentMethodsDemo = [
    {
      value: "visa",
      label: "VISA",
    },
    {
      value: "mastercard",
      label: "Mastercard",
    },
    {
      value: "mada",
      label: "Mada",
    },
  ];

  let [modal, setModal] = useState(false);
  let [AddingModal, setAddingModal] = useState(false);

  useEffect(() => {
    props.getPaymentGateways();
  }, []);

  const onChange = (input) => {
    setState({ ...state, ...input });
  };

  const reset = () => {
    setState(state);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const addToggle = () => {
    setAddingModal(!AddingModal);
  };

  const onSelectPaymentCompany = (e) => {
    e.preventDefault();
    let inputs = state;
    console.log(inputs);

    if (inputs.paymentGatewayID === "") {
      return ErrorAlert("error", `You Should select Payment Type to Continue!`);
    } else {
      props.updateState(state);
      toggle();
      addToggle();
    }
  };

  const onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = state;

    if (inputs.nameEN === "" || inputs.nameAR === "") {
      errors.nameError = "Sorry, 'Name' in both payments are required!";
    }

    if (errors.nameError === "") {
      addPayment(e);
    } else {
      setState({ errors: errors });
    }
  };

  const addPayment = () => {
    console.log(" addPayment", state);

      let payment = state
       delete payment.errors;

    console.log(JSON.stringify(payment));

    try {
      props.addPayment(JSON.stringify(payment));
      console.log(" addPayment", payment);
      SuccessAlert("success", "Sucessfully Added");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    addToggle();
    reset();
  };

  return (
    <View
    {...props}
      data={state}
      onChange={onChange}
      onSelect={onSelectPaymentCompany}
      onSumbit={addPayment}
      onValidate={onValidate}
      errors={state.errors}
      modal={modal}
      toggle={toggle}
      addingModal={AddingModal}
      addToggle={addToggle}
      paymentMethodsDemo={paymentMethodsDemo}
    />
  );
};

const mapStateToProps = (state) => ({
  gateways: state.payments.gateways
});

export default connect(mapStateToProps, { addPayment , getPaymentGateways})(Index);
