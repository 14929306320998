import React, { PropTypes } from "react";
import Collapse from "components/general/Collapse";
import Form from "components/general/FormControllerList";
import _ from "lodash";
const View = (props) => {
  const { addresses } = props;
  console.log(addresses);
  return (
    <Collapse
      group={{ label: "Address Book", id: "adress_book_defualt", open: false }}
    >
      {addresses.map((address, index) => {
        return (
          <div className="p-l-25 p-r-25">
            <Collapse
              group={{
                label: `Address No. ${address._id}`,
                id: address._id,
                open: false,
              }}
            >
              <div>
                <Form
                  {...props}
                  type={"text"}
                  label={"Address ID"}
                  name={"_id"}
                  value={address._id}
                  default={address._id}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />
                <Form
                  {...props}
                  type={"text"}
                  label={"Address Name"}
                  name={"name"}
                  value={address.name}
                  default={address.name}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />
                <Form
                  {...props}
                  type={"text"}
                  label={"Phone"}
                  name={"phone"}
                  value={address.phone}
                  default={address.phone}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />
                <Form
                  {...props}
                  type={"text"}
                  label={"Address Line"}
                  name={"address"}
                  value={address.address}
                  default={address.address}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />
                <Form
                  {...props}
                  type={"text"}
                  label={"Country"}
                  name={"address"}
                  value={address.country}
                  default={address.country}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />
                <Form
                  {...props}
                  type={"text"}
                  label={"Province"}
                  name={"province"}
                  value={address.province}
                  default={address.province}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />
                <Form
                  {...props}
                  type={"text"}
                  label={"City"}
                  name={"city"}
                  value={address.city}
                  default={address.city}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />
                <Form
                  {...props}
                  type={"text"}
                  label={"District"}
                  name={"district"}
                  value={address.district}
                  default={address.district}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />

                <Form
                  {...props}
                  type={"text"}
                  label={"Altitude & Longitude"}
                  name={"altitude_longitude"}
                  value={address.altitude_longitude}
                  default={address.altitude_longitude}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />

                <Form
                  {...props}
                  type={"textarea"}
                  label={"More Instruction"}
                  name={"more_instruction"}
                  value={address.more_instruction}
                  default={address.more_instruction}
                  target={addresses[index]}
                  targetName="addresses"
                  disabled={true}
                  updateState={props.onChange}
                />

                {/* <Form
                    {...props}
                    type={"decimal"}
                    label={"Price"}
                    name={"price"}
                    value={productCatalog.price}
                    default={productCatalog.price}
                    target={productCatalog}
                    targetName="products_catalogs"
                    updateState={props.onChange}
                  />
                  <Form
                    {...props}
                    type={"decimal"}
                    label={"Final Price"}
                    name={"final_price"}
                    value={
                      productCatalog.taxable
                        ? calculatePriceWithTax(
                            productCatalog.price,
                            productCatalog.catalog_id.tax.percentage
                          )
                        : productCatalog.final_price
                    }
                    default={
                      productCatalog.taxable
                        ? calculatePriceWithTax(
                            productCatalog.price,
                            productCatalog.catalog_id.tax.percentage
                          )
                        : productCatalog.final_price
                    }
                    target={productCatalog}
                    targetName="products_catalogs"
                    updateState={props.onChange}
                  />
                  <Form
                    {...props}
                    type={"decimal"}
                    label={"Cost"}
                    name={"cost"}
                    value={productCatalog.cost}
                    default={productCatalog.cost}
                    target={productCatalog}
                    targetName="products_catalogs"
                    updateState={props.onChange}
                  />
                  <Form
                    {...props}
                    type={"text"}
                    label={"Revenue"}
                    name={"revenue"}
                    value={productCatalog.price - productCatalog.cost}
                    default={productCatalog.cost}
                    target={productCatalog}
                    targetName="products_catalogs"
                    disabled={true}
                    updateState={props.onChange}
                  />

                  <Form
                    {...props}
                    type={"switch"}
                    label={"Taxable"}
                    name={"taxable"}
                    value={productCatalog.taxable}
                    default={productCatalog.taxable}
                    target={productCatalog}
                    targetName="products_catalogs"
                    id={`taxable${index}`}
                    updateState={props.onChange}
                  /> */}
              </div>
            </Collapse>
          </div>
        );
      })}
    </Collapse>
  );
};

View.propTypes = {};

export default View;
