import {
  ADD_ORDER,
  EDIT_ORDER,
  DELETE_ORDER,
  GET_ORDERS,
  FIND_ORDER,
  UPDATE_ORDER_STATUS,
  ORDER_NO_SHOW,
} from "../../actions/OrderMangement/types";

const initialState = {
  item: null,
  items: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_ORDER:
    case FIND_ORDER:
      return {
        ...state,
        item: action.payload,
      };
    case DELETE_ORDER:
      return {
        ...state,
        item: action.payload,
      };
    case GET_ORDERS:
      return {
        ...state,
        items: action.payload,
      };
    case ADD_ORDER:
      return {
        ...state,
        item: action.payload,
        items: [...state?.items, action.payload],
      };
    case UPDATE_ORDER_STATUS:
      return {
        ...state,
        item: action.payload,
        items: [...state?.items, action.payload],
      };
    case ORDER_NO_SHOW:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
}
