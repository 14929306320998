import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

/**
 * General Tab Component
 *  @component
 * @prop {string | number} defualtActiveID - The id of the defualt active tab
 * @prop {Array.<{name: string, id: string | number }>} tabsList
 *
 */
const Tabs = (props) => {
  let { defualtActiveID, tabsList } = props;
  const [activeTab, setActiveTab] = useState(defualtActiveID);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      {/* Nav Tabs Link */}
      <Nav tabs>
        {tabsList.map((tab) => (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === tab.id })}
              onClick={() => {
                toggle(tab.id);
              }}
            >
              {tab.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      {/* Tabs Contents */}
      <TabContent activeTab={activeTab}>
        {props.children}
        {/* <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <h4>Tab 1 Contents</h4>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="6">
              <Card body>
                <CardTitle>Special Title Treatment</CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Button>Go somewhere</Button>
              </Card>
            </Col>
            <Col sm="6">
              <Card body>
                <CardTitle>Special Title Treatment</CardTitle>
                <CardText>
                  With supporting text below as a natural lead-in to additional
                  content.
                </CardText>
                <Button>Go somewhere</Button>
              </Card>
            </Col>
          </Row>
        </TabPane> */}
      </TabContent>
    </div>
  );
};

export default Tabs;
