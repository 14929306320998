import {
  BULK_UPLOAD_FILE,
  BULK_IMPORT_PROGRESS_DATA,
  BULK_IMPORT_GET_DATA,
} from "./types";
import io from "socket.io-client";

import {
  restfulUrl,
  API_URL,
  //CRM_LOCAL_TEST,
} from "../../../../src/context/url";
import fetchAPIClass from "../fetchAPI";

export const bulkUploadFile = (file, type) => (dispatch) => {
  let url = `${API_URL}/bulk/upload?indexing_for=${type}`;
  try {
    console.log("inside upload media", type);
    var formData = new FormData();
    formData.append("media", file);

    let responseMedia = fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) =>
        dispatch({
          type: BULK_UPLOAD_FILE,
          payload: data,
        })
      );

    return responseMedia;
  } catch (err) {
    console.log(err);
  }
};

export const bulkProcessImportData = (res) => (dispatch) => {
  const buisnessId = "5cc2f00577ebc157b4f1c64c";
  let url = `${API_URL}/bulk/update-customers-crm`;

  // Connect to the server
  const socket = io(API_URL);

  // Listen for real-time updates
  socket.on("update", (data) => {
    // Dispatch an action with the real-time data
    console.log("dispatched data=>", data);
    dispatch({
      type: BULK_IMPORT_PROGRESS_DATA,
      payload: data,
    });
  });

  try {
    res.buisnessId = buisnessId;
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(res),
    })
      .then((res) => res.json())
      .then((data) => {
        // Handle response data as needed
        dispatch({
          type: BULK_IMPORT_PROGRESS_DATA,
          payload: data,
        });
      });

    console.log("succed bulk processing");
  } catch (err) {
    console.log("bulk error=>", err);
  }
};
export const bulkGetImportsData = () => (dispatch) => {
  let url = `${API_URL}/bulk/imports`;

  try {
    const api = new fetchAPIClass(url, "GET", null, BULK_IMPORT_GET_DATA);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};
