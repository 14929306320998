import {
  ADD_SEGMENTATION,
  GET_SEGMENTATIONS,
  DELETE_SEGMENTATION,
  EDIT_SEGMENTATION,
  FIND_SEGMENTATION,
  DUPLICATE_SEGMENTATION,
  ADD_TEMPLATE_SEGMENTATION,
  GET_TEMPLATE_SEGMENTATIONS,
  DELETE_TEMPLATE_SEGMENTATION,
  EDIT_TEMPLATE_SEGMENTATION,
  FIND_TEMPLATE_SEGMENTATION,
} from "../actions/types";

const initialState = {
  items: [],
  item: null,
  templates: [],
  template: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_SEGMENTATION:
    case EDIT_SEGMENTATION:
    case DELETE_SEGMENTATION:
    case FIND_SEGMENTATION:
    case DUPLICATE_SEGMENTATION:
      // case FIND_TEMPLATE_SEGMENTATION:
      return {
        ...state,
        item: action.payload,
      };
    case GET_SEGMENTATIONS:
      return {
        ...state,
        items: action.payload,
      };
    case GET_TEMPLATE_SEGMENTATIONS:
      return {
        ...state,
        templates: action.payload,
      };
    case FIND_TEMPLATE_SEGMENTATION:
      return {
        ...state,
        template: action.payload,
      };
    default:
      return state;
  }
}
