import {
  GET_ECOM_GROUPS,
  EDIT_ECOM_GROUP,
  DELETE_ECOM_GROUP,
  FIND_ECOM_GROUP,
  ADD_ECOM_GROUP,
} from "../../actions/Ecommerce/types";

const initialState = {
  items: null,
  item: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ECOM_GROUP:
    case EDIT_ECOM_GROUP:
    case FIND_ECOM_GROUP:
    case DELETE_ECOM_GROUP:
      return {
        ...state,
        item: action.payload,
      };
    case GET_ECOM_GROUPS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
}
