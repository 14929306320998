import React from "react"
import _ from "lodash"

const ContactSummary = (/*{ summaryInfo }*/) => {
  const summary = [
    {
      title: "Total Session",
      count: "150",
      icon: "fa fa-globe"
    },
    {
      title: "Total Pageview",
      count: "10K",
      icon: "fa fa-eye"
    },
    {
      title: "Total Sales",
      count: "4,000",
      icon: "fa fa-usd"
    },
    {
      title: "Total Transactions",
      count: 10,
      icon: "fa fa-shopping-cart"
    }
  ]
  return _.map(summary, info => (
    <div className="summary-item col-lg-3 col-xs-6">
      <div className="tiles m-b-10">
        <div className="tiles-body">
          <div className="widget-stats">
            <div className="wrapper transparent">
              <span className="item-title">{info.title}</span>
              <span className="item-count animate-number semi-bold">
                {info.count}
              </span>
            </div>
          </div>
          <div className="widget-stats">
            <div className="icon">
              <i className={info.icon} aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
}
export default ContactSummary
