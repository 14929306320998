import React, { Component } from "react"
import validator from "validator"
import { button } from "react-validation"

const _Button = ({ hasErrors, ...props }) => (
  <button {...props} disabled={hasErrors} />
)

const Button = button(_Button)

const email = value => {
  if (!validator.isEmail(value) && value.toString().trim().length !== 0) {
    return (
      <span className="form-error is-visible">
        {value} is not a valid email
      </span>
    )
  }
}

const phone = value => {
  if (
    (!validator.isMobilePhone(value) ||
      value.toString().trim().length < 9 ||
      value.toString().trim().length > 14) &&
    value.toString().trim().length !== 0
  ) {
    return (
      <span className="form-error is-visible">
        The phone number is not valid
      </span>
    )
  }
}

const lt = (value, props) => {
  // get the maxLength from component's props
  if (value.toString().trim().length > props.maxLength) {
    // Return jsx
    return (
      <span className="error">
        The value exceeded {props.maxLength} symbols.
      </span>
    )
  }
}

// const password = (value, props, components) => {
//   // NOTE: Tricky place. The 'value' argument is always current component's value.
//   // So in case we're 'changing' let's say 'password' component - we'll compare it's value with 'confirm' value.
//   // But if we're changing 'confirm' component - the condition will always be true
//   // If we need to always compare own values - replace 'value' with components.password[0].value and make some magic with error rendering.
//   if (value !== components["confirm"][0].value) {
//     // components['password'][0].value !== components['confirm'][0].value
//     // 'confirm' - name of input
//     // components['confirm'] - array of same-name components because of checkboxes and radios
//     return <span className="error">Passwords are not equal.</span>
//   }
// }

const password = (value, props, components) => {
  if (
    value.toString().trim().length !== 0 &&
    value.toString().trim().length < 3
  ) {
    return (
      <span className="form-error is-visible">
        Password must be at least 3 characters long
      </span>
    )
  }
}

const required = value => {
  if (!value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return "This field is required"
  }
}

class Validation extends Component {
  render() {
    return <div />
  }
}
export { email, phone, Validation, Button, required, password, lt }
