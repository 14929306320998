import React, { PropTypes } from 'react';
import View from './View'
import voucher_codes from 'voucher-code-generator'
const Index = props => {
   const {couponData} = props
   const generateCoupons = () => {
    let coupons = voucher_codes.generate({
    length: couponData.code_length ,
    count: couponData.coupon_qty,
    charset: voucher_codes.charset(couponData.code_format),
    prefix: couponData.code_prefix,
    postfix: couponData.code_suffix
});
props.onChange({coupons:coupons})
  };
    
    return (
        <div>
            <View {...props}  generateCoupons={generateCoupons}/>
        </div>
    );
};

Index.propTypes = {
    
};

export default Index;