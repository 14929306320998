import React from "react";
import FormComponent from "components/general/FormController";
import { Button, Form, ModalFooter } from "reactstrap";
import _ from "lodash";
import { Alert } from "reactstrap";
import { GoogleLogin } from "react-google-login";

const View = (props) => {
  const { handleSwitchToSignup, data, onChange, errors, responseGoogle } =
    props;
  // const responseGoogle = (response) => {
  //   console.log(response);
  // };

  return (
    <div id="auth" className="container">
      <div className="row">
        <div
          className="control-group col-lg-12 m-b-30"
          style={{ textAlign: "center" }}
        >
          <h1> {"LOGIN To UXAUTOMATION "}</h1>
        </div>
      </div>

      <div className="row login-container column-seperation">
        {/* <Form className="login-form" onSubmit={props.sumbit}> */}
        <div className="col-md-5 offset-md-1">
          <p>
            {"Use Google, or your email to sign in."}
            <br />
            {
              "Don't have a Uxautomation account Create Now to recive all uxautomation services"
            }
          </p>
          {/* <a href="#" onClick={handleSwitchToSignup}>
              {"Sign up Now!"}
            </a>{" "} */}
          <button
            className="btn btn-block btn-info md-8"
            onClick={handleSwitchToSignup}
            type="button"
          >
            <span
              class="pull-left icon-facebook"
              style={{ fontStyle: "italic" }}
            ></span>{" "}
            <span class="bold">{"Sign up Now!"}</span>
          </button>
          <GoogleLogin
            clientId="678234694031-2eoiq8htrsbv5ltggrssj7uqvdl9ebm2.apps.googleusercontent.com"
            render={(renderProps) => (
              // <button
              //   onClick={renderProps.onClick}
              //   disabled={renderProps.disabled}
              // >

              //   This is my custom Google button
              // </button>
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="btn btn-block btn-success md-8"
                type="button"
              >
                <span
                  className="pull-left icon-twitter"
                  style={{ fontStyle: "italic" }}
                ></span>
                <span className="bold">{"Login with Google"}</span>
              </button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
          {/* <button className="btn btn-block btn-success md-8" type="button">
            <span
              className="pull-left icon-twitter"
              style={{ fontStyle: "italic" }}
            ></span>
            <span className="bold">{"Login with Google"}</span>
          </button> */}
        </div>

        <div className="col-md-5">
          <div className="">
            {/* <div className="control-group col-lg-12 hidden login-form-error">
              <div className="error-block">
                <span className="form-error is-visible">
                  <i className="fa fa-warning m-r-5" />
                </span>
              </div>
            </div> */}

            <FormComponent
              type="email"
              name="email"
              label="Email"
              value={data.email}
              updateState={onChange}
              error={_.get(errors, "nameError")}
            />
            {!_.isEmpty(_.get(errors, "nameError")) && (
              <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
            )}

            <FormComponent
              type="password"
              name="password"
              label="Password"
              value={data.password}
              updateState={onChange}
            />

            {/* <div className="control-group col-lg-12 switch">
              <p>Create A new account?</p>

              <Button
                className="btn btn-primary btn-cons btn-cons"
                onClick={handleSwitchToSignup}
              >
                {"Signup"}
              </Button>
            </div> */}
            <div class="col-lg-12 form-action">
              <Button
                className="btn btn-primary btn-cons btn-cons"
                onClick={props.onSumbit}
              >
                {"Login"}
              </Button>
            </div>
          </div>
        </div>
        {/* </Form> */}
      </div>
    </div>
  );
};

export default View;
