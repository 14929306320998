import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";
//import GroupList from "./group-list"
import { getAttributes } from "redux/actions/Ecommerce/AttributeAction";
import {
  editSegmentation,
  deleteSegmentation,
  findSegmentaion,
  duplicateSegmentation,
} from "../../../../redux/actions/SegmentationActions";
import { Form } from "reactstrap";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
import _ from "lodash";
import { handelLogicAlgorithm } from "../../../../helpers/logicHandler";

const business_id = "5cc2f00577ebc157b4f1c64c";

const Index = (props) => {
  let segmentation = props.location.state.item;
  console.log(segmentation);

  const [isChecked, setIsChecked] = useState(
    _.toLower(segmentation.status) === "active" ? true : false
  );
  let [state, setState] = useState(segmentation);
  const [users, setUsers] = useState([]);
  const [result, setResult] = useState({
    users: {
      total: 0,
      percentage: "0%",
      className: "user",
    },
    sessions: {
      total: 0,
      percentage: "0%",
      className: "session",
    },
    pageviews: {
      total: 0,
      percentage: "0%",
      className: "page-view",
    },
    sales: {
      total: 0,
      percentage: "0%",
      className: "sale",
    },
  });

  // let [algorithm, setAlgorithm] = useState({
  //   operator: { label: "All", value: "all" },
  //   logic_list: [
  //     {
  //       attribute: {
  //         system_name: "",
  //         inner_name: "",
  //         label: "",
  //         path: "",
  //         type: "",
  //         operator: [],
  //       },
  //       operator: { label: "All", value: "all" },
  //       value: "",
  //       child: {
  //         empty: true,
  //         logic_list: [],
  //         operator: "",
  //       },
  //     },
  //   ],
  // });

  useEffect(() => {
    props.getAttributes();
    props.findSegmentaion(segmentation._id);
  }, []);
  useEffect(() => {
    if (props.segment) {
      if (props.segment.segmentation) {
        handelUserInfo(props.segment.segmentation.result.customers_list);
        setResult(props.segment.summary);
      }
    }
  }, [props.segment]);

  // useEffect(() => {
  //   if (props.attributes) {
  //     let handeledLogic = handelLogicAlgorithm(
  //       segmentation.logic,
  //       props.attributes
  //     );
  //     console.log(handeledLogic);
  //     setAlgorithm(handeledLogic);
  //   }
  // }, [props.attributes]);

  let [, setRender] = useState(); //for forcing to re render

  function handleUpdate() {
    //passing empty object will re-render the component
    setRender({});
  }
  console.log(props.segment);

  const handelUserInfo = (customersInfo) => {
    let info = [];
    _.map(customersInfo, (customer) => {
      let { _id, name, email, phone, last_login, country, city, status } =
        customer;
      info.push({
        _id,
        name,
        email,
        phone,
        last_login,
        country,
        city,
        status,
      });
      setUsers(info);

      return info;
    });
  };

  const inputsToJSON = (inputs) => {
    return JSON.stringify(inputs);
  };

  // const handelUpdatedInputs = () => {
  //   let logicAlgorithm = {
  //     _id: algorithm._id,
  //     operator: algorithm.operator /*.value*/,
  //   };
  //   let logic_list = _.map(algorithm.logic_list, (logic) => ({
  //     attribute: logic.attribute._id || "",
  //     child: !logic.child.empty ? handelUpdatedChildInputs(logic.child) : null,
  //     operator: logic.operator /*.value*/,
  //     value: logic.value,
  //   }));

  //   let logic = { ...logicAlgorithm, logic_list };
  //   console.log({
  //     ...state,
  //     logic,
  //   });
  //   return {
  //     ...state,
  //     logic,
  //   };
  // };

  // const handelUpdatedChildInputs = (childLogic) => {
  //   let algorithm = { operator: childLogic.operator /*.value*/ };
  //   let logic_list = _.map(childLogic.logic_list, (logic) => ({
  //     attribute: logic.attribute._id || "",
  //     operator: logic.operator /*.value*/,
  //     value: logic.value,
  //   }));

  //   return {
  //     ...algorithm,
  //     logic_list,
  //   };
  // };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    try {
      //  let inputs = handelUpdatedInputs();
      let inputs = inputsToJSON(state);
      let response = props.editSegmentation(inputs, segmentation._id);

      SuccessAlert("success", "Sucessfully updated");
      console.log(response);
      props.history.push("/crm/segmentations"); //redirect to SegmentationList
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const duplicateSegment = (e) => {
    e.preventDefault();

    try {
      let inputs = inputsToJSON(state);
      let response = props.duplicateSegmentation(inputs, segmentation._id);

      SuccessAlert("success", "Sucessfully duplicated");
      console.log(response);
      props.history.push("/crm/segmentations"); //redirect to SegmentationList
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleOnStatusChange = () => {
    setIsChecked(!isChecked);
    onChange({ status: !isChecked ? "Active" : "Inactive" });
  };

  const getAttributeOptions = (groupsAttributes) => {
    let attributes = [];
    groupsAttributes.map((group) => {
      return attributes.push(...group.attributes);
    });
    return attributes;
  };

  const deleteSegment = () => {
    try {
      let response = props.deleteSegmentation(segmentation._id);

      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
      props.history.push("/crm/segmentations"); //redirect to SegmentationList
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const onChange = (input) => {
    setState({ ...state, [_.keys(input)]: _.get(input, `${_.keys(input)}`) });
  };

  const updateState = (input) => {
    setState({ ...state, ...input });

    handleUpdate(); //to force re-render the component
  };

  if (!segmentation) {
    return <div>Loading...</div>;
  } else {
    /*  let attributeOptions = ""
      if (props.groups/*getAttributeList.loading*/ /*) {
        attributeOptions = getAttributeOptions(
          //this.props.getAttributeList.businessAccount.attributes
          props.groups.data.businessAccount.attributes
        )
      }*/
    return (
      <div>
        <Form onSubmit={handleOnSubmit}>
          <View
            {...props}
            onChange={onChange}
            data={state}
            logic={state.logic}
            isChecked={isChecked}
            result={result}
            deleteSegment={deleteSegment}
            handleOnStatusChange={handleOnStatusChange}
            customers={
              props.segment
                ? _.flattenDeep(
                    users
                  ) /*handelUserInfo(props.segment.result.customers_list) */
                : []
            }
            duplicateSegment={duplicateSegment}
            updateState={updateState}
            //handelUserInfo={handelUserInfo}
          ></View>
        </Form>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  groups: state.attribute.items,
  segment: state.segmentations.item,
  attributes: state.ecommerceAttributes.items,
});

export default connect(mapStateToProps, {
  getAttributes,
  editSegmentation,
  deleteSegmentation,
  findSegmentaion,
  duplicateSegmentation,
})(Index);
