import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getCountries,
  editCountry,
  deleteCountry,
} from "../../../../redux/actions/Ecommerce/settings/countryAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import CollapseList from "components/general/PageDetails/CollapseList";
//import AddBrand from "./Add";
import View from "./View";

const Index = (props) => {
  useEffect(() => {
    props.getCountries();
  }, [props]);

  const deleteCountry = (countryId) => {
    try {
      let response = props.deleteCountry(countryId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editCountry = (countryId, input) => {
    try {
      let response = props.editCountry(JSON.stringify(input), countryId);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log(props.countries);
  return (
    <div>
      {props.countries && (
        <View
          {...props}
          deleteCountry={deleteCountry}
          editCountry={editCountry}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  countries: state.countries.items,
});

export default connect(mapStateToProps, {
  getCountries,
  deleteCountry,
  editCountry,
})(Index);
