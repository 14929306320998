import React, { useState } from "react";
import { connect } from "react-redux";
import EditGroup from "views/CRM/ProfileAttribute/EditGroup";
import { editGroup } from "redux/actions/Ecommerce/GroupAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";

const Index = (props) => {
  const editGroup = (group, groupId) => {
    console.log("Edit GroupEcom", groupId);
    try {
      let response = props.editGroup(JSON.stringify(group), groupId);
      SuccessAlert("success", "Sucessfully Added");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };
  return <EditGroup {...props} editEcomGroup={editGroup} isEcomGroup={true} />;
};

export default connect(null, { editGroup })(Index);
