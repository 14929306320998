import React from "react";
import FormComponent from "components/general/FormController";
import { Button, Form, ModalFooter } from "reactstrap";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {
  const { handleSwitchToLogin, data, onChange, errors } = props;
  return (
    <div className="auth-container">
      <div id="auth" className="container">
        <div className="row">
          <div
            className="control-group col-lg-12 m-b-30"
            style={{ textAlign: "center" }}
          >
            <h1> {"REGISTRATION AT UXAUTOMATION "}</h1>
          </div>
        </div>
        <div className="row">
          <div className="form-container col-sm-6 col-md-6 m-auto">
            {/* <Form className="login-form" onSubmit={props.sumbit}> */}
            <div className="control-group col-lg-12 hidden login-form-error">
              <div className="error-block">
                <span className="form-error is-visible">
                  <i className="fa fa-warning m-r-5" />
                </span>
              </div>
            </div>

            <FormComponent
              type="email"
              name="email"
              label="Email"
              value={data.email}
              updateState={onChange}
              error={_.get(errors, "nameError")}
            />
            {!_.isEmpty(_.get(errors, "nameError")) && (
              <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
            )}

            <FormComponent
              type="text"
              name="firstName"
              label="First Name"
              value={data.firstName}
              updateState={onChange}
              error={_.get(errors, "nameError")}
            />
            {!_.isEmpty(_.get(errors, "nameError")) && (
              <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
            )}
            <FormComponent
              type="text"
              name="lastName"
              label="Last Name"
              value={data.lastName}
              updateState={onChange}
              error={_.get(errors, "nameError")}
            />
            {!_.isEmpty(_.get(errors, "nameError")) && (
              <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
            )}

            <FormComponent
              type="phone"
              name="phone"
              label="Phone"
              value={data.phone}
              updateState={onChange}
              error={_.get(errors, "nameError")}
            />
            {!_.isEmpty(_.get(errors, "nameError")) && (
              <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
            )}

            <FormComponent
              type="text"
              name="storeName"
              label="Company Name"
              value={data.storeName}
              updateState={onChange}
              error={_.get(errors, "nameError")}
            />
            {!_.isEmpty(_.get(errors, "nameError")) && (
              <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
            )}

            <FormComponent
              type="password"
              name="password"
              label="Password"
              value={data.password}
              updateState={onChange}
            />

            <div class="">
              <Button
                className="btn btn-primary btn-cons md-8"
                type="button"
                onClick={props.onSumbit}
              >
                {"Signup"}
              </Button>
            </div>
            <div className="row mt-4">
              <div className="control-group col-lg-12 switch">
                <p>
                  {"Already got an account ?  "}

                  <a href="#" onClick={handleSwitchToLogin}>
                    {"Login here"}
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* </Form> */}
        </div>
      </div>
    </div>
  );
};

export default View;
