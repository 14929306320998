import React, { PropTypes } from "react";
import { Row, Col } from "reactstrap";
import { restfulUrl } from "../../../context/url";
import Card from "../Card";
import MediaLibrary from "../MediaLibrary";
import SingleMedia from "./Single";

const Index = (props) => {
  const media = props.data[props.name];

  const handleDelete = (pic, index, e) => {
    e.preventDefault();
    console.log(index);
    let media = props.data[props.name];
    media.splice(index, 1);
    props.updateState({ media: media }, props.index);
  };

  const handleEdit = () => {};
  return props.multiple ? (
    <Row lg="5" sm="6" md="4">
      {media.map((pic, index) => (
        <Col className="mb-4">
          {/*FIXME: pass from props  */}

          <Card cardStyle={{ height: 255 }} image={restfulUrl + "/" + pic}>
            <div className="d-flex">
              <button class={"btn w-100 mt-3 mr-1"}>{"Edit"}</button>
              <button
                class={"btn btn-primary w-100 mt-3"}
                onClick={(e) => handleDelete(pic, index, e)}
              >
                {"Delete"}
              </button>
            </div>
          </Card>
        </Col>
      ))}
      <Col className="mb-4">
        <Card
          cardStyle={{ height: 255 }}
          bodyStyle={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MediaLibrary {...props} />
        </Card>
      </Col>
    </Row>
  ) : (
    <SingleMedia {...props} media={media} />
  );
};

export default Index;
