import React from "react";
const RowDetails = ({ rowData }) => {
  console.log("row data=>", rowData);
  // Convert createdAt to a Date object
  const createdAt = new Date(rowData.createdAt);
  // Format createdAt as a human-readable string
  const createdAtString = createdAt.toLocaleString();
  return (
    <div>
      <h3>Import Details</h3>
      <hr />
      <p className="file-name">
        <strong>File Name:</strong> {rowData.file_name}
      </p>
      <hr />
      <p className="import-type">
        <strong>Import Type:</strong> {rowData.type}
      </p>
      <hr />
      <p className="import-source">
        <strong>Import Source:</strong> {rowData.source}
      </p>
      <hr />
      <p className="import-Date">
        <strong>Import Date:</strong> {createdAtString}
      </p>
      <hr />
      <p className="import-status">
        <strong>Import Status:</strong> {rowData.status}
      </p>
      <hr />
      <p className="user">
        <strong>User:</strong> {rowData.user}
      </p>
      <hr />
      {rowData.results && rowData.results[0] && (
        <>
          <h4>Object</h4>
          <div style={{ paddingLeft: "45px" }}>
            <p className="total-rows">
              <strong>Total Rows:</strong> {rowData.results[0].total_rows}
            </p>
            <p className="total-imported">
              <strong>Total Imported:</strong>{" "}
              {rowData.results[0].total_imported}
            </p>
            <p className="total-updated">
              <strong>Total Updated:</strong> {rowData.results[0].total_updated}
            </p>
            <p className="total-invalid">
              <strong>Total Invalid:</strong> {rowData.results[0].total_invalid}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
export default RowDetails;
