import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getPickups,
  editPickup,
  deletePickup,
} from "../../../../redux/actions/settings/Order/pickup";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import CollapseList from "components/general/PageDetails/CollapseList";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import _ from "lodash";

//import AddBrand from "./Add";
import View from "./View";

const demoData = [
  {
    _id: 1,
    activate: true,
    name_en: "Pickup 1",
    name_ar: "Pickup 2",
    channel: "",
    coverage_area: "",
    maximum_options :"",
  },
  {
    _id: 2,
    activate: true,
    name_en: "pickup",
    name_ar: "pickup",
    channel: "",
    coverage_area: "",
    maximum_options :"",
  },
];

const coverageAreaDemo = [
  {
    value: "district",
    label: "District",
  },
  {
    value: "city",
    label: "City",
  },
  {
    value: "region",
    label: "Region",
  },
  {
    value: "country",
    label: "Country",
  }
];

 

const Index = (props) => {

  useEffect(() => {
    props.getCatalogs();
  }, []);

  useEffect(() => {
    props.getPickups();
  }, []);

  const deletePickup = (pickupId) => {
    try {
      let response = props.deletePickup(pickupId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editPickup = (pickupId, input) => {
    try {
      input.maximumOptions = _.toNumber(input.maximumOptions)

      console.log(JSON.stringify(input))
      let response = props.editPickup(JSON.stringify(input));
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log(props.pickups);
  return (
    <div>
      {props.pickups && (
        <View
          {...props}
          deletePickup={deletePickup}
          editPickup={editPickup}
          coverageAreaDemo={coverageAreaDemo}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pickups: state.pickups.items,
  catalogs: state.catalogs.items,
});

export default connect(mapStateToProps, {
  getPickups,
  deletePickup,
  editPickup,
  getCatalogs,
})(Index);
