import Swal from "sweetalert2"

function ErrorAlert(title, message) {

    Swal.fire({
        icon: 'error',
        title: message
    })

}

export default ErrorAlert
