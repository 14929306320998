import React from "react";
import DownloadCloud from "./assets/DownloadCloud";
const SampleBox = () => {
  return (
    <div className="prep-box-main">
      <div className="prep-box-wr">
        <DownloadCloud />
        <h2>Download a sample spreadsheet</h2>
        <p>
          Download this small sample file and test the
          <br /> import process so there's no surprises.
        </p>
        <button className="prep-button-main">
          Download sample spreadsheet
        </button>
      </div>
    </div>
  );
};

export default SampleBox;
