import React, { Component } from "react";
import View from "../../../../components/general/Delete";
import { connect } from "react-redux";
import { deleteSegmentation } from "../../../../redux/actions/SegmentationActions";
import { restfulUrl } from "../../../../context/url";
import fetchApi from "../../../../fetchApi/fetchData";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";

class index extends Component {
  deleteSegment = () => {
    try {
      let response = this.props.deleteSegmentation(this.props.customerID);

      SuccessAlert("success", "Sucessfully deleted");
      if (this.props.fromDetails) this.props.history.push("/segmentations");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  render() {
    console.log(this.props);
    return (
      <>
        <View {...this.props} onSubmit={this.deleteSegment} />
      </>
    );
  }
}

export default connect(null, { deleteSegmentation })(index);
