import React, { useEffect, PropTypes } from "react";
import { connect } from "react-redux";
import {
  getPromotions,
  deletePromotion,
  getPromotionsTemplates,
  getPromotionTypes,
} from "../../../redux/actions/Ecommerce/PromotionActions";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";
import _ from "lodash"
import View from "./View";

const Index = (props) => {

  useEffect(() => {
    // Get promotions list
    props.getPromotions();
  }, []);

  useEffect(() => {
    // Get promotions templates list
    props.getPromotionsTemplates();
  }, []);

  useEffect(() => {
    props.getPromotionTypes();
  }, []);

  useEffect(() => {
    props.getPromotionTypes();
  }, []);

  /*useEffect(() => {
   if(props.promotions && props.promotionTypes) {
     _.forEach(props.promotions, promotion => {
       if(!_.isEmpty(promotion.promotion_type))
       promotion.typeName = (_.find(props.promotionTypes, type => type._id === promotion.promotion_type._id)).type
     })
   }

  }, [props.promotions, props.promotionTypes]);*/

  useEffect(() => {
    if(props.promotions) {
      _.forEach(props.promotions, promotion => {
        promotion.typeName = promotion.promotion_type.type
      })
    }
 
   }, [props.promotions]);



  console.log(props.promotions);
  console.log(props.promotionTemplates);

  const deletePromotion = (promotionId) => {
    console.log(promotionId)
    try {
      let response = props.deletePromotion(promotionId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    props.history.push("/ecommerce/add-promotion");
  };

  return (
    <div>
      {/*<AddCategory {...props} />*/}
      {props.promotions && (
        <View
          {...props}
          deletePromotion={deletePromotion}
          handleCreate={handleCreate}
        />
      )}
    </div>
  );
};

Index.propTypes = {};

const mapStateToProps = (state) => ({
    promotions: state.promotions.items,
    promotionTemplates: state.promotions.templates,
    promotionTypes: state.promotions.promotion_types,

});

export default connect(mapStateToProps, { getPromotions, deletePromotion, getPromotionTypes,getPromotionsTemplates })(
  Index
);
