//import React, { useState, useEffect, useContext } from "react";
import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addProduct } from "../../../../redux/actions/Ecommerce/ProductAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
import _ from "lodash";
import {productTypes} from 'seeder/productTypes'

class index extends Component {
  state = this.initialState;

  onChange = (input) => {
    this.setState(input);
  };

  get initialState() {
    return {
      business_id: "5cc2f00577ebc157b4f1c64c",
      sku: "",
      name_en: "",
      name_ar: "",
      type: "",
      errors: {
        skuError: "",
        nameError: "",
        nameArError: "",
        typeError: "",
      },
      modal: false,
    };
  }

  reset() {
    this.setState(this.initialState);
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
    //this.reset()
  };

  onValidate = (e) => {
    e.preventDefault();
    let errors = {
      skuError: "",
      nameError: "",
      nameArError: "",
      typeError: "",
    };
    let inputs = this.state;

    if (inputs.name_en === "" || inputs.name_ar === "") {
      errors.nameError = "name Englinsh & Arabic are required !";
    }

    if (inputs.sku.length < 10) {
      errors.skuError = "please enter valid sku";
    }

    if (errors.nameError === "" && errors.skuError === "") {
      this.addProduct(e);
    } else {
      //setErrors(errors)
      this.setState({ errors: errors });
    }
  };

  addProduct = (e) => {
    e.preventDefault();
    console.log(this.state);
    let customer = `{
    "business_id": "${this.state.business_id}",
    "sku":"${this.state.sku}",
    "type":"${this.state.type}",
    "name_en": "${this.state.name_en}",
    "name_ar": "${this.state.name_ar}"
    }`;

    try {
      let response = this.props.addProduct(customer);

      SuccessAlert("success", "Sucessfully Added");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    this.reset();
    this.toggle();
  };

  render() {
    console.log(this.state)
    return (
      <View
        data={this.state}
        productTypes={productTypes}
        onChange={this.onChange}
        onSumbit={this.addProduct}
        onValidate={this.onValidate}
        errors={this.state.errors}
        modal={this.state.modal}
        toggle={this.toggle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  product: state.attribute.item,
});

export default connect(mapStateToProps, { addProduct })(index);
