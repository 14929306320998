import React, { Component } from "react";
import slugify from "react-slugify";
import View from "./View";

class index extends Component {
  state = {
    options: "",
    delimiter: "",
    option_action: "add",
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = () => {
    let split = "\n";
    if (this.state.delimiter) split = this.state.delimiter;
    let options = this.state.options.split(split);
    options = options.map((option) => {
      return {
        value: slugify(option, { delimiter: "_" }),
        label: option,
        arabic_label: option,
        is_default: false,
      };
    });
    this.props.onLoadOptions(options, this.state.option_action);
  };

  render() {
    return <View onChange={this.onChange} onSubmit={this.onSubmit} />;
  }
}

export default index;
