import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getPayments,
  editPayment,
  deletePayment,
} from "../../../../redux/actions/settings/Order/paymnet";
import { getCustomerTypes } from "redux/actions/settings/customerTypeAction";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import CollapseList from "components/general/PageDetails/CollapseList";
//import AddBrand from "./Add";
import View from "./View";
import {productTypes} from 'seeder/productTypes'


const demoData = [
  {
    _id: 1,
    activate: true,
    name_en: "flow1",
    name_ar: "flow1",
    channel: "",
    customerTypes: null,
    productTypes: null,
    paymentMethods: null,
    shippingMethods: null,
    options: [
      {
        action_status: "new",
        description: "order placed by",
        customer_status: "new",
        is_cancelation: true,
        is_return: true,
        is_refund: true,
        is_pending: '',
      },
      {
        action_status: "new",
        description: "order placed by",
        customer_status: "new",
        is_cancelation: true,
        is_return: true,
        is_refund: true,
        is_pending: '',
      },
      {
        action_status: "new",
        description: "order placed by",
        customer_status: "new",
        is_cancelation: true,
        is_return: true,
        is_refund: true,
        is_pending: '',
      }
    ],
  }
];

const Index = (props) => {

  useEffect(() => {
    props.getPayments(demoData);
  }, []);

  useEffect(() => {
    props.getCustomerTypes();
  }, []);

  useEffect(() => {
    props.getCatalogs();
  }, []);


  const deleteCarrier = (carrierId) => {
    try {
      let response = props.deletePayment(carrierId);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editCarrier = (carrierId, input) => {
    try {
      let response = props.editPayment(JSON.stringify(input), carrierId);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log(props.payments);
  return (
    <div>
      {props.payments && (
        <View
          {...props}
          deleteCarrier={deleteCarrier}
          editCarrier={editCarrier}
          productTypes={productTypes}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  payments: state.payments.items,
  customerTypes: state.customerTypes.items,
  catalogs: state.catalogs.items,
});

export default connect(mapStateToProps, {
  getPayments,
  deletePayment,
  editPayment,
  getCustomerTypes,
  getCatalogs,
})(Index);
