import React, { Component } from "react";
import _ from "lodash";
import View from "./View";
import { Col, Row } from "reactstrap";

const Index = (props) => {
  const onRemoveOption = (option, index) => {
    console.log("Remove", index);
    let options = props.data.options.slice();
    options.splice(index, 1);
    props.data.options = options;
    props.updateState({ [props.targetName]: props.target });
  };

  const onLoadOptions = (loadOptions, action) => {
    let options;
    if (action === "replace") options = loadOptions.slice();
    else {
      options = props.options.slice();
      options.push(...loadOptions);
    }
    if (!_.isEmpty(props.data) && !_.isEmpty(props.data.option))
      props.updateState({ option: options });
    else props.updateState({ options });
  };

  const onClickAddOption = (e) => {
    e.preventDefault();
    let options = props.options.slice();
    options = [...options, props.newOptions];
    //
    props.targetName === "carriers"? props.data[props.targetOptionName] = options : props.data.options = options;

    props.updateState({ [props.targetName]: props.target });
  };

  const onClearOption = (e) => {
    e.preventDefault();
    let options = props.options.slice();
    options = [
      {
        value: "",
        label: "",
        arabic_label: "",
        is_default: true,
      },
    ];
    if (!_.isEmpty(props.data) && !_.isEmpty(props.data.option))
      props.updateState({ option: options });
    else props.updateState({ options });
  };

  return (
    <div>
      <div className="options">
        <Row className="d-flex">
          <Col sm="12" className="d-flex  justify-content-evenly">
            {props.headers.map((header, index) => (
              <label>{header}</label>
            ))}
          </Col>
        </Row>
      </div>
      {props.options.map((option, index) => {
        return (
          <View
            {...props}
            option={option}
            index={index}
            onRemoveOption={onRemoveOption}
            onClickAddOption={onClickAddOption}
            key={index.toString()}
          />
        );
      })}
      {props.hasAddOption && (
        // <Actions
        //   onClearOption={onClearOption}
        //   onClickAddOption={onClickAddOption}
        //   onLoadOptions={onLoadOptions}

        // />
        <div>
          <hr></hr>
          <Col md="8" className="d-flex justify-content-evenly">
            <div onClick={onClickAddOption} className="option cta col-xs-4">
              <i className="material-icons m-r-5">add</i>
              <a href="#">{props.addMore}</a>
            </div>
          </Col>
        </div>
      )}
    </div>
  );
};

export default Index;
