import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "components/general/Modal";
import FormComponent from "components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {
    const { onChange, data, modal, toggle, errors, catalogs } = props;

    return (
        <>
            <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
                {"Add New"}
            </Button>
            <ModalComponent title="Add New Map" toggle={toggle} isOpen={modal}>
                <Form onSubmit={props.onSumbit}>
                    <FormComponent
                        type="text"
                        name="name_en"
                        label="Map English Name"
                        value={data.name_en}
                        updateState={onChange}
                        error={_.get(errors, "nameError")}
                    />
                    {!_.isEmpty(_.get(errors, "nameError")) && (
                        <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                    )}

                    <FormComponent
                        type="text"
                        name="name_ar"
                        label="Map Arabic Name"
                        value={data.name_ar}
                        updateState={onChange}
                        error={_.get(errors, "nameError")}
                    />
                    {!_.isEmpty(_.get(errors, "nameError")) && (
                        <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                    )}

                    <FormComponent
                        type="groupCheckbox"
                        options={_.map(catalogs, catalog => ({ label: _.get(catalog, 'name_en'), value: _.get(catalog, '_id') }))}
                        name="channel"
                        label="Channel"
                        value={data.channel}
                        valueKey="value"
                        labelKey="label"
                        updateState={onChange}
                    />

                    <FormComponent
                        type="text"
                        name="google_api_key"
                        label="Google Api Key"
                        value={data.google_api_key}
                        updateState={onChange}
                        error={_.get(errors, "nameError")}
                    />

                    <ModalFooter>
                        <Button
                            onClick={props.onValidate}
                            className="btn btn-primary btn-cons btn-cons"
                        >
                            {"Create"}
                        </Button>
                        <Button className="btn btn-white btn-cons" onClick={toggle}>
                            {"Cancel"}
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalComponent>
        </>
    );
};
export default View;
