//import React, { useState, useEffect, useContext } from "react";
import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addCustomer } from "redux/actions/CustomerAction";
import { getAttributeBy } from "redux/actions/Ecommerce/AttributeAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import { business_id, graphqlUrl, AddCustomerUrl } from "context/url";
import useFetchStatus from "fetchApi/useFetch";
import fetchApi from "fetchApi/fetchData";
import _ from "lodash";
import { getCustomerTypes } from "redux/actions/settings/customerTypeAction";

class index extends Component {
  state = this.initialState;
  componentWillMount() {
    const business_id = `business_id: "5cc2f00577ebc157b4f1c64c"`;

    this.props.getAttributeBy("inner_name", "gender");
    this.props.getCustomerTypes();
  }

  onChange = (input) => {
    this.setState(input);
  };

  get initialState() {
    return {
      business_id: "5cc2f00577ebc157b4f1c64c",
      name: "",
      email: "",
      phone: "",
      birth_date: "",
      gender: "",
      customer_type: null,
      phone_opt_in: true,
      email_opt_in: true,
      errors: {
        nameError: "",
        emailError: "",
        phoneError: "",
      },
      modal: false,
    };
  }

  reset() {
    this.setState(this.initialState);
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
      emailError: "",
      phoneError: "",
    };
    let inputs = this.state;
    console.log(inputs);

    if (inputs.name === "") {
      errors.nameError = "Contact name is required !";
    }

    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRex.test(inputs.email)) {
      errors.emailError = "Invalid Email Address!";
    }

    if (inputs.phone.length < 10) {
      errors.phoneError = "please enter valid phone number";
    }

    if (
      errors.nameError === "" &&
      errors.emailError === "" &&
      errors.phoneError === ""
    ) {
      this.addContact(e);
    } else {
      //setErrors(errors)
      this.setState({ errors: errors });
    }
  };

  addContact = (e) => {
    e.preventDefault();
    let customer = `{
    "business_id": "${this.state.business_id}",
    "name": "${this.state.name}",
    "email": "${this.state.email}",
    "phone": "${this.state.phone}",
    "birth_date":"${this.state.birth_date}",
    "customer_type": "${this.state.customer_type}",
    "gender":"${this.state.gender}",
    "phone_opt_in": ${this.state.phone_opt_in},
    "email_opt_in": ${this.state.email_opt_in}
    }`;

    console.log(customer);
    try {
      let response = this.props.addCustomer(customer);

      SuccessAlert("success", "Sucessfully Added");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    this.reset();
    this.toggle();
  };

  render() {
    return (
      <View
        customerTypes={this.props.customerTypes}
        data={this.state}
        onChange={this.onChange}
        onSumbit={this.addContact}
        genderOptions={this.props.attribute}
        onValidate={this.onValidate}
        errors={this.state.errors}
        modal={this.state.modal}
        toggle={this.toggle}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  attribute: state.ecommerceAttributes.item,
  customerTypes: state.customerTypes.items,
});

export default connect(mapStateToProps, {
  getAttributeBy,
  addCustomer,
  getCustomerTypes,
})(index);
