import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

const ModalComponent = props => {
  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} size={props.size}>
        <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
        <ModalBody>{props.children}</ModalBody>
      </Modal>
    </div>
  )
}
export default ModalComponent
