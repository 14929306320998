import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Form, ModalFooter } from "reactstrap";
const Index = (props) => {
  return (
    <Button className="btn btn-primary btn-cons btn-cons" onClick="">
      {"Create New"}
    </Button>
  );
};

export default Index;
