import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getCustomerTypes } from "redux/actions/settings/customerTypeAction";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import {
  getCarts,
  deleteCart,
  editCart,
} from "redux/actions/settings/Order/cart";

import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/SuccessAlert";
import View from "./View";

const Index = (props) => {
  useEffect(() => {
    props.getCustomerTypes();
  }, []);

  useEffect(() => {
    props.getCatalogs();
  }, []);

  useEffect(() => {
    props.getCarts();
  }, []);

  //   const useForceUpdate = () => {
  //     const [value, setValue] = useState(0); // integer state
  //     return () => setValue(value => value + 1); // update the state to force render
  // }

  const deleteCart = (cartId) => {
    try {
      /** TODO: HERE WILL BE THE LINK WITH API */
      props.deleteCart(cartId);
      SuccessAlert("success", "Sucessfully deleted");
      // const forceUpdate = toUpdate();
      // this.props.history.push("/");
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const editCart = (cartId, input) => {
    try {
      /** TODO: HERE WILL BE THE LINK WITH API */
      console.log(input);
      props.editCart(JSON.stringify(input));
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log(props.carts);
  return (
    <div>
      {props.carts && (
        <View {...props} deleteCart={deleteCart} editCart={editCart} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerTypes: state.customerTypes.items,
  catalogs: state.catalogs.items,
  carts: state.carts.items,
});

export default connect(mapStateToProps, {
  getCustomerTypes,
  getCatalogs,
  getCarts,
  deleteCart,
  editCart,
})(Index);
