import React, { Component } from "react";
import _ from "lodash";
import View from "./View";
import Actions from "./Actions";

const Index = (props) => {
  const onRemoveOption = (option, index) => {
    console.log("Remove", index);
    let options = props.options.slice();
    options.splice(index, 1);
    if (!_.isEmpty(props.data) && !_.isEmpty(props.data.option))
      props.updateState({ option: options })
    else
      props.updateState({ options })
  };

  const onLoadOptions = (loadOptions, action) => {
    let options;
    if (action === "replace"){
      options = _.uniqBy(loadOptions.slice(),'value');
    }
    else {
      options = props.options.slice();
      _.forEach(loadOptions, lo => {
        if(!_.find(options, o => o.value === lo.value))
          options.push(lo);
      })
      
    }
    if (!_.isEmpty(props.data) && !_.isEmpty(props.data.option))
      props.updateState({ option: options })
    else
      props.updateState({ option:options })
  };

  const onClickAddOption = (e) => {
    e.preventDefault();
    let options = props.options.slice();
    options = [
      ...options,
      {
        value: "",
        label: "",
        arabic_label: "",
        is_default: false,
      },
    ];
    if (!_.isEmpty(props.data) && !_.isEmpty(props.data.option))
      props.updateState({ option: options })
    else
      props.updateState({ option:options })
  };

  const onClearOption = (e) => {
    e.preventDefault();
    let options = props.options.slice();
    options = [
      {
        value: "",
        label: "",
        arabic_label: "",
        is_default: true,
      },
    ];
    if (!_.isEmpty(props.data) && !_.isEmpty(props.data.option))
      props.updateState({ option: options })
    else
      props.updateState({ options })
  };

  return (
    <div>
      {props.options.map((option, index) => {
        return (
          <View
            {...props}
            option={option}
            index={index}
            onRemoveOption={onRemoveOption}
            onClickAddOption={onClickAddOption}
            key={index.toString()}
          />
        );
      })}
      {props.hasAddOption && (
        <Actions
          onClearOption={onClearOption}
          onClickAddOption={onClickAddOption}
          onLoadOptions={onLoadOptions}
          handleDownloadExcel={props.handleDownloadExcel}
        />
      )}
    </div>
  );
};

export default Index;
