import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Table from "../../../../components/general/Table";
import Card from "../../../../components/general/Card";
import HeaderActions from "../../../../components/general/HeaderBtns";
import Collapse from "components/general/Collapse";
import Delete from "components/general/Delete";
import _, { method } from "lodash";
import { addCountry } from "redux/actions/Ecommerce/settings/countryAction";
import AddCountry from "./Add";

import FormComponent from "components/general/FormControllerList";
import { Alert } from "reactstrap";
import index from "views/CRM/ContactProfile/ContactDetails/general-info";
import Options from "../Options";

// FIXME: Status has issues
const View = (props) => {
  let { deletePickup, editPickup, pickups, catalogs, coverageAreaDemo } = props;
  console.log(props);

  let [state, setPickups] = useState(pickups);
  let [errors, setError] = useState({
    nameError: "",
  });

  const updateState = (input) => {
    console.log("Input", input);
    setPickups({ ...state, ...input });
  };

  console.log("pickups", pickups);

  return (
    <div className="setting">
      <Card name="Pickup Setting" goBack={props.history.goBack}>
        <div className="contact-table">
          {/* Add Country */}
          <div className="d-flex justify-content-end">
            <AddCountry {...props} />
          </div>

          <Col xs="12" lg="8" xl="9" className="group-list">
            {_.map(pickups, (data, key) => {
              // List of Attrbiute
              return (
                <Collapse
                  group={{
                    label: `${data.nameEN}`,
                    id: key,
                    open: false,
                  }}
                >
                  {/* Form */}
                  <FormComponent
                    type="switch"
                    id={`activate_${key}`}
                    name="isActive"
                    label="Activate"
                    target={pickups[key]}
                    targetName="pickups"
                    value={data.isActive}
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  <FormComponent
                    type="text"
                    name="nameEN"
                    label="English Name"
                    target={pickups[key]}
                    targetName="pickups"
                    value={data.nameEN}
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  {!_.isEmpty(_.get(errors, "nameError")) && (
                    <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                  )}
                  <FormComponent
                    type="text"
                    name="nameAR"
                    label="Arabic Name"
                    value={data.nameAR}
                    target={pickups[key]}
                    targetName="pickups"
                    updateState={updateState}
                    error={_.get(errors, "nameError")}
                  />
                  {!_.isEmpty(_.get(errors, "nameError")) && (
                    <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                  )}
                  <FormComponent
                    type="select"
                    options={_.map(catalogs, (catalog) => ({
                      label: _.get(catalog, "name_en"),
                      value: _.get(catalog, "_id"),
                    }))}
                    name="channels"
                    label="Channel"
                    value={data.channels}
                    target={pickups[key]}
                    targetName="pickups"
                    valueKey="value"
                    labelKey="label"
                    updateState={updateState}
                  />
                    <FormComponent
                    type="select"
                    options={coverageAreaDemo}
                    name="coverageArea"
                    label="Coverage Area"
                    value={data.coverageArea}
                    target={pickups[key]}
                    targetName="pickups"
                    valueKey="value"
                    labelKey="label"
                    updateState={updateState}
                  />
                    <FormComponent
                    type="integer"
                    name="maximumOptions"
                    label="Maximum Options"
                    value={data.maximumOptions}
                    target={pickups[key]}
                    targetName="pickups"
                    updateState={updateState}
                  />
                  
                     
                  
                 
                  
                 
                  {/* Language Actions */}
                  <div className="row justify-content-end">
                    <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                      <Button
                        className="btn btn-primary btn-cons btn-cons"
                        onClick={() =>
                          editPickup(pickups[key]._id, pickups[key])
                        }
                      >
                        {"Save"}
                      </Button>

                      <Delete
                        data={data.nameEN}
                        onSubmit={deletePickup}
                        customerID={pickups[key]._id}
                        actionType="button"
                        buttonStyle="btn btn-white btn-cons"
                      />
                    </div>
                  </div>
                </Collapse>
              );
            })}
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default View;
