export const maximumShippingOptions = [
  {
    value: 1,
    label: "1 Shipment",
  },
  {
    value: 2,
    label: "2 shipments",
  },
  {
    value: 3,
    label: "3 shipments",
  },
  {
    value: 4,
    label: "4 shipments",
  },
  {
    value: 5,
    label: "5 shipments",
  },
];
