import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import {
  bulkUploadFile,
  bulkProcessImportData,
  bulkGetImportsData,
} from "redux/actions/Ecommerce/BulkUpload";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import View from "./View";

import _ from "lodash";
const business_id = "5cc2f00577ebc157b4f1c64c";

const Index = (props) => {
  useEffect(() => {
    props.bulkGetImportsData();
  }, []);
  /**
   * This handler to reset steps active state,
   * and then set the step in the argument
   */

  return (
    <>
      <View {...props} />
    </>
  );
};

const mapStateToProps = (state) => (
  console.log("states=>", state),
  {
    uploadedFile: state.bulkUpload.item,
    bulkProgressData: state.bulkUpload.progress_data,
    bulkImportData: state.bulkUpload.items,
  }
);
export default connect(mapStateToProps, {
  bulkUploadFile,
  bulkProcessImportData,
  bulkGetImportsData,
})(Index);
