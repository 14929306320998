import React from "react";
import ModalComponent from "../../../../../components/general/Modal";
import { Button, Form, ModalFooter } from "reactstrap";
const View = (props) => {
  const { modal, toggle, attr } = props;
  return (
    <div>
      <ModalComponent
        title="Delete Attribute"
        toggle={() => toggle(attr)}
        isOpen={modal}
      >
        <Form onSubmit={props.onSumbit}>
          <div className="text-center">
            {`Are you sure you want to remove ${attr.label} Attribute ?`}
          </div>
          <ModalFooter>
            <Button
              type="submit"
              className="btn btn-default"
              onClick={() => toggle(attr)}
            >
              {"Delete"}
            </Button>
            <Button className="btn btn-secondary" onClick={() => toggle(attr)}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </div>
  );
};
export default View;
