import React, { Component } from "react";
import { FormGroup, Label, Input, CustomInput, FormFeedback } from "reactstrap";
import ReactSVG from "react-svg";

import Condition from "./Condition";
import Form from "../../FormControllerList";

const index = (props) => {
  const handleAddCondition = (e, type = null) => {
    let logic = props.logic;

    if (type === "child") {
      logic = {
        ...logic,
        logic_list: [...logic.logic_list],
      };
      if (!logic.logic_list.slice(-1).pop()["child"]) {
        logic.logic_list[logic.logic_list.length - 1].child = {
          logic_list: [],
          operator: "all",
        };
      }
      if (logic.logic_list.slice(-1).pop()["child"]) {
        //logic.logic_list.slice(-1).pop()["child"].empty = false;
        logic.logic_list
          .slice(-1)
          .pop()
          ["child"].logic_list.push({
            attribute: {
              system_name: "",
              inner_name: "",
              label: "",
              path: "",
              type: "",
              operator: [],
            },
            operator: "",
            value: "",
          });
      }
    } else {
      logic = {
        ...logic,
        logic_list: [
          ...logic.logic_list,
          {
            attribute: {
              system_name: "",
              inner_name: "",
              label: "",
              path: "",
              type: "",
              operator: [],
            },
            operator: "",
            value: "",
            child: {
              // empty: true,
              logic_list: [],
              operator: "all",
            },
          },
        ],
      };
    }
    let key = props.targetName? props.targetName : "logic"
    props.updateState({ [key] : logic });
  };

  const handleRemoveCondition = (index, childIndex = null) => {
    let logic = props.logic;

    if (childIndex == null) {
      logic.logic_list.splice(index, 1);
    } else if (typeof childIndex === "number" && childIndex > -1) {
      if (logic.logic_list[index].child.logic_list.length <= 1) {
        childIndex = "all";
      }

      logic.logic_list[index].child.logic_list.splice(childIndex, 1);
    }

    if (childIndex === "all") {
      // logic.logic_list[index].child.empty = true;
      logic.logic_list[index].child.logic_list = [];
    }

    props.updateState({ logic });
  };

  const handleSwitchOperator = (e, operator, index = null) => {
    e.preventDefault();
    let logic = props.logic;
    if (index != null) {
      logic.logic_list[index].child.operator = operator;
    } else {
      logic.operator = operator;
    }
    props.updateState({ logic });
  };

  const switcherLayout = (operator, index) => {
    console.log("switcherLayout");
    console.log(operator, index);
    let layout = (
      <div className="operator-switcher">
        <button
          onClick={(e) => handleSwitchOperator(e, "all", index)}
          className={operator === "all" ? "btn clicked" : "btn"}
        >
          {"And"}
        </button>
        <button
          onClick={(e) => handleSwitchOperator(e, "any", index)}
          className={operator === "any" ? "btn clicked" : "btn"}
        >
          {"Or"}
        </button>
      </div>
    );
    return layout;
  };

  return (
    <div className="segment-logic-wrapper steps-container">
      <div className="row segment-logic">
        <div className="logic-wrapper col-12">
          <p className="main-operator-container">
            <span>{"IF Contact Match"}</span>
            <Form
              {...props}
              type={"select"}
              options={[
                { label: "All", value: "all" },
                { label: "Any", value: "any" },
              ]}
              valueKey="value"
              labelKey="label"
              isClearable={false}
              isSearchable={false}
              value={props.logic.operator}
              name={"operator"}
              target={props.logic}
              targetName={props.targetName? props.targetName : "logic"}
              updateState={props.updateState}
            />
            <span>{"of The Following Conditions"}</span>
          </p>

          <div className="logic-list-container">
            {props.attributes &&
              props.logic.logic_list.map((condition, index) => {
                return (
                  <Condition
                    {...props}
                    condition={condition}
                    index={index}
                    handleRemoveCondition={handleRemoveCondition}
                    switcherLayout={switcherLayout}
                  />
                );
              })}
          </div>

          <div className="add-logic">
            <div className="add">
              <a onClick={handleAddCondition}>
                <ReactSVG src="/assets/img/svg/add_circle.svg" />
                {"Add Conditions"}
              </a>
            </div>
            <div className="add">
              <a onClick={(e) => handleAddCondition(e, "child")}>
                <ReactSVG src="/assets/img/svg/add_circle.svg" />
                {"Add Conditions Combination"}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
