import {
  ADD_CATALOG,
  EDIT_CATALOG,
  GET_CATALOGS,
  FIND_CATALOG,
  DELETE_CATALOG,
} from "../../actions/Ecommerce/types"

const initialState = {
  item: null,
  items: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CATALOG:
    case EDIT_CATALOG:
    case FIND_CATALOG:
    case DELETE_CATALOG:
      return {
        ...state,
        item: action.payload,
      }
    case GET_CATALOGS:
      return {
        ...state,
        items: action.payload,
      }
    default:
      return state
  }
}
