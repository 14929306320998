import React, { useState } from "react";
import ModalComponent from "../Modal";
import { Button, Form, ModalFooter } from "reactstrap";

export default ({
  data,
  onSubmit,
  actionType,
  customerID,
  linkTitle,
  buttonStyle,
  fromDetails = false,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDelete = () => setDeleteModal(!deleteModal);

  return (
    <>
      {actionType === "button" ? (
        <Button
          color="secondary"
          style={{ width: "100%", marginRight: "10px" }}
          className={buttonStyle}
          onClick={toggleDelete}
        >
          {"Delete"}
        </Button>
      ) : actionType === "link" ? (
        <a onClick={toggleDelete} href="#" className="pointer">
          {linkTitle}
        </a>
      ) : (
        <span style={{ color: "inherit" }} onClick={toggleDelete}>
          <i className="fa fa-trash" />
        </span>
      )}
      <ModalComponent
        title="Delete Attribute"
        toggle={toggleDelete}
        isOpen={deleteModal}
      >
        <Form onSubmit={(e) => { e.preventDefault(); onSubmit(customerID)}}>
          <div className="text-center">
            {`Are you sure you want to remove ${data} ?`}
          </div>
          <ModalFooter>
            <Button
              type="submit"
              className="btn btn-default"
              onClick={toggleDelete}
            >
              {"Delete"}
            </Button>
            <Button className="btn btn-secondary" onClick={toggleDelete}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
