import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "components/general/Modal";
import FormComponent from "components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {

    const { onChange, data, modal, toggle, errors } = props;


    return (
        <>
            <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
                {"Create New"}
            </Button>
            <ModalComponent
                title="Create New Role"
                toggle={toggle}
                isOpen={modal}
            >
                <Form onSubmit={props.onSumbit}>
                    <FormComponent
                        type="text"
                        name="name_en"
                        label="English Name"
                        value={data.name_en}
                        updateState={onChange}
                        error={_.get(errors, "nameError")}
                    />
                    {!_.isEmpty(_.get(errors, "nameError")) && (
                        <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                    )}

                    <FormComponent
                        type="text"
                        name="name_ar"
                        label="Arabic Name"
                        value={data.name_ar}
                        updateState={onChange}
                        error={_.get(errors, "nameError")}
                    />
                    {!_.isEmpty(_.get(errors, "nameError")) && (
                        <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                    )}

                    <FormComponent
                        type="text"
                        name="description_en"
                        label="Description English"
                        value={data.description_en}
                        updateState={onChange}
                    />
                     <FormComponent
                        type="text"
                        name="description_ar"
                        label="Description Arabic"
                        value={data.description_ar}
                        updateState={onChange}
                    />

                    <FormComponent
                        type="groupCheckbox"
                        options={_.map(props.permissions, permission => ({ label: _.get(permission, 'name'), value: _.get(permission, '_id') }))}
                        name="permissions"
                        label="Role Permissions"
                        value={data.permissions}
                        valueKey="value"
                        labelKey="label"
                        updateState={onChange}
                    />

                    <ModalFooter>
                        <Button
                            onClick={props.onValidate}
                            className="btn btn-primary btn-cons btn-cons"
                        >
                            {"Create"}
                        </Button>
                        <Button className="btn btn-white btn-cons" onClick={toggle}>
                            {"Cancel"}
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalComponent>
        </>
    );
};
export default View;
