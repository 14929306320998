import {
  ADD_DELIVERY,
  EDIT_DELIVERY,
  DELETE_DELIVERY,
  GET_DELIVERYS,
  FIND_DELIVERY,
} from "../../../actions/settings/types";

const initialState = {
  item: null,
  items: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_DELIVERY:
    case FIND_DELIVERY:
    case DELETE_DELIVERY:
      return {
        ...state,
        item: action.payload,
      };
    case GET_DELIVERYS:
      return {
        ...state,
        items: action.payload,
      };
    case ADD_DELIVERY:
      return {
        ...state,
        item: action.payload,
        items: [...state.items, action.payload],
      };
    default:
      return state;
  }
}
