import React from 'react';
import * as XLSX from 'xlsx';
//import '@fortawesome/fontawesome-free/css/all.min.css';
import DownloadCloud from "../import-export/assets/DownloadCloud"


const ExportButton = ({ data, fileName, style }) => {
    console.log({data, fileName})
  const handleExport = (e) => {
    e.preventDefault();
    // Convert JSON data to worksheet
    const ws = XLSX.utils.json_to_sheet(data);
    
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and download it
    XLSX.writeFile(wb, fileName);
  };

  if(style == true) 
  return (<div onClick={handleExport} className="option cta col-xs-4">
    <i className="material-icons m-r-5">file_download</i>
    <a href="#">{"Export Excel"}</a>
  </div>) 
  else
  return (
    <button onClick={handleExport} style={{ display: 'flex', alignItems: 'center' }}>
      {/* <i className="fas fa-file-export" style={{ marginRight: '8px' }}></i> */}
      <DownloadCloud className="type-step-box-icon" width={20} height={20} />
      Export to Excel
    </button>
  );
};

export default ExportButton;