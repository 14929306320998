import {
  ADD_CONFIG_ATTRIBUTE,
  EDIT_CONFIG_ATTRIBUTE,
  EDIT_CONFIG_ATTRIBUTES,
  GET_CONFIG_ATTRIBUTES,
  FIND_CONFIG_ATTRIBUTE,
  DELETE_CONFIG_ATTRIBUTE,
} from "./types";
import  fetchAPIClass  from "../fetchAPI";
import { restfulUrl , API_URL} from "../../../context/url";

export const getconfigAttributes = () => (dispatch) => {
  console.log("inside get Attribute E");
  let url = `${API_URL}/ecommerce/attribute/configurable/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_CONFIG_ATTRIBUTES);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getAttribute = (attributeId) => (dispatch) => {
  let url = `${API_URL}//ecommerce/attribute/configurable/find/${attributeId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_CONFIG_ATTRIBUTE);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addAttribute = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/attribute/configurable/add`;
  console.log("InSide Add attribute Action", requestBody);
  try {
    const api = new fetchAPIClass(
      url,
      "POST",
      requestBody,
      ADD_CONFIG_ATTRIBUTE
    );
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const editAttribute = (inputs, attributeId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/attribute/configurable/edit/${attributeId}`;
  console.log("InSide Add attribute Action", requestBody, "id", attributeId);
  try {
    const api = new fetchAPIClass(
      url,
      "PUT",
      requestBody,
      EDIT_CONFIG_ATTRIBUTE
    );
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};


export const editConfigProductAttributes = (inputs,productId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/attribute/configurable/list/edit/${productId}`;
  console.log("InSide Add attribute Action", requestBody);
  try {
    const api = new fetchAPIClass(
      url,
      "PUT",
      requestBody,
      EDIT_CONFIG_ATTRIBUTES
    );
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};


export const deleteAttribute = (attributeId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/attribute/configurable/delete/${attributeId}`;
  console.log(url);
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_CONFIG_ATTRIBUTE);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};
