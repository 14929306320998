import {
  ADD_CART,
  EDIT_CART,
  DELETE_CART,
  GET_CARTS,
  FIND_CART,
} from "../../../actions/settings/types";

const initialState = {
  item: null,
  items: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_CART:
    case FIND_CART:
      return {
        ...state,
        item: action.payload,
        items: [...state.items],
      };
    case DELETE_CART:
      return {
        items: [...state.items.filter((item) => item !== action.payload)],
      };
    case GET_CARTS:
      return {
        ...state,
        items: action.payload,
      };
    case ADD_CART:
      return {
        ...state,
        item: action.payload,
        items: [...state.items, action.payload],
      };
    default:
      return state;
  }
}
