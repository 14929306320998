import {
    GET_CUSTOMER_ATTRIBUTES,
    ADD_CUSTOMER_ATTRIBUTES,
    EDIT_CUSTOMER_ATTRIBUTES,
    FIND_CUSTOMER_ATTRIBUTE,
    DELETE_CUSTOMER_ATTRIBUTE
  } from "../actions/types"
  
  const initialState = {
    items: null,
    item: {}
  }
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case EDIT_CUSTOMER_ATTRIBUTES:
      case ADD_CUSTOMER_ATTRIBUTES:
      case FIND_CUSTOMER_ATTRIBUTE:
      case DELETE_CUSTOMER_ATTRIBUTE:
        return {
          ...state,
          item: action.payload
        }
      case GET_CUSTOMER_ATTRIBUTES:
        return {
          ...state,
          items: action.payload
        }
      default:
        return state
    }
  }
  