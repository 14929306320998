import React, { PropTypes, useState } from "react";
import product from "redux/reducers/Ecommerce/product";
import View from "./View";
const Index = (props) => {
  const { variants } = props;
  const [isApplyMediaToAll, setApplyMediaToAll] = useState(true);

  const updateMedia = (input, index) => {
    let products = variants.slice();
    products[index].media = input.media;
    props.updateVariants(products);
  };

  const updateIsApplyToAll = (input) => {
    console.log("update Applu Media to all ", input);
    setApplyMediaToAll(input.isApplyMediaToAll);
  };

  const updateUniversalMedia = (input, index) => {
    let products = variants.slice();
    products.map((product) => (product.media = input.media));
    props.updateVariants(products);
  };

  return (
    <View
      {...props}
      updateMedia={updateMedia}
      updateUniversalMedia={updateUniversalMedia}
      isApplyMediaToAll={isApplyMediaToAll}
      updateIsApplyToAll={updateIsApplyToAll}
    />
  );
};

Index.propTypes = {};

export default Index;
