import React from "react"
import _ from "lodash"

const HeaderBtns = (props) => {
    const { createAction, importAction, ActionList } = props
    return (
        <div className="header-actions d-flex">
            {props.children}
           { createAction &&
            <button className="btn btn-primary btn-cons btn-cons" onClick={createAction.onClick}> {createAction.title} </button> }
            <button type="button" className="btn btn-white btn-cons" onClick={importAction.onClick}>{importAction.title}</button>
            {/* Action Btn */}
            <div className="btn-group">
                <span className="btn btn-white dropdown-toggle btn-cons" data-toggle="dropdown">{ActionList.title}<span className="caret" /></span>
                <ul className="dropdown-menu">
                    <li>
                        <a href="#">{ActionList.list}</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default HeaderBtns
