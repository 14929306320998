import React, { useState } from "react";
import { Button, Row } from "reactstrap";
//import ImagePicker from "react-image-picker";
//import "react-image-picker/dist/index.css";

const Gallary = (props) => {
  const { mediaData } = props;
  const [files, setFiles] = useState([]);
  const { urlPath } = props;
  /**
   * For update state of files
   * @param {*} pics
   */
  const handleSelectMultipe = (pics) => {
    console.log("pics", pics);
    pics = pics.map((pic) => {
      return "upload/" + pic.value;
    });
    setFiles(pics);
  };

  const handleSelect = (pic) => {
    console.log("pics", pic);
    pic = "upload/" + pic.value;
    setFiles(pic);
  };

  /**
   * Update the state of parent component
   * @returns void
   */
  const submit = () => {
    let media = props.data[props.name].slice();
    media = media.concat(files);
    props.updateState({ [props.name]: media }, props.index);

    props.toggle();
  };

  /**
   * Update the state of parent component
   * @returns void
   */
  const submitObject = () => {
    let media = props.target;
    media[props.srcKey] = files;
    props.updateState({ [props.name]: props.data[props.name] });

    props.toggle();
  };

  return (
    <div>
      <Row>
        {props.mediaList && (
          {/*<ImagePicker
            images={props.mediaList.map((image, i) => ({
              src: urlPath + image,
              value: image,
            }))}
            onPick={props.multiple ? handleSelectMultipe : handleSelect}
            multiple={props.multiple}
          />*/}
          )}
      </Row>
      <Button
        type="submit"
        className="btn btn-default"
        onClick={props.listOfObject ? submitObject : submit}
      >
        {"Load"}
      </Button>
    </div>
  );
};

export default Gallary;
