import React from "react";
const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#fff"
    viewBox="0 0 16 16"
  >
    <path
      d="M13.854 3.646a.5.5 0 0 0-.708-.708L6 10.293 2.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7z"
      strokeWidth="2"
    />
  </svg>
);
export default CheckIcon;
