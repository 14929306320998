import React, { useEffect, useState } from "react";
import { Col, Button } from "reactstrap";
import Card from "../../../../components/general/Card";
import Collapse from "components/general/Collapse";
import Delete from "components/general/Delete";
import AddOrderFlow from "./Add";
import _ from "lodash"
import FormComponent from "components/general/FormControllerList";
import { Alert } from "reactstrap";
import Options from "../../Order/Options";

// FIXME: Status has issues
const View = (props) => {
    let { deleteCarrier, editCarrier, payments, catalogs, customerTypes, productTypes } = props;
    //console.log(props);

    let [state, setPayments] = useState(payments);
    let [errors, setError] = useState({
        nameError: "",
    });

    let optionsHeaders = [
        "Actions Status",
        "Description",
        "Customer Status",
        "Cancelation",
        "Return",
        "Refund",
        "Pending"
    ]
    let emptyRowOptions = {
        action_status: "",
        description: "",
        customer_status: "",
        is_cancelation: false,
        is_return: false,
        is_refund: false,
        is_pending: '',
    }

    const updateState = (input) => {
        console.log("Input", input);
        setPayments({ ...state, ...input });
    };

    console.log("Carriers", payments);

    return (
        <div className="setting">
            <Card name="Delivery Setting" goBack={props.history.goBack}>
                <div className="contact-table">
                    {/* Add Carrier */}
                    <div className="d-flex justify-content-end">
                        <AddOrderFlow  {...props} />
                    </div>

                    <Col xs="12" lg="8" xl="9" className="group-list">
                        {_.map(payments, (data, key) => {
                            // List of Attrbiute
                            let attributeList = [];
                            attributeList = [
                                {
                                    name: "action_status",
                                    type: "select",
                                    options: [{ label: 'New', value: 'new' }],
                                    target: "options",
                                    targetName: "payments",
                                    valueKey: "value",
                                    labelKey: "label",
                                },
                                {
                                    name: "description",
                                    type: "text",
                                    target: "options",
                                    targetName: "payments",
                                },
                                {
                                    name: "customer_status",
                                    type: "select",
                                    options: [{ label: 'New', value: 'new' }],
                                    target: "options",
                                    targetName: "payments",
                                    valueKey: "value",
                                    labelKey: "label",
                                },
                                {
                                    name: "is_cancelation",
                                    type: "switch",
                                    id:`${key}_is_cancelation`,
                                    target: "options",
                                    targetName: "payments",
                                },
                                {
                                    name: "is_return",
                                    type: "switch",
                                    id:`${key}is_return`,
                                    target: "options",
                                    targetName: "payments",
                                },
                                {
                                    name: "is_refund",
                                    type: "switch",
                                    id:`${key}is_refund`,
                                    target: "options",
                                    targetName: "payments",
                                },
                                {
                                    name: "is_pending",
                                    type: "text",
                                    target: "options",
                                    targetName: "payments",
                                },
                            ]
        
                            return (
                                <Collapse
                                    group={{
                                        label: `${data.name_en}`,
                                        id: key,
                                        open: false,
                                    }}
                                >
                                    {/* Form */}
                                    <FormComponent
                                        type="switch"
                                        id={`activate_${key}`}
                                        name="activate"
                                        label="Activate"
                                        target={payments[key]}
                                        targetName="payments"
                                        value={data.activate}
                                        updateState={updateState}
                                        error={_.get(errors, "nameError")}
                                    />
                                    <FormComponent
                                        type="text"
                                        name="name_en"
                                        label="English Name"
                                        target={payments[key]}
                                        targetName="payments"
                                        value={data.name_en}
                                        updateState={updateState}
                                        error={_.get(errors, "nameError")}
                                    />
                                    {!_.isEmpty(_.get(errors, "nameError")) && (
                                        <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                                    )}
                                    <FormComponent
                                        type="text"
                                        name="name_ar"
                                        label="Arabic Name"
                                        value={data.name_ar}
                                        target={payments[key]}
                                        targetName="payments"
                                        updateState={updateState}
                                        error={_.get(errors, "nameError")}
                                    />
                                    {!_.isEmpty(_.get(errors, "nameError")) && (
                                        <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
                                    )}
                                    <FormComponent
                                        type="select"
                                        options={_.map(catalogs, catalog => ({ label: _.get(catalog, 'name_en'), value: _.get(catalog, '_id') }))}
                                        name="channel"
                                        label="Channel"
                                        value={data.channel}
                                        target={payments[key]}
                                        targetName="payments"
                                        valueKey="value"
                                        labelKey="label"
                                        updateState={updateState}
                                    />
                                    <FormComponent
                                        type="groupCheckbox"
                                        options={_.map(customerTypes, type => ({ label: _.get(type, 'name_en'), value: _.get(type, '_id') }))}
                                        name="customerTypes"
                                        label="Customer Type"
                                        value={data.customerTypes}
                                        target={payments[key]}
                                        targetName="payments"
                                        valueKey="value"
                                        labelKey="label"
                                        updateState={updateState}
                                    />
                                    <FormComponent
                                        type="groupCheckbox"
                                        options={productTypes}
                                        name="productTypes"
                                        label="Product Type"
                                        value={data.productTypes}
                                        target={payments[key]}
                                        targetName="payments"
                                        valueKey="value"
                                        labelKey="label"
                                        updateState={updateState}
                                    />
                                    <FormComponent
                                        type="groupCheckbox"
                                        options={[]}
                                        name="paymentMethods"
                                        label="Payment Method"
                                        value={data.paymentMethods}
                                        target={payments[key]}
                                        targetName="payments"
                                        valueKey="value"
                                        labelKey="label"
                                        updateState={updateState}
                                    />
                                    <FormComponent
                                        type="groupCheckbox"
                                        options={[]}
                                        name="shippingMethods"
                                        label="Shipping Method"
                                        value={data.shippingMethods}
                                        target={payments[key]}
                                        targetName="payments"
                                        valueKey="value"
                                        labelKey="label"
                                        updateState={updateState}
                                    />
                                    <Collapse
                                        group={{
                                            label: "Order Flow / Status Setting",
                                            id: `"data"${key}`,
                                            open: false,
                                        }}
                                    >
                                        <Options
                                            attributeList={attributeList}
                                            {...props}
                                            data={data}
                                            target={payments}
                                            targetName={"payments"}
                                            options={data.options}
                                            updateState={updateState}
                                            hasAddOption={true}
                                            headers={optionsHeaders}
                                            newOptions={emptyRowOptions}
                                            addMore={"Add New Action Flow"}
                                        />
                                    </Collapse>

                                    {/* Language Actions */}
                                    <div className="row justify-content-end">
                                        <div className="m-t-25 justify-content-end d-flex align-items-center col-12 col-sm-4">
                                            <Button
                                                className="btn btn-primary btn-cons btn-cons"
                                                onClick={() =>
                                                    // editCarrier(payments[key]._id, payments[key])
                                                    console.log()
                                                }
                                            >
                                                {"Save"}
                                            </Button>

                                            <Delete
                                                data={data.name_en}
                                                onSubmit={deleteCarrier}
                                                // customerID={payments[key]._id}

                                                actionType="button"
                                                buttonStyle="btn btn-white btn-cons"
                                            />
                                        </div>
                                    </div>
                                </Collapse>
                            );
                        })}
                    </Col>
                </div>
            </Card>
        </div>
    );
};

export default View;
